import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  //Lista
  drums: null,

  search: null,

  /* Select drums lot */
  drums_lot: null,
  drums_homogenize: null,
  drums_merms: null,
  drums_fractional: null
}

export const drumSlice = createSlice({
  name: "drums",
  initialState: initialState,
  reducers: {
    setDrums: (state, action) => {
      state.drums = action.payload
    },
    setDrums_homogenize: (state, action) => {
      state.drums_homogenize = action.payload
    },
    setDrums_fractional: (state, action) => {
      state.drums_fractional = action.payload
    },
    setSelectDrums: (state, action) => {
      state.drums_lot = action.payload
    },
    setDrums_merms: (state, action) => {
      state.drums_merms = action.payload
    },
  },
});


//Async functions
export const fetchDrums = (params) => (dispatch) => {
  clientAxios
    .get(params.url, {params, signal: params.abortController.signal})
    .then((result) => {
      return dispatch(setDrums(result.data));
    })
    .catch((error) => {
      
      if (!params.abortController.signal.aborted) {
        console.error('Error:', error.message);
      }

      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
  
};

export const fetchDrumsFractional = (params) => (dispatch) => {
  clientAxios
  .get(params.url, {params})
  .then((result) => {
    dispatch(setDrums_fractional(result.data));
  })
  .catch((error) => {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  });
}

export const fetchDrumsHomogenize = (params) => (dispatch) => {
  clientAxios
  .get(params.url, {params})
  .then((result) => {
    switch (params.homogenize) {
      case 1:{
        return dispatch(setDrums_homogenize(result.data));
      }break;
      case 0:{
        return dispatch(setDrums_homogenize(result.data));
      } break;
    }
  })
  .catch((error) => {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  });
}

export const fetchDrumsMerms =  (params) => (dispatch) => {
  clientAxios
    .get(params.url, {params})
    .then((result) => {
      return dispatch(setDrums_merms(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const {setDrums, setSelectDrums, setDrums_homogenize, setDrums_merms, setDrums_fractional} = drumSlice.actions;

export const drumState = (state) => state.drums;

export default drumSlice.reducer;