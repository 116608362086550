const SpinnerScreen = () => {
  const style = {
    position: "absolute",
    zIndex: "4",

    top: "0px",
    left: "0px",
  };

  return (
    <div
      className="modal fade show bg-dark bg-opacity-25 border-0 min-vh-100 h-100 modal-transition bg-modal-blur"
      style={{ display: "block", zIndex: 5 }}
      id="focus-modal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div
        className="w-100 min-vh-100 d-flex justify-content-center align-items-center bg-modal-blur"
        style={style}
      >
        <div className="d-flex justify-content-center align-items-center">
          <div class="spinner-grow me-1 bg-miel" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow me-1 bg-miel" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow me-1 bg-miel" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpinnerScreen;
