import { useState } from "react";
import { useContext } from "react";
import Alerts from "../../../components/alerts/Alerts";
import BtnAccept from "../../../components/forms/BtnAccept";
import Input from "../../../components/forms/Input";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { fetchVehicles } from "../../../slices/vehicles/Index";
import { Validate } from "../../../utils/ValidateForms";

const ModalVehicles = ({ setSelect, select, setModal, dispatch }) => {
  /*********************************************** INITIAL STATE ***********************************************************/
  const context = useContext(authContext);
  const { values, forms, validations } = context;
  const { URL_CREATE_VEHICLE, URL_INDEX_VEHICLES } = values;
  const { FORM_VEHICLE } = forms;
  const { VALIDATION_VEHICLE } = validations;

  const [form, setForm] = useState(JSON.parse(JSON.stringify(FORM_VEHICLE)));

  const [validation, setValidation] = useState({ ...VALIDATION_VEHICLE });
  const [loading, setLoading] = useState(false);

  const initialState = {
    model: "",
    patent: "",
  };

  const { model, patent } = initialState;

  /**********************************************************************************************************************/

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "patente":
      case "modelo":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
    }
  };

  const onClick = async (e, value) => {
    e.preventDefault();
    switch (e.target.name) {
      case "close":
        {
          setSelect(null);
          setModal(false);
        }
        break;
      case "aceptar":
        {
          //Delete property
          const validation = Validate(form);
          setValidation(validation);
          //Validaciones
          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "201",
              type: "error",
            });
          }

          let formData = new FormData();
          formData.append("model", form.modelo[0]);
          formData.append("patent", form.patente[0]);

          const response = await ApiRest({
            url: URL_CREATE_VEHICLE,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Creando nuevo vehiculo.",
              code: "201",
              type: "success",
            });

            //Actualizar datos OC
            let params = {
              url: URL_INDEX_VEHICLES,
            };
            
            dispatch(fetchVehicles(params));

            //cerrar modal
            return setModal(false);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "guardar":
        {
        }
        break;
    }
  };

  return (
    <div
      className="modal fade show bg-dark bg-opacity-25  modal-transition bg-modal-blur"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 4 }}>
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div className="modal-header p-5">
            <h1 className="modal-title" id="nueva-ordenModalLabel">
              Nuevo vehiculo
            </h1>
          </div>
          <div className="modal-body p-5">
            <form>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Vehiculo"}
                    name={"vehiculo"}
                    type={"text"}
                    value={"Vehiculo"}
                    validate={{ msg: "", validate: false }}
                    disabled={true}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Modelo"}
                    name={"modelo"}
                    type={"text"}
                    value={
                      form.modelo[0] !== null
                        ? form.modelo[0]
                        : select
                        ? model
                        : form.modelo[0]
                    }
                    validate={validation.modelo}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Patente"}
                    name={"patente"}
                    type={"text"}
                    value={
                      form.patente[0] !== null
                        ? form.patente[0]
                        : select
                        ? patent
                        : form.patente[0]
                    }
                    validate={validation.patente}
                  />
                </div>

                <div className="col-md-12 d-flex justify-content-end align-items-end">
                  <div className="btn-group">
                    {select ? (
                      <BtnAccept
                        onClick={onClick}
                        name={"Guardar"}
                        value={"guardar"}
                        loading={loading}
                        loadingMsg={"Guardando..."}
                      />
                    ) : (
                      <BtnAccept
                        onClick={onClick}
                        name={"aceptar"}
                        value={"Aceptar"}
                        loading={loading}
                        loadingMsg={"Creando..."}
                      />
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalVehicles;
