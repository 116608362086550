import Input from "../../../components/forms/Input";
import InputSearch from "../../../components/forms/InputSearch";
import { fetchProviders } from "../../../slices/providers/Index";

const Filters = ({ params, setParams, dispatch, setModal, rol }) => {
  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":
        {
          if (e.target.value.trim() == "") {
            return dispatch(fetchProviders({ url: params.url }));
          }

          setParams({
            ...params,
            search: e.target.value,
          });
        }
        break;
    }
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "modalMasiveStore": {
        setModal.ModalMasiveStore(true);
      } break;
      case "showAccordion":
        {
          if (
            document
              .getElementById("collapse-filters")
              .classList.contains("show")
          ) {
            document.getElementById("btn-filters").classList.add("collapsed");
            document
              .getElementById("collapse-filters")
              .classList.remove("show");
          } else {
            document
              .getElementById("btn-filters")
              .classList.remove("collapsed");
            document.getElementById("collapse-filters").classList.add("show");
          }
        }
        break;
      case "search":
        {
          if (params.search.trim() == "") {
            return "";
          }

          return dispatch(fetchProviders(params));
        }
        break;
    }
  };

  return (
    <div className="">
      <div class="accordion mb-3" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <label className="accordion-button collapsed" id="btn-filters">
              <img src="../assets/images/icon-filter.svg" style={{ width: "25px" }} class="me-2" />
              Filtros
              <button
                class="d-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-controls="flush-collapseOne"
                name="showAccordion"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </h2>
          <div
            id="collapse-filters"
            class="accordion-collapse collapse "
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <div class="row justify-content-start">
                <div class="col-md-3 d-flex flex-column mb-3 d-none">
                  <label class="form-label">Proveedor</label>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">
                      <img
                        src="../assets/images/icon-input-proveedor.svg"
                        alt=""
                      />
                    </span>
                    <select class="form-select">
                      <option selected=""></option>
                      <option value="1">001 - </option>
                      <option value="2">002 - </option>
                      <option value="3">003 - </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3 d-flex flex-column mb-3">
                  <Input
                    onChange={onChange}
                    label={"Fecha desde"}
                    name={"fecha_desde"}
                    type={"date"}
                    validate={{ msg: "", validate: false }}
                    icon={"/assets/images/icon-input-fecha.svg"}
                  />
                </div>

                <div class="col-md-3 d-flex flex-column mb-3">
                  <Input
                    onChange={onChange}
                    label={"Fecha hasta"}
                    name={"fecha_hasta"}
                    type={"date"}
                    validate={{ msg: "", validate: false }}
                    icon={"/assets/images/icon-input-fecha.svg"}
                  />
                </div>
              </div>
              <div class="row justify-content-start d-none">
                <div class="col-md-3 d-flex flex-column mb-3">
                  <label class="form-label">Producto</label>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">
                      <img src="../assets/images/tambor.svg" alt="" />
                    </span>
                    <input
                      type="number"
                      class="form-control"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
                <div class="col-md-3 d-flex flex-column mb-3">
                  <label class="form-label">O/C</label>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">
                      <img src="../assets/images/icon-input-cuit.svg" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {rol == 5 ? (
          <div className="col-md-2">
            <label className="btn btn-primary d-flex justify-content-center items-align-center">
              <i
                style={{ pointerEvents: "none" }}
                className="material-icons text-white me-2"
              >
                add
              </i>
              Carga masiva proveedores
              <button
                className="d-none"
                name="modalMasiveStore"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Filters;
