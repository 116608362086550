import ReactTooltip from "react-tooltip";
import { OrderTableKeys } from "../../utils/OrderTableKeys";

const Tbody = ({
  contents,
  order,
  special_contents,
  actions,
  onClick,
  actionSelect,
  length
}) => {

  let special = special_contents ? special_contents : { position: null };

  const selectState = (states, active) => {
    let result = 0;
    states.map((state) => {
      if (state.name == active) {
        result = state.position;
      }
    })
    return result
  }

  /* Action selected */
  let selected = actionSelect ? actionSelect.selected : []
  return (
    <tbody className="border-0 bg-transparent">
      {contents.map((content, index) => (
        <tr className={selected.indexOf(content.id) >= 0 ? "pin-active" : ""}>
          {actionSelect ? (
            <td className="border-bottom">
              <label className={actionSelect.class}>
                <div
                  contentEditable="true"
                  dangerouslySetInnerHTML={{ __html: actionSelect.icon }}
                ></div>
                <button
                  className="d-none"
                  name={actionSelect.name}
                  onClick={(event) => onClick(event, { content, index })}
                ></button>
              </label>
            </td>
          ) : (
            ""
          )}

          {OrderTableKeys(content, order).map((item, index) => {
            if (special.position == index) {
              return (
                <td className="border-bottom">
                  <div class="progressbullet">
                    <div class="bar">
                      <div class="bar__fill" style={{ width: (selectState(special.position_name, content.sector.name) * 25) + "%" }}></div>
                    </div>
                    {special.status.map((active, index) => {
                      if (index < selectState(special.position_name, content.sector.name)) {
                        return (
                          <div class="point point--active">
                            <div
                              class="bullet"
                              data-tip
                              data-for={active}
                            ></div>
                            <ReactTooltip
                              id={active}
                              data-bs-placement="top"
                              className="custom-tooltip py-0 px-2"
                              effect="solid"
                              style={{ zIndex: 4 }}
                            >
                              {active}
                            </ReactTooltip>
                          </div>
                        );
                      } else {
                        return (
                          <div class="point">
                            <div
                              class="bullet"
                              data-tip
                              data-for={active}
                            ></div>
                            <ReactTooltip
                              id={active}
                              data-bs-placement="top"
                              className="custom-tooltip py-0 px-2"
                              effect="solid"
                              style={{ zIndex: 4 }}
                            >
                              {active}
                            </ReactTooltip>
                          </div>
                        );
                      }
                    })}
                  </div>
                </td>
              );
            } else {
              return <td className="border-bottom">{item}</td>;
            }
          })}



          <td className={actions.length > 0 ? "border-bottom p-4 d-flex justify-content-center" : "d-none"}>
            {actions.map((action) => (
              <label
                class={action.value ? content[action.property] == action.value ? action.class : "d-none" : action.class}
                data-tip
                data-for={action.id+"_"+index}
              >
                <div className="me-1" contentEditable='true' dangerouslySetInnerHTML={{ __html: typeof action.icon == 'string' ? action.icon : action.icon(content[action.if_icon]) }}></div>


                {action.text}
                <button
                  className="d-none"
                  name={action.name}
                  onClick={(event) => onClick(event, content)}
                ></button>

                {typeof action.icon == 'string' ?
                <ReactTooltip
                    id={action.id+"_"+index}
                    data-bs-placement="top"
                    className="custom-tooltip py-0 px-2"
                    effect="solid"
                    style={{ zIndex: 4 }}
                  > 
                  {action.title}
                  </ReactTooltip>
                  : <ReactTooltip
                    id={action.id+"_"+index}
                    data-bs-placement="top"
                    className="custom-tooltip py-0 px-2"
                    effect="solid"
                    style={{ zIndex: 4 }}
                  > {content[action.if_icon]}
                  </ReactTooltip>
                  }
              </label>
            )
            )}
          </td>

        </tr>
      ))}

      {contents.length == 0 ? (
        <tr className="">
          <td colSpan={length}>
            <div className="d-flex justify-content-center py-3 fs-4">
              No existen datos actualmente
            </div>
          </td>
        </tr>
      ) : (
        ""
      )}
    </tbody>
  );
};

export default Tbody;
