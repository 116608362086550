import { useState } from "react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContentSwiper from "../../../components/sliders/Swiper";
import { authContext } from "../../../context/AuthContext";
import ContentOrder from "../Contents/ContentOrderWithdrawal";
import Notifications from "../notifications/Notifications";

const Logistic = ({orderWithdrawal}) => {
  /*********************************************** INITIAL STATE ***********************************************************/
  const navigate = useNavigate();

  /**********************************************************************************************************************/
  //Sliders
  const swipper_oc = [
    {
      title: "O/R Pendientes",
      link: "/dashboard/orderwithdrawal/pending",
      icon: "/assets/images/icon-advertencia.svg",

      total: orderWithdrawal? orderWithdrawal.pending_orders : 0,
      icon: "/assets/images/icon-advertencia.svg",
      name: "redirect",
    },
    {
      title: "O/R Entregadas",
      link: "/dashboard/orderwithdrawal/submitted",
      icon: "/assets/images/icon-check.svg",
      
      total: orderWithdrawal? orderWithdrawal.delivered_orders : 0,
      icon: "/assets/images/icon-advertencia.svg",
      name: "redirect",
    },
  ];

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "redirect":
        {
          navigate(value);
        }
        break;
    }
  };

  //Content Order
  const order_process = {
    title: {
      text: "proceso",
      color: "#F16A1B",
    },
    btn_ruta: {
      text: "Ver Ruta",
      name: "ver-ruta",
    },
    status: 3,
  };

  const order_actives = {
    title: {
      text: "activas",
      color: "#08B1BA",
    },
    btn_ruta: {
      text: "Generar Ruta",
      name: "crear-ruta",
    },
    status: 1,
  };

  const SwiperMediaQuery = 4;
  useEffect(() => {
    //Media Query Swipper
  }, []);
  return (
    <div className="row">
      <div className="col-md-12 col-lg-8">
        <div className="overflow-hidden d-slider1">
          <ContentSwiper
            SwiperMediaQuery={SwiperMediaQuery}
            contents={swipper_oc}
            onClick={onClick}
          />
        </div>

        <ContentOrder data={order_process} />
        <ContentOrder data={order_actives} />
      </div>


      {/* Notificactions */}

      <Notifications />
    </div>
  );
};

export default Logistic;
