import BtnAccept from "../components/forms/BtnAccept";
import CheckBox from "../components/forms/CheckBox";
import Input from "../components/forms/Input";
import Select from "../components/forms/Select";
import TextTarea from "../components/forms/TextTarea";

export const TableEditable = ({
  content,
  onBlur,
  onChange,
  onClick,
  index,
}) => {
  switch (content.form) {
    case "input":
      {
        if (content.disabled) {
          return <div className="p-0 m-0">{content.value[0]}</div>;
        } else {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <Input
                uniqueKey={content.index ? content.index[0] : index}
                onBlur={onBlur}
                onChange={onChange}
                label={""}
                name={content.name}
                type={content.type}
                value={content.value[0]}
                validate={{ msg: "", validate: false }}
                disabled={content.disabled}
              />
            </div>
          );
        }
      }
      break;
    case "checkbox":
      if (content.disabled) {
        return <div>{content.value[0]}</div>;
      } else {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <CheckBox
              uniqueKey={content.index ? content.index[0] : index}
              onChange={onChange}
              label={content.text}
              name={content.name}
              type={"checkbox"}
              value={content.value[0]}
              validate={{ msg: "", validate: false }}
            />
          </div>
        );
      }
      break;
    case "select":
      {
        if (content.disabled) {
          return <div>{content.value[0]}</div>;
        } else {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <Select
                uniqueKey={content.index ? content.index[0] : index}
                onChange={onChange}
                label={""}
                name={content.name}
                type=""
                defaultValue={content.value[0]}
                value={content.selectValue}
                showIndex="name"
                sendIndex="name"
                validate={{ msg: "", validate: false }}
                icon={"/assets/images/icon-arrow-input.svg"}
              />
            </div>
          );
        }
      }
      break;
    case "texttarea":
      {
        if (content.disabled) {
          return <div>{content.value[0]}</div>;
        } else {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <TextTarea
                index={content.index ? content.index[0] : index}
                onChange={onChange}
                label={""}
                name={content.name}
                type={"text"}
                value={content.value[0]}
                validate={{ msg: "", validate: false }}
                rows={"1"}
              />
            </div>
          );
        }
      }
      break;
    case "btn":
      {
        if (content.disabled) {
          return <div>{content.value[0]}</div>;
        } else {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <BtnAccept
                uniqueKey={content.index ? content.index[0] : index}
                onClick={onClick}
                name={content.name}
                value={content.value[0]}
                loading={content.loading}
                loadingMsg={"Generando..."}
              />
            </div>
          );
        }
      }
      break;
    case "file":
      {
        if (content.disabled) {
          return <div className="p-0 m-0">{content.value[0]}</div>;
        } else {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <Input
                uniqueKey={content.index ? content.index[0] : index}
                onChange={onChange}
                label={""}
                name={content.name}
                type={content.type}
                validate={{ msg: "", validate: false }}
                disabled={content.disabled}
              />
            </div>
          );
        }
      }
      break;
    case "showfile":
      {
        if (content.disabled) {
          return <div>{content.value[0]}</div>;
        } else {
          return (
            <div className="d-flex">
              {content.value[0].map((file, key) => (
                <div className="">
                  <label className="btn">
                    <img
                      src={file}
                      className="img-fluid"
                      style={{ maxWidth: "100px" }}
                      alt=""
                      title="Eliminar?"
                    />
                    <button
                      id={index}
                      className="d-none"
                      name={content.name}
                      onClick={(event) => onClick(event, key)}
                    ></button>
                  </label>
                </div>
              ))}
            </div>
          );
        }
      }
      break;
    case "color":
      {
        if (content.disabled) {
          return (
            <div className="d-flex justify-content-center">
                <label className="btn border p-2 rounded-pill" style={{background: content.value_color.color_primary? content.value_color.color_primary[0] : "#D9D9D9"}}>
                  <label className="btn border p-0 m-0 rounded-pill" style={{ width: "20px", height: "20px", background: content.value_color.color_secondary? content.value_color.color_secondary[0] : "#343434" }}>
                  </label>
                </label>
            </div>
          );
        } else {

          return (
            <div className="d-flex justify-content-center">
                <label className="btn border p-2" style={{background: content.value_color.color_primary? content.value_color.color_primary[0] : "#D9D9D9"}}>

                  <label className="btn border p-0 m-0" style={{ width: "20px", height: "20px", background: content.value_color.color_secondary? content.value_color.color_secondary[0] : "#343434" }}>
                  <button
                      id={index}
                      className="d-none"
                      name={content.name}
                      onClick={(event) => onClick(event, content.value[0])}
                    ></button>
                  </label>

                  <button
                      id={index}
                      className="d-none"
                      name={content.name}
                      onClick={(event) => onClick(event, content.value[0])}
                    ></button>
                </label>
            </div>
          );
        }
      }
      break;
  }

  return "";
};
