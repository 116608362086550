/* 
Order Entry setForms

drums
wax
*/

import { format, formatDistance } from "date-fns";

export const setForms_OrderEntry_drums = (details) => {
  let result = [];

  

  details.map((d) => {

    d.drums.map((drums, index) => {
      
    console.log("drum oe");
    console.log(drums)

      let codigo_senasa = ""
      let codigo_geomiel = ""
      let tipo_tambor = ""
      let estiba = "A"
      let observacion = ""
      let neto = ""
      let bruto = ""
      let tara = ""
      if(drums.withdrawal_detail ){
        codigo_geomiel = drums.withdrawal_detail.geomiel_code
        codigo_senasa = drums.withdrawal_detail.senasa_code
        tipo_tambor = 'Alto'
      }
      if(drums.entry_details.length > 0){
        codigo_senasa = drums.entry_details[0].senasa_code
        codigo_geomiel = drums.entry_details[0].geomiel_code
        tipo_tambor = drums.entry_details[0].drum_height
        estiba = drums.entry_details[0].stowage
        observacion = drums.entry_details[0].comments
        neto = drums.entry_details[0].total_weight
        bruto = drums.entry_details[0].gross_weight
        tara = drums.entry_details[0].tare
      }
     

      let form = {
        key: [index, ""],
        /* Tambor */
        entregado: [drums.state_id == 2 ? true : false, ""],
        producto_nombre: ["Tambor", ""],
        producto_id: [1, ""], //product_id
        tambor_id: [drums.id, ""], //honey_drum_id
        codigo_senasa: [codigo_senasa, "required"],
        codigo_geomiel: [codigo_geomiel, "required"],
        tipo_tambor: [tipo_tambor, "required"],
        peso_inicial: [drums.net_weight, ""],
        neto: [neto.trim() == "" ? drums.net_weight : neto, "required"],
        bruto: [bruto.trim() == "" ? drums.gross_weight : bruto, "required"],
        tara: [tara.trim() == "" ? drums.tare : tara, "required"],
        estiba: [estiba, "required"],

        observacion: [observacion, ""],
        imagenes: [[], ""], //images
        imagenes_url: [[], ""], //images
        cantidad_archivos: [0 + " Imagenes", ""],
        purchase_order: [drums.purchase_order_id, ""],
      };

      result.push(form);
    });
  });

  return result;
};

export const setForms_OrderEntry_wax = (details) => {
  let result = [];

  details.map((d, index) => {
    if (d.wax_amount) {
      let form = {
        key: [index, ""],
        //
        producto_id: [2, ""], //product_id
        producto_nombre: ["Cera", ""], //product_id
        cera_id: [d.wax_id, ""], //product_id
        kilos_ingresados: [d.wax_amount, "required"],
        operculo: ["", ""],
        recupero: ["", ""],
        borra: ["", ""],

        observacion: ["", ""],
        imagenes: [[], ""], //images
        imagenes_url: [[], ""], //images
        cantidad_archivos: [0 + " Imagenes", ""],
        purchase_order: [d.purchase_order_id, ""],
      };

      result.push(form);
    }
  });

  return result;
};

/*
Analize Drums O/C
#Pending
*/

export const setForm_analize_drums = (details) => {
  let result = [];

  //console.log(details);
  details.map((t, index) => {
    let form = {
      key: [index, ""],
      geomiel_code: [t.entry_details[0].geomiel_code,""],
      tambor_id: [t.id, ""], //honey_drum_id
      color: ["", ""], //color
      humedad: ["", ""], //moisture
      adulteraciones: ["", ""], //adulteration
      solidificacion: ["", ""], //solidification
      archivo: ["", ""], //file
      lote: [t.lot_analysis_id, ""], //honey_lot
      observaciones: ["", ""], //comments
    };

    result.push(form);
  });

  return result;
};

/*
Analize Drums O/C
#Analyzed
*/

export const setForm_analized_drums = (details) => {
  let result = [];

  //console.log(details);
  details.map((t, index) => {
    let form = {
      key: [index, ""],

      estado: [t.approved == 1 ? "Aprobado" : "Rechazado", ""], //honey_drum_id
      fecha_resultados: [
        format(new Date(t.updated_at), "yyyy-M-dd").toString(),
        "",
      ], //honey_drum_id

      tambor_id: [t.id, ""], //honey_drum_id
      color: [t.color, ""], //color
      humedad: [t.moisture, ""], //moisture
      adulteraciones: [t.adulteration, ""], //adulteration
      solidificacion: [t.solidification, ""], //solidification
      archivo: [t.file, ""], //file
      lote: [t.lot_analysis_id, ""], //honey_lot
      observaciones: [t.comments, ""], //comments
    };

    result.push(form);
  });

  return result;
};

export const setForm_analyzed_lot = (lot) => {
  const {
    dextrins,
    measurement_type_hmf,
    adulterated_honey,
    analysis_date,
    analyzed,
    ceft,
    comments,
    conductivity,
    created_at,
    daps,
    diastose,
    //%c4
    //f+g
    fg,
    fructose,
    //g+h
    glucose,
    hmf_value,
    id,
    moisture_by_refractometry,
    //p-m
    purchase_order_id,
    qnl,
    rim,
    rip,
    scp,
    sd,
    sdm,
    sm,
    smm,
    smp,
    smt,
    smx,
    sp,
    sq,
    ss,
    st,
    str,
    sucrose,
    sz,
    table_by_hanna,
    taf,
    tcn,
    tmp,
    tyl,
    updated_at,
  } = lot;

  let form = {
    date: [
      analysis_date
        ? format(new Date(analysis_date+' 00:00:00'), "yyyy-MM-dd").toString()
        : format(new Date(), "yyyy-MM-dd").toString(),
    ],
    lote_id: [id, ""], //lot_analysis_id
    dextrinas: [dextrins ? dextrins : "", ""], //dextrins
    tipo_medicion_hmf: [measurement_type_hmf ? measurement_type_hmf : "", ""], //measurement_type_hmf
    //measurement_type_hmf
    valor_hmf: [hmf_value ? hmf_value : "", ""], //hmg_value
    fructuosa: [fructose ? fructose : "", ""], //fructose
    glucosa: [glucose ? glucose : "", ""], //glucose
    sacarosa: [sucrose ? sucrose : "", ""], //sucrose
    fg: [fg ? fg : "", ""], //fg
    f_g: [lot["f+g"] ? lot["f+g"] : "", ""], //f+g
    g_h: [lot["g+h"] ? lot["g+h"] : "", ""], //G+H
    miel_adulterada: [adulterated_honey ? adulterated_honey : "", ""], //adulteres_honey
    p_m: [lot["p-m"] ? lot["p-m"] : "", ""], //p-m
    rim: [rim ? rim : "", ""], //rim
    rip: [rip ? rip : "", ""], //rip
    c4: [lot["%c4"] ? lot["%c4"] : "", ""], //%c4
    diastasa: [diastose ? diastose : "", ""], //diastose
    conductividad: [conductivity ? conductivity : "", ""], //conductivity
    color_por_hanna: [table_by_hanna ? table_by_hanna : "", ""], //table_by_hanna
    humedad_refractometro: [
      moisture_by_refractometry ? moisture_by_refractometry : "",
      "",
    ], //moisture_by_refractometry
    sz: [sz ? sz : "", ""], //sz
    smt: [smt ? smt : "", ""], //smt
    ss: [ss ? ss : "", ""], //ss
    smm: [smm ? smm : "", ""], //smm
    daps: [daps ? daps : "", ""], //daps
    sdm: [sdm ? sdm : "", ""], //sdm
    smx: [smx ? smx : "", ""], //smx
    sp: [sp ? sp : "", ""], //sp
    smp: [smp ? smp : "", ""], //smp
    sd: [sd ? sd : "", ""], //sd
    sq: [sq ? sq : "", ""], //sq
    st: [st ? st : "", ""], //st
    sm: [sm ? sm : "", ""], //sm
    scp: [scp ? scp : "", ""], //scp
    tmp: [tmp ? tmp : "", ""], //tmp
    qnl: [qnl ? qnl : "", ""], //qnl
    str: [str ? str : "", ""], //str
    ceft: [ceft ? ceft : "", ""], //ceft
    tyl: [tyl ? tyl : "", ""], //tyl
    taf: [taf ? taf : "", ""], //taf
    tcn: [tcn ? tcn : "", ""], //tcm
    observaciones: [comments ? comments : "", ""], //comments
  };

  return form;
};

export const setForm_extra_analyzed_lot = (lot) => {
  const {
    dextrins,
    measurement_type_hmf,
    adulterated_honey,
    analysis_date,
    analyzed,
    ceft,
    comments,
    conductivity,
    created_at,
    daps,
    diastose,
    //%c4
    //f+g
    fg,
    fructose,
    //g+h
    glucose,
    hmf_value,
    id,
    moisture_by_refractometry,
    //p-m
    purchase_order_id,
    qnl,
    rim,
    rip,
    scp,
    sd,
    sdm,
    sm,
    smm,
    smp,
    smt,
    smx,
    sp,
    sq,
    ss,
    st,
    str,
    sucrose,
    sz,
    table_by_hanna,
    taf,
    tcn,
    tmp,
    tyl,
    updated_at,
  } = lot;

  let form = {
    analysis_date: [],
    lote_id: [id, ""], //lot_analysis_id
    dextrinas: [dextrins ? dextrins : "", "required"], //dextrins
    tipo_medicion_hmf: [
      measurement_type_hmf ? measurement_type_hmf : "",
      "required",
    ], //measurement_type_hmf
    //measurement_type_hmf
    valor_hmf: [hmf_value ? hmf_value : "", "required"], //hmg_value
    fructuosa: [fructose ? fructose : "", "required"], //fructose
    glucosa: [glucose ? glucose : "", "required"], //glucose
    sacarosa: [sucrose ? sucrose : "", "required"], //sucrose
    fg: [fg ? fg : "", "required"], //fg
    f_g: [lot["f+g"] ? lot["f+g"] : "", ""], //f+g
    g_h: [lot["g+h"] ? lot["g+h"] : "", ""], //G+H
    miel_adulterada: [adulterated_honey ? adulterated_honey : "", "required"], //adulteres_honey
    p_m: [lot["p-m"] ? lot["p-m"] : "", "required"], //p-m
    rim: [rim ? rim : "", "required"], //rim
    rip: [rip ? rip : "", "required"], //rip
    c4: [lot["%c4"] ? lot["%c4"] : "", "required"], //%c4
    diastasa: [diastose ? diastose : "", "required"], //diastose
    conductividad: [conductivity ? conductivity : "", "required"], //conductivity
    color_por_hanna: [table_by_hanna ? table_by_hanna : "", "required"], //table_by_hanna
    humedad_refractometro: [
      moisture_by_refractometry ? moisture_by_refractometry : "",
      "required",
    ], //moisture_by_refractometry
    sz: [sz ? sz : "", "required"], //sz
    smt: [smt ? smt : "", "required"], //smt
    ss: [ss ? ss : "", "required"], //ss
    smm: [smm ? smm : "", "required"], //smm
    daps: [daps ? daps : "", "required"], //daps
    sdm: [sdm ? sdm : "", "required"], //sdm
    smx: [smx ? smx : "", "required"], //smx
    sp: [sp ? sp : "", "required"], //sp
    smp: [smp ? smp : "", "required"], //smp
    sd: [sd ? sd : "", "required"], //sd
    sq: [sq ? sq : "", "required"], //sq
    st: [st ? st : "", "required"], //st
    sm: [sm ? sm : "", "required"], //sm
    scp: [scp ? scp : "", "required"], //scp
    tmp: [tmp ? tmp : "", "required"], //tmp
    qnl: [qnl ? qnl : "", "required"], //qnl
    str: [str ? str : "", "required"], //str
    ceft: [ceft ? ceft : "", "required"], //ceft
    tyl: [tyl ? tyl : "", "required"], //tyl
    taf: [taf ? taf : "", "required"], //taf
    tcn: [tcn ? tcn : "", "required"], //tcm
    observaciones: [comments ? comments : "", "required"], //comments
  };

  return form;
};

/*

Export 
#Clients
#Export
#contracts

*/

export const setForm_client = (client) => {


  let form =   {
    empresa: [client.business_name, "required"], //business_name

    pais_id: [client.city.province.country.id, "required"],//country_id
    pais: [client.city.province.country.name, "required"],//country

    direccion: [client.address, "required"],//address

    provincia: [client.city.province.name, "required"],//province_name
    provincia_id: [client.city.province.id, ""], 

    ciudad: [client.city.name, "required"],//city_name

    email: [client.email, "required"],//email
    condicion: [client.condition, "required"],//condition
    observacion: [client.comments, "required"],//comments
  }

  return form;
};

export const setForm_contract = (contract) => {
  let form = {
    numero_contrato: [contract.contract_number, "required"], //contract_number
    cliente_id: [contract.customer_id, "required"], //customer_id
    cliente: [contract.customer.business_name, "required"], //customer_id
    pais_id: [contract.country_id, "required"], //country_id
    pais: [contract.country.name, "required"], //country name
    color: [contract.color, "required"], //color
    fecha_envio: [
      format(new Date(contract.shipment_date), "yyyy-MM-dd").toString(),
      "required",
    ], //shipment_date

    producto: [contract.product_id, "required"], //product_id
    kilos: [contract.kilos, "required"], //kilos
    unidad: [contract.units, "required"], //units
    tipo: ["", ""],

    condiciones_venta: [contract.sales_condition, "required"], //sales_condition

    precio_final: [contract.full_price, "required"], //full_price
    precio_argentina: [contract.arg_price, "required"], //arg_price
    condicion_completa: [contract.condition_full, "required"], //condition_full
    condicion_argentina: [contract.condition_arg, "required"], //condition_arg
    flete: [contract.cfr_freight, ""], //cfr_freight

    lotes_exportacion: [contract.export_lots, ""],
    fecha_creacion: [contract.created_at, ""]
  };

  return form;
};

export const setForm_export = (exports) => {
  let result = [];

  exports.map((exp) => {
    let form = {
      id: [exp.id , ""],
      date: [format(new Date(exp.created_at), "dd/MM/yyyy").toString(), ""],
      name: [exp.name, ""],
      honey_drums_count: [exp.honey_drums_count, ""],
      primary_color: [exp.primary_color, ""],
      secondary_color: [exp.secondary_color, ""],
      destiny: [exp.contract ? exp.contract.country.name : "", ""],
      count_drums: [exp.homogenized == 1? exp.fractionated_honey_drums_count : exp.purchased_honey_drums_count]
    };

    result.push(form);
  });

  return result;
};

export const setForm_lot = (lot) => {
  //console.log(lot)
  let form = {
    id: [lot.id, "required"], //export_lot_id
    nombre: [lot.name, "required"],
    contrato: [lot.contract.contract_number, ""],
    consolidacion: [lot.consolidation_date, "required"], //consolidation_date
    color: [lot.color, "required"],
    booking: [lot.booking, "required"],
    contenedor: [lot.container, "required"],
    permiso: [lot.permission, "required"],
    isf: [lot.isf, "required"],
    analisis: [lot.analysis == 0? false : true, "required"],  //analysis
    nombre_analisis: [lot.analysis_name, "required"],
    recibido: [lot.received == 1? true : false, ""],
    saldado: [lot.settled == 1? true : false, ""],
    sae: [lot.sae, "required"],
    lote_senasa: [lot.senasa_lot, "required"],
    precinto_senasa: [lot.senasa_seal, "required"],
    precinto_ana: [lot.ana_seal, "required"],
    precinto_linea: [lot.line_seal, "required"],
    precinto_tsh: [lot.tsh_seal, "required"],
    precio_completo: [lot.full_price, "required"],
    precio_argentina: [lot.arg_price, "required"],
    cfr_completo: [lot.cfr_full, "required"],
    cfr_argentina: [lot.cfr_arg, "required"],
    cfr_transporte: [lot.cfr_freight, "required"],

    tambores: [lot.homogenized == 1 ? lot.fractionated_honey_drums  : lot.honey_drums, ""],
    tambores_bruto: [lot.homogenized == 1 ? lot.fractionated_honey_drums_sum_gross_weight : lot.honey_drums_sum_gross_weight, ""],
    tambores_tara: [lot.homogenized == 1 ? lot.fractionated_honey_drums_sum_tare : lot.honey_drums_sum_tare, ""],
    tambores_neto: [lot.homogenized == 1 ? lot.fractionated_honey_drums_sum_net_weight : lot.honey_drums_sum_net_weight, ""]
  };

  return form;
};

export const setForm_drum_homogeinize = (drums) => {
  let result = []

  let drum_nro = 1;
  let result_nro = drums.total;

  drums.data.map((drum, index) => {

    if(
      index != 0
    ){
      if(result_nro < 10){
        drum_nro =  drums.total;
      } else {
        drum_nro = drum_nro + 10;
      }
    }

    let form = {
    nro: [drum_nro, ""],
    tambor_id: [drum.honey_drum_id, ""],
    color: [drum.color, ""],
    humedad: [drum.moisture, ""],
    solidificacion: [drum.solidification, ""],
    fg: [drum.fg, ""],
    hmf: [drum.hmf, ""],
    am1: [drum.am1, ""],
    am2: [drum.am2, ""],
    estado: [drum.homogenization_analyzed == 1? drum.approved == 1? "APROBADO" : "RECHAZADO" : "PENDIENTE", ""],
    glifosato: [drum.glyphosate, ""],

    updated_at: [drum.updated_at, ""],
    comments: [drum.comments, ""],
    }

    result.push(form);
  })

  return result;
}

export const setForm_drum_request = (drums) => {
  //console.log(drums);
  let result = [];

  drums.map((drum, index) => {


    let form = {
      key: [index, ""],
      tambor_id: [drum.id, ""],
      estiba: ["", ""],
      peso: [drum.net_weight, ""],
      etiqueta: [drum.labeled == 1? true : false, ""],
      chequeado: [drum.checked == 1? true : false, ""]
    }

    result.push(form);
  })


  return result;
}
