import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  //Lista
  vehicles: null,
}

export const vehicleSlice = createSlice({
  name: "vehicles",
  initialState: initialState,
  reducers: {
    setVehicles: (state, action) => {
      state.vehicles = action.payload;
    },
  },
});


//Async functions
export const fetchVehicles = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setVehicles(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};



export const { setVehicles } = vehicleSlice.actions;

export const vehicleState = (state) => state.vehicles;

export default vehicleSlice.reducer;