export const GlobalConst = {
  //Api context Login
  URL_LOGIN: "auth/login",
  URL_LOGOUT: "auth/logout",
  URL_ME: "auth/me",

  //API USERS
  URL_INDEX_USERS: "auth/users",
  URL_CREATE_USER: "auth/user",
  URL_INDEX_ROLES: "auth/roles",

  /* Product */
  URL_INDEX_PRODUCT: "product/index",

  /* Geolocation */
  URL_INDEX_PROVINCE: "geolocation/province/index",
  URL_INDEX_CITY: "geolocation/city/index",
  URL_INDEX_COUNTRY: "geolocation/country/index",

  //API PROVIDERS
  URL_CREATE_PROVIDERS: "provider/store",
  URL_UPDATE_PROVIDER: "provider/update",
  URL_INDEX_PROVIDERS: "provider/index",
  URL_SHOW_PROVIDER: "provider/show",
  URL_DELETE_PROVIDER: "provider/destroy",

  /*
    Equivalence
  */
  URL_CREATE_EQUIVALENCE: "product/equivalence/store",
  URL_UPDATE_EQUIVALENCE: "product/equivalence/update",
  URL_INDEX_EQUIVALENCE: "product/equivalence/index",
  URL_DELETE_EQUIVALENCE: "product/equivalence/destroy",

  /* 
  Supply
  */
  URL_INDEX_SUPPLY: "supply/index",
  URL_CREATE_SUPPLY: "supply/store",
  /* 
  Stock
  */
  URL_SHOW_STOCK: "stock/show",
  URL_CREATE_STOCK: "stock/store",

  /*
  Request Order
  */

  URL_INDEX_REQUEST: "request/index",
  URL_CREATE_REQUEST: "request/store",
  URL_UPDATE_REQUEST: "request/update",

  //API PURCHASE
  URL_CREATE_PURCHASE: "purchase/store",
  URL_INDEX_PURCHASE: "purchase/index",
  URL_SHOW_PURCHASE: "purchase/show",
  URL_UPDATE_PURCHASE: "purchase/update",
  URL_DELETE_PURCHASE: "purchase/destroy",
  URL_SET_WAREHOUSE_PURCHASE: "purchase/assign/warehouse",

  /* 
  API WITHDRAWAL ORDER 
  */
  URL_GENERATE_ORDER_WITHDRAWAL: "withdrawal/generate",
  URL_WITHDRAWAL: "withdrawal/withdraw",
  URL_CREATE_DETAILS_WITHDRAWAL: "withdrawal/withdraw",
  URL_INDEX_WITHDRAWAL: "withdrawal/index",
  URL_SHOW_WITHDRAWAL: "withdrawal/show",
  URL_UPDATE_WITHDRAWAL: "withdrawal/update",
  URL_DELIVERED: "withdrawal/delivered",
  URL_START_WITHDRAWAL: "withdrawal/start",

  /*
  entry orders
  */
  URL_INDEX_ENTRY: "entry/index",
  URL_CREATE_ENTRY: "entry/enter",
  URL_ENTRY: "entry/enter",

  /* 
    Laboratory 
    Analyze 
    #OrderPurchase
    #Lot  
  */

  URL_INDEX_ANALIZE: "lab/index",
  URL_SHOW_ANALIZE: "lab/show",
  URL_ANALIZE_DRUM: "lab/analyze/drum",
  URL_ANALIZE_LOT: "lab/analyze/lot",
  URL_INDEX_LOT: "lab/index/lot",
  URL_SHOW_LOT: "lab/show/lot",
  URL_SHOW_EXTRA_ANALIZE: "lab/show/lot/extra",

  /* 
  Laboratory
  homogenization 
  */

  URL_INDEX_HOMOGENIZATION: "lab/homogenization/index",
  URL_CREATE_HOMOGENIZATION: "lab/homogenization/store",
  URL_SHOW_HOMOGENIZATION: "lab/homogenization/show",
  URL_START_HOMOGENIZATION: "lab/homogenization/homogenize",

  /*
    Export
    Lot
  */

  URL_INDEX_EXPORT_LOT: "export/lot/index",
  URL_SHOW_EXPORT_LOT: "export/lot/show",
  URL_CREATE_EXPORT_LOT: "export/lot/create",
  URL_UPDATE_EXPORT_LOT: "export/lot/update",
  URL_UPLOAD_EXPORT_LOT: "export/lot/upload/files",

  URL_ANALYZE_EXPORT_LOT: "export/lot/analyze",

  URL_COLOR_EXPORT_LOT: "export/lot/assign/colors",
  URL_CONTRACT_EXPORT_LOT: "export/lot/assign/contract",
  URL_CHECK_EXPORT_LOT: "export/lot/assign/check",
  URL_LABEL_EXPORT_LOT: "export/lot/assign/label",
  URL_FINISHED_EXPORT_LOT: "export/lot/set/finished",

  /* 
  Clients
  */
  URL_INDEX_CUSTOMER: "customer/index",
  URL_SHOW_CUSTOMER: "customer/show",
  URL_SHOW_CUSTOMER_CONTRACT: "customer/show/with/contracts",
  URL_CREATE_CUSTOMER: "customer/store",
  URL_UPDATE_CUSTOMER: "customer/update",

  /*
  Contract
  */

  URL_INDEX_CONTRACT: "contract/index",
  URL_SHOW_CONTRACT: "contract/show",
  URL_CREATE_CONTRACT: "contract/store",
  URL_UPDATE_CONTRACT: "contract/update",
  URL_FINISH_CONTRACT: "contract/set/finished",

  /*
  Vehicles
  */
  URL_INDEX_VEHICLES: "asset/vehicle",
  URL_CREATE_VEHICLE: "asset/vehicle",

  /* 
  Wharehouses
  */
  URL_INDEX_WAREHOUSES: "asset/warehouse",
  URL_CREATE_WAREHOUSE: "",

  /*
  Colors
  */
  URL_INDEX_COLOR: "export/color/index",
  URL_CREATE_COLOR: "export/color/store",

  /*
  Drums
  */
  URL_INDEX_DRUM: "product/drum/index",
  URL_SHOW_DRUM: "product/drum/show",
  URL_SET_HOMOGENEIZATION: "product/drum/set/homogenization",
  URL_STORE_DRUM: "product/drum/store",
  URL_ASSIGN_LABEL_DRUM: "product/drum/assign/label",
  URL_ASSIGN_CHECK_DRUM: "product/drum/assign/check",
  URL_DELIVERED_DRUM: "product/drum/set/state/delivered",
};
