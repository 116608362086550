import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  contract_actives: null,
  contract_close: null,

  search: [],
};

export const contractSlice = createSlice({
  name: "contracts",
  initialState: initialState,
  reducers: {
    setContract_actives: (state, action) => {
      state.contract_actives = action.payload;
    },
    setContract_close: (state, action) => {
      state.contract_close = action.payload;
    },

    setSearch_contract: (state, action) => {
      state.search = action.payload;
    },
  },
});


//Async functions
export const fetchContracts = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params.active_status) {
        case 1:
          dispatch(setContract_actives(result.data));
        break;
        case 0:
          dispatch(setContract_close(result.data));
        break;
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchSearchContract = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      dispatch(setSearch_contract(result.data.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};
 


export const { setContract_actives, setContract_close, setSearch_contract } = contractSlice.actions;

export const contractState = (state) => state.contracts;

export default contractSlice.reducer;