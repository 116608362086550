import { useContext } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import BtnAccept from "../../../components/forms/BtnAccept";
import CheckBox from "../../../components/forms/CheckBox";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { fetchDrumsHomogenize } from "../../../slices/drums/Index";
import CreateContractLot from "./CreateContractLot";
import TextTarea from "../../../components/forms/TextTarea";

const CreateContractLotH = ({ setModal, drums, contract }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { values, forms } = context;
  //context
  const { URL_SHOW_CONTRACT, URL_CREATE_EXPORT_LOT, URL_INDEX_DRUM } = values;
  const { FORM_EXTRA_ANALYZED } = forms;

  const [loading, setLoading] = useState(false);
  const [extraAnalyzed, setExtraAnalyzed] = useState(false);
  const [formExtraAnalyzed, setFormExtraAnalyzed] = useState(
    JSON.parse(JSON.stringify(FORM_EXTRA_ANALYZED))
  );

  const [homogenize, setHomogenize] = useState({
    drums: drums.drums,
    drums_id: drums.h,
  });

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "close":
        {
          setModal(false);
        }
        break;
      case "crear":
        {
          let formData = new FormData();
          //formData.append("contract_id", contract.id);

          /* Create Lot */
          homogenize.drums.map((drum, index) => {
            formData.append("honey_drums[" + index + "][id]", drum.id[0]);
            formData.append(
              "honey_drums[" + index + "][homogenize]",
              drum.homogeneizar[0] ? 1 : 0
            );
          });

          formData.append("analysis", extraAnalyzed ? 1 : 0);
          if (extraAnalyzed) {
            //formData.append("dextrins", formExtraAnalyzed? 1 : 0);
            //formData.append("measurement_type_hmf", formExtraAnalyzed.hmf? 1 : 0);
            //formData.append("hmf_value", formExtraAnalyzed.hmf_value? 1 : 0);
            formData.append(
              "analysis_types[fructose]",
              formExtraAnalyzed.fructosa[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[glucose]",
              formExtraAnalyzed.glucosa[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sucrose]",
              formExtraAnalyzed.sacarosa[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[fg]",
              formExtraAnalyzed.fg[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[f+g]",
              formExtraAnalyzed.f_g[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[g+h]",
              formExtraAnalyzed.g_h[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[adulterated_honey]",
              formExtraAnalyzed.miel_adulterada[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[p-m]",
              formExtraAnalyzed.p_m[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[rim]",
              formExtraAnalyzed.rim[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[rip]",
              formExtraAnalyzed.rip[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[%c4]",
              formExtraAnalyzed.c4[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[diastose]",
              formExtraAnalyzed.diastosa[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[conductivity]",
              formExtraAnalyzed.conductividad[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[table_by_hanna]",
              formExtraAnalyzed.color_hanna[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[moisture_by_refractometry]",
              formExtraAnalyzed.humedad_refractometro[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sz]",
              formExtraAnalyzed.sz[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[smt]",
              formExtraAnalyzed.smt[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[ss]",
              formExtraAnalyzed.ss[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[smm]",
              formExtraAnalyzed.smm[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[daps]",
              formExtraAnalyzed.daps[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sdm]",
              formExtraAnalyzed.sdm[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[smx]",
              formExtraAnalyzed.smx[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sp]",
              formExtraAnalyzed.sp[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[smp]",
              formExtraAnalyzed.smp[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sd]",
              formExtraAnalyzed.sd[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sq]",
              formExtraAnalyzed.sq[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[st]",
              formExtraAnalyzed.st[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sm]",
              formExtraAnalyzed.sm[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[scp]",
              formExtraAnalyzed.scp[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[tmp]",
              formExtraAnalyzed.tmp[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[qnl]",
              formExtraAnalyzed.qnl[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[str]",
              formExtraAnalyzed.str[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[ceft]",
              formExtraAnalyzed.ceft[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[tyl]",
              formExtraAnalyzed.tyl[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[taf]",
              formExtraAnalyzed.taf[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[tcn]",
              formExtraAnalyzed.tcn[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[comments]",
              formExtraAnalyzed.comments[0] 
            );
          }
          //
          const response = await ApiRest({
            url: URL_CREATE_EXPORT_LOT,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Creando nuevo lote de exportacion.",
              code: "201",
              type: "success",
            });

            //Actualizar

            let params = {
              url: URL_INDEX_DRUM,
              analyzed: 1,
              homogenize: 1,
              with_contract: 0,
            };
            dispatch(fetchDrumsHomogenize(params));

            //cerrar modal
            return navigate("/dashboard/contract/actives");
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "comments":{
        let setValue = formExtraAnalyzed;
        setValue[e.target.name][0] = e.target.value;

        setFormExtraAnalyzed({
          ...formExtraAnalyzed,
          [e.target.name]: setValue[e.target.name],
        });
      } break;
      case "homogeneizar":
        {
          let drums = homogenize.drums;
          let drums_id = homogenize.drums_id;

          if (e.target.checked) {
            drums.push(value);
            drums_id.push(value.id[0]);
          } else {
            drums_id.splice(drums_id.indexOf(value.id[0]), 1);
            drums = drums.filter((item) => item.id[0] != value.id[0]);
          }

          setHomogenize({ ...homogenize, drums_id: drums_id, drums: drums });
        }
        break;
      case "analisis_extra":
        {
          if (extraAnalyzed) {
            setExtraAnalyzed(false);
          } else {
            setExtraAnalyzed(true);
          }
        }
        break;
      case "tcn":
      case "tyl":
      case "str":
      case "taf":
      case "ceft":
      case "qnl":
      case "tmp":
      case "sd":
      case "daps":
      case "scp":
      case "smp":
      case "smm":
      case "sm":
      case "sp":
      case "ss":
      case "st":
      case "smx":
      case "smt":
      case "sq":
      case "sdm":
      case "sz":
      case "humedad_refractometro":
      case "color_hanna":
      case "diastosa":
      case "conductividad":
      case "c4":
      case "rip":
      case "rim":
      case "p_m":
      case "miel_adulterada":
      case "g_h":
      case "f_g":
      case "fg":
      case "sacarosa":
      case "glucosa":
      case "fructosa":
        {
          let setValue = formExtraAnalyzed;
          setValue[e.target.name][0] = e.target.checked;

          setFormExtraAnalyzed({
            ...formExtraAnalyzed,
            [e.target.name]: setValue[e.target.name],
          });
        }
        break;
    }
  };
  return (
    <div
      class="modal d-block bg-dark bg-opacity-25  modal-transition bg-modal-blur "
      tabindex="-1"
      aria-labelledby="nuevo-proveedorModalLabel"
      aria-hidden="true"
      id="modal-provider"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered"
        style={{ maxWidth: "1500px" }}
      >
        <div class="modal-content card my-5 p-5">
          <label className="btn btn-close">
            <button
              type="button"
              class="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div class="d-flex justify-content-start">
            <div class="pe-0">
              <h1>
                <strong>Lote N° 00000</strong>
              </h1>
            </div>
          </div>
          <div class="modal-body py-5 px-2">
            <div
              class="tab-pane fade show active"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <div class="table-responsive">
                <div class="tool-table d-flex">
                  <a
                    href="#"
                    class="d-flex align-items-center justify-content-center me-2"
                  >
                    <img
                      src="../assets/images/icon-download.svg"
                      width="18px"
                    />
                  </a>
                  <a
                    href="#"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <img src="../assets/images/icon-print.svg" width="18px" />
                  </a>
                </div>
                <table
                  id="example"
                  class="table table-striped"
                  data-toggle="data-table"
                >
                  <thead>
                    <tr>
                      <th class="text-center"></th>
                      <th class="text-center">Tambor</th>
                      <th class="text-center">Estado</th>
                      <th class="text-center">HMF</th>
                      <th class="text-center">Color</th>
                      <th class="text-center">Humedad</th>
                      <th class="text-center">Kg Bruto</th>
                      <th class="text-center">Kg Tara</th>
                      <th class="text-center">Kg Neto</th>
                      <th class="text-center">Solidificacion</th>
                      <th class="text-center">AM I</th>
                      <th class="text-center">AM II</th>
                      <th class="text-center">Glifosato</th>
                      <th class="text-center">FG</th>
                      <th class="text-center">Proveedor</th>
                      <th class="text-center">OC</th>
                      <th class="text-center">N° SENASA</th>
                      <th class="text-center">Observaciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {drums.drums.map((drum, index) => (
                      <tr>
                        <td class="text-center">
                          <div className="form-check mb-3 w-100">
                            <input
                              type="checkbox"
                              name="homogeneizar"
                              onChange={(event) => onChange(event, drum)}
                              className="form-check-input m-0 my-1 me-1"
                              checked={
                                homogenize.drums_id.indexOf(drum.id[0]) >= 0
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="form-check-label"
                              for="customCheck1"
                            ></label>
                          </div>
                        </td>
                        <td class="text-center">{drum.id}</td>
                        <td class="text-center">{drum.approved}</td>
                        <td class="text-center">{drum.hmf}</td>
                        <td class="text-center">{drum.color}</td>
                        <td class="text-center">{drum.moisture}</td>
                        <td class="text-center">{drum.gross_weight}</td>
                        <td class="text-center">{drum.tare}</td>
                        <td class="text-center">{drum.net_weight}</td>
                        <td class="text-center">{drum.solidification}</td>
                        <td class="text-center">{""}</td>
                        <td class="text-center">{""}</td>
                        <td class="text-center">{""}</td>
                        <td class="text-center">{""}</td>
                        <td class="text-center">{""}</td>
                        <td class="text-center">{drum.purchase_order_id}</td>
                        <td class="text-center">{""}</td>
                        <td class="text-center">{drum.comments}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div class=" mt-3">
              <CheckBox
                onChange={onChange}
                label={"Análisis extra"}
                name={"analisis_extra"}
                type={"checkbox"}
                value={extraAnalyzed}
              />
            </div>
            <hr />
            {extraAnalyzed ? (
              <div
                id="collapseOne"
                class="accordion-collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="row">
                  <div class="col-12 card card-body">
                    <p class="mb-3 fw-bold">Azúcares</p>
                    <div class="row">
                      <div class="col d-flex">
                        <span class="me-3">Fructosa</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"fructosa"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.fructosa[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">Glucosa (G)</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"glucosa"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.glucosa[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">Sacarosa</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"sacarosa"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.sacarosa[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">F/G</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"fg"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.fg[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">F+G</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"f_g"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.f_g[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">G+H</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"g_h"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.g_h[0]}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 card card-body">
                    <p class="mb-3 fw-bold">Adulteraciones</p>
                    <div class="row">
                      <div class="col d-flex">
                        <span class="me-3">Miel adulterada</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"miel_adulterada"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.miel_adulterada[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">Δ (P-M)</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"p_m"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.p_m[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">RIM</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"rim"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.rim[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">RIP</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"rip"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.rip[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">%C4</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"c4"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.c4[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">Conductividad</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"conductividad"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.conductividad[0]}
                        />
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col d-flex">
                        <span class="me-3">Diastasa (Phadebas)</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"diastosa"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.diastosa[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">Color por HANNA</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"color_hanna"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.color_hanna[0]}
                        />
                      </div>

                      <div class="col-3 d-flex">
                        <span class="me-3">Humedad por Refractòmetro (H)</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"humedad_refractometro"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.humedad_refractometro[0]}
                        />
                      </div>

                      <div class="col"></div>
                      <div class="col"></div>
                    </div>
                  </div>
                  <div class="col-12 card card-body">
                    <p class="mb-3 fw-bold">Antibióticos Kids AI</p>
                    <div class="row">
                      <div class="col d-flex">
                        <span class="me-3">SZ</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"sz"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.sz[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">SDM</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"sdm"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.sdm[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">SQ</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"sq"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.sq[0]}
                        />
                      </div>

                      <div class="col d-flex d-flex">
                        <span class="me-3">SMT</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"smt"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.smt[0]}
                        />
                      </div>

                      <div class="col d-flex d-flex">
                        <span class="me-3">SMX</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"smx"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.smx[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">ST</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"st"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.st[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">SS</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"ss"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.ss[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">SP</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"sp"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.sp[0]}
                        />
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col d-flex">
                        <span class="me-3">SM</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"sm"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.sm[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">SMM</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"smm"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.smm[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">SMP</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"smp"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.smp[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">SCP</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"scp"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.scp[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">DAPS</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"daps"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.daps[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">SD</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"sd"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.sd[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">TMP</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"tmp"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.tmp[0]}
                        />
                      </div>
                      <div class="col "></div>
                    </div>
                  </div>
                  <div class="col-12 card card-body">
                    <p class="mb-3 fw-bold">Antibióticos Kids AII</p>
                    <div class="row">
                      <div class="col d-flex">
                        <span class="me-3">QNL</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"qnl"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.qnl[0]}
                        />
                      </div>
                      <div class="col d-flex">
                        <span class="me-3">CEFT</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"ceft"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.ceft[0]}
                        />
                      </div>
                      <div class="col d-flex">
                        <span class="me-3">TAF</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"taf"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.taf[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">STR</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"str"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.str[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">TYL</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"tyl"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.tyl[0]}
                        />
                      </div>

                      <div class="col d-flex">
                        <span class="me-3">TCN</span>
                        <CheckBox
                          onChange={onChange}
                          label={""}
                          name={"tcn"}
                          type={"checkbox"}
                          value={formExtraAnalyzed.tcn[0]}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 card card-body">
                    <TextTarea
                  onChange={onChange}
                  label={"Notas"}
                  name={"comments"}
                  type={"text"}
                  value={formExtraAnalyzed.comments[0]}
                  validate={{ msg: "", validate: false }}
                />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div class="col-12 text-end">
              <BtnAccept
                onClick={onClick}
                name={"crear"}
                value={"Crear lote"}
                loading={loading}
                loadingMsg={"Creando...."}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateContractLotH;
