import { format } from "date-fns/esm";

const fill = (number, len) => {
  return "0".repeat(len - number.toString().length) + number.toString();
};

const mount = (orders) => {
  //console.log(orders)
  let product_amount = 0;

  orders.map((order) => {
    order.purchase_orders.map((p_order) => {
      product_amount = p_order.honey_drums_count;
    });
  });

  return {
    product_amount: product_amount,
  };
};

export const ConcatWithdrawalOrder = (orders) => {
  let result = [];
  let total = mount(orders);

  orders.map((order) => {
    //console.log(order)
    /* Validate Order purchase */
    let provider_amount = 0;
    let provider_name = "";
    if(order.purchase_orders.length > 0){
      provider_amount = order.purchase_orders.length;
      provider_name =  String(order.purchase_orders[0].provider.id).length < 4
        ? fill(order.purchase_orders[0].provider.id, 4) +
          " - " +
          order.purchase_orders[0].provider.business_name
        : order.purchase_orders[0].provider.id +
          " - " +
          order.purchase_orders[0].provider.business_name

     
    }

        order = {
          ...order,
          provider_amount: provider_amount,
          provider_name: provider_name,
  
          or_name:
            String(order.id).length >= 4
              ? "OR " + order.id
              : "OR " + fill(order.id, 4),
  
          oc_amount: order.purchase_orders.length,
          //oc_name: "OC "+fill(order.purchase_order.id, 4),
          state_name: order.state.name,
          product_amount: order.withdrawal_details.length,
          honey_drums_count: order.purchase_orders[0].honey_drums_count,
          date: format(
            new Date(order.date + " 00:00:00"),
            "dd/MM/yyyy"
          ).toString(),
          destiny: "Deposito",
          arrival_date: format(new Date(order.arrival_date), 'dd/MM/yyyy H:s:i').toString()
        };
  
        result.push(order);
    
  });

  return result;
};


export const ConcatWithdrawalOrderFilter = (orders, filter) => {
  let result = [];
  let total = mount(orders);

  orders.map((order) => {
   if(filter.indexOf(order.state_id) >= 0){
     /* Validate Order purchase */
     let provider_amount = 0;
     let provider_name = "";
     if(order.purchase_orders.length > 0){
       provider_amount = order.purchase_orders.length;
       provider_name =  String(order.purchase_orders[0].provider.id).length < 4
         ? fill(order.purchase_orders[0].provider.id, 4) +
           " - " +
           order.purchase_orders[0].provider.business_name
         : order.purchase_orders[0].provider.id +
           " - " +
           order.purchase_orders[0].provider.business_name
 
      
     }
 
         order = {
           ...order,
           provider_amount: provider_amount,
           provider_name: provider_name,
   
           or_name:
             String(order.id).length >= 4
               ? "OR " + order.id
               : "OR " + fill(order.id, 4),
   
           oc_amount: order.purchase_orders.length,
           //oc_name: "OC "+fill(order.purchase_order.id, 4),
           state_name: order.state.name,
           product_amount: total.product_amount,
           date: format(
             new Date(order.date + " 00:00:00"),
             "yyyy/MM/dd"
           ).toString(),
           destiny: "Deposito",
         };
   
     
     result.push(order);
   }
    
  });

  return result;
};