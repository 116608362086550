import { useState } from "react";
import { useContext } from "react";
import Alerts from "../../../components/alerts/Alerts";
import BtnAccept from "../../../components/forms/BtnAccept";
import Input from "../../../components/forms/Input";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { fetchVehicles } from "../../../slices/vehicles/Index";
import { Validate } from "../../../utils/ValidateForms";

const ModalWareHouses = ({ setSelect, select, setModal, dispatch }) => {
  const context = useContext(authContext)
  const { forms, validations } = context
  const { FORM_DESTINO } = forms
  const { VALIDATION_DESTINO } = validations
  const [form, setForm] = useState(FORM_DESTINO)
  const [validation, setValidation] = useState(JSON.parse(JSON.stringify(VALIDATION_DESTINO)))
  /*********************************************** INITIAL STATE ***********************************************************/


  /**********************************************************************************************************************/

  const onChange = (e, value) => {
        let setCampo = form;
        setCampo[e.target.name][0] = e.target.value;

        setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
        });
  };

  function objectToFormData(form) {
    const formData = new FormData();

    for (const key in form) {

      if (Array.isArray(form[key])) {
        formData.append(key, form[key][0]); // Append only the first element of the array
      } else {
        formData.append(key, form[key]);
      }
    }

    return formData;
  }

  const onClick = async (e, value) => {
    e.preventDefault();
    switch (e.target.name) {
      case "close":
        {
          setSelect(null);
          setModal(false);
        }
        break;
      
      case "submit":
      {
        const formDataObject = objectToFormData(form)

        for (const [key, value] of formDataObject.entries()) {
            console.log(`${key}: ${value}`);
        }
      }
    }
  };

  return (
    <div
      className="modal fade show bg-dark bg-opacity-25  modal-transition bg-modal-blur"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 4 }}>
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div className="modal-header p-5">
            <h1 className="modal-title" id="nueva-ordenModalLabel">
              Nuevo destino
            </h1>
          </div>
          <div className="modal-body p-5">
            <form>
              <div className="row">
                <div class="col-md-6 col-12 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Nombre"}
                    name={"name"}
                    type={"text"}
                    value={form.name[0]}
                    validate={validation.name}
                  />
                </div>
                <div class="col-md-6 col-12 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Direccion"}
                    name={"adress"}
                    type={"text"}
                    value={form.adress[0]}
                    validate={validation.adress}
                  />
                </div>
                <div class="col-md-6 col-12 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Ciudad"}
                    name={"city_id"}
                    type={"text"}
                    value={form.city_id[0]}
                    validate={validation.city_id}
                  />
                </div>
                <div className="w-full d-flex justify-content-end mt-4">
                  <button
                    onClick={onClick}
                    name="submit"
                    className='btn btn-primary fw-bold'>
                    Agregar destino
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWareHouses;
