import ReactTooltip from "react-tooltip";
import Table from "../../../components/tables/Table";
import { fetchOrderPurchaseList } from "../../../slices/orderPurchase/Index";
import { ConcatOrderPurchase } from "../../../utils/ConcatOrderPurchase";

const ContentPurchase = ({ orderPurchase, setParams, params, setSelect, setModal, dispatch }) => {
  const campos = [
    {
      name: "Nº DE ÓRDEN",
      order: {
        state: true,
        param: "id",
        active: params.sort,
      },
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "ESTADO",
      order: false,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "UBICACIóN",
      order: false,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "ACCIONES",
      order: false,
      class: "border-0 d-flex justify-content-center align-items-center",
    },
  ];

  const order = ["oc_name", "sector_id", "sector_name"];
  const contents = orderPurchase ? ConcatOrderPurchase(orderPurchase.data) : [];
  const actions = [
    {
      title: "Editar orden",
      name: "editar_orden",
      icon: "",
      id: "editar",

      class:
        "btn btn-primary border-0 d-flex align-items-center justify-content-center",
      text: "VER",
    },
  ];

  const special_status = {
    position: 1,
    status: ["Transporte", "Depósito", "Laboratorio", "Venta"],
    position_name: [
      {
        name: "logística",
        position: 1,
      },
      {
        name: "depósito",
        position: 2,
      },
      {
        name: "laboratorio",
        position: 3,
      },
      {
        name: "venta",
        position: 4,
      },
    ],
  };

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = orderPurchase
    ? orderPurchase
    : {
        current_page: 0,
        first_page_url: 0,
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: "",
        to: 0,
        total: 0,
      };

  const pagination = {
    type: null,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "order":
        {
          let setValueParams = params;
          let sort = [];

          setValueParams.sort.map((order) => {
            if (order.order_by == value.param) {
              order = {
                ...order,
                ordering: value.order,
              };
            }

            sort.push(order);
          });

          params = {
            ...params,
            sort: [
              {
                order_by: value.param,
                ordering: value.order,
              },
            ],
          };


          setParams(params);

          dispatch(fetchOrderPurchaseList(params));
        }
        break;
      case "page":
        {
          params = {
            ...params,
            url: value,
          };

          dispatch(fetchOrderPurchaseList(params));
        }
        break;
      case "editar_orden":
        {
          setSelect(value)
          setModal(true);
        }
        break;
      case "download-xls":
        {
        }
        break;
      case "download-print":
        {
          return true;
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="col-md-12 col-lg-12">
      <div
        className="overflow-hidden card"
        data-aos="fade-up"
        data-aos-delay="600"
      >
        <div className="p-0 py-0 card-body">
          <Table
            hidden_Filters={true}
            special_contents={special_status}
            campos={campos}
            order={order}
            contents={contents}
            actions={actions}
            pagination={pagination}
            onClick={onClick}
          />
        </div>

        <div className="p-0 card-body d-none">
          <div className="table-responsive">
            <table
              id="table table-responsive"
              className="table mb-0 table-striped"
              role="grid"
            >
              <thead>
                <tr>
                  <th>N °de Orden</th>
                  <th>Estado</th>
                  <th>Ubicación</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h6>OC2356</h6>
                  </td>
                  <td>
                    <div className="progressbullet">
                      <div className="bar">
                        <div
                          className="bar__fill"
                          style={{ width: "66%" }}
                        ></div>
                      </div>
                      <div className="point point--active">
                        <div
                          className="bullet"
                          data-tip
                          data-for="Transporte"
                        ></div>

                        <ReactTooltip
                          id="Transporte"
                          data-bs-placement="rop"
                          className="custom-tooltip py-1"
                          effect="solid"
                          style={{ zIndex: 4 }}
                        >
                          Transporte
                        </ReactTooltip>
                      </div>
                      <div className="point point--active">
                        <div
                          className="bullet"
                          data-tip
                          data-for="Deposito"
                        ></div>
                        <ReactTooltip
                          id="Deposito"
                          data-bs-placement="rop"
                          className="custom-tooltip py-1"
                          effect="solid"
                          style={{ zIndex: 4 }}
                        >
                          Depósito
                        </ReactTooltip>
                      </div>
                      <div className="point point--active">
                        <div
                          className="bullet"
                          data-tip
                          data-for="Laboratorio"
                        ></div>
                        <ReactTooltip
                          id="Laboratorio"
                          data-bs-placement="rop"
                          className="custom-tooltip py-1"
                          effect="solid"
                          style={{ zIndex: 4 }}
                        >
                          Laboratorio
                        </ReactTooltip>
                      </div>
                      <div className="point">
                        <div className="bullet" data-tip data-for="Venta"></div>
                        <ReactTooltip
                          id="Venta"
                          data-bs-placement="rop"
                          className="custom-tooltip py-1"
                          effect="solid"
                          style={{ zIndex: 4 }}
                        >
                          Venta
                        </ReactTooltip>
                      </div>
                    </div>
                  </td>
                  <td>En laboratorio</td>
                  <td className="d-flex justify-content-end">
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#nueva-ordenModal"
                    >
                      VER
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h6>OC2356</h6>
                  </td>
                  <td>
                    <div className="progressbullet">
                      <div className="bar">
                        <div
                          className="bar__fill"
                          style={{ width: "33%" }}
                        ></div>
                      </div>
                      <div className="point point--active">
                        <div
                          className="bullet"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-className="custom-tooltip"
                          data-bs-title="Treansporte"
                        ></div>
                      </div>
                      <div className="point point--active">
                        <div
                          className="bullet"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-className="custom-tooltip"
                          data-bs-title="Depósito"
                        ></div>
                      </div>
                      <div className="point">
                        <div
                          className="bullet"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-className="custom-tooltip"
                          data-bs-title="Laboratorio"
                        ></div>
                      </div>
                      <div className="point">
                        <div
                          className="bullet"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-className="custom-tooltip"
                          data-bs-title="Venta"
                        ></div>
                      </div>
                    </div>
                  </td>
                  <td>En depósito</td>
                  <td className="d-flex justify-content-end">
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#nueva-ordenModal"
                    >
                      VER
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h6>OC2356</h6>
                  </td>
                  <td>
                    <div className="progressbullet">
                      <div className="bar">
                        <div
                          className="bar__fill"
                          style={{ width: "100%" }}
                        ></div>
                      </div>
                      <div className="point point--active">
                        <div
                          className="bullet"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-className="custom-tooltip"
                          data-bs-title="Treansporte"
                        ></div>
                      </div>
                      <div className="point point--active">
                        <div
                          className="bullet"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-className="custom-tooltip"
                          data-bs-title="Depósito"
                        ></div>
                      </div>
                      <div className="point point--active">
                        <div
                          className="bullet"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-className="custom-tooltip"
                          data-bs-title="Laboratorio"
                        ></div>
                      </div>
                      <div className="point point--active">
                        <div
                          className="bullet"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-className="custom-tooltip"
                          data-bs-title="Venta"
                        ></div>
                      </div>
                    </div>
                  </td>
                  <td>En transporte</td>
                  <td className="d-flex justify-content-end">
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#nueva-ordenModal"
                    >
                      VER
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentPurchase;
