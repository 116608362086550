import { Alert } from "bootstrap";
import { useContext } from "react";
import Alerts from "../../../components/alerts/Alerts";
import Table from "../../../components/tables/Table";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { fetchSupplies } from "../../../slices/supplies/Index";

const ContentTable = ({
  campos,
  order,
  contents,
  actions,
  pagination,
  setLoading,
  loading,

  setParams,
  params,
  dispatch,
  setSelect,
  setModal,
}) => {
  //Global Const
  const context = useContext(authContext);
  const { values } = context;
  //context
  const {URL_DELETE_EQUIVALENCE, URL_INDEX_EQUIVALENCE } = values;



  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "page":{
        let flowparams = params;
        flowparams.url = value;

        dispatch(fetchSupplies(flowparams));
      }
      break;
      case "editar_equivalencia": {
        setSelect(value);
        setModal(true);
      } break;
      case "eliminar_equivalencia": {
        const setFunction = async () => {
          let formData = new FormData();
          formData.append("_method", "DELETE");
          formData.append('id', value.id);

          const response = await ApiRest({
            url: URL_DELETE_EQUIVALENCE,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {

            //Actualizar datos OC
            let params = {
              url: URL_INDEX_EQUIVALENCE
            }
            dispatch(fetchSupplies(params));

            return ""
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });

        }

        return Alerts({
          msg: "Alert",
          title: "",
          code: "201",
          type: "delete",
          setFunction: setFunction
        });
      } break;  
    }

  }

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":
        {
          setParams({
            ...params,
            search: e.target.value,
          });

          if (
            e.target.value.length > 3 &&
            e.target.value.length > params.search.length
          ) {
            return dispatch(
              fetchSupplies({
                url: params.url,
                search: e.target.value,
              })
            );
          }

          if (e.target.value.trim() == "") {
            return dispatch(
              fetchSupplies({
                url: params.url,
              })
            );
          }
        }
        break;
    }
  };

  return (
    <Table
    campos={campos}
    order={order}
    contents={contents}
    actions={actions}
    pagination={pagination}
  
    onClick={onClick}
    onChange={onChange}
    />
  )
}

export default ContentTable