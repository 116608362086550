import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BtnAccept from "../../components/forms/BtnAccept";
import Spinner from "../../components/loads/Spinner";
import { authContext } from "../../context/AuthContext";
import { contractState, fetchContracts } from "../../slices/contracts/Index";
import { meState } from "../../slices/users/Index";
import Actives from "./actives/Index";
import Close from "./close/Index";
import Filters from "./filters/Filters";

const Contracts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status } = useParams();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_CONTRACT } = values;

  const _user = useSelector(meState);
  const _contracts = useSelector(contractState);

  const me = _user.me;
  const rol = me.roles[0];
  const rol_id = me.roles[0].id;
  const contract_actives = _contracts.contract_actives; 
  const contract_close =  _contracts.contract_close;

  //params
  const [params, setParams] = useState({
    url: URL_INDEX_CONTRACT,
    active_status: status == "actives" ? 1 : status == "close" ? 0 : "",
    start_date: "",
    end_date: "",
    sort: [
      {
        order_by: "id",
        ordering: "desc",
      },
    ],
  });

  const h1 =
    status == "close"
      ? "Contratos cerrados"
      : status == "actives"
      ? "Contratos activos"
      : "";

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "nuevo_contrato":
        {
          return navigate("/dashboard/contract/details/"+null)
        }
        break;
      case "contrato":
        {
          return navigate("/dashboard/contract/details/"+value.id)
        }
        break;
    }
  };

  useEffect(() => {
    if(!contract_actives || !contract_close){
      let params = {
        url: URL_INDEX_CONTRACT,
        active_status: status == "actives" ? 1 : status == "close" ? 0 : "",
        start_date: "",
        end_date: "",
        sort: [
          {
            order_by: "id",
            ordering: "desc",
          },
        ],
      }
      dispatch(fetchContracts(params));
    }
  },[status])
  return (
    <main class="main-content">
      <div class="position-relative iq-banner">
        <div class="iq-navbar-header" style={{ height: "215px" }}>
          <div class="container-fluid iq-container">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center">
                  <div class="box-img">
                    <img src="/assets/images/icon-equivalencias.svg" />
                  </div>
                  <h1 class="ms-2 mb-0">{h1}</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              class="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div class="conatiner-fluid content-inner mt-n5 py-0">
        <div class="row">
          <div class="col-md-12 bg-grey rounded">
            <div class="row row-cols-1">
              <div class="col-12 mt-5 mb-3">
                {/* FILTERS */}
                <Filters 
                setParams={setParams} params={params} rol={rol} 
                />
              </div>
              <div className="col-md-12 mb-3">
                <div className="d-flex justify-content-end">
                  <BtnAccept
                    onClick={onClick}
                    name={"nuevo_contrato"}
                    value={"Añadir Contrato"}
                    loading={false}
                    loadingMsg={""}
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="card">
                    {
                      contract_actives || contract_close
                      ? status == "actives" && contract_actives 
                      ? <Actives 
                      data={contract_actives}
                      setParams={setParams}
                      params={params}
                      dispatch={dispatch}
                      navigate={navigate}
                      status={status}
                      />
                      : status == "close" &&  contract_close
                      ? <Close 
                      data={contract_close}
                      setParams={setParams}
                      params={params}
                      dispatch={dispatch}
                      navigate={navigate}
                      status={status}
                      />
                      : <Spinner/>
                      : <Spinner/>
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Contracts;
