import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";



const initialState = {
  providers: null,
  provider: null,
  state_count: null,
  state_count_entry: null,
  search: [],
};

export const providersSlice = createSlice({
  name: "providers",
  initialState: initialState,
  reducers: {
    setProvidersList: (state, action) => {
      state.providers = action.payload;
    },
    setSearchProviders: (state, action) => {
      state.search = action.payload;
    },
    setProvider: (state, action) => {
      state.provider = action.payload;
    },
    resetSearchProviders: (state, action) => {
      state.search = action.payload;
    },
    setStateCount: (state, action) => {
      state.state_count = action.payload;
    },
    setStateCountEntry: (state, action) => {
      state.state_count_entry = action.payload;
    },
  },
});

//Async functions
export const fetchProviders = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setProvidersList(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchProvider = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setProvider(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchSearch = (params) => (dispatch) => {
  dispatch(setSearchProviders(null))

  clientAxios
  .get(params.url, { params })
  .then((result) => {
    return dispatch(setSearchProviders(result.data.data));
  })
  .catch((error) => {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  });
}

export const fetchStateCount = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setStateCount(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};


export const fetchStateCountEntry = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setStateCountEntry(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};


export const { setProvidersList, setStateCountEntry, setStateCount, setSearchProviders, resetSearchProviders, setProvider } = providersSlice.actions;

export const providersState = (state) => state.providers;

export default providersSlice.reducer;
