import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../../components/loads/Spinner";
import Table from "../../../components/tables/Table";
import { ConcatOrderEntry } from "../../../utils/ConcatOrderEntry";

const ContentOrderEntry = ({ data, title }) => {
  const navigate = useNavigate();

  //Tabla
  const campos = [
    {
      name: "O/C",
      order: false,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "O/R",
      order: false,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "PRODUCTOS",
      order: false,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "",
      order: false,
      class: "border-0 d-flex justify-content-center align-items-center",
    },
  ];

  const order = ["oc_name", "or_name", "products"];
  const contents = data ? ConcatOrderEntry(data.data) : null;
  const actions = [
    {
      title: "Ingresar",
      name: "ingresar",
      icon: "",
      id: "llegada",
      
      class:
        "btn btn-primary border-0 d-flex align-items-center justify-content-center",
      text: "Ingresar",
    },
  ];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = data
    ? data
    : {
        current_page: 0,
        first_page_url: 0,
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: "",
        to: 0,
        total: 0,
      };

  const pagination = {
    type: 2,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "ingresar":
        console.log(value);

        return navigate("/dashboard/orderentry/pending/"+value.purchase_order_id);
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "":
        break;
    }
  };
  return (
    <div className="col-md-12">
      <div className="d-flex mb-3">
        <h2 className="me-5">{title}</h2>
        <Link
          to={"/dashboard/orderentry/pending"}
          className="d-flex align-items-center btn-link"
        >
          Ver todos
          <svg
            className="ms-2"
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
              fill="#8A92A6"
            />
          </svg>
        </Link>
      </div>
      
      <div className="" id="search">
      <div className="card card-body rounded-pill d-flex justify-content-around text-center py-3">
      <div className="input-group search-input">
        <input
          type="search"
          className="form-control"
          placeholder="Buscar órden de compra"
          name="search"
          onChange={(event) => onChange(event)}
        />
        <svg
          className="ms-3"
          width="20px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="11.7669"
            cy="11.7666"
            r="8.98856"
            stroke="#F16A1B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></circle>
          <path
            d="M18.0186 18.4851L21.5426 22"
            stroke="#F16A1B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </div>
    </div>
      </div>

      <div
        className="overflow-hidden card"
        data-aos="fade-up"
        data-aos-delay="600"
      >
        <div className="p-0 card-body">
          {data ? (
            <Table
              hidden_Filters={true}
              campos={campos}
              order={order}
              contents={contents}
              actions={actions}
              pagination={pagination}
              onClick={onClick}
              onChange={onChange}
            />
          ) : (
            <div><Spinner/></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentOrderEntry;
