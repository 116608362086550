import { useState } from "react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authContext } from "../../../context/AuthContext";
import { fetchOrderPurchaseList } from "../../../slices/orderPurchase/Index";

const Filters = ({
  h1,
  URL,
  fetch,
  initialParams,
  btn
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [params, setParams] = useState({
    url: URL,
    search: ""
  })

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":{

        setParams({
          ...params,
          search: e.target.value
        })

        if(e.target.value.length > params.search.length){
          return dispatch(
            fetch({initialParams})
          );
        }

        if (e.target.value.trim() == "") {
          return dispatch(fetch({initialParams}));
        }
      }break;
    }
  }
  return (
    <div
    className="col-md-12"
    id="search"
    data-aos="fade-up"
    data-aos-delay="600"
  >
    <div className="d-flex justify-content-between mb-2">
    <h2 className="mb-3">{h1}
    </h2>  
    <div>
    {
      btn
      ? <label className="btn btn-secondary py-2"
      >Ver todos
      <button className="d-none" onClick={(event) => navigate(btn.redirect)}></button></label>
      : ""
    }
    </div>
    </div>
    <div className="card card-body rounded-pill d-flex justify-content-around text-center py-3">
      <div className="input-group search-input">
        <input
          type="search"
          className="form-control"
          placeholder="Buscar órden de compra"
          name="search"
          onChange={(event) => onChange(event)}
        />
        <svg
          className="ms-3"
          width="20px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="11.7669"
            cy="11.7666"
            r="8.98856"
            stroke="#F16A1B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></circle>
          <path
            d="M18.0186 18.4851L21.5426 22"
            stroke="#F16A1B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </div>
    </div>
  </div>
  )
}

export default Filters