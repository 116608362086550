const CheckBox = ({
  onChange,
  label,
  name,
  type,
  value,
  uniqueKey,
  disabled,
}) => {
  return (
    <div className="form-check w-100">
      <input
        type={type}
        name={name}
        onChange={(event) => onChange(event, value)}
        onKeyUp={(event) => {
          if (parseInt(event.keyCode) == 13) {
            if (document.getElementById(uniqueKey).checked) {
              document.getElementById(uniqueKey).checked = false;
              onChange(event, value)
            } else {
              document.getElementById(uniqueKey).checked = true;
              onChange(event, value)
            }
          }
        }}
        className="form-check-input m-0 my-1 me-1 input-checkbox"
        id={uniqueKey}
        checked={value}
        disabled={disabled}
      />
      <label className="form-check-label" for="customCheck1">
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
