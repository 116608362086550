import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/loads/Spinner";
import clientAxios from "../../../config/ClientAxios";
import { authContext } from "../../../context/AuthContext";
import ModalDrumsDetails from "../../drums/modals/ModalDrumsDetails";
import ProductsDetails from "./ProductsDetails";

const OrderDetails = ({}) => {
  const { id } = useParams();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_SHOW_PURCHASE } = values;

  //Initial States
  const [order, setOrder] = useState(null);
  const {
    buyer,
    comments,
    created_at,
    date,
    order_state_id,
    payment_method_id,
    payment_method,
    prepaid,
    honey_drums,
    provider_id,
    provider,
    purchase_details,
    sector_id,
    updated_at,
    user_id,
  } = order
    ? order
    : {
        buyer: "",
        comments: "",
        created_at: "",
        date: "",
        order_state_id: "",
        payment_method: { name: "" },
        payment_method_id: "",
        prepaid: "",
        provider: { business_name: "" },
        provider_id: "",
        purchase_details: [],
        sector_id: "",
        updated_at: "",
        user_id: "",
      };

  const [modalDrumsDetails, setModalDrumDetails] = useState(false);
  const [select, setSelect] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      let params = { id: id };
      const response = await clientAxios.get(URL_SHOW_PURCHASE, { params });
      setOrder(response.data[0]);
    };

    fetch();
  }, []);

  console.log(order);
  return (
    <main class="main-content">
      {order ? (
        <div class="position-relative iq-banner">
          <div class="container">
            <div class="card my-5 p-5">
              <div class="row">
                <div class="col-md-12">
                  <h1>Detalle</h1>
                  <label className="d-none">
                  <img
                    src="/assets/images/icon-print.svg"
                    width="20px"
                    title="imprimir"
                  />
                    <button className="d-none"
                    name=""></button>
                  </label>
                  <hr />
                </div>
                <div class="col-md-3 my-2">
                  <h5 class="mb-3">Fecha</h5>
                  <p>{format(new Date(date+" 00:00:00"), "dd/MM/yyyy").toString()}</p>
                </div>
                <div class="col-md-3 my-2">
                  <h5 class="mb-3">Comprador</h5>
                  <p>{buyer}</p>
                </div>
                <div class="col-md-3 my-2">
                  <h5 class="mb-3">Proveedor</h5>
                  <p>{provider.business_name}</p>
                </div>
                <div class="col-md-3 my-2">
                  <h5 class="mb-3">Tipo de pago</h5>
                  <p>{payment_method.name}</p>
                </div>
                <div class="col-md-3 my-2">
                  <h5 class="mb-3">Pago anticipado</h5>
                  <p>{prepaid == 0 ? "NO" : "SI"}</p>
                </div>
                <div class="col-md-3 my-2">
                  <h5 class="mb-3">Ubicación</h5>
                  <p class="mb-0">{provider.address}</p>
                  <strong>
                    {provider.city.name}, {provider.province.name}.
                  </strong>
                </div>

                <div class="col-md-12 mt-5">
                  <h2>Transacciones realizadas</h2>
                </div>

                <div className="col-md-12 mt-5">
                  {purchase_details.map((details) => (
                    <>
                    <ProductsDetails data={details} honey_drums={honey_drums} modalDrumsDetails={modalDrumsDetails} setModalDrumDetails={setModalDrumDetails} setSelect={setSelect} select={select}/>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {
              modalDrumsDetails
              ? <ModalDrumsDetails 
                setModal={setModalDrumDetails}
                select={select}
              />
              : ""
            }
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <Spinner />
        </div>
      )}
    </main>
  );
};

export default OrderDetails;
