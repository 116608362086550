export const OrderEditableKeys = (contents, orders) => {


  let result = [];

  orders.map((order) => {
  Object.keys(contents).map((key) => {
    
    if(order.property == key){
      order = {
        ...order,
        value: contents[key],
        index: contents["key"]
      }

      /* Color picket */
      if(order.value_color){
        order = {
          ...order,
          value_color: {
            color_primary: contents[order.value_color.color_primary],
            color_secondary: contents[order.value_color.color_secondary]
          },
        }
      }

      result.push(order);
    }
    })
})


  return result
}