import { format } from "date-fns";
import { useEffect } from "react";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import Alerts from "../../../components/alerts/Alerts";
import BtnAccept from "../../../components/forms/BtnAccept";
import CheckBox from "../../../components/forms/CheckBox";
import Input from "../../../components/forms/Input";
import InputSearchMini from "../../../components/forms/InputSearchMini";
import Select from "../../../components/forms/Select";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { fetchOrderWithdrawal } from "../../../slices/orderwithdrawal/Index";
import {
  fetchOrderPurchaseList,
  fetchSearchPurchase,
  orderPurchaseState,
  setSearch,
} from "../../../slices/orderPurchase/Index";
import {
  fetchSearch,
  resetSearchProviders,
  setSearchProviders,
} from "../../../slices/providers/Index";
import { fetchUsers, meState } from "../../../slices/users/Index";
import { fetchVehicles, vehicleState } from "../../../slices/vehicles/Index";
import {
  fetchWareHous,
  wareHouseState,
} from "../../../slices/wareHouses/Index";
import { ConcatOrderPurchase } from "../../../utils/ConcatOrderPurchase";
import { ConcatVehicles } from "../../../utils/ConcatVehicles";
import { Fill } from "../../../utils/Fill";
import { Validate } from "../../../utils/ValidateForms";
import ModalAddOrders from "./ModalAddOrders";

const ModalOrderWithdrawal = ({
  setModal,

  dispatch,
}) => {
  /*********************************************** INITIAL STATE ***********************************************************/
  const context = useContext(authContext);
  const { values, forms, validations, initial } = context;
  const {
    URL_INDEX_PURCHASE,
    URL_GENERATE_ORDER_WITHDRAWAL,
    URL_INDEX_WITHDRAWAL,
    URL_INDEX_VEHICLES,
    URL_INDEX_USERS,
    URL_INDEX_WAREHOUSES,
  } = values;
  const { FORM_ORDER_WITHDRAWAL } = forms;
  const { VALIDATION_ORDER_WITHDRAWAL } = validations;
  const { DESTINO, TRANSPORTISTA, VEHICULO } = initial;

  const _users = useSelector(meState);
  const _wareHouses = useSelector(wareHouseState);
  const _vehicles = useSelector(vehicleState);
  const _orderPurchase = useSelector(orderPurchaseState);

  const orderPurchase = _orderPurchase.search;
  const vehicles = _vehicles.vehicles;
  const wareHouses = _wareHouses.wareHouses;
  const users = _users.users_logistic;

  const [selectOrders, setSelectOrders] = useState([]);
  const [modalAddOrders, setModalAddOrders] = useState(false);
  const [loading, setLoading] = useState(false);
  //params
  const [params, setParams] = useState({
    url: URL_INDEX_PURCHASE,
  });

  const [paramsVehicles, setParamsVehicles] = useState({
    url: URL_INDEX_VEHICLES,
  });

  //Forms
  const [form, setForm] = useState(
    JSON.parse(JSON.stringify(FORM_ORDER_WITHDRAWAL))
  );

  const [validation, setValidation] = useState({
    ...VALIDATION_ORDER_WITHDRAWAL,
  });

  const initialState = {
    date: new Date(),
  };

  const { date } = initialState;
  /**********************************************************************************************************************/

  const onClick = async (e, value) => {
    e.preventDefault();
    switch (e.target.name) {
      case "quitar-orden":
        {
          let array = selectOrders;

          if (array.indexOf(value) < 0) {
            array.push(value);
          } else {
            array = array.filter((id) => value !== id);
          }

          return setSelectOrders([...array]);
        }
        break;

      case "añadir_orden":
        {
          let array = selectOrders;

          if (array.indexOf(value.id) < 0) {
            array.push(value.id);
          } else {
            array.filter((id) => value.id !== id);
          }
          setSelectOrders([...array]);
          return dispatch(setSearch([]));
        }
        break;
      /*
        case "añadir-orden":
        {
          setModalAddOrders(true);
        }
        break;
      */
      case "close":
        {
          //Close modal
          setModal(false);
        }
        break;
      case "aceptar":
        {
          //Delete property
          const validation = Validate(form);
          setValidation(validation);
          //Validaciones
          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "201",
              type: "error",
            });
          }

          //Validaciones
          if (selectOrders.length < 0) {
            return Alerts({
              msg: "Warning",
              title: "Órden de compra no seleccionada.",
              code: "201",
              type: "error",
            });
          }

          //create form
          let formData = new FormData();
          selectOrders.map((order, index) => {
            formData.append("purchase_orders[" + index + "]", order);
          });
          formData.append("warehouse_id", form.destino[0]);
          formData.append("vehicle_id", form.vehiculo[0]);
          formData.append("user_id", form.transportista[0]);
          formData.append("pesos", form.pesos_entregados[0]);
          formData.append("dollars", form.dolares_entregados[0]);
          formData.append("date", form.fecha[0]);

          //
          const response = await ApiRest({
            url: URL_GENERATE_ORDER_WITHDRAWAL,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Creando nueva órden de retiro",
              code: "201",
              type: "success",
            });

            setSelectOrders([]);

            //cerrar modal
            return setModal(false);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "aceptar_nuevo":
        {
        }
        break;
      case "guardar":
        {
        }
        break;
      default:
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "prioritario":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = e.target.checked;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
      case "dolares_entregados":
      case "pesos_entregados":
      case "vehiculo":
      case "transportista":
      case "destino":
      case "fecha":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
      case "searchOrderPurchase":
        {
          let type = e.target.value.slice(0, 2).toLowerCase();


          switch (type) {
            case "oc":
              {
                //form
                let setCampo = form;

                //
                let params = {
                  url: URL_INDEX_PURCHASE,
                  search: e.target.value.slice(2, e.target.value.length),
                  state_id: 1,
                  with_withdrawal_order: 0,
                };

                if (
                  e.target.value.length > 0 &&
                  e.target.value.length > setCampo["order_purchase"][0].length
                ) {
                  dispatch(fetchSearchPurchase(params));
                }

                if (e.target.value.trim() == "") {
                  dispatch(setSearch([]));
                }

                //Insert value form
                setCampo["order_purchase"][0] = e.target.value;

                setForm({
                  ...form,
                  proveedor: setCampo["order_purchase"],
                });
              }
            break;
            default:
              {
                //form
                let setCampo = form;

                //
                let params = {
                  url: URL_INDEX_PURCHASE,
                  search: e.target.value,
                  state_id: 1,
                  with_withdrawal_order: 0,
                };

                if (
                  e.target.value.length > 0 &&
                  e.target.value.length > setCampo["order_purchase"][0].length
                ) {
                  dispatch(fetchSearchPurchase(params));
                }

                if (e.target.value.trim() == "") {
                  dispatch(setSearch([]));
                }

                //Insert value form
                setCampo["order_purchase"][0] = e.target.value;

                setForm({
                  ...form,
                  proveedor: setCampo["order_purchase"],
                });
              }
              break;
          }
        }
        break;
    }
  };

  /********************API*****************/
  useEffect(() => {
    if (!orderPurchase) dispatch(fetchOrderPurchaseList(params));
  }, []);

  useEffect(() => {
    if (!vehicles) {
      dispatch(fetchVehicles(paramsVehicles));
    }
  }, []);

  useEffect(() => {
    if (!users) {
      let params = {
        url: URL_INDEX_USERS,
        role: "logistica",
      };
      dispatch(fetchUsers(params));
    }
  }, []);

  useEffect(() => {
    if (!wareHouses) {
      let params = {
        url: URL_INDEX_WAREHOUSES,
      };
      dispatch(fetchWareHous(params));
    }
  }, []);
  /********************LOGS*****************/
  //console.log(orderPurchase);
  return (
    <div
      className="modal fade show bg-dark bg-opacity-25  modal-transition bg-modal-blur"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 4 }}>
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div className="modal-header p-5">
            <h1 className="modal-title" id="nueva-ordenModalLabel">
              Nuevo órden de retiro -{" "}
              <span className="text-primary">N° 0000</span>
            </h1>
          </div>
          <div className="modal-body p-5">
            <form>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Fecha"}
                    name={"fecha"}
                    type={"date"}
                    value={form.fecha[0]}
                    validate={validation.fecha}
                  />
                </div>

                <div className="col-md-6">
                  <label className="mb-1">Prioritario</label>
                  <CheckBox
                    onChange={onChange}
                    label={"Si"}
                    name={"prioritario"}
                    type={"checkbox"}
                    value={form.prioritario[0]}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <Select
                    onChange={onChange}
                    label="Destino"
                    name="destino"
                    type=""
                    defaultValue={form.destino[0]}
                    value={wareHouses ? wareHouses : []}
                    showIndex="name"
                    sendIndex="id"
                    validate={validation.destino}
                    icon={"/assets/images/icon-arrow-input.svg"}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <Select
                    onChange={onChange}
                    label="Transportista"
                    name="transportista"
                    type=""
                    defaultValue={form.transportista[0]}
                    value={users ? users : []}
                    showIndex="name"
                    sendIndex="id"
                    validate={validation.transportista}
                    icon={"/assets/images/icon-arrow-input.svg"}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <Select
                    onChange={onChange}
                    label="Vehiculo"
                    name="vehiculo"
                    type=""
                    defaultValue={form.vehiculo[0]}
                    value={vehicles ? ConcatVehicles(vehicles.data) : []}
                    showIndex="name"
                    sendIndex="id"
                    validate={validation.vehiculo}
                    icon={"/assets/images/icon-arrow-input.svg"}
                  />
                </div>

                <div className="col-md-12">
                  <div className="fs-3">Efectivo</div>
                  <hr />
                </div>

                <div className="col-md-6 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Pesos entregados"}
                    name={"pesos_entregados"}
                    type={"number"}
                    value={form.pesos_entregados[0]}
                    validate={validation.pesos_entregados}
                    icon={"/assets/images/icon-moneda-input.svg"}
                    nameType={"$"}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Dólares entregados"}
                    name={"dolares_entregados"}
                    type={"number"}
                    value={form.dolares_entregados[0]}
                    validate={validation.dolares_entregados}
                    icon={"/assets/images/icon-moneda-input.svg"}
                    nameType={"$"}
                  />
                </div>

                <div className="col-md-12">
                  <div className="fs-3">Retiro</div>
                  <hr />
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="row w-100">
                    {selectOrders.map((order) => (
                      <label
                        className="col-md-3 mb-3 btn me-2 border border-dark px-3 text-dark"
                        title="Quitar órden"
                      >
                        {String(order).length >= 4
                          ? "OC " + order
                          : "OC " + Fill(order, 4)}
                        <button
                          className="d-none"
                          name="quitar-orden"
                          onClick={(event) => onClick(event, order)}
                        ></button>
                      </label>
                    ))}
                  </div>

                  <label className="btn border-dark m-0 text-dark d-none">
                    Añadir otra órden
                    <button
                      className="d-none"
                      name="añadir-orden"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                </div>

                <div className="col-md-6 mb-3">
                  <InputSearchMini
                    onChange={onChange}
                    label={"Nueva órden de compra"}
                    name={"searchOrderPurchase"}
                    type={"text"}
                    value={form.order_purchase[0]}
                    //Autocomplete
                    onClick={onClick}
                    nameAutocomplete="añadir_orden"
                    valueAutocomplete={
                      orderPurchase ? ConcatOrderPurchase(orderPurchase) : null
                    }
                    showIndex="oc_name"
                    sendIndex="id"
                    validate={{ msg: "", validate: false }}
                  />
                </div>

                <div className="col-md-12 d-flex justify-content-end align-items-end">
                  <div className="btn-group">
                    <BtnAccept
                      onClick={onClick}
                      name={"aceptar"}
                      value={"Aceptar"}
                      loading={loading}
                      loadingMsg={"Creando..."}
                    />
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="visually-hidden">Toggle Dropdown</span>
                    </button>
                    <ul className="dropdown-menu p-0 m-0">
                      <li>
                        <label className="dropdown-item p-0 m-0">
                          <BtnAccept
                            onClick={onClick}
                            name={"crear_nuevo"}
                            value={"Aceptar y nuevo"}
                            loading={loading}
                            loadingMsg={"Creando..."}
                          />
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* MODAL */}

      {modalAddOrders && orderPurchase ? (
        <ModalAddOrders
          data={orderPurchase}
          setModal={setModalAddOrders}
          selectOrders={selectOrders}
          setSelectOrders={setSelectOrders}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ModalOrderWithdrawal;
