import { useNavigate, useParams } from "react-router-dom";
import Filters from "./filters/Filters";

import { useContext, useEffect, useState } from "react";
import { authContext } from "../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderPurchaseList,
  orderPurchaseState,
} from "../../slices/orderPurchase/Index";
import Spinner from "../../components/loads/Spinner";
import Submitted from "./submitted/Index";
import Pending from "./pending/Index";
import ModalProviders from "../../components/modals/ModalProviders";
import ModalOrderPurchase from "../../components/modals/ModalOrderPurchase";
import BtnSticky from "../../components/forms/BtnSticky";
import { fetchSupplies, suppliesState } from "../../slices/supplies/Index";
import Actives from "./actives/Index";
import {
  fetchOrderWithdrawal,
  orderState,
} from "../../slices/orderwithdrawal/Index";
import { format } from "date-fns/esm";
import { add } from "date-fns";
import { meState } from "../../slices/users/Index";

const OrderWithdrawal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { label } = useParams();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_WITHDRAWAL, URL_INDEX_PURCHASE, URL_INDEX_EQUIVALENCE } =
    values;
  //Initial States
  const _user = useSelector(meState);
  const _supplies = useSelector(suppliesState);
  const _orderPurchase = useSelector(orderPurchaseState);
  const _withdrawal_order = useSelector(orderState);

  const withdrawal_order_submitted =
    _withdrawal_order.withdrawal_order_submitted;
  const withdrawal_order_actives = _withdrawal_order.withdrawal_order_actives;
  const withdrawal_order_pending = _withdrawal_order.withdrawal_order_pending;
  const withdrawal_order = _withdrawal_order.withdrawal_order;

  const orderPurchase = _orderPurchase.orderPurchase;
  const supplies = _supplies.supplies;
  const me = _user.me;
  const rol_id = me.roles[0].id;

  const [select, setSelect] = useState(null);

  //Modals
  const [modalProviders, setModalProviders] = useState(false);
  const [modalOrderPurchase, setModalOrderPurchase] = useState(false);

  //params
  const [paramsWithdrawal, setParamsWithdrawal] = useState({
    url: URL_INDEX_WITHDRAWAL,
    ["state_id[0]"]:
      label == "pending"
        ? 1
        : label == "submitted"
        ? 2
        : label == "actives"
        ? 3
        : null,
    ["state_id[1]"]:
      label == "pending"
        ? 4
        : label == "submitted"
        ? 2
        : label == "actives"
        ? 3
        : null,
    search: "",
    start_date: format(
      add(new Date(), { months: -12 }),
      "yyyy-MM-dd"
    ).toString(),
    end_date: format(new Date(), "yyyy-MM-dd").toString(),
    sort: [
      /*
      {
        order_by: "date",
        ordering: "desc",
      },
      */
      {
        order_by: "id",
        ordering: "desc",
      },
      /*
      {
        order_by: "providers.business_name",
        ordering: "desc",
      },
      */
    ],
  });

  const [params, setParams] = useState({
    url: URL_INDEX_PURCHASE,
  });

  const [paramsEquivalence, setParamsEquivalence] = useState({
    url: URL_INDEX_EQUIVALENCE,
  });

  //Title
  const h1 =
    label == "pending"
      ? "Órdenes de retiro"
      : label == "submitted"
      ? "Órdenes entregadas"
      : label == "actives"
      ? "Órdenes activas"
      : "";

  //Api
  //
  useEffect(() => {
    if (!supplies) {
      dispatch(fetchSupplies(paramsEquivalence));
    }
  }, [paramsEquivalence]);

  useEffect(() => {
    if (!orderPurchase) dispatch(fetchOrderPurchaseList(params));
  }, [params]);

  useEffect(() => {
    if (
      !withdrawal_order ||
      !withdrawal_order_actives ||
      !withdrawal_order_pending ||
      !withdrawal_order_submitted
    ) {
      let params = {
        url: URL_INDEX_WITHDRAWAL,
        ["state_id[0]"]:
          label == "pending"
            ? 1
            : label == "submitted"
            ? 2
            : label == "actives"
            ? 3
            : null,
        ["state_id[1]"]:
          label == "pending"
            ? 4
            : label == "submitted"
            ? 2
            : label == "actives"
            ? 3
            : null,
        sort: [
          /*
              {
                order_by: "date",
                ordering: "desc",
              },
              */
          {
            order_by: "id",
            ordering: "desc",
          },
          /*
              {
                order_by: "providers.business_name",
                ordering: "desc",
              },
              */
        ],
      };
      dispatch(fetchOrderWithdrawal(params));
    }
  }, [label]);
  return (
    <main class="main-content">
      <div class="position-relative iq-banner">
        <div class="iq-navbar-header" style={{ height: "215px" }}>
          <div class="container-fluid iq-container">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center">
                  <div class="box-img">
                    <img
                      src="/assets/images/icon-ordenes-de-compra.svg"
                      width="80%"
                    />
                  </div>
                  <h1 class="ms-2 mb-0">{h1}</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              class="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div class="conatiner-fluid content-inner mt-n5 py-0">
        <div class="row">
          <div class="col-md-12 bg-grey rounded">
            <div class="row row-cols-1">
              <div class="col-12 mt-5 mb-3">
                {/* Filters */}
                <Filters
                  setParams={setParamsWithdrawal}
                  params={paramsWithdrawal}
                  dispatch={dispatch}
                  label={label}
                />
              </div>

              <div className="col-md-12">
                <div class="card">
                  {withdrawal_order ||
                  withdrawal_order_pending ||
                  withdrawal_order_submitted ||
                  withdrawal_order_actives ? (
                    label == "pending" && withdrawal_order_pending ? (
                      <Pending
                        data={withdrawal_order_pending}
                        setParams={setParamsWithdrawal}
                        params={paramsWithdrawal}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={setModalProviders}
                        navigate={navigate}
                        label={label}
                      />
                    ) : label == "submitted" && withdrawal_order_submitted ? (
                      <Submitted
                        data={withdrawal_order_submitted}
                        setParams={setParamsWithdrawal}
                        params={paramsWithdrawal}
                        navigate={navigate}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={setModalProviders}
                        label={label}
                      />
                    ) : label == "actives" && withdrawal_order_actives ? (
                      <Actives
                        data={withdrawal_order_actives}
                        setParams={setParamsWithdrawal}
                        params={paramsWithdrawal}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={setModalProviders}
                        label={label}
                      />
                    ) : (
                      <Spinner />
                    )
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(modalProviders && rol_id == 5) || (modalProviders && rol_id == 1) ? (
        <ModalProviders
          /**PRIMARY***/
          setSelect={setSelect}
          select={select}
          setModalProviders={setModalProviders}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}

      {(modalOrderPurchase && rol_id == 5) ||
      (modalOrderPurchase && rol_id == 1) ? (
        <ModalOrderPurchase
          setSelect={setSelect}
          select={select}
          setModalOrderPurchase={setModalOrderPurchase}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}

      {rol_id == 5 || rol_id == 1 ? (
        <BtnSticky
          setModalProviders={setModalProviders}
          setModalOrderPurchase={setModalOrderPurchase}
        />
      ) : (
        ""
      )}
    </main>
  );
};

export default OrderWithdrawal;
