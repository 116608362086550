import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  select: null,
};

export const selectSlice = createSlice({
  name: "select",
  initialState: initialState,
  reducers: {
    setSelect: (state, action) => {
      state.select = action.payload;
    },
  },
});


export const { setSelect } = selectSlice.actions;

export const selectState = (state) => state.select;

export default selectSlice.reducer;
