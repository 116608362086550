import { Provider } from "react-redux"
import store from "../store/Index";

const MasterProvider = ({children}) => {


  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}

export default MasterProvider