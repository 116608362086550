const Toogle = ({
  onChange,
  label,
  name,
  type,
  value,
  uniqueKey,
  disabled,
}) => {
  return (
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id={uniqueKey}
          name={name}
          onChange={(event) => onChange(event, value)}
          onKeyUp={(event) => {
            if (parseInt(event.keyCode) == 13) {
              if (document.getElementById(uniqueKey).checked) {
                document.getElementById(uniqueKey).checked = false;
                onChange(event, value);
              } else {
                document.getElementById(uniqueKey).checked = true;
                onChange(event, value);
              }
            }
          }}
          checked={value}
          disabled={disabled}
        />
        <label class="form-check-label" for="flexSwitchCheckDefault">
          {label}
        </label>
      </div>
  );
};

export default Toogle;
