const Spinner = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div class="spinner-grow me-1 bg-miel" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow me-1 bg-miel" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow me-1 bg-miel" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
