import { configureStore } from "@reduxjs/toolkit";
// reducer
import geolocation from "../slices/geolocation";
import providers from "../slices/providers/Index";
import supplies from "../slices/supplies/Index"
import products from "../slices/products/Index"
import orderPurchase from "../slices/orderPurchase/Index"
import paymentMethods from "../slices/paymentMethods/Index"
import me from "../slices/users/Index"
import order from "../slices/orderwithdrawal/Index"
import vehicles from "../slices/vehicles/Index"
import wareHouses from "../slices/wareHouses/Index"
import orderEntry from "../slices/orderEntry/Index"
import laboratory from "../slices/laboratory/Index"
import select from "../slices/select/Index"
import clients from "../slices/clients/Index"
import contracts from "../slices/contracts/Index"
import drums from "../slices/drums/Index"
import expoLot from "../slices/exportLot/Index"
import homogenize from "../slices/homogenize/Index"
import requestOrder from "../slices/requestOrder/Index"
import colors from "../slices/colors/Index"
import notifications from "../slices/notifications/Index"


export default configureStore({
  reducer: {
    me,
    geolocation,
    providers,
    supplies,
    products,
    orderPurchase,
    paymentMethods,
    order,
    vehicles,
    wareHouses,
    orderEntry,
    laboratory,
    clients,
    contracts,
    drums,
    expoLot,
    homogenize,
    requestOrder,
    colors,
    notifications,


    //Global functions
    select
  }
})