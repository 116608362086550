import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
// Import Swiper styles
import "swiper/css";
import { Link } from "react-router-dom";

const ContentSwiper = ({
  SwiperMediaQuery,
  lineLink,

  contents,
  onClick,
}) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={SwiperMediaQuery}
      navigation
      className="mb-2 swiper-wrapper list-inline"
    >
      {contents.map((item) => (
        <SwiperSlide
          className="swiper-slide card card-slide"
          style={{ width: "206.667px", marginRight: "35px" }}
        >
          <div className="card-body d-flex align-items-center justify-content-around">
            <div className="progress-detail">
              <h4 className="counter">{item.total}</h4>
              <p className="mb-0">{item.title}</p>
              <label className="btn p-0 m-0 d-flex align-items-center btn-link">
                {item.lineLink}
                <svg
                  className="ms-2"
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                    fill="#8A92A6"
                  />
                </svg>
                <button
                  className="d-none"
                  name={item.name}
                  
                  onClick={(event) => onClick(event, item.link)}
                ></button>
              </label>
            </div>
            <div className="d-flex align-self-end">
              <img src={item.icon} alt="" width={"30"} loading="lazy" />
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ContentSwiper;
