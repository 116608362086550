import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  //Lista
  requestOrder_pending: null,
  requestOrder_request: null,

  search: null,
}

export const requestOrderSlice = createSlice({
  name: "requestOrder",
  initialState: initialState,
  reducers: {
    setRequestOrder_pendig: (state, action) => {
      state.requestOrder_pending = action.payload
    },
    setRequestOrder_request: (state, action) => {
      state.requestOrder_request = action.payload
    },
   
  },
});


//Async functions
export const fetchRequestOrder = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params.finished) {
        case 0:{
            dispatch(setRequestOrder_pendig(result.data))
        }break;
        case 1:{
          dispatch(setRequestOrder_request(result.data))
      }break;
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const {setRequestOrder_pendig, setRequestOrder_request} = requestOrderSlice.actions;

export const requestOrderState = (state) => state.requestOrder;

export default requestOrderSlice.reducer;