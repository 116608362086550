import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Spinner from "../../components/loads/Spinner";
import Li_NavLink from "../../components/menu/Li_NavLink";
import { BtnDialConst } from "../../consts/BtnDialConst";
import { RolNavLinks } from "../../consts/NavConst";
import { authContext } from "../../context/AuthContext";
import { Logout } from "../../helpers/Logout";
import { fetchMe, meState } from "../../slices/users/Index";
import NavBar from "../navBar/NavBar";

const SidebarMenu = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { values } = context;
  const { URL_ME } = values;
  const user = useSelector(meState);
  const me = user.me;
  const auth = user.auth;
  const rol = me ? me.roles[0] : null;

  //SelectMenu
  const [menu, setMenu] = useState(me ? rol.id : 0);
  const [btn, setBtn] = useState(me ? rol.id : 0);

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "carga-masiva": {
        navigate("/masivestore")
      } break;
      case "navBar-subMenu":
        {
          if (
            document
              .getElementById("submenu1-" + value)
              .classList.contains("show")
          ) {
            document
              .getElementById("btn-submenu1-" + value)
              .classList.add("collapsed");
            document
              .getElementById("submenu1-" + value)
              .classList.remove("show");
          } else {
            document
              .getElementById("btn-submenu1-" + value)
              .classList.remove("collapsed-" + value);
            document.getElementById("submenu1-" + value).classList.add("show");
          }
        }
        break;
      case "navbar-toggle":
        {
          if (
            document.getElementById("navBar-toggle").classList.contains("show")
          ) {
            document.getElementById("navBar-toggle").classList.remove("show");
          } else {
            document.getElementById("navBar-toggle").classList.add("show");
          }
        }
        break;
      case "sidebar-toggle":
        {
          if (
            document
              .getElementById("sidebar-nav")
              .classList.contains("sidebar-mini")
          ) {
            document
              .getElementById("sidebar-nav")
              .classList.remove("sidebar-mini");

            document.getElementById("img-logo").classList.remove('d-none');
            document.getElementById("img-abeja").classList.remove('d-none');
            document.getElementById("text-logout").classList.remove('d-none');
            document.getElementById("sidebar-menu").classList.remove('d-none');
            document.getElementById("content-main").classList.remove('m-0');
          } else {
            document
              .getElementById("sidebar-nav")
              .classList.add("sidebar-mini");
            document.getElementById("img-logo").classList.add('d-none');
            document.getElementById("img-abeja").classList.add('d-none');
            document.getElementById("text-logout").classList.add('d-none');
            document.getElementById("sidebar-menu").classList.add('d-none');
            document.getElementById("content-main").classList.add('m-0');
          }
        }
        break;
      case "changeMenu":
        {
          setMenu(value);
          setBtn(value);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!me) {
      let params = {
        url: URL_ME,
      };
      dispatch(fetchMe(params));
    }
  }, []);

  useEffect(() => {
    setMenu(me ? rol.id : 0);
    setBtn(me ? rol.id : 0);
  }, [me]);

  const links = me ? RolNavLinks(menu, rol.id) : [];
  return (
    <div className="">
      {!me ? (
        ""
      ) : (
        <aside
          className="sidebar sidebar-default navs-rounded-all on-resize"
          id="sidebar-nav"
        >
          <div className="sidebar-header d-flex align-items-center justify-content-start">
            <label className="sidebar-toggle d-md-block d-lg-block">
              <i className="icon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.25 12.2744L19.25 12.2744"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </i>
              <button
                className="d-none"
                data-toggle="sidebar"
                data-active="true"
                name="sidebar-toggle"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </div>
          <div className="sidebar-body pt-0 data-scrollbar">
            <div className="sidebar-list">
              <Link to={"/dashboard/home"} className="navbar-nav iq-main-menu me-1" id="img-logo">
                <img
                  src="/assets/images/geomiel-logo.svg"
                  alt="Geomiel"
                  height="44px"
                />
              </Link>
              <ul className="navbar-nav iq-main-menu" id="sidebar-menu" style={{maxHeight:'calc(100vh - 170px)', overflowY:'scroll'}}>
                {links.map((link, index) => {
                  if (!link.dropdown && !link.hidden) {
                    if (link.url) {
                      return (
                        <Li_NavLink
                          url={link.url}
                          text={link.text}
                          dropdown={link.dropdown}
                        />
                      );
                    } else {
                      return (
                        <li className="nav-item">
                          <label
                            className={
                              menu == link.value
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            {menu == link.rol ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-left"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                />
                              </svg>
                            ) : (
                              ""
                            )}

                            <span className="item-name">{link.text}</span>
                            <button
                              className="d-none"
                              name={link.name}
                              onClick={(event) => onClick(event, link.value)}
                            ></button>
                          </label>
                        </li>
                      );
                    }
                  } else {
                    if (!link.hidden) {
                      return (
                        <li className="nav-item">
                          <label
                            className="nav-link w-100 border-0 collapsed"
                            id={"btn-submenu1-" + index}
                          >
                            <span className="item-name">{link.text}</span>
                            <i className="right-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 5l7 7-7 7"
                                />
                              </svg>
                            </i>
                            <button
                              className="d-none"
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              aria-controls="horizontal-menu-01"
                              href="#"
                              name="navBar-subMenu"
                              onClick={(event) => onClick(event, index)}
                            ></button>
                          </label>
                          <ul
                            className="sub-nav collapse"
                            id={"submenu1-" + index}
                            data-bs-parent="#sidebar-menu"
                          >
                            {link.subMenu.map((subLink) => (
                              <Li_NavLink
                                url={subLink.url}
                                text={subLink.text}
                                dropdown={subLink.dropdown}
                                icon={subLink.icon}
                              />
                            ))}
                          </ul>
                        </li>
                      );
                    }
                  }
                })}
              </ul>


            </div>
            <div className="sidebar-footer d-xxl-block d-sm-none" style={{position: "block"}}>
              <div className="w-100 d-flex justify-content-center complementos" id="img-abeja">
                  </div>
                  <div className="w-100 d-flex justify-content-center align-items-end" id="text-logout">
                    <button
                      className="nav-link border-0 bg-transparent w-100 px-2"
                      name="logout"
                      onClick={(event) => Logout(navigate, dispatch)}
                    >
                      <svg
                        width="19"
                        height="16"
                        viewBox="0 0 19 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2294_142710)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.592 12.4681C11.592 12.5998 11.5335 12.7261 11.4295 12.8192C11.3255 12.9123 11.1844 12.9646 11.0374 12.9646H2.16379C2.0167 12.9646 1.87564 12.9123 1.77163 12.8192C1.66763 12.7261 1.6092 12.5998 1.6092 12.4681V3.5306C1.6092 3.39891 1.66763 3.27262 1.77163 3.1795C1.87564 3.08639 2.0167 3.03407 2.16379 3.03407H11.0374C11.1844 3.03407 11.3255 3.08639 11.4295 3.1795C11.5335 3.27262 11.592 3.39891 11.592 3.5306V5.51672C11.592 5.6484 11.6504 5.7747 11.7544 5.86781C11.8584 5.96093 11.9995 6.01324 12.1465 6.01324C12.2936 6.01324 12.4347 5.96093 12.5387 5.86781C12.6427 5.7747 12.7011 5.6484 12.7011 5.51672V3.5306C12.7011 3.13554 12.5259 2.75666 12.2138 2.47731C11.9018 2.19795 11.4786 2.04102 11.0374 2.04102H2.16379C1.72253 2.04102 1.29934 2.19795 0.987314 2.47731C0.675292 2.75666 0.5 3.13554 0.5 3.5306L0.5 12.4681C0.5 12.8632 0.675292 13.2421 0.987314 13.5214C1.29934 13.8008 1.72253 13.9577 2.16379 13.9577H11.0374C11.4786 13.9577 11.9018 13.8008 12.2138 13.5214C12.5259 13.2421 12.7011 12.8632 12.7011 12.4681V10.482C12.7011 10.3503 12.6427 10.224 12.5387 10.1309C12.4347 10.0378 12.2936 9.98547 12.1465 9.98547C11.9995 9.98547 11.8584 10.0378 11.7544 10.1309C11.6504 10.224 11.592 10.3503 11.592 10.482V12.4681Z"
                            fill="#F16A1B"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.0851 8.35082C18.1368 8.3047 18.1777 8.2499 18.2057 8.18958C18.2336 8.12926 18.248 8.06459 18.248 7.99928C18.248 7.93397 18.2336 7.8693 18.2057 7.80897C18.1777 7.74865 18.1368 7.69386 18.0851 7.64773L14.7575 4.66856C14.6534 4.57533 14.5121 4.52295 14.3649 4.52295C14.2176 4.52295 14.0763 4.57533 13.9722 4.66856C13.8681 4.7618 13.8096 4.88825 13.8096 5.02011C13.8096 5.15196 13.8681 5.27841 13.9722 5.37165L16.3536 7.50275H6.6005C6.45341 7.50275 6.31234 7.55506 6.20834 7.64818C6.10433 7.7413 6.0459 7.86759 6.0459 7.99928C6.0459 8.13096 6.10433 8.25726 6.20834 8.35038C6.31234 8.44349 6.45341 8.49581 6.6005 8.49581H16.3536L13.9722 10.6269C13.8681 10.7201 13.8096 10.8466 13.8096 10.9784C13.8096 11.1103 13.8681 11.2368 13.9722 11.33C14.0763 11.4232 14.2176 11.4756 14.3649 11.4756C14.5121 11.4756 14.6534 11.4232 14.7575 11.33L18.0851 8.35082Z"
                            fill="#F16A1B"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2294_142710">
                            <rect
                              width="17.7471"
                              height="15.8889"
                              fill="white"
                              transform="translate(0.5 0.0546875)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      Cerrar sesión
                    </button>
                  </div>
            </div>
          </div>
        </aside>
      )}

      {!me ? (
        auth == "authenticated" ? (
          <div className="d-flex justify-content-center align-items-center min-vh-100">
            <Spinner />
          </div>
        ) : auth == false ? (
          navigate("/login")
        ) : (
          ""
        )
      ) : (
        <main className="main-content" id="content-main">
          {/* Menu de usuario */}
          <NavBar onClick={onClick} />
          {children}
        </main>
      )}

      {
        !me
          ? ""
          : <BtnDialConst btn={btn} rol={rol} />
      }
    </div>
  );
};

export default SidebarMenu;
