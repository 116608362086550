import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BtnAccept from "../../components/forms/BtnAccept";
import Spinner from "../../components/loads/Spinner";
import { authContext } from "../../context/AuthContext";
import { clientState, fetchClients } from "../../slices/clients/Index";
import { setSelect } from "../../slices/select/Index";
import { meState } from "../../slices/users/Index";
import Filters from "./filters/Filters";
import ModalClient from "./modals/ModalClient";
import TableClients from "./tables/tableClients";

const Clients = () => {
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_CUSTOMER } = values;

  const _user = useSelector(meState);
  const _clients = useSelector(clientState);
  const clients = _clients.clients;
  const me = _user.me;
  const rol = me.roles[0];
  const rol_id = me.roles[0].id;

  const [select, setSelect] = useState(null);
  const [modalClient, setModalClient] = useState(false);

  //params
  const [params, setParams] = useState({
    url: URL_INDEX_CUSTOMER,
    start_date: "",
    end_date: "",
    sort: [
      {
        order_by: "id",
        ordering: "desc",
      },
    ],
  });

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "cliente":
        {
          setModalClient(true);
        }
        break;
    }
  };

  useEffect(() => {
    if (!clients) {
      let params = {
        url: URL_INDEX_CUSTOMER,
        sort: [
          {
            order_by: "id",
            ordering: "desc",
          },
        ],
      };
      dispatch(fetchClients(params));
    }
  }, []);

  //console.log(clients);
  return (
    <main class="main-content">
      <div class="position-relative iq-banner">
        <div class="iq-navbar-header" style={{ height: "215px" }}>
          <div class="container-fluid iq-container">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center">
                  <div class="box-img">
                    <img src="/assets/images/icon-equivalencias.svg" />
                  </div>
                  <h1 class="ms-2 mb-0">Clientes</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              class="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div class="conatiner-fluid content-inner mt-n5 py-0">
        <div class="row">
          <div class="col-md-12 bg-grey rounded">
            <div class="row row-cols-1">
              <div class="col-12 mt-5 mb-3">
                {/* FILTERS */}
                <Filters setParams={setParams} params={params} rol={rol} />
              </div>
              <div className="col-md-12 mb-3">
                <div className="d-flex justify-content-end">
                  <BtnAccept
                    onClick={onClick}
                    name={"cliente"}
                    value={"Añadir Cliente"}
                    loading={false}
                    loadingMsg={""}
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="card">
                  {clients ? (
                    <TableClients
                      setParams={setParams}
                      params={params}
                      data={clients}
                      setModal={setModalClient}
                      setSelect={setSelect}
                    />
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalClient ? (
        <ModalClient
          setSelect={(e) => e}
          select={select}
          setModal={setModalClient}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}
    </main>
  );
};

export default Clients;
