import { useState } from "react"
import BtnAccept from "../../../components/forms/BtnAccept"
import { format } from "date-fns";
import { suppliesState } from "../../../slices/supplies/Index";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/forms/Input";
import Papa from "papaparse";
import ApiRest from "../../../hooks/useRest";
import { fetchProviders } from "../../../slices/providers/Index";
import Alerts from "../../../components/alerts/Alerts";

const MasiveStoreModal = ({
  setModal,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const allowedExtensions = ["csv"];

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "enviar": {
        let formData = new FormData;

        data.map((d, index) => {
          formData.append('providers[' + index + '][business_name]', d.razon_social);
          formData.append('providers[' + index + '][email]', d.email);
          formData.append('providers[' + index + '][cuit]', d.cuit);
          formData.append('providers[' + index + '][telephone]', d.telefono);
          formData.append('providers[' + index + '][address]', d.direccion);
          formData.append('providers[' + index + '][country_name]', 'Argentina');
          formData.append('providers[' + index + '][province_name]', d.provincia);
          formData.append('providers[' + index + '][city_name]', d.localidad);
          formData.append('providers[' + index + '][activity_start_date]', d.inicio_actividad);
          formData.append('providers[' + index + '][hives]', d.colmenas);
          formData.append('providers[' + index + '][comments]', d.observaciones);
        });

        const response = await ApiRest({
          url: 'provider/bulkload',
          formData: formData,
          setLoading: setLoading,
          method: "post",
        });

        if (response.status == 201 || response.status == 200) {
          Alerts({
            msg: "Alert",
            title: "Se crearon nuevos proveedores.",
            code: "201",
            type: "success",
          });

          //Actualizar datos providers
          let params;
          dispatch(fetchProviders((params = { url: '/provider/index' })));

          //cerrar modal
          return setModal(false);
        }

        console.log(response);
      } break;
    }
  }
  const onChange = (e, value) => {
    switch (e.target.name) {
      case "file": {
        const input_file = e.target.files[0];
        const fileExtension = input_file?.type.split("/")[1];
        if (!allowedExtensions.includes(fileExtension)) {
          console.log("Ingrese un Archivo CSV");
          return;
        }

        if (!input_file) return console.log("Ingrese un Archivo Valido");

        const reader = new FileReader();

        reader.onload = async ({ target }) => {
          const csv = Papa.parse(target.result, { header: true });
          const parsedData = csv?.data;

          console.log(parsedData);
          setData(parsedData);
        };

        reader.readAsText(input_file);
      } break;
    }
  }

  return (
    <div
      className="modal  fade show bg-dark bg-opacity-25  modal-transition bg-modal-blur"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        style={{ maxWidth: "1200px" }}
      >
        <div className="modal-content bg-modal-blanco">
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => setModal(false)}
            ></button>
          </label>
          <div className="modal-header p-5">
            <h1 className="modal-title bold" id="nueva-ordenModalLabel">
              <strong>
                Carga masiva de proveedores
              </strong>
            </h1>
          </div>
          <div className="modal-body p-5">
            <div className="row">
              <div className="col-md-12 mb-4">
                <Input
                  onChange={onChange}
                  label={"Archivo de datos CSV"}
                  name={"file"}
                  type={"file"}
                  validate={{ validate: false, msg: "" }}
                />
              </div>
              <div className="col-md-12">
                <div className="table-responsive p-0">
                  {
                    data
                      ? <table className="table table-hover p-0 m-0" id="tabla-general">
                        <thead>
                          <tr >
                            <th className="p-1">Razon socail</th>
                            <th className="p-1">E-mail</th>
                            <th className="p-1">CUIT</th>
                            <th className="p-1">Telefono</th>
                            <th className="p-1">Dirección</th>
                            <th className="p-1">Provincia</th>
                            <th className="p-1">Localidad</th>
                            <th className="p-1">Inicio de actividad</th>
                            <th className="p-1">Colmenas</th>
                            <th className="p-1">Observacion</th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {
                            data.map((d, index) => (
                              <tr>
                                <td>{d.razon_social}</td>
                                <td>{d.email}</td>
                                <td>{d.cuit}</td>
                                <td>{d.telefono}</td>
                                <td>{d.direccion}</td>
                                <td>{d.provincia}</td>
                                <td>{d.localidad}</td>
                                <td>{d.inicio_actividad}</td>
                                <td>{d.colmenas}</td>
                                <td>{d.observaciones}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                      : null
                  }
                </div>
              </div>


              <div class="col-md-12 d-flex justify-content-end align-items-end">
                <div class="btn-group">
                  <BtnAccept
                    onClick={onClick}
                    name={"enviar"}
                    value={"Guardar"}
                    loading={loading}
                    loadingMsg={"Subiendo datos..."}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MasiveStoreModal