import clientAxios from "../config/ClientAxios"

const ApiRest = async ({
  url,
  formData,
  setLoading,
  method,
}) => {
  setLoading(true)

  const response = clientAxios({
    method: method,
    url: url,
    data: formData,
    header: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }).then((result) => {
    setLoading(false);
    return result
  }).catch((error) => {
    setLoading(false);
    if(error.response){
      return error.response;
    } else if (error.request){
      return error.request;
    } else {
      return error.message;
    }
  })


  return response;
}

export default ApiRest