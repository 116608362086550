import { format } from "date-fns"

export const ConcatStateCountEntry = (states) => {
  const result = []

  states.map((state) => {
    state = {
      ...state,
      created_at: format(new Date(state.created_at), 'dd/MM/yyyy').toString()
    }

    if(state.transaction == "Egreso"){
      if(state.applied_balance == "Miel"){


        let equivalencia = (parseFloat(state.selling_price) * parseFloat(state.units) / parseFloat(state.price_per_kg))

        if(state.type == "Canje"){
          equivalencia = 0.00;
        }

        if(state.type == "Adelanto"){
          equivalencia = parseFloat(state.kilograms);
        }

        
        if(state.type == "Servicios"){
          equivalencia = (parseFloat(state.price_per_unit) * parseFloat(state.units) / parseFloat(state.price_per_kg))
          state = {
            ...state,
            selling_price: state.price_per_unit
          }
        }
  
        state = {
          ...state,
          kilograms: (equivalencia).toFixed(2),
          sald: (equivalencia * parseFloat(state.price_per_kg ?? 0)).toFixed(2),
          wax_price_per_kg: "",
          wax_kilograms: "",
          wax_sald: ""
        }
      }
  
      if(state.applied_balance == "Opérculo"){
        
        let equivalencia = (parseFloat(state.selling_price) * parseFloat(state.units) / parseFloat(state.price_per_kg))

        if(state.type == "Canje"){
          equivalencia = 0.00;
        }

        if(state.type == "Adelanto"){
          equivalencia = parseFloat(state.kilograms);
        }

        
        if(state.type == "Servicios"){
          equivalencia = (parseFloat(state.price_per_unit) * parseFloat(state.units) / parseFloat(state.price_per_kg))
          state = {
            ...state,
            selling_price: state.price_per_unit
          }
        }
  
        state = {
          ...state,
          wax_price_per_kg: state.price_per_kg,
          wax_kilograms: (equivalencia).toFixed(2),
          wax_sald: (equivalencia * parseFloat(state.price_per_kg)).toFixed(2),
          price_per_kg: "",
          kilograms: "",
          sald: "",
        }
      }
    }

    
    if(state.transaction == "Ingreso"){
      if(state.applied_balance == "Miel"){

        state = {
          ...state,
          sald: "",
          wax_price_per_kg: "",
          wax_kilograms: "",
          wax_sald: ""
        }
      }
  
      if(state.applied_balance == "Opérculo"){
  
        state = {
          ...state,
          wax_price_per_kg: state.price_per_kg,
          wax_kilograms: state.kilograms,
          wax_sald: "",
          price_per_kg: "",
          kilograms: "",
          sald: "",
        }
      }
    }

    result.push(state)
  })

  return result
}