const Filters = ({ rol, setModal }) => {
  const onClick = (e, value) => {
    switch (e.target.name) {
      case "modal":
        {
          setModal(true);
        }
        break;
    }
  };
  
  return (
    <div className="row">
      {rol.id == 5 ? (
        <div className="col-md-3">
          <label className="mdl-button mdl-js-button rounded-0 mdl-button--fab mdl-js-ripple-effect">
            <i
              style={{ pointerEvents: "none" }}
              className="material-icons text-white"
            >
              add
            </i>
            <button
              className="d-none"
              name="modal"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Filters;
