import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Input from "../forms/Input";

const ModalSetFile = ({ index, setModal, name, form, setForm, onChange }) => {
  const navigate = useNavigate();

  const onClick = (e, value) => {
    e.preventDefault();
    switch (e.target.name) {
      case "close":
        {
          setModal(false);
        }
        break;
      case "imagenes_url":
        {
          let setValue = form;

          setValue[e.target.id][e.target.name][0].splice(value, 1);

          setValue[e.target.id]["imagenes"][0].splice(value, 1);

          setValue[e.target.id]["cantidad_archivos"][0] =
            setValue[e.target.id]["imagenes"][0].length + " Imagenes";

          setForm([...setValue]);
        }
        break;
      case "dowload_all":
        {
          form[index]["imagenes_url"][0].map((file, key) => {
            var a = document.createElement("a");
            a.href = file;
            a.download = "image_"+key;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          });
        }
        break;
    }
  };

  useEffect(() => {
    /* Scroll top */
    document.getElementById("focus-modal").focus();
  }, []);
  return (
    <div
      className="modal fade show bg-dark bg-opacity-25 border-0 min-vh-100 h-100 modal-transition bg-modal-blur"
      style={{ display: "block", zIndex: 5 }}
      id="focus-modal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 5 }}>
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div className="modal-header w-100 border-0 p-2">
            <h3
              className="modal-title text-center border-0 w-100"
              id="nueva-ordenModalLabel"
            >
              Cargar fotos
            </h3>
          </div>
          <div className="modal-body p-5">
            <form action="">
              <div className="d-flex justify-content-center align-items-center mb-3">
                <Input
                  uniqueKey={index}
                  onChange={onChange}
                  label={""}
                  name={name}
                  type={"file"}
                  validate={{ msg: "", validate: false }}
                  disabled={false}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center border-0 border-bottom border-dark text-dark py-1">
                <div>Imagenes {form[index]["imagenes_url"][0].length}</div>

                <div>
                  <label className="btn btn-primary">
                    Descargar todos
                    <button
                      className="d-none"
                      name="dowload_all"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  {form[index]["imagenes_url"][0].map((file, key) => (
                    <div className="me-2 border-0">
                      <div className="content-img" style={{ maxWidth: "100px", height: "110px" }}>
                        <img
                          src={file}
                          className="img-fluid "
                          style={{ maxWidth: "100px" }}
                          alt=""
                          title="Eliminar?"
                        />
                      </div>
                      <div className="justify-content-between w-100">
                        <label className="btn text-primary border px-2 py-1 me-1">
                          <a className="text-end" href={file} target="_blank">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-eye"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                            </svg>
                          </a>
                          <button
                            id={index}
                            className="d-none"
                            name="imagenes_url"
                            onClick={""}
                          ></button>
                        </label>

                        <label className="btn text-primary border px-2 py-1 me-1">
                          <a
                            id={key}
                            className="text-end"
                            href={file}
                            download=""
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </a>
                          <button
                            id={index}
                            className="d-none"
                            name="imagenes_url"
                            onClick={""}
                          ></button>
                        </label>

                        <label className="btn btn-danger px-2 py-1">
                          <div className="text-end">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                            </svg>
                          </div>
                          <button
                            id={index}
                            className="d-none"
                            name="imagenes_url"
                            onClick={(event) => onClick(event, key)}
                          ></button>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSetFile;
