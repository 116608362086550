import { useSelector } from "react-redux";
import { fetchNotifications, notificationState } from "../../../slices/notifications/Index";
import { useEffect } from "react";

const NotificationsHead = ({
  me,
  dispatch
}) => {
  
  const _notifications = useSelector(notificationState);
  const notifications = _notifications.notifications;
  
  useEffect(() => {
    if (!notifications) {
      let params = {
        url: "notification/all"
      };
      dispatch(fetchNotifications(params));
    }
  }, []);

  console.log(notifications);
  return  (
    
    <div className="p-0 card-body">
      { /* 
      <a href="#" className="iq-sub-card">
      <div className="d-flex align-items-center">
        <img src="assets/images/shapes/alert.svg" />
        <div className="ms-3 w-100">
          <p className="mb-0 ">
            El tambor 2 de la OC 2315 fue observado.
          </p>
        </div>
      </div>
    </a>
    <a href="#" className="iq-sub-card">
      <div className="d-flex align-items-center">
        <img src="assets/images/shapes/money.svg" />
        <div className="ms-3 w-100">
          <p className="mb-0 ">La OC 2253 fue pagada.</p>
        </div>
      </div>
    </a>
    <a href="#" className="iq-sub-card">
      <div className="d-flex align-items-center">
        <img src="assets/images/shapes/money.svg" />
        <div className="ms-3 w-100">
          <p className="mb-0 ">La OC 2365 fue pagada.</p>
        </div>
      </div>
    </a>
    <a href="#" className="iq-sub-card">
      <div className="d-flex align-items-center">
        <img src="assets/images/shapes/alert.svg" />
        <div className="ms-3 w-100">
          <p className="mb-0 ">
            El tambor 52 de la OC 362 fue observado.
          </p>
        </div>
      </div>
    </a>
    <a href="#" className="iq-sub-card">
      <div className="d-flex align-items-center">
        <img src="assets/images/shapes/shop.svg" />
        <div className="ms-3 w-100">
          <p className="mb-0 ">
            Se ha modificado la equivalencia en Tambor nuevo.
          </p>
        </div>
      </div>
    </a>
  */}
    
  </div>
  )
}

export default NotificationsHead