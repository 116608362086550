import './App.css';
//Router
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
//Context
import { AuthProvider } from './context/AuthContext';
//Auth
import Login from './pages/login/Index';

//Dashboard
import SidebarMenu from './pages/sidebarMenu/SidebarMenu';
import Home from './pages/Home/Index'
//Test
import Test from './test/Index';
import OrderPurchase from './pages/orderPurchase';
import MasterProvider from './providers/MasterProvider';
import Providers from './pages/providers/Index';
import Supplies from './pages/supplies/Index';
import StateCount from './pages/providers/StateCount/Index';
import OrderDetails from './pages/orderPurchase/details/Index';
import OrderWithdrawal from './pages/orderWithdrawal/Index';
import OrderWithdrawalDetails from './pages/orderWithdrawal/details/OrderWithdrawalPending';
import Vehicles from './pages/vehicles/Index';
import WareHouses from './pages/wareHouses/Index';
import Users from './pages/users/Index';
import OrderEntry from './pages/orderEntry/Index';
import OrderWithdrawalActive from './pages/orderWithdrawal/details/OrderWithdrawalActive';
import OrderWithdrawalSubmitted from './pages/orderWithdrawal/details/OrderWithdrawalSubmitted';
import OrderEntryPending from './pages/orderEntry/details/OrderEntryPending';
import AnalyzeOrderPurchase from './pages/analize/orderPurchase';
import AnalyzeLot from './pages/analize/lot';
import LoteDetails from './pages/analize/lot/details/LoteDetails';
import Clients from './pages/clients/Index';
import Contracts from './pages/contracts/Index';
import DrumsAnalyzed from './pages/contracts/drums/DrumsAnalyzed';
import CreateContractLot from './pages/lot/details/CreateContractLot';
import Lot from './pages/lot/Index';
import LotDetails from './pages/lot/details/LotDetails';
import Export from './pages/exports/Index';
import DrumsHomogenize from './pages/homogenization/drums/Index';
import LotHomogenization from './pages/homogenization/Index';
import LotHomogenizationAnalyse from './pages/analize/homogenization';
import IncludeDrums from './pages/analize/homogenization/analyze/drums/Index';
import RequestOrder from './pages/requestOrder/Index';
import RequestLotDetails from './pages/lot/details/RequestLotDetails';
import DrumsMerms from './pages/homogenization/merms/Index';
import ExportLotDetails from './pages/lot/details/ExportLotDetails';
import ContractLots from './pages/contracts/lots/Index';
import ContractDetail from './pages/contracts/details/ContractDetail';
import ClientDetails from './pages/clients/details/ClientDetails';
import ContractClientDetails from './pages/clients/details/ContractClientDetails';
import Stocks from './pages/supplies/stocks/Index';
import OrderEntrySubmitted from './pages/orderEntry/details/OrderEntrySubmitted';
import MasiveStore from './pages/masivestore/Index';

function App() {

  return (
    <MasterProvider>
      <AuthProvider>
        <Router>
          <Routes>

            {/* Routes Dashboard / Auth */}

            <Route
              exact
              path='/login'
              element={<Login />}
            />

            <Route
              exact
              path='/'
              element={<Login />}
            />

            <Route
              exact
              path='/dashboard/home'
              element={<SidebarMenu><Home /></SidebarMenu>}
            />

            {/* Routes Dashboard / Carga Masiva */}


            <Route
              exact
              path='/masivestore'
              element={<MasiveStore />}
            />


            {/* Routes Dashboard / Administrator */}

            <Route
              exact
              path='/dashboard/users/:roles'
              element={<SidebarMenu><Users /></SidebarMenu>} />

            <Route
              exact
              path='/dashboard/vehicles'
              element={<SidebarMenu><Vehicles /></SidebarMenu>} />

            <Route
              exact
              path='/dashboard/wareHouses'
              element={<SidebarMenu><WareHouses /></SidebarMenu>} />


            <Route
              exact
              path='/dashboard/export/lot/:status'
              element={<SidebarMenu><Export /></SidebarMenu>} />

              
            <Route
              exact
              path='/dashboard/export/lot/details/:id'
              element={<SidebarMenu><ExportLotDetails/></SidebarMenu>} />


            {/* Routes Dashboard / Buyer */}

            <Route
              exact
              path='/dashboard/orderpurchase/:label'
              element={<SidebarMenu><OrderPurchase /></SidebarMenu>} />

            <Route
              exact
              path='/dashboard/orderpurchase/details/:id'
              element={<OrderDetails />} />

            <Route
              exact
              path='/dashboard/providers'
              element={<SidebarMenu><Providers /></SidebarMenu>} />

            <Route
              exact
              path='/dashboard/providers/:id'
              element={<SidebarMenu><StateCount /></SidebarMenu>} />

            <Route
              exact
              path='/dashboard/supplies/equivalencies'
              element={<SidebarMenu><Supplies /></SidebarMenu>} />

            <Route
              exact
              path='/dashboard/supplies/stock'
              element={<SidebarMenu><Stocks /></SidebarMenu>} />
              
            <Route
              exact
              path='/dashboard/order/:status'
              element={<SidebarMenu><Supplies /></SidebarMenu>} />


            {/* Routes Dashboard / Logistic */}

            <Route
              exact
              path='/dashboard/orderwithdrawal/:label'
              element={<SidebarMenu><OrderWithdrawal /></SidebarMenu>} />

            <Route
              exact
              path='/dashboard/orderwithdrawal/pending/:or_id'
              element={
                <OrderWithdrawalDetails />
              }
            />

            <Route
              exact
              path='/dashboard/orderwithdrawal/active/:or_id'
              element={<OrderWithdrawalActive />}
            />

            <Route
              exact
              path='/dashboard/orderwithdrawal/submitted/:or_id'
              element={<OrderWithdrawalSubmitted />}
            />


            {/* Routes Dashboard / Storage */}

            <Route
              exact
              path='/dashboard/orderentry/:label'
              element={<SidebarMenu><OrderEntry /></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/orderentry/pending/:oi_id'
              element={<OrderEntryPending />}
            />

            <Route
              exact
              path='/dashboard/orderentry/submitted/:oi_id'
              element={<OrderEntrySubmitted />}
            />


            <Route
              exact
              path='/dashboard/homogenization/:status'
              element={<SidebarMenu><LotHomogenization/></SidebarMenu>}
            />
            
            
            <Route
              exact
              path='/dashboard/requestorder/:status'
              element={<SidebarMenu><RequestOrder/></SidebarMenu>} />

            <Route
              exact
              path='/dashboard/requestorder/details/:id'
              element={<SidebarMenu><RequestLotDetails/></SidebarMenu>} />


            {/* Routes Dashboard / Laboratory */}

            <Route
              exact
              path='/dashboard/orderpurchase/analyze/:status'
              element={<SidebarMenu><AnalyzeOrderPurchase /></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/lot/analyze/:status'
              element={<SidebarMenu><AnalyzeLot /></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/lot/analyze/details/:lot_id/:oc_id'
              element={<SidebarMenu><LoteDetails /></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/homogenization/analyze/:status'
              element={<SidebarMenu><LotHomogenizationAnalyse /></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/homogenization/analyze/drums/:id/:status'
              element={<SidebarMenu><IncludeDrums /></SidebarMenu>}
            />

            {/* Routes Dashboard / Export */}

            <Route
              exact
              path='/dashboard/contract/:status'
              element={<SidebarMenu><Contracts /></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/contract/details/:id'
              element={<SidebarMenu><ContractDetail /></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/contract/drums/:id'
              element={<SidebarMenu><DrumsAnalyzed /></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/contract/lots/:id'
              element={<SidebarMenu><ContractLots/></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/contract/lot/details/:id'
              element={<CreateContractLot />}
            />

            <Route
              exact
              path='/dashboard/lot/:status'
              element={<SidebarMenu><Lot /></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/lot/details/:id'
              element={<SidebarMenu><LotDetails /></SidebarMenu>}
            />


            <Route
              exact
              path='/dashboard/homogenization/drums'
              element={<SidebarMenu><DrumsHomogenize /></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/homogenization/merms'
              element={<SidebarMenu><DrumsMerms /></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/lot/homogenization/:status'
              element={<SidebarMenu><LotHomogenization /></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/clients'
              element={<SidebarMenu><Clients /></SidebarMenu>}
            />

            <Route
              exact
              path='/dashboard/clients/details/:id'
              element={<ClientDetails />}
            />

            <Route
              exact
              path='/dashboard/clients/contract/:id'
              element={<ContractClientDetails />}
            />

            {/* Routes Testing */}

            <Route exact path='/test' element={<Test />} />

          </Routes>
        </Router>
      </AuthProvider>
    </MasterProvider>
  );
}

export default App;
