export const setFormDetailsWax = (details) => {
  console.log(details);

  let result = [];

  details.map((d, index) => {
    if (d.wax) {
      let form = {
        key: [index, ""],
        //
        
        producto_nombre: ["cera", ""],
        producto_id: [2, ""], //product_id
        cera_entregado: [false, ""], //deliveri
        cera_id: [d.wax.id, ""], //wax_id
        unidad: ["", "required"], //Units
        peso_unidad: ["", "required"], //weight_per_unit
        observacion: ["", ""], //comments
        imagenes: [[], ""], //images
        imagenes_url: [[], ""], //images
        cantidad_archivos: [0+" Imagenes", ""],
        purchase_order: [d.wax.purchase_order_id, ""],
      };

      result.push(form);
    }
  });

  return result;
};
