import { useState } from "react";
import ReactTooltip from "react-tooltip";

const BtnSticky = ({ setModalProviders, setModalOrderPurchase }) => {
  const [floating, setFloating] = useState(false);
  const onClick = (e, value) => {
    switch (e.target.name) {
      case "floating-action":
        if (!floating) {
          setFloating(true);
          document
            .getElementById("fab_ctn")
            .classList.add("is-showing-options");
        } else {
          setFloating(false);
          document
            .getElementById("fab_ctn")
            .classList.remove("is-showing-options");
        }
        break;
      case "modal-orderPurchase":
        {
          setModalOrderPurchase(true);

          setFloating(false);
          document
            .getElementById("fab_ctn")
            .classList.remove("is-showing-options");
        }
        break;
      case "modal-providers":
        {
          setModalProviders(true);

          setFloating(false);
          document
            .getElementById("fab_ctn")
            .classList.remove("is-showing-options");
        }
        break;
      default:
        break;
    }
  };

  /*
  
    <div className="mdl-button--fab_flinger-container d-none" id="fab_ctn">
      <button
        className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect bg-primary"
        id="fab_btn"
        name="floating-action"
        onClick={(event) => onClick(event)}
      >
        <i
          style={{ pointerEvents: "none" }}
          className="material-icons text-white"
        >
          add
        </i>
      </button>
      <div className="mdl-button--fab_flinger-options">
        <label>
          <button
            className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect"
            data-tip
            data-for="NuevoProveedor"
            name="modal-providers"
            onClick={(event) => onClick(event)}
          >
            <img
              style={{ pointerEvents: "none" }}
              src="/assets/images/icon-proveedor-01.svg"
              width="22px"
            />
          </button>
        </label>

        <ReactTooltip
          id="NuevoProveedor"
          data-bs-placement="left"
          className="custom-tooltip"
          effect="solid"
          style={{ zIndex: 4 }}
        >
          Nuevo Proveedor
        </ReactTooltip>

        <label>
          <button
            className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect"
            data-tip
            data-for="NuevaOrdenDeCompra"
            name="modal-orderPurchase"
            onClick={(event) => onClick(event)}
          >
            <img
              style={{ pointerEvents: "none" }}
              src="/assets/images/icon-nuevo-proveedor.svg"
              width="22px"
            />
          </button>
        </label>

        <ReactTooltip
          id="NuevaOrdenDeCompra"
          data-bs-placement="left"
          className="custom-tooltip"
          effect="solid"
          style={{ zIndex: 4 }}
        >
          Nueva órden de compra
        </ReactTooltip>
      </div>
    </div>
     */

  return (
  <></>
  );
};

export default BtnSticky;
