import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { SketchPicker, ChromePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import reactCSS from "reactcss";
import { authContext } from "../../context/AuthContext";
import ApiRest from "../../hooks/useRest";
import { fetchExport } from "../../slices/exportLot/Index";
import { fetchOrderPurchaseList } from "../../slices/orderPurchase/Index";
import { setSelect } from "../../slices/select/Index";
import { fetchWareHous, wareHouseState } from "../../slices/wareHouses/Index";
import Alerts from "../alerts/Alerts";
import BtnAccept from "../forms/BtnAccept";
import Select from "../forms/Select";

const ModalWarehouse = ({ setModal, order }) => {
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_SET_WAREHOUSE_PURCHASE, URL_INDEX_WAREHOUSES, URL_INDEX_PURCHASE } = values;

  
  const _wareHouses = useSelector(wareHouseState);

  const wareHouses = _wareHouses.wareHouses;

  const [loading, setLoading] = useState(false);
  const [warehouse, setWarehouse] = useState(null);


  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "close":
        {
          setModal(false);
        }
        break;
      case "guardar":
        {

          if (
            !warehouse
          ) {
            return Alerts({
              msg: "Alert",
              title: "Selecciona un destino valido.",
              code: "201",
              type: "warning",
            });
          }

          let formData = new FormData();

          formData.append("_method", "PUT");
          formData.append("purchase_order_id", order.id);
          formData.append("warehouse_id", warehouse);

          //
          const response = await ApiRest({
            url: URL_SET_WAREHOUSE_PURCHASE,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Orden de compra enviada a deposito.",
              code: "201",
              type: "success",
            });

            dispatch(
              fetchOrderPurchaseList({
                url: URL_INDEX_PURCHASE,
                order_state_id: 1,
                order_by: "id",
                ordering: "desc",
              })
            );
            dispatch(
              fetchOrderPurchaseList({
                url: URL_INDEX_PURCHASE,
                order_state_id: null,
                order_by: "id",
                ordering: "desc",
              })
            );

            //cerrar modal
            return setModal(false);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "destino":
        {
          setWarehouse(e.target.value);
        }
        break;
    }
  };

  
  useEffect(() => {
    if (!wareHouses) {
      let params = {
        url: URL_INDEX_WAREHOUSES,
      };
      dispatch(fetchWareHous(params));
    }
  }, []);
  return (
    <div
      className="modal fade show bg-dark bg-opacity-25 border-0 min-vh-100 h-100 modal-transition bg-modal-blur"
      style={{ display: "block", zIndex: 5 }}
      id="focus-modal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        style={{ maxWidth: "500px" }}
      >
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 5 }}>
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div className="modal-header w-100 border-0 p-3">
            <h3
              className="modal-title text-start  border-0 w-100"
              id="nueva-ordenModalLabel"
            >
              Egresar Lote
            </h3>
          </div>
          <div className="modal-body p-3">
            <div className="row">
              <div className="col-md-12 mb-3">
                <Select
                    onChange={onChange}
                    label="Destino"
                    name="destino"
                    type=""
                    defaultValue={warehouse}
                    value={wareHouses ? wareHouses : []}
                    showIndex="name"
                    sendIndex="id"
                    validate={{msg: "", validate: false}}
                    icon={"/assets/images/icon-arrow-input.svg"}
                  />
              </div>
            </div>

            <div class="col-md-12 d-flex justify-content-end align-items-end">
              <div class="btn-group">
                <BtnAccept
                  onClick={onClick}
                  name={"guardar"}
                  value={"Guardar"}
                  loading={loading}
                  loadingMsg={"Guardando Lote"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWarehouse;
