import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContentSwiper from "../../../components/sliders/Swiper";
import ContentContracts from "../Contents/ContentContracts";
import ContentPurchase from "../Contents/ContentPurchase";
import Filters from "../filters/Filters";
import Notifications from "../notifications/Notifications";

const Exports = ({ orderPurchase, params, setSelect, setModal }) => {
  const navigate = useNavigate();

   /*********************************************** INITIAL STATE ***********************************************************/
   const [modal_orderWithdrawal, setModalOrderWithdrawal] = useState(false);
   const [SwiperMediaQuery, setSwiperMediaQuery] = useState(4);

  //Sliders
  const swipper_oc = [
    {
      title: "Contenedores exportados",
      link: "",
      lineLink: "Ver Todos",

      total: 0,
      icon: "/assets/images/icon-advertencia.svg",
      name: "redirect",
    },
    {
      title: "Contratos pendientes",
      link: "",
      lineLink: "Ver Todos",

      total: 0,
      icon: "/assets/images/icon-check.svg",
      name: "redirect",
    },
    {
      title: "Notificaciones",
      link: "",
      lineLink: "",

      total: 0,
      icon: "/assets/images/icon-check.svg",
      name: "redirect",
    },
  ];

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "redirect":
        {
          navigate(value);
        }
        break;
      case "modalGenerateOrderWithdrawal":
        {
          setModalOrderWithdrawal(true);
        }
        break;
    }
  };

  useEffect(() => {
    //Media Query Swipper
  }, []);
  return (
    <div className="row">
      <div className="col-md-12 col-lg-8">
        <div className="overflow-hidden d-slider1">
          <ContentSwiper
            SwiperMediaQuery={SwiperMediaQuery}
            contents={swipper_oc}
            onClick={onClick}
          />
        </div>
      </div>

      <ContentContracts />

    </div>
  );
};

export default Exports;
