import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import EdiTables from "../../../components/editables/EdiTables";
import BtnAccept from "../../../components/forms/BtnAccept";
import Spinner from "../../../components/loads/Spinner";
import ModalSetFile from "../../../components/modals/ModalSetFile";
import { authContext } from "../../../context/AuthContext";
import { clearGuard, getGuard, setGuard } from "../../../guards/guards";
import ApiRest from "../../../hooks/useRest";
import {
  fetchOrderWithdrawal,
  fetchOrderWithdrawalDetails,
} from "../../../slices/orderwithdrawal/Index";
import { ConcatDetails } from "../../../utils/ConcatDetails";
import { Fill } from "../../../utils/Fill";
import { setFormDetailsWax } from "../../../utils/setFormDetailsWax";
import { SetFormDetails } from "../../../utils/SetFormDetailsWithdrawal";
import { Validate } from "../../../utils/ValidateForms";

const ModalOrderWithdrawalDrumsDetails = ({
  dispatch,
  id,
  setModal,
  withdrawal_details,
  redirect,
}) => {
  const navigate = useNavigate();
  /*********************************************** INITIAL STATE ***********************************************************/
  const context = useContext(authContext);
  const { values, forms, validations, guards } = context;

  const { ORDER_WITHDRAWAL_DRUMS_DETAILS, ORDER_WITHDRAWAL_WAX_DETAILS } =
    guards;
  const {
    URL_CREATE_DETAILS_WITHDRAWAL,
    URL_SHOW_WITHDRAWAL,
    URL_UPDATE_WITHDRAWAL,
    URL_INDEX_WITHDRAWAL,
  } = values;
  const { FORM_ORDER_DETAILS_DRUM, FORM_ORDER_DETAILS_WAX } = forms;
  const { VALIDATION_ORDER_DETAILS_DRUM, VALIDATION_ORDER_DETAILS_WAX } =
    validations;

  const [modalImageDrum, setModalImageDrum] = useState(false);
  const [modalImageWax, setModalImageWax] = useState(false);

  const [select, setSelect] = useState(null);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(50);
  const [form_drum, setFormDrum] = useState(null);

  const [form_wax, setFormWax] = useState(null);

  const onChange = async (e, value) => {
    switch (e.target.name) {
      case "neto":
      case "tara":
        {
          let setValue = form_drum;
          setValue[e.target.id][e.target.name][0] = e.target.value;

          if (e.target.name == "neto")
            setValue[e.target.id]["bruto"][0] =
              parseFloat(e.target.value) +
              parseFloat(
                setValue[e.target.id]["tara"][0]
                  ? setValue[e.target.id]["tara"][0]
                  : 0
              );
          if (e.target.name == "tara")
            setValue[e.target.id]["neto"][0] =
              - parseFloat(e.target.value) +
              parseFloat(
                setValue[e.target.id]["bruto"][0]
                  ? setValue[e.target.id]["bruto"][0]
                  : 0
              );

          setFormDrum([...setValue]);
        }
        break;
      case "entregar_todos":
        {
          let setValue = form_drum;

          setValue.map((value, index) => {
            setValue[index]["tambor_entregado"][0] = e.target.checked;
          });

          setFormDrum([...setValue]);
        }
        break;
      case "tambor_entregado":
        {
          let setValue = form_drum;
          setValue[e.target.id][e.target.name][0] = e.target.checked;

          setFormDrum([...setValue]);
        }
        break;
      case "imagenes":
        {
          let setValue = form_drum;

          setValue[e.target.id][e.target.name + "_url"][0] = [
            ...setValue[e.target.id][e.target.name + "_url"][0],
            URL.createObjectURL(e.target.files[0]),
          ];

          setValue[e.target.id][e.target.name][0] = [
            ...setValue[e.target.id][e.target.name][0],
            e.target.files[0],
          ];

          setValue[e.target.id]["cantidad_archivos"][0] =
            setValue[e.target.id][e.target.name + "_url"][0].length +
            " Imagenes";

          setFormDrum([...setValue]);
        }
        break;
      case "bruto":
      case "tipo_tambor":
      case "observacion":
      case "codigo_geomiel":
      case "codigo_senasa":
        {
          let setValue = form_drum;
          setValue[e.target.id][e.target.name][0] = e.target.value;

          setFormDrum([...setValue]);
        }
        break;
      /* Form wax */
      case "cera_entregado":
        {
          let setValue = form_wax;
          setValue[e.target.id][e.target.name][0] = e.target.checked;

          setFormWax([...setValue]);

          /* */
          setGuard(ORDER_WITHDRAWAL_WAX_DETAILS, JSON.stringify(form_wax));
        }
        break;
      case "observacion_wax":
        {
          let setValue = form_wax;
          setValue[e.target.id]["observacion"][0] = e.target.value;

          setFormWax([...setValue]);
        }
        break;
      case "imagenes_wax":
        {
          let setValue = form_wax;

          setValue[e.target.id]["imagenes_url"][0] = [
            ...setValue[e.target.id]["imagenes_url"][0],
            URL.createObjectURL(e.target.files[0]),
          ];

          setValue[e.target.id]["imagenes"][0] = [
            ...setValue[e.target.id]["imagenes"][0],
            e.target.files[0],
          ];

          setValue[e.target.id]["cantidad_archivos"][0] =
            setValue[e.target.id]["imagenes_url"][0].length + " Imagenes";

          setFormWax([...setValue]);
        }
        break;
      case "unidad":
      case "peso_unidad":
        {
          let setValue = form_wax;
          setValue[e.target.id][e.target.name][0] = e.target.value;

          setFormWax([...setValue]);
        }
        break;
    }
  };

  const onClick = async (e, value) => {
    e.preventDefault();

    switch (e.target.name) {
      case "close":
        /* */
        setGuard(
          ORDER_WITHDRAWAL_DRUMS_DETAILS + "_" + id,
          JSON.stringify(form_drum)
        );
        setGuard(
          ORDER_WITHDRAWAL_WAX_DETAILS + "_" + id,
          JSON.stringify(form_wax)
        );

        setModal(false);
        break;
      case "modal_file_drum":
        {
          setSelect(value);
          setModalImageDrum(true);
        }
        break;
      case "modal_file_wax":
        {
          setSelect(value);
          setModalImageWax(true);
        }
        break;
      case "imagenes_url_wax":
        {
          let setValue = form_wax;

          delete setValue[e.target.id][e.target.name][0][value];

          delete setValue[e.target.id]["imagenes"][0][value];

          setValue[e.target.id][e.target.name][0][value].filter(
            (item) => item != null
          );
          setValue[e.target.id]["imagenes"][0][value].filter(
            (item) => item != null
          );

          setFormWax([...setValue]);
        }
        break;
      case "imagenes_url":
        {
          let setValue = form_drum;

          delete setValue[e.target.id][e.target.name][0][value];

          delete setValue[e.target.id]["imagenes"][0][value];

          setValue[e.target.id][e.target.name][0][value].filter(
            (item) => item != null
          );

          setValue[e.target.id]["imagenes"][0][value].filter(
            (item) => item != null
          );

          setFormDrum([...setValue]);
        }
        break;
      case "aceptar":
        {
          let products = [];

          if (form_drum.length > 0) {
            form_drum.map((form) => {
              //Delete property
              //validation = Validate(form);
              //setValidationDrum(validation);
              //Validaciones

              products.push(form);
            });

            /*
            if (validation.error.validate) {
              return Alerts({
                msg: "Warning",
                title: validation.error.msg,
                code: "201",
                type: "error",
              });
            }
            */
          }

          if (form_wax.length > 0) {
            form_wax.map((wax) => {
              //Delete property
              //validation_wax = Validate(wax);
              //setValidationWax(validation_wax);

              products.push(wax);
            });

            //Validaciones
            /*
            if (validation_wax.error.validate) {
              return Alerts({
                msg: "Warning",
                title: validation_wax.error.msg,
                code: "201",
                type: "error",
              });
            }
            */
          }

          //Armar formulario

          let resultProducts = [];

          let formData = new FormData();
          formData.append(
            "withdrawal_order_id",
            withdrawal_details.withdrawal_order[0].id
          );

          products.map((product, index) => {
            let formProduct = {};
            if (product.producto_id[0] == 1) {
              formData.append(
                "products[" + index + "][delivered]",
                product.tambor_entregado[0] ? 1 : 0
              );
              formData.append(
                "products[" + index + "][product_id]",
                product.producto_id[0]
              );
              formData.append(
                "products[" + index + "][senasa_code]",
                product.codigo_senasa[0]
              );
              formData.append(
                "products[" + index + "][geomiel_code]",
                product.codigo_geomiel[0]
              );
              formData.append("products[" + index + "][tare]", product.tara[0]);
              formData.append(
                "products[" + index + "][total_weight]",
                product.neto[0]
              );
              formData.append(
                "products[" + index + "][gross_weight]",
                product.bruto[0]
              );
              formData.append(
                "products[" + index + "][honey_drum_id]",
                product.tambor_id[0]
              );
              formData.append(
                "products[" + index + "][comments]",
                product.observacion[0]
              );

              product.imagenes[0].map((img, index) => {
                formData.append(
                  "products[" + index + "][images][" + index + "]",
                  img
                );
              });
            } else {
              /*
              formProduct = {
                delivered: product.tambor_entregado[0],
                product_id: product.producto_id[0],
                units: product.unidad[0],
                weight_per_unit: product.peso_unidad[0],
                wax_id: product.cera_id[0],
                comments: product.observacion[0],
              };
              */

              formData.append(
                "products[" + index + "][delivered]",
                product.cera_entregado[0] ? 1 : 0
              );
              formData.append(
                "products[" + index + "][product_id]",
                product.producto_id[0]
              );
              formData.append(
                "products[" + index + "][units]",
                product.unidad[0]
              );
              formData.append(
                "products[" + index + "][weight_per_unit]",
                product.peso_unidad[0]
              );
              formData.append(
                "products[" + index + "][wax_id]",
                product.cera_id[0]
              );
              formData.append(
                "products[" + index + "][comments]",
                product.observacion[0]
              );
            }

            resultProducts.push(formProduct);
          });

          /*
          formData = {
            ...formData,
            products: resultProducts,
          };
          */

          //formData.append("exchange", form.provincia[0]);

          const response = await ApiRest({
            url: URL_CREATE_DETAILS_WITHDRAWAL,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Realizado exitosamente",
              code: "201",
              type: "exito",
            });

            //Actualizar datos OR
            let params = {
              url: URL_SHOW_WITHDRAWAL,
              withdrawal_order_id: withdrawal_details.withdrawal_order[0].id,
            };

            dispatch(fetchOrderWithdrawalDetails(params));

            clearGuard(ORDER_WITHDRAWAL_DRUMS_DETAILS);
            clearGuard(ORDER_WITHDRAWAL_WAX_DETAILS);

            //cerrar modal
            if (redirect) {
              params = {
                url: URL_INDEX_WITHDRAWAL,
                ["state_id[0]"]: 3,
                ["state_id[1]"]: 4,
                order_by: "id",
                ordering: "desc",
              };
              dispatch(fetchOrderWithdrawal(params));

              return setModal(false);
            }

            return navigate(
              "/dashboard/orderwithdrawal/active/" +
                withdrawal_details.withdrawal_order[0].id
            );
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;

      case "next":
        {
          if (currentPage < form_drum.length) {
            setPage(page + 50);
            setCurrentPage(currentPage + 50);
          }
        }
        break;
      case "back":
        {
          if (page !== 0) {
            setPage(page - 50);
            setCurrentPage(currentPage - 50);
          }
        }
        break;
    }
  };

  /*********************************************** INITIAL STATE ***********************************************************/

  const campos = [
    {
      name: "O/C",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "PRODUCTO",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ID",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ENTREGADO",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",

      input: {
        name: "entregar_todos",
        type: "checkbox",
        onChange: onChange,
      },
    },
    {
      name: "TIPO TAMBOR",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "CODIGO SENASA",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "CODIGO GEOMIEL",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "BRUTO",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "TARA",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "NETO",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "OBSERVACION",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "IMAGENES",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    /*
    {
      name: "ARCHIVOS",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    */
  ];
  const order = [
    {
      content_name: "purchase_order",
      property: "purchase_order",

      name: "purchase_order",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "producto_nombre",
      property: "producto_nombre",

      name: "producto_nombre",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "tambor_id",
      property: "tambor_id",

      name: "id",
      form: "input",
      type: "number",
      disabled: true,
    },
    {
      property: "tambor_entregado",

      name: "tambor_entregado",
      text: "Si",
      form: "checkbox",
      type: "checkbox",
      disabled: false,
    },
    {
      property: "tipo_tambor",

      name: "tipo_tambor",
      form: "select",
      type: "select",
      selectValue: [{ name: "Petiso" }, { name: "Alto" }],
      disabled: false,
    },
    {
      content_name: "codigo_senasa",
      property: "codigo_senasa",

      name: "codigo_senasa",
      form: "input",
      type: "text",
      disabled: false,
    },
    {
      content_name: "codigo_geomiel",
      property: "codigo_geomiel",

      name: "codigo_geomiel",
      form: "input",
      type: "text",
      disabled: false,
    },
    {
      content_name: "bruto",
      property: "bruto",

      name: "bruto",
      form: "input",
      type: "number",
      disabled: false,
    },
    {
      content_name: "tara",
      property: "tara",

      name: "tara",
      form: "input",
      type: "number",
      disabled: false,
    },
    {
      content_name: "neto",
      property: "neto",

      name: "neto",
      form: "input",
      type: "number",
      disabled: false,
    },
    {
      content_name: "observacion",
      property: "observacion",

      name: "observacion",
      form: "texttarea",
      type: "text",
      disabled: false,
    },
    {
      content_name: "imagenes",
      property: "cantidad_archivos",

      name: "modal_file_drum",
      form: "btn",
      type: "file",
      disabled: false,
    },
    /*
    {
      content_name: "imagenes",
      property: "imagenes",

      name: "imagenes",
      form: "file",
      type: "file",
      disabled: false,
    },
    {
      content_name: "imagenes_url",
      property: "imagenes_url",

      name: "imagenes_url",
      form: "showfile",
      type: "",
      disabled: false,
    },
    */
  ];
  const contents = form_drum ? form_drum.slice(page, currentPage) : [];

  /* Form wax */

  const campos_wax = [
    {
      name: "O/C",
      order: true,
      class: "",
    },
    {
      name: "PRODUCTO",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ID",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ENTREGADO",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "UNIDADES",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "PESO POR UNIDAD",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "OBSERVACION",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "IMAGENES",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
  ];
  const order_wax = [
    {
      content_name: "purchase_order",
      property: "purchase_order",

      name: "purchase_order",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "producto_nombre",
      property: "producto_nombre",

      name: "producto_nombre",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "cera_id",
      property: "cera_id",

      name: "id",
      form: "input",
      type: "number",
      disabled: true,
    },
    {
      property: "cera_entregado",

      name: "cera_entregado",
      text: "Si",
      form: "checkbox",
      type: "checkbox",
      disabled: false,
    },
    {
      content_name: "unidad",
      property: "unidad",

      name: "unidad",
      form: "input",
      type: "text",
      disabled: false,
    },
    {
      content_name: "peso_unidad",
      property: "peso_unidad",

      name: "peso_unidad",
      form: "input",
      type: "text",
      disabled: false,
    },
    {
      content_name: "observacion_wax",
      property: "observacion",

      name: "observacion_wax",
      form: "texttarea",
      type: "text",
      disabled: false,
    },
    {
      content_name: "imagenes",
      property: "cantidad_archivos",

      name: "modal_file_wax",
      form: "btn",
      type: "file",
      disabled: false,
    },
    /*
    {
      content_name: "imagenes_wax",
      property: "imagenes",

      name: "imagenes_wax",
      form: "file",
      type: "file",
      disabled: false,
    },
    {
      content_name: "imagenes_url_wax",
      property: "cantidad_archivos",

      name: "imagenes_url_wax",
      form: "showfile",
      type: "",
      disabled: false,
    },
    */
  ];
  const contents_wax = withdrawal_details ? form_wax : [];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = {
    current_page: 0,
    first_page_url: 0,
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  };
  const pagination = {
    type: null,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  /**********************************************************************************************************************/

  useEffect(() => {
    if (!withdrawal_details) {
      let params = {
        url: URL_SHOW_WITHDRAWAL,
        withdrawal_order_id: id,
      };
      dispatch(fetchOrderWithdrawalDetails(params));
    }
  });

  useEffect(() => {
    if (withdrawal_details) {
      let guard_drums = getGuard(ORDER_WITHDRAWAL_DRUMS_DETAILS + "_" + id);
      let guard_wax = getGuard(ORDER_WITHDRAWAL_WAX_DETAILS + "_" + id);

      if (guard_wax) {
        setFormWax(JSON.parse(guard_wax));
      } else {
        setFormWax(setFormDetailsWax(withdrawal_details.details));
      }

      if (guard_drums) {
        setFormDrum(JSON.parse(guard_drums));
      } else {
        setFormDrum(SetFormDetails(withdrawal_details.details));
      }
    }
  }, [withdrawal_details, id]);

  console.log(withdrawal_details);
  return (
    <div
      className="modal  fade show bg-dark bg-opacity-25  modal-transition bg-modal-blur"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        style={{ maxWidth: "2000px" }}
      >
        <div className="modal-content bg-modal-blanco">
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div className="modal-header p-5">
            <h1 className="modal-title bold" id="nueva-ordenModalLabel">
              <strong>
                Retiro N°{" "}
                {String(withdrawal_details.withdrawal_order[0].id).length >= 4
                  ? withdrawal_details.withdrawal_order[0].id
                  : Fill(withdrawal_details.withdrawal_order[0].id, 4)}
              </strong>
            </h1>
          </div>
          <div className="modal-body p-5">
            <div className="row">
              <div className="col text-center mb-5">
                <div className="detail">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="text-dark text-start">
                        Orden de compra
                      </span>
                      <p className="text-start mt-1 mb-0">{
                        withdrawal_details.details.map((order) =>{
                          if(String(order.purchase_order.id).length >= 4 ){
                            return "OC "+order.purchase_order.id
                          } else {
                            return "OC "+Fill(order.purchase_order.id, 4)
                          }
                        })
                      }</p>
                    </div>
                    <img
                      src="/assets/images/icon-ordenes-de-compra.svg"
                      className="ms-2"
                      width="50px"
                      height="50px"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col text-center mb-5 px-5">
                <div className="detail">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="text-dark text-start pe-5">
                        Apicultor
                      </span>
                      <p className="text-start mt-1 mb-0 text-nowrap">
                        {withdrawal_details
                          ? withdrawal_details.details.map((or) => (
                              <div>
                                {or.purchase_order.provider.business_name}
                              </div>
                            ))
                          : ""}
                      </p>
                    </div>
                    <img
                      src="/assets/images/icon-provedor.svg"
                      className="ms-5"
                      width="50px"
                      height="50px"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col text-center mb-5">
                <div className="detail">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="text-dark text-start pe-5">
                        Productos
                      </span>
                      <p className="text-start mt-1 mb-0 text-nowrap">
                        {form_drum ? form_drum.length + " " : 0}Tambores
                      </p>
                      <p className="text-start mt-1 mb-0 text-nowrap">
                        {withdrawal_details
                          ? withdrawal_details.details.map((or) => (
                              <span>
                                {or.wax_amount
                                  ? or.wax_amount + " KG "
                                  : 0 + " KG "}
                              </span>
                            ))
                          : 0}
                        Cera
                      </p>
                    </div>
                    <img
                      src="/assets/images/icon-equivalencias.svg"
                      className="ms-3"
                      width="50px"
                      height="50px"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            {form_drum ? (
              form_drum.length <= currentPage - page ? (
                ""
              ) : (
                <div className="d-flex justify-content-end mb-3">
                  <label className="btn btn-primary me-2">
                    Anterior
                    <button
                      className="d-none"
                      name="back"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                  <label className="btn btn-primary">
                    Siguiente
                    <button
                      className="d-none"
                      name="next"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                </div>
              )
            ) : (
              ""
            )}

            <div className="mb-4">
              {form_drum ? (
                <EdiTables
                  hidden_Filters={true}
                  campos={campos}
                  order={order}
                  contents={contents}
                  pagination={pagination}
                  onClick={onClick}
                  onChange={onChange}
                />
              ) : (
                <div>
                  {" "}
                  <Spinner />{" "}
                </div>
              )}
            </div>

            {form_drum ? (
              form_drum.length <= currentPage - page ? (
                ""
              ) : (
                <div className="d-flex justify-content-end mb-3">
                  <label className="btn btn-primary me-2">
                    Anterior
                    <button
                      className="d-none"
                      name="back"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                  <label className="btn btn-primary">
                    Siguiente
                    <button
                      className="d-none"
                      name="next"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                </div>
              )
            ) : (
              ""
            )}

            <div className="mb-4">
              {form_wax ? (
                <EdiTables
                  hidden_Filters={true}
                  campos={campos_wax}
                  order={order_wax}
                  contents={contents_wax}
                  pagination={pagination}
                  onClick={onClick}
                  onChange={onChange}
                />
              ) : (
                <div>
                  {" "}
                  <Spinner />{" "}
                </div>
              )}
            </div>

            <div className="w-100 d-flex justify-content-end">
              <div className="btn-group">
                <BtnAccept
                  onClick={onClick}
                  name={"aceptar"}
                  value={"Aceptar"}
                  loading={loading}
                  loadingMsg={"Generando..."}
                />
              </div>
            </div>
          </div>

          {modalImageDrum && form_drum ? (
            <ModalSetFile
              setModal={setModalImageDrum}
              name={"imagenes"}
              setSelect={setSelect}
              index={select}
              form={form_drum}
              setForm={setFormDrum}
              onChange={onChange}
            />
          ) : (
            ""
          )}

          {modalImageWax && form_wax ? (
            <ModalSetFile
              setModal={setModalImageWax}
              name={"imagenes_wax"}
              setSelect={setSelect}
              index={select}
              form={form_wax}
              setForm={setFormWax}
              onChange={onChange}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalOrderWithdrawalDrumsDetails;
