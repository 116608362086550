import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  products: [
    {
      id: 1,
      name: "Tambor de miel",
      type: null,
      exchange: true
    },
    {
      id: 2,
      name: "Cera",
      type: "Operculo",
      exchange: false
    },
    {
      id: 3,
      name: "Cera",
      type: "Borra",
      exchange: false
    },
    {
      id: 4,
      name: "Cera",
      type: "recupero",
      exchange: false
    },
  ],
};

export const productsSlice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {

  },
});


//Async functions
export const fetchProducts = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params })
    .then((result) => {
      return "";
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};



export const {  } = productsSlice.actions;

export const productsState = (state) => state.products;

export default productsSlice.reducer;