import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  //Lista
  wareHouses: null,
}

export const wareHouseSlice = createSlice({
  name: "wareHouses",
  initialState: initialState,
  reducers: {
    setWareHouse: (state, action) => {
      state.wareHouses = action.payload;
    },
  },
});


//Async functions
export const fetchWareHous = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setWareHouse(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};



export const { setWareHouse } = wareHouseSlice.actions;

export const wareHouseState = (state) => state.wareHouses;

export default wareHouseSlice.reducer;