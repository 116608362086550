export const ConcatSupplyStock = (supplies) => {
  let result = []

  console.log({supplies});


  supplies.map((supply) => {
    supply = {
      ...supply,
      model: supply.supply_models[0].name
    }

    result.push(supply)
  })



  return result
}