import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  //Lista
  orderPurchase: null,
  orderPurchase_pending: null,
  orderPurchase_submitted: null,
  orderPurchase_provider: null,

  search: [],
}

export const orderPurchaseSlice = createSlice({
  name: "orderPurchase",
  initialState: initialState,
  reducers: {
    setOrderPurchaseList: (state, action) => {
      state.orderPurchase = action.payload
    },
    setOrderPurchaseList_pending: (state, action) => {
      state.orderPurchase_pending = action.payload
    },
    setOrderPurchaseList_submitted: (state, action) => {
      state.orderPurchase_submitted = action.payload
    },
    setOrderPurchase_provider: (state, action) => {
      state.orderPurchase_provider = action.payload
    },
    setSearch: (state, action) => {
      state.search = action.payload
    },
  },
});


//Async functions
export const fetchOrderPurchaseList = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params.order_state_id) {
        case null:{ //All Orders pending
          return dispatch(setOrderPurchaseList(result.data));
        }
        case 1:
        case "null":{ //All Orders pending
          return dispatch(setOrderPurchaseList_pending(result.data));
        }
        break;
        case 2:{ //submitted
          return dispatch(setOrderPurchaseList_submitted(result.data));
        }
        break;
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchOrderPurchaseProvider = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
          return dispatch(setOrderPurchase_provider(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchSearchPurchase = (params) => (dispatch) => {
  dispatch(setSearch(null))

  clientAxios
    .get(params.url, { params})
    .then((result) => {
          return dispatch(setSearch(result.data.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};


export const { setOrderPurchaseList, setOrderPurchaseList_submitted, setOrderPurchaseList_pending, setOrderPurchase_provider, setSearch} = orderPurchaseSlice.actions;

export const orderPurchaseState = (state) => state.orderPurchase;

export default orderPurchaseSlice.reducer;