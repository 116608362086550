import { format } from "date-fns";
import Input from "../../../../components/forms/Input";
import InputSearch from "../../../../components/forms/InputSearch";

const Filters = ({ params, setParams, dispatch, label }) => {
  const onChange = (e, value) => {
    switch (e.target.name) {
      case "fecha_desde":{
        setParams({
          ...params,
          start_date: e.target.value != "" ? format(new Date(e.target.value+" 00:00:00"), "yyyy-MM-dd").toString() : "", 
        });

        if(e.target.value != ""){
          
          /*
          dispatch(fetchOrderPurchaseList({
            url: params.url,
            order_state_id: label == "pending" ? 1 : label == "submitted" ? 2 : null,
            start_date: format(new Date(e.target.value+" 00:00:00"), "yyyy-MM-dd").toString(), 
            end_date: params.end_date == "" ?  format(new Date(e.target.value+" 00:00:00"), "yyyy-MM-dd").toString() : params.end_date , 
          }));
          */
        } else {

          if(e.target.value == "" && params.end_date == ""){
            /*
            dispatch(fetchOrderPurchaseList({
              url: params.url,
              order_state_id: label == "pending" ? 1 : label == "submitted" ? 2 : null,
           })); 
           */
          }
      }
      } break;
      case "fecha_hasta":{

        setParams({
          ...params,
          end_date: e.target.value != "" ? format(new Date(e.target.value+" 00:00:00"), "yyyy-MM-dd").toString() : "", 
        });
        
        if(e.target.value != ""){  
          /*
          dispatch(fetchOrderPurchaseList({
            url: params.url,
            order_state_id: label == "pending" ? 1 : label == "submitted" ? 2 : null,
            start_date: params.start_date == "" ? format(new Date(e.target.value+" 00:00:00"), "yyyy-MM-dd").toString() : params.start_date ,
            end_date: format(new Date(e.target.value+" 00:00:00"), "yyyy-MM-dd").toString(), 
          }));
          */

         } else {
          if(e.target.value == "" && params.start_date == ""){
            /*
            dispatch(fetchOrderPurchaseList({
              url: params.url,
              order_state_id: label == "pending" ? 1 : label == "submitted" ? 2 : null
           }));
           */
          }
         }
        
      } break;
    }
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "showAccordion":
        {
          if (
            document
              .getElementById("collapse-filters")
              .classList.contains("show")
          ) {
            document.getElementById("btn-filters").classList.add("collapsed");
            document
              .getElementById("collapse-filters")
              .classList.remove("show");
          } else {
            document
              .getElementById("btn-filters")
              .classList.remove("collapsed");
            document.getElementById("collapse-filters").classList.add("show");
          }
        }
        break;
      case "search":
        {
          if (params.search.trim() == "") {
            return "";
          }
          /*
          return dispatch(
            fetchOrderPurchaseList({
              url: params.url,
              search: params.search,
              order_state_id:
                label == "pending" ? "null" : label == "submitted" ? 2 : null,
            })
          );
          */
        }
        break;
    }
  };

  return (
    <div className="">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <label className="accordion-button collapsed" id="btn-filters">
              <img src="/assets/images/icon-filter.svg" style={{width: "25px"}} class="me-2" />
              Filtros
              <button
                class="d-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-controls="flush-collapseOne"
                name="showAccordion"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </h2>
          <div
            id="collapse-filters"
            class="accordion-collapse collapse "
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <div class="row justify-content-start">
                <div class="col-md-3 d-flex flex-column mb-3 d-none">
                  <label class="form-label">Proveedor</label>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">
                      <img
                        src="../assets/images/icon-input-proveedor.svg"
                        alt=""
                      />
                    </span>
                    <select class="form-select">
                      <option selected=""></option>
                      <option value="1">001 - </option>
                      <option value="2">002 - </option>
                      <option value="3">003 - </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3 d-flex flex-column mb-3">
                  <Input
                    onChange={onChange}
                    label={"Fecha desde"}
                    name={"fecha_desde"}
                    type={"date"}
                    value={params.start_date}
                    validate={{ msg: "", validate: false }}
                    icon={"/assets/images/icon-input-fecha.svg"}
                  />
                </div>

                <div class="col-md-3 d-flex flex-column mb-3">
                  <Input
                    onChange={onChange}
                    label={"Fecha hasta"}
                    name={"fecha_hasta"}
                    type={"date"}
                    value={params.end_date}
                    validate={{ msg: "", validate: false }}
                    icon={"/assets/images/icon-input-fecha.svg"}
                  />
                </div>
              </div>
              <div class="row justify-content-start d-none">
                <div class="col-md-3 d-flex flex-column mb-3">
                  <label class="form-label">Producto</label>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">
                      <img src="../assets/images/tambor.svg" alt="" />
                    </span>
                    <input
                      type="number"
                      class="form-control"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
                <div class="col-md-3 d-flex flex-column mb-3">
                  <label class="form-label">O/C</label>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">
                      <img src="../assets/images/icon-input-cuit.svg" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
