import { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import Table from "../../../components/tables/Table";
import clientAxios from "../../../config/ClientAxios";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { fetchOrderPurchaseList } from "../../../slices/orderPurchase/Index";
import { fetchProviders } from "../../../slices/providers/Index";
import { ClearParams } from "../../../utils/ClearParams";

const ContentTable = ({
  campos,
  order,
  contents,
  actions,
  pagination,

  setParams,
  params,
  dispatch,
  setSelect,
  setModal,
}) => {
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { values } = context;
  const {  URL_DELETE_PROVIDER, URL_INDEX_PROVIDERS } =
    values;
  const [loading, setLoading] = useState(false);

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "order":
        {
          let setValueParams = params;
          let sort = [];

          setValueParams.sort.map((order) => {
            if (order.order_by == value.param) {
              order = {
                ...order,
                ordering: value.order,
              };
            }

            sort.push(order);
          });

          params = {
            ...params,
            sort: [
              {
                order_by: value.param,
                ordering: value.order,
              },
            ],
          };

          setParams(params);
          let clear_params = ClearParams(params);

          dispatch(fetchProviders(clear_params));
        }
        break;
      case "page":
        {
          let flowparams = params;
          flowparams.url = value;

          dispatch(fetchProviders(flowparams));
        }
        break;
      case "editar_proveedor":
        {
          setSelect(value);
          setModal(true);
        }
        break;
      case "detalle_cuenta":
        {
          return navigate(
            "/dashboard/providers/" + value.id
          );
        }
        break;
      case "eliminar_proveedor":{
          const setFunction = async () => {
            let formData = new FormData();
            formData.append("_method", "DELETE");
            formData.append('id', value.id);
  
            const response = await ApiRest({
              url: URL_DELETE_PROVIDER,
              formData: formData,
              setLoading: setLoading,
              method: "post",
            });
  
            //Error
            if (response.status == 201 || response.status == 200) {
  
              //Actualizar datos OC
              //let params = ;
              dispatch(
                fetchProviders({
                  url: URL_INDEX_PROVIDERS,
                })
              );
  
              return ""
            }
  
            Object.keys(response.data).map((key) => {
              return Alerts({
                msg: "Alert",
                title: response.data[key][0],
                code: "401",
                type: "error",
              });
            });
  
          }
  
          return Alerts({
            msg: "Alert",
            title: "",
            code: "201",
            type: "delete",
            setFunction: setFunction
          });
        } break;
      default:
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":
        {
          setParams({
            ...params,
            search: e.target.value,
          });

          if (
            e.target.value.length > params.search.length
          ) {
            return dispatch(
              fetchProviders({
                url: params.url,
                search: e.target.value,
              })
            );
          }

          if (e.target.value.trim() == "") {
            return dispatch(
              fetchProviders({
                url: params.url,
              })
            );
          }
        }
        break;
    }
  };

  return (
    <Table
      campos={campos}
      order={order}
      contents={contents}
      actions={actions}
      pagination={pagination}
      onClick={onClick}
      onChange={onChange}
    />
  );
};

export default ContentTable;
