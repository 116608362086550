import { ConcatOrderPurchase } from "../../../utils/ConcatOrderPurchase";

const ModalAddOrders = ({ data, setModal, selectOrders, setSelectOrders }) => {
  const onClick = (e, value) => {
    switch (e.target.name) {
      case "close":{
        setModal(false);
      } break;
      case "añadir_orden":{
        let array = selectOrders;

        if(array.indexOf(value.id) < 0){
          array.push(value.id);
        } else {
          array.filter((id) => value.id !== id);
        }
        setSelectOrders([...array]);
        return  setModal(false);
      }break;
    }
  };
  const orderPurchase = ConcatOrderPurchase(data.data);
  return (
    <div
      className="modal fade show bg-dark bg-opacity-25  modal-transition bg-modal-blur"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 4 }}>
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div className="modal-header p-5">
            <h1 className="modal-title" id="nueva-ordenModalLabel">
              Añadir una órden de compra
            </h1>
          </div>
          <div className="modal-body p-5">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="" colSpan={2}>Órden de compra</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {
                  orderPurchase.map((order) => {
                    if(selectOrders.indexOf(order.id) < 0 && !order.withdrawal_order_id){ 
                      return <tr>
                      <td className="border-0 fs-3">{order.oc_name}</td>
                      <td className="border-0 fs-3">{order.provider_name}</td>
                      <td className="border-0">
                        <label className="btn btn-primary">
                          Añadir Órden
                          <button className="d-none"
                          name="añadir_orden"
                          onClick={(event) => onClick(event, order)}></button>
                        </label>
                      </td>
                    </tr>
                    }
                  })}
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddOrders;
