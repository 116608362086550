import { format } from "date-fns";
import { Fill } from "./Fill";

const total = (order) => {
  let wax_amount = 0;
  let wax_delivered = 0;

  /* Wax amount */
  order.wax.map((wax) => {
    wax_delivered = wax_delivered + parseFloat(wax.wax_delivered);
    wax_amount = wax_amount + parseFloat(wax.remaining_kilos);
  });

  return {
    wax_delivered: wax_delivered,
    wax_amount: wax_amount,
  };
};

export const ConcatOrderEntry = (orders) => {
  let result = [];


  orders.map((order) => {
    let oc_name = order.purchase_order.purchase_order_code ? order.purchase_order.purchase_order_code : null ;
    /* Result */
    order = {
      ...order,

      date: format(new Date(order.created_at), "dd/MM/yyyy").toString(),

      oc_name: oc_name ? "OC " +oc_name :
        String(order.purchase_order_id).length >= 4
          ? "OC " + order.purchase_order_id
          : "OC " + Fill(order.purchase_order_id, 4),

      or_name:
        String(order.withdrawal_order_id).length >= 4
          ? "OR " + order.withdrawal_order_id
          : "OR " + Fill(order.withdrawal_order_id, 4),

      products: "Varios",
      wax_delivered: total(order).wax_delivered + " KG",
      wax_amount: total(order).wax_amount + " KG",
      drums_amount: order.honey_drums.length + ""
    };

    result.push(order);
  });

  return result;
};
