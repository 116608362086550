import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/loads/Spinner";
import { authContext } from "../../context/AuthContext";
import { expoLotState, fetchExport } from "../../slices/exportLot/Index";
import { fetchRequestOrder, requestOrderState } from "../../slices/requestOrder/Index";
import { meState } from "../../slices/users/Index";
import Graduated from "./graduated/Index";
import Pending from "./pending/Index";


const RequestOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useParams();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_EXPORT_LOT } = values;
  //Initial States
  const _user = useSelector(meState);
  const _requestOrder = useSelector(requestOrderState);

  const requestOrder_pending = _requestOrder.requestOrder_pending;
  const requestOrder_request = _requestOrder.requestOrder_request;
  const me = _user.me;
  const rol_id = me.roles[0].id;

  const [select, setSelect] = useState(null);
  //

  const [params, setParams] = useState({
    url: URL_INDEX_EXPORT_LOT,
    consolidated: status == "pending" ? 0 : status == "graduated" ? 1 : null,
    search: "",
    start_date: "",
    end_date: "",
    sort: [
      {
        order_by: "id",
        ordering: "desc",
      },
    ],
  });

  //Title
  const h1 =
    status == "pending"
      ? "Egresos pendientes"
      : status == "graduated"
      ? "Egresos"
      : "";

  useEffect(() => {
    if (!requestOrder_pending || !requestOrder_request) {
      let params = {
        url: URL_INDEX_EXPORT_LOT,
        consolidated: 1,
        contain_contract: 1,
        contain_colors: 1,
        finished:  status == "pending"
        ? 0
        : status == "graduated"
        ? 1
        : "",
        sort: [
          {
            order_by: "id",
            ordering: "desc",
          },
        ],
      };
      dispatch(fetchRequestOrder(params));
    }
  }, [status]);
  return (
    <main className="main-content">
      <div className="position-relative iq-banner">
        <div className="iq-navbar-header" style={{ height: "215px" }}>
          <div className="container-fluid iq-container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <div className="box-img">
                    <img src="/assets/images/icon-ingreso.svg" width="80%" />
                  </div>
                  <h1 className="ms-2 mb-0">{h1}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              className="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div className="conatiner-fluid content-inner mt-n5 py-0">
        <div className="row">
          <div className="col-md-12 bg-grey rounded">
            <div className="row row-cols-1">
              <div className="col-12 mt-5 mb-3">
                {/* Filters */}
              </div>

              <div className="col-md-12">
                <div className="card">
                  {requestOrder_pending || requestOrder_request ? (
                    status == "pending" && requestOrder_pending ? (
                      <Pending
                        data={requestOrder_pending}
                        setParams={setParams}
                        params={params}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={() => {}}
                        label={status}
                        rol={rol_id}
                      />
                    ) : status == "graduated" && requestOrder_request ? (
                      <Graduated
                        data={requestOrder_request}
                        setParams={setParams}
                        params={params}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={() => {}}
                        label={status}
                        rol={rol_id}
                      />
                    ) : (
                      <Spinner />
                    )
                  ) : (
                    <Spinner />
                  ) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default RequestOrder;
