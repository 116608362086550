import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import clientAxios from "../../../../config/ClientAxios";
import { authContext } from "../../../../context/AuthContext";
import { Fill } from "../../../../utils/Fill";
import { setFormDetailsWax } from "../../../../utils/setFormDetailsWax";

const ModalOrderPurchaseDetails = ({ setModal, setSelect, select }) => {
  const navigate = useNavigate();

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "close":
        {
          setSelect(null);
          setModal(false);
        }
        break;
      case "analizados":
        {
          if (select.analyzed_drums_count > 0) {
            setModal(false);

            return navigate("/dashboard/orderpurchase/analyze/analyze")
          }
        }
        break;
      case "pendientes":
        {
          if (select.pending_drums_count > 0) {
            setModal(false);
          }
        }
        break;
      case "lotes":
        {
          return navigate("/dashboard/lot/analyze/pending");
        }
        break;
    }
  };

  console.log(select);
  return (
    <div
      className="modal fade show bg-dark bg-opacity-25 modal-transition bg-modal-blur"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 4 }}>
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div class="modal-header p-5">
            <h1 class="modal-title bold" id="nueva-ordenModalLabel">
              <strong>
                O/C{" "}
                {String(select.id).length >= 4 ? select.id : Fill(select.id, 4)}
              </strong>
            </h1>
            <img src="../assets/images/beee.png" alt="" />
          </div>
          <div class="modal-body p-5">
            <div class="row justify-content-between">
              <div class="col-md-3 text-start mb-5">
                <div class="card">
                  <div class="d-flex flex-column">
                    <di class="p-4">
                      <span class="text-dark text-start fw-bolder fs-3">
                        {select.honey_drums_count}
                      </span>
                      <p class="text-start mt-1 mb-0">Tambores</p>
                    </di>
                  </div>
                </div>
              </div>
              <div class="col-md-3 text-start mb-5">
                <div class="card">
                  <div class="d-flex flex-column">
                    <div class="p-4">
                      <span class="text-dark text-start fw-bolder fs-3">{select.lot_analysis_count}</span>
                      <p class="text-start mt-1 mb-0">Lotes</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div>
                <p>Detalle - Tambores</p>
              </div>
              <hr class="mt-0 mb-5" />
            </div>
            <div class="d-flex flex-row justify-content-start">
              <div class="card col-md-3 me-4">
                <div class="card-body d-flex align-items-center justify-content-around">
                  <div class="progress-detail">
                    <h4 class="counter fw-bolder fs-2">
                      {select.pending_drums_count}
                    </h4>
                    <p class="mb-0">Tambores pendientes</p>
                    <label class="d-flex align-items-center btn-link">
                      Ver todos
                      <svg
                        class="ms-2"
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                          fill="#8A92A6"
                        />
                      </svg>
                      <button
                        className="d-none"
                        name="pendientes"
                        onClick={(event) => onClick(event)}
                      ></button>
                    </label>
                  </div>
                  <div class="d-flex align-self-end">
                    <img src="../assets/images/icon-advertencia.svg" alt="" />
                  </div>
                </div>
              </div>
              <div class="card col-md-3">
                <div class="card-body d-flex align-items-center justify-content-around">
                  <div class="progress-detail">
                    <h4 class="counter fw-bolder fs-2">
                      {select.analyzed_drums_count}
                    </h4>
                    <p class="mb-0">Tambores Analizados</p>
                    <label class="d-flex align-items-center btn-link">
                      Ver todos
                      <svg
                        class="ms-2"
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                          fill="#8A92A6"
                        />
                      </svg>
                      <button
                        className="d-none"
                        name="analizados"
                        onClick={(event) => onClick(event)}
                      ></button>
                    </label>
                  </div>
                  <div class="d-flex align-self-end">
                    <img src="../assets/images/icon-check.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div>
                <p>Detalle - Tambores</p>
              </div>
              <hr class="mt-0 mb-5" />
            </div>
            <div class="row">
              {select.lot_analysis.map((lot) => (
                <div class="card col">
                  <div class="card-body d-flex align-items-center justify-content-around">
                    <div class="progress-detail">
                      <h5 class="counter fw-bolder">
                        <span class="fs-2">{lot.honey_drums_count}</span>{" "}
                        Tambores
                      </h5>
                      <p class="mb-0">Lote {lot.id}</p>
                      <label class="btn p-0 m-0 d-flex align-items-center btn-link">
                        Ver todos
                        <svg
                          class="ms-2"
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                            fill="#8A92A6"
                          />
                        </svg>
                        <button
                            className="d-none"
                            name="lotes"
                            onClick={(event) => onClick(event)}
                          ></button>
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalOrderPurchaseDetails;
