export const ConcatProducts = (products) => {
  let result = [];


  products.map((prd) => {
    if(prd.type){
    let name = prd.name+" - "+prd.type
    prd = {...prd, name: name}
    }

    
    result.push(prd);
  })
  
  return result
}