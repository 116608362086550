import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import Alerts from "../../../components/alerts/Alerts";
import BtnAccept from "../../../components/forms/BtnAccept";
import Input from "../../../components/forms/Input";
import InputSearchMini from "../../../components/forms/InputSearchMini";
import Select from "../../../components/forms/Select";
import TextTarea from "../../../components/forms/TextTarea";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { fetchClients } from "../../../slices/clients/Index";
import geolocation, {
  fetchCityList,
  fetchProvinceList,
  fetchSearchCountry,
  fetchSearchProvince,
  geolocationState,
  setCityList,
  setSearchCity,
  setSearchCountry,
  setSearchProvince,
} from "../../../slices/geolocation";
import { meState } from "../../../slices/users/Index";
import { setForm_client } from "../../../utils/setForms";
import { Validate } from "../../../utils/ValidateForms";

const ModalClient = ({ setModal, setSelect, select, dispatch }) => {
  const context = useContext(authContext);
  const { values, forms, validations } = context;
  const {
    URL_INDEX_COUNTRY,
    URL_INDEX_PROVINCE,
    URL_CREATE_CUSTOMER,
    URL_INDEX_CUSTOMER,
    URL_UPDATE_CUSTOMER,
  } = values;
  const { FORM_CLIENTS } = forms;
  const { VALIDATION_CLIENT } = validations;

  const _user = useSelector(meState);
  const _geolocation = useSelector(geolocationState);

  //const province = _geolocation.province;
  const province = _geolocation.province;
  const search_province = _geolocation.search_province;
  const country = _geolocation.search_country;
  const city = _geolocation.city;
  const search_city = _geolocation.search_city;
  const roles = _user.roles;

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(null);
  const [validation, setValidation] = useState(
    JSON.parse(JSON.stringify(VALIDATION_CLIENT))
  );

  const onClick = async (e, value) => {
    e.preventDefault();

    switch (e.target.name) {
      case "guardar":
        {
          //create form
          let formData = new FormData();
          formData.append("id", select.id);
          formData.append("business_name", form.empresa[0]);
          formData.append("condition", form.condicion[0]);
          formData.append("email", form.email[0]);
          formData.append("comments", form.observacion[0]);

          formData.append("country_id", form.pais_id[0]);
          formData.append("province_name", form.provincia[0]);
          formData.append("city_name", form.ciudad[0]);
          formData.append("address", form.direccion[0]);

          //
          const response = await ApiRest({
            url: URL_UPDATE_CUSTOMER,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Actualizando cliente.",
              code: "201",
              type: "success",
            });

            //Actualizar datos providers
            let params;
            dispatch(fetchClients((params = { url: URL_INDEX_CUSTOMER })));

            //cerrar modal
            return setModal(false);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "aceptar":
        {
          const filteredData = {};
          for (const key in form) {
              if (key !== "observacion") {
                  filteredData[key] = form[key];
              }
          }

          const validation = Validate(filteredData);


          setValidation(validation);
          //Validaciones
          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "201",
              type: "error",
            });
          }

          //create form
          let formData = new FormData();
          formData.append("business_name", form.empresa[0]);
          formData.append("condition", form.condicion[0]);
          formData.append("email", form.email[0]);
          formData.append("comments", form.observacion[0]);

          formData.append("country_id", form.pais_id[0]);
          formData.append("province_name", form.provincia[0]);
          formData.append("city_name", form.ciudad[0]);
          formData.append("address", form.direccion[0]);

          //
          const response = await ApiRest({
            url: URL_CREATE_CUSTOMER,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Creando nuevo cliente.",
              code: "201",
              type: "success",
            });

            //Actualizar datos providers
            let params;
            dispatch(fetchClients((params = { url: URL_INDEX_CUSTOMER })));

            //cerrar modal
            return setModal(false);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "ciudad":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = value.name;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });

          return dispatch(setSearchCity([]));
        }
        break;
      case "provincia":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = value.name;
          setCampo[e.target.name + "_id"][0] = value.id;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
            [e.target.name + "_id"]: setCampo[e.target.name + "_id"],
          });

          dispatch(setSearchProvince([]));

          return dispatch(fetchCityList(value.id));
        }
        break;
      case "pais":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = value.name;
          setCampo["pais_id"][0] = value.id;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
            ["pais_id"]: setCampo["pais_id"],
          });

          let params = {
            url: URL_INDEX_PROVINCE,
            country_id: value.id,
          };
          dispatch(fetchProvinceList(params));

          return dispatch(setSearchCountry({ data: [] }));
        }
        break;
      case "close":
        {
          setSelect(null);
          setModal(false);
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "empresa":
      case "condicion":
      case "observacion":
      case "email":
      case "direccion":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
      case "pais_search":
        {
          let setCampo = form;
          setCampo["pais"][0] = e.target.value;

          setForm({
            ...form,
            pais: setCampo["pais"],
          });

          if (e.target.value.length > 3) {
            let params = {
              url: URL_INDEX_COUNTRY,
              search: e.target.value,
            };
            return dispatch(fetchSearchCountry(params));
          }

          return dispatch(setSearchCountry({ data: [] }));
        }
        break;
      case "provincia_search":
        {
          let setCampo = form;
          setCampo["provincia"][0] = e.target.value;

          setForm({
            ...form,
            provincia: setCampo["provincia"],
          });

          if (e.target.value.length > 3) {
            const result = province.filter(
              (item) =>
                item.name.toLowerCase().search(e.target.value.toLowerCase()) !=
                -1
            );
            return dispatch(setSearchProvince(result));
          }

          return dispatch(setSearchProvince([]));
        }
        break;
      case "ciudad_search":
        {
          let setCampo = form;
          setCampo["ciudad"][0] = e.target.value;

          setForm({
            ...form,
            ciudad: setCampo["ciudad"],
          });

          if (e.target.value.length > 3) {
            const result = city.filter(
              (item) =>
                item.name.toLowerCase().search(e.target.value.toLowerCase()) !=
                -1
            );
            return dispatch(setSearchCity(result));
          }

          return dispatch(setSearchCity([]));
        }
        break;
    }
  };

  useEffect(() => {
    if (select) {
      setForm(setForm_client(select));
    } else {
      setForm(JSON.parse(JSON.stringify(FORM_CLIENTS)));
    }
  }, [select]);

  console.log(select);
  return (
    <div
      class="modal d-block bg-dark bg-opacity-25  modal-transition bg-modal-blur "
      tabindex="-1"
      aria-labelledby="nuevo-proveedorModalLabel"
      aria-hidden="true"
      id="modal-provider"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content modal-naranja">
          <label className="btn btn-close">
            <button
              type="button"
              class="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div class="d-flex align-items-center pt-5 px-5">
            <img
              src="/assets/images/icon-provedor.svg"
              style={{ width: "40px" }}
              alt=""
            />
            <h1 class="text-white">Nuevo cliente - {select ? "" : "0000"} </h1>
          </div>
          <div class="modal-body p-5">
            {form ? (
              <form autoComplete="off">
                <div class="row">
                  <div class="col-md-6 mb-3 text-white">
                    <Input
                      onChange={onChange}
                      label={"Nombre o razón social"}
                      name={"empresa"}
                      type={"text"}
                      value={form.empresa[0]}
                      validate={validation.empresa}
                    />
                  </div>
                  <div class="col-md-6 mb-3 text-white">
                    <InputSearchMini
                      onChange={onChange}
                      label={"Pais de origen"}
                      name={"pais_search"}
                      type={"text"}
                      //defaultValue={select? select.city.name : ""}
                      value={form.pais[0]}
                      //Autocomplete
                      onClick={onClick}
                      nameAutocomplete="pais"
                      valueAutocomplete={country.data}
                      showIndex="name"
                      sendIndex="name"
                      validate={validation.pais}
                    />
                  </div>
                  <div class="col-md-3 mb-3 text-white">
                    <Input
                      onChange={onChange}
                      label={"Dirección"}
                      name={"direccion"}
                      type={"text"}
                      value={form.direccion[0]}
                      validate={validation.direccion}
                    />
                  </div>

                  <div className="col-md-3 mb-3 text-white">
                    <InputSearchMini
                      onChange={onChange}
                      label={"Provincia/Estado"}
                      name={"provincia_search"}
                      type={"text"}
                      //defaultValue={select? select.city.name : ""}
                      value={form.provincia[0]}
                      //Autocomplete
                      onClick={onClick}
                      nameAutocomplete="provincia"
                      valueAutocomplete={search_province}
                      showIndex="name"
                      sendIndex="name"
                      validate={validation.pais}
                    />
                  </div>

                  <div class="col-md-3 mb-3 text-white">
                    <InputSearchMini
                      onChange={onChange}
                      label={"Localidad"}
                      name={"ciudad_search"}
                      type={"text"}
                      //defaultValue={select? select.city.name : ""}
                      value={form.ciudad[0]}
                      //Autocomplete
                      onClick={onClick}
                      nameAutocomplete="ciudad"
                      valueAutocomplete={search_city}
                      showIndex="name"
                      sendIndex="name"
                      validate={validation.ciudad}
                    />
                  </div>

                  <div class="col-md-6 mb-3 text-white">
                    <Input
                      onChange={onChange}
                      label={"Email"}
                      name={"email"}
                      type={"email"}
                      value={form.email[0]}
                      validate={validation.email}
                    />
                  </div>

                  <div className="col-md-6 mb-3 text-white">
                    <Select
                      onChange={onChange}
                      label="Condiciones"
                      name="condicion"
                      type=""
                      defaultValue={form.condicion[0]}
                      value={[{ name: "Directo" }, { name: "Broker" }]}
                      showIndex="name"
                      sendIndex="name"
                      validate={validation.condicion}
                    />
                  </div>

                  <div class="col-12 mb-3 text-white">
                    <TextTarea
                      onChange={onChange}
                      label={"Observaciones"}
                      name={"observacion"}
                      type={"text"}
                      value={form.observacion[0]}
                      validate={validation.observacion}
                    />
                  </div>

                  <div class="col-md-12 d-flex justify-content-end align-items-end">
                    <div class="btn-group">
                      <BtnAccept
                        onClick={onClick}
                        name={select ? "guardar" : "aceptar"}
                        value={select ? "Guardar" : "Aceptar"}
                        loading={loading}
                        loadingMsg={
                          select ? "Guardando datos..." : "Subiendo datos..."
                        }
                      />
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalClient;
