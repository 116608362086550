import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { MapContainer, TileLayer, LayersControl, Marker, Popup, useMap, useMapEvent, Rectangle, Polyline } from "react-leaflet";
import {ReactLeafletWaypoints} from 'react-leaflet-waypoints';

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
}


const BOUNDS_STYLE = { weight: 1 }

const Maps = () => {
  let direccion_1 = "Av. 9 de Julio 4500";
  let provincia_1 = "Chaco";
  let localidad_1 = "Barranqueras";

  
  let direccion_2 = "Av. 9 de Julio 1000";
  let provincia_2 = "Chaco";
  let localidad_2 = "Barranqueras";


  function MinimapBounds({ parentMap, zoom }) {
    const minimap = useMap()
  
    // Clicking a point on the minimap sets the parent's map center
    const onClick = useCallback(
      (e) => {
        parentMap.setView(e.latlng, parentMap.getZoom())
      },
      [parentMap],
    )
    useMapEvent('click', onClick)
  
    // Keep track of bounds in state to trigger renders
    const [bounds, setBounds] = useState(parentMap.getBounds())
    const onChange = useCallback(() => {
      setBounds(parentMap.getBounds())
      // Update the minimap's view to match the parent map's center and zoom
      minimap.setView(parentMap.getCenter(), zoom)
    }, [minimap, parentMap, zoom])
  
    // Listen to events on the parent map
    const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [])
    //useEventHandlers({ instance: parentMap }, handlers)
  
    return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />
  }

  function MinimapControl({ position, zoom }) {
    const parentMap = useMap()
    const mapZoom = zoom || 0
  
    // Memoize the minimap so it's not affected by position changes
    const minimap = useMemo(
      () => (
        <MapContainer
          style={{ height: 80, width: 80 }}
          center={[-27.488642912751924, -58.9292716874782]}
          zoom={40}
          dragging={false}
          doubleClickZoom={false}
          scrollWheelZoom={false}
          attributionControl={false}
          zoomControl={false}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <MinimapBounds parentMap={parentMap} zoom={40} />
        </MapContainer>
      ),
      [],
    )
  
    const positionClass =
      (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
    return (
      <div className={positionClass}>
        <div className="leaflet-control leaflet-bar">{minimap}</div>
      </div>
    )
  }

  const center = {
    lat: -27.488642912751924,
    lng: -58.9292716874782,
  }

  function DraggableMarker() {
    const [draggable, setDraggable] = useState(false)
    const [position, setPosition] = useState(center)
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setPosition(marker.getLatLng())
          }
        },
      }),
      [],
    )
    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d)
    }, [])
  
    return (
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}>
        <Popup minWidth={90}>
          <span onClick={toggleDraggable}>
            {draggable
              ? 'Marker is draggable'
              : 'Click here to make marker draggable'}
          </span>
        </Popup>
      </Marker>
    )
  }

  
const polyline = [
  [-27.49171701183173, -58.954541090469064],
  [-27.4863871894049, -58.93213928118032],
]


const position = [-27.49171701183173, -58.954541090469064];
const position2 = [-27.4863871894049, -58.93213928118032];

const limeOptions = { color: 'blue' }

  return (
    <div className="min-vh-100">
      {
        <MapContainer center={position} zoom={20} scrollWheelZoom={false} style={{width: "100%", height: "400px"}}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position2}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
          <Marker position={position}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
          <Polyline pathOptions={limeOptions} positions={polyline} />
            <MinimapControl position="topright" />
        </MapContainer>
      }

      <div className="">
        <iframe
          src={
            "https://maps.google.com/?q=" +
            encodeURI(direccion_1 + " " + provincia_1 + " " + localidad_1) +
            "&z=14&t=m&output=embed"
          }
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          height="400px"
          className="my-3 w-100"
        ></iframe>
      </div>

      <div className="">
      <ReactLeafletWaypoints
      layerUrl="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      waypoints={[{lat: '-27.486265053057284', lng: '-58.932070321791635'},{lat: '-27.486112768457062', lng: '-58.94606072376124'}]}
    />
      </div>
    </div>
  );
};

export default Maps;
