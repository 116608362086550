import { format } from "date-fns";
import { useContext, useEffect } from "react";
import Input from "../../../components/forms/Input";
import InputSearch from "../../../components/forms/InputSearch";
import Select from "../../../components/forms/Select";
import { fetchOrderPurchaseList } from "../../../slices/orderPurchase/Index";
import { authContext } from "../../../context/AuthContext";
import { fetchDrums } from "../../../slices/drums/Index";
import clientAxios from "../../../config/ClientAxios";

const FiltersDrums = ({ params, setParams, dispatch, label }) => {
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_DRUM } = values;

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "fecha_desde":
        {
          setParams({
            ...params,
            start_date:
              e.target.value != ""
                ? format(
                  new Date(e.target.value + " 00:00:00"),
                  "yyyy-MM-dd"
                ).toString()
                : "",
          });

          if (e.target.value != "") {
            dispatch(
              fetchOrderPurchaseList({
                url: params.url,
                order_state_id:
                  label == "pending" ? 1 : label == "submitted" ? 2 : null,
                start_date: format(
                  new Date(e.target.value + " 00:00:00"),
                  "yyyy-MM-dd"
                ).toString(),
                end_date:
                  params.end_date == ""
                    ? format(
                      new Date(e.target.value + " 00:00:00"),
                      "yyyy-MM-dd"
                    ).toString()
                    : params.end_date,
              })
            );
          } else {
            if (e.target.value == "" && params.end_date == "") {
              dispatch(
                fetchOrderPurchaseList({
                  url: params.url,
                  order_state_id:
                    label == "pending" ? 1 : label == "submitted" ? 2 : null,
                })
              );
            }
          }
        }
        break;
      case "fecha_hasta":
        {
          setParams({
            ...params,
            end_date:
              e.target.value != ""
                ? format(
                  new Date(e.target.value + " 00:00:00"),
                  "yyyy-MM-dd"
                ).toString()
                : "",
          });

          if (e.target.value != "") {
            dispatch(
              fetchOrderPurchaseList({
                url: params.url,
                order_state_id:
                  label == "pending" ? 1 : label == "submitted" ? 2 : null,
                start_date:
                  params.start_date == ""
                    ? format(
                      new Date(e.target.value + " 00:00:00"),
                      "yyyy-MM-dd"
                    ).toString()
                    : params.start_date,
                end_date: format(
                  new Date(e.target.value + " 00:00:00"),
                  "yyyy-MM-dd"
                ).toString(),
              })
            );
          } else {
            if (e.target.value == "" && params.start_date == "") {
              dispatch(
                fetchOrderPurchaseList({
                  url: params.url,
                  order_state_id:
                    label == "pending" ? 1 : label == "submitted" ? 2 : null,
                })
              );
            }
          }
        }
        break;
      case "adulteracion": {
        let set = {
          ...params,
          [e.target.name]: e.target.value
        };

        dispatch(fetchDrums(set));

        setParams({
          ...params,
          [e.target.name]: e.target.value
        });
      } break;
      case "glisofato":
      case "hmf_value":
      case "net_weight":
      case "moisture":
      case "color": {
        let input = e.target.value.split(",");

        setParams({
          ...params,
          [e.target.name]: input
        });

        console.log(input);
        if (input.length == 2) {
          if (params.hasOwnProperty(e.target.name)) {
            if (params[e.target.name][1].length < input[1].length) {
              let set = {
                ...params,
                [e.target.name]: input
              };
              dispatch(fetchDrums(set));
            }
          } else {
            let set = {
              ...params,
            };
            dispatch(fetchDrums(set));
          }
        }
      } break;
    }
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "showAccordion":
        {
          if (
            document
              .getElementById("collapse-filters")
              .classList.contains("show")
          ) {
            document.getElementById("btn-filters").classList.add("collapsed");
            document
              .getElementById("collapse-filters")
              .classList.remove("show");
          } else {
            document
              .getElementById("btn-filters")
              .classList.remove("collapsed");
            document.getElementById("collapse-filters").classList.add("show");
          }
        }
        break;
      case "search":
        {
          if (params.search.trim() == "") {
            return "";
          }

          return dispatch(
            fetchOrderPurchaseList({
              url: params.url,
              search: params.search,
              order_state_id:
                label == "pending" ? "null" : label == "submitted" ? 2 : null,
            })
          );
        }
        break;
    }
  };

  return (
    <div className="">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <label className="accordion-button collapsed" id="btn-filters">
              <img
                src="/assets/images/icon-filter.svg"
                style={{ width: "25px" }}
                class="me-2"
              />
              Filtros
              <button
                class="d-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-controls="flush-collapseOne"
                name="showAccordion"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </h2>
          <div
            id="collapse-filters"
            class="accordion-collapse collapse "
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <div class="row justify-content-start">
                <div class="col-md-3 d-flex flex-column mb-3">
                  <Input
                    onChange={onChange}
                    label={"Color"}
                    name={"color"}
                    type={"text"}
                    value={params.color}
                    validate={{ msg: "", validate: false }}
                    nameType={"mm"}
                    icon={"/assets/images/icon-input-fecha.svg"}
                  />
                </div>
                <div class="col-md-3 d-flex flex-column mb-3">
                  <Input
                    onChange={onChange}
                    label={"Humedad"}
                    name={"moisture"}
                    type={"text"}
                    value={params.moisture}
                    validate={{ msg: "", validate: false }}
                    nameType={"%"}
                    icon={"/assets/images/icon-input-fecha.svg"}
                  />
                </div>
                <div class="col-md-3 d-flex flex-column mb-3">
                  <Input
                    onChange={onChange}
                    label={"Kg Neto"}
                    name={"net_weight"}
                    type={"text"}
                    value={params.net_weight}
                    validate={{ msg: "", validate: false }}
                    nameType={"Kg"}
                    icon={"/assets/images/icon-input-fecha.svg"}
                  />
                </div>
                <div class="col-md-3 d-flex flex-column mb-3">
                  <Input
                    onChange={onChange}
                    label={"HMF"}
                    name={"hmf_value"}
                    type={"text"}
                    value={params.hmf_value}
                    validate={{ msg: "", validate: false }}
                    icon={"/assets/images/icon-input-fecha.svg"}
                  />
                </div>
                <div class="col-md-3 d-flex flex-column mb-3">
                  <Select
                    onChange={onChange}
                    label="Adulteracion"
                    name="adulteracion"
                    type=""
                    defaultValue={""}
                    value={[{ name: "Negativo" }, { name: "Positivo" }]}
                    showIndex="name"
                    sendIndex="id"
                    validate={{ msg: "", validate: false }}
                    icon={null}
                  />
                </div>
                <div class="col-md-3 d-flex flex-column mb-3">
                  <Input
                    onChange={onChange}
                    label={"Glisofato"}
                    name={"glisofato"}
                    type={"text"}
                    value={params.glisofato}
                    validate={{ msg: "", validate: false }}
                    icon={"/assets/images/icon-input-fecha.svg"}
                  />
                </div>

                <div class="col-md-3 d-flex flex-column mb-3 d-none">
                  <Input
                    onChange={onChange}
                    label={"Fecha desde"}
                    name={"fecha_desde"}
                    type={"date"}
                    value={params.start_date}
                    validate={{ msg: "", validate: false }}
                    icon={"/assets/images/icon-input-fecha.svg"}
                  />
                </div>

                <div class="col-md-3 d-flex flex-column mb-3 d-none">
                  <Input
                    onChange={onChange}
                    label={"Fecha hasta"}
                    name={"fecha_hasta"}
                    type={"date"}
                    value={params.end_date}
                    validate={{ msg: "", validate: false }}
                    icon={"/assets/images/icon-input-fecha.svg"}
                  />
                </div>
              </div>
              <div class="row justify-content-start d-none">
                <div class="col-md-3 d-flex flex-column mb-3">
                  <label class="form-label">Producto</label>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">
                      <img src="../assets/images/tambor.svg" alt="" />
                    </span>
                    <input
                      type="number"
                      class="form-control"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
                <div class="col-md-3 d-flex flex-column mb-3">
                  <label class="form-label">O/C</label>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">
                      <img src="../assets/images/icon-input-cuit.svg" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersDrums;
