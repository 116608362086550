const InputFile = ({
  onChange,
  label,
  name,
  type,
  value,
  validate,
  icon,
  nameType,
}) => {
  return (
    <div>
      <label className="form-label" for="customFile">
        {label}
      </label>
      <input 
      type="file" 
      className="form-control file" 
      id="customFile" 
      name={name}
      onChange={(event) => onChange(event)}

      accept=".pdf, .png, .jpg, jpeg"
      />
      
      {validate.validate ? (
        <div className="text-danger">{validate.msg}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InputFile;
