import { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/tables/Table";
import { authContext } from "../../../context/AuthContext";
import { fetchHomogenizedReady } from "../../../slices/homogenize/Index";
import { ClearParams } from "../../../utils/ClearParams";
import { ConcatExpoLot } from "../../../utils/ConcatExpoLot";
import ModalAssingContract from "../modals/ModalAssingContract";
import ModalChargeDrums from "../modals/ModalChargeDrums";
import DrumsFractional from "./drums/Index";

const Fractional = ({
  data,

  setParams,
  params,
  dispatch,
  status,
}) => {
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_EXPORT_LOT } = values;

  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState(null);
  const [drums, setDrums] = useState(null)
  const [loading, setLoading] = useState(false);
  /*********************************************** INITIAL STATE ***********************************************************/
  const campos = [
    {
      name: "Nº LOTE EXP",
      order: {
        state: true,
        param: "id",
        active: params.sort,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "UNIDADES",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "MIEL A HOMOGENEIZAR",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "MIEL EMVASADA",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ACCIONES",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-center align-items-center",
    },
  ];
  const order = ["name", "purchased_honey_drums_count", "purchased_honey_drums_sum_net_weight", "fractionated_honey_drums_sum_net_weight"];
  const contents = data ? ConcatExpoLot(data.data) : [];
  const actions = [
    {
      title: "Ver Tambores",
      name: "ver_tambores",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 13 11" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:evenodd;fill:rgb(94.509804%,41.568627%,10.588235%);fill-opacity:1;" d="M 13 5.75 C 13 5.75 10.65625 1.5 6.75 1.5 C 2.84375 1.5 0.5 5.75 0.5 5.75 C 0.5 5.75 2.84375 10 6.75 10 C 10.65625 10 13 5.75 13 5.75 Z M 1.417969 5.75 C 1.792969 6.316406 2.230469 6.847656 2.714844 7.328125 C 3.71875 8.324219 5.09375 9.226562 6.75 9.226562 C 8.40625 9.226562 9.78125 8.324219 10.789062 7.328125 C 11.273438 6.847656 11.707031 6.316406 12.085938 5.75 C 11.707031 5.183594 11.273438 4.652344 10.789062 4.171875 C 9.78125 3.175781 8.40625 2.273438 6.75 2.273438 C 5.09375 2.273438 3.71875 3.175781 2.710938 4.171875 C 2.226562 4.652344 1.792969 5.183594 1.414062 5.75 Z M 1.417969 5.75 "/><path style=" stroke:none;fill-rule:evenodd;fill:rgb(94.509804%,41.568627%,10.588235%);fill-opacity:1;" d="M 6.75 3.785156 C 6.230469 3.785156 5.730469 3.992188 5.359375 4.359375 C 4.992188 4.730469 4.785156 5.230469 4.785156 5.75 C 4.785156 6.269531 4.992188 6.769531 5.359375 7.140625 C 5.730469 7.507812 6.230469 7.714844 6.75 7.714844 C 7.269531 7.714844 7.769531 7.507812 8.140625 7.140625 C 8.507812 6.769531 8.714844 6.269531 8.714844 5.75 C 8.714844 5.230469 8.507812 4.730469 8.140625 4.359375 C 7.769531 3.992188 7.269531 3.785156 6.75 3.785156 Z M 4 5.75 C 4 5.019531 4.289062 4.320312 4.804688 3.804688 C 5.320312 3.289062 6.019531 3 6.75 3 C 7.480469 3 8.179688 3.289062 8.695312 3.804688 C 9.210938 4.320312 9.5 5.019531 9.5 5.75 C 9.5 6.480469 9.210938 7.179688 8.695312 7.695312 C 8.179688 8.210938 7.480469 8.5 6.75 8.5 C 6.019531 8.5 5.320312 8.210938 4.804688 7.695312 C 4.289062 7.179688 4 6.480469 4 5.75 Z M 4 5.75 "/></g></svg>',
      id: "ver_tambores",

      class: "btn border-0 d-flex align-items-center justify-content-center",
      text: "",
    },
  ];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = data
      ? data
      : {
        current_page: 0,
        first_page_url: 0,
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: "",
        to: 0,
        total: 0,
      };
  const pagination = {
    type: 1,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  /**********************************************************************************************************************/

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "order":
        {
          let setValueParams = params;
          let sort = [];

          setValueParams.sort.map((order) => {
            if (order.order_by == value.param) {
              order = {
                ...order,
                ordering: value.order,
              };
            }

            sort.push(order);
          });

          params = {
            ...params,
            sort: [
              {
                order_by: value.param,
                ordering: value.order,
              },
            ],
          };


          setParams(params);
          let clear_params = ClearParams(params);

          dispatch(fetchHomogenizedReady(clear_params));
        }
        break;
      case "page":
        {
          params = {
            ...params,
            url: value,
          };
          dispatch(fetchHomogenizedReady(params));
        }
        break;
      case "ver_tambores": {
        setDrums(value);
      } break;
      case "cargar_tambores":
        {
          setSelect(value)
          setModal(true)
        }
        break;
      case "download-xls":
        {
        }
        break;
      case "download-print":
        {
          return true;
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "":
        {
        }
        break;
    }
  };

  console.log(contents);

  if (drums) {
    return (<div class="card-body p-0 m-0">
      <DrumsFractional
        data={drums}
        setActive={setDrums}
      />
    </div>)
  }

  return (
    <div class="card-body">
      <Table
        print={true}
        campos={campos}
        order={order}
        contents={contents}
        actions={actions}
        pagination={pagination}
        onClick={onClick}
        onChange={onChange}
      />

      {
        modal
          ? <ModalChargeDrums
            setModal={setModal}
            select={select}
            loading={loading}
            setLoading={setLoading}
          />
          : ""
      }
    </div>
  );
};

export default Fractional;
