import { format } from "date-fns";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Spinner from "../../../components/loads/Spinner";
import clientAxios from "../../../config/ClientAxios";
import { authContext } from "../../../context/AuthContext";

const ModalDrumsDetails = ({ select, setModal }) => {
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_SHOW_DRUM } = values;

  const [drum, setDrum] = useState(null);
  const { entry_order, purchase_order, sector, state, withdrawal_order } = drum
    ? drum[0]
    : {
        entry_order: {},
        purchase_order: { date: "2001-01-01", purchase_details: [] },
        sector: { name: "", created_at: "" },
        state: {},
        withdrawal_order: null,
      };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "close":
        {
          setModal(false);
        }
        break;
    }
  };

  useEffect(() => {
    if (!drum) {
      const fetch = async () => {
        let params = {
          id: select.id,
        };

        const response = await clientAxios.get(URL_SHOW_DRUM, { params });

        setDrum(response.data);
      };

      fetch();
    }
  }, []);


  return (
    <div
      className="modal fade show bg-dark bg-opacity-25  modal-transition bg-modal-blur"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 4 }}>
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div className="modal-header p-5">
            <h1 className="modal-title" id="nueva-ordenModalLabel">
              Detalle tambor - {select.entry_details[0] && <>
              
                {select.entry_details[0].geomiel_code}
              </>
                }
              <span className="text-primary"></span>
            </h1>
          </div>
          {drum ? (
            <div class="modal-body p-5">
              {purchase_order.purchase_details.map((detail) => {
                if (detail.product_id == 1) {
                  return (
                    <div class="row">
                      <div class="col-md-12 mt-3">
                        <h4 class="mb-3">Compra</h4>
                        <hr />
                      </div>
                      <div class="col-md-2 my-2">
                        <h6>Fecha</h6>
                        <p>
                          {format(
                            new Date(purchase_order.date),
                            "dd/M/yyyy"
                          ).toString()}
                        </p>
                      </div>
                      <div class="col-md-3 my-2">
                        <h6>Peso neto apicultor</h6>
                        <p className="text-nowrap">
                          {drum[0].net_weight} kg
                        </p>
                      </div>
                      <div class="col-md-2 my-2">
                        <h6>Tara</h6>
                        <p className="text-nowrap">{drum[0].tare} kg</p>
                      </div>
                      <div class="col-md-2 my-2">
                        <h6>Bruto</h6>
                        <p className="text-nowrap">
                          {drum[0].gross_weight} kg
                        </p>
                      </div>
                      <div class="col-md-6 my-2">
                        <h6 className="mb-1">Observaciones</h6>
                        <div className="d-flex">
                          {purchase_order.comments
                            ? purchase_order.comments
                            : "No existen observaciones"}
                        </div>
                      </div>
                      <div class="col-md-6 my-2">
                        <h6 className="mb-1">Imagenes adjuntas</h6>
                        <div className="d-flex p-1">
                          {purchase_order.images.length > 0 ? (
                            purchase_order.images.map((img) => (
                              <img
                                src={img.url}
                                style={{ width: "100px" }}
                                className="img-fluid me-1 border"
                                alt=""
                              />
                            ))
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="70px"
                              height="70px"
                              fill="currentColor"
                              class="bi bi-image-alt border p-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zm4.225 4.053a.5.5 0 0 0-.577.093l-3.71 4.71-2.66-2.772a.5.5 0 0 0-.63.062L.002 13v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4.5l-4.777-3.947z" />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              <div class="row">
                <div class="col-md-12 mt-3">
                  <h4 class="mb-3">Logística</h4>
                  <hr />
                </div>
                <div className="col-md-12">
                  {withdrawal_order ? (
                    withdrawal_order.withdrawal_details.length > 0 ? (
                      withdrawal_order.withdrawal_details.map((detail) => (
                        <div className="row">
                          <div class="col-md-2 my-2">
                            <h6>Fecha</h6>
                            <p>
                              {format(
                                new Date(withdrawal_order.date),
                                "dd/M/yyyy"
                              ).toString()}
                            </p>
                          </div>
                          <div class="col-md-3 my-2">
                            <h6>Peso neto transportista</h6>
                            <p>{detail.total_weight} kg</p>
                          </div>
                          <div class="col-md-2 my-2">
                            <h6>Tara</h6>
                            <p>{detail.tare} kg</p>
                          </div>
                          <div class="col-md-2 my-2">
                            <h6>Bruto</h6>
                            <p>{detail.gross_weight} kg</p>
                          </div>
                          <div class="col-md-6 my-2">
                            <h6 className="mb-1">Observaciones</h6>
                            <div className="d-flex">
                              {detail.comments
                                ? detail.comments
                                : "No existen observaciones"}
                            </div>
                          </div>
                          <div class="col-md-6 my-2">
                            <h6 className="mb-1">Imagenes adjuntas</h6>
                            <div className="d-flex p-1">
                              {detail.images.length > 0 ? (
                                detail.images.map((img) => (
                                  <img
                                    src={img.url}
                                    style={{ width: "100px" }}
                                    className="img-fluid me-1 border"
                                    alt=""
                                  />
                                ))
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="70px"
                                  height="70px"
                                  fill="currentColor"
                                  class="bi bi-image-alt border p-1"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M7 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zm4.225 4.053a.5.5 0 0 0-.577.093l-3.71 4.71-2.66-2.772a.5.5 0 0 0-.63.062L.002 13v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4.5l-4.777-3.947z" />
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="my-3">Tambor no ingresado</div>
                    )
                  ) : (
                    <div className="my-3">No existen datos</div>
                  )}
                </div>
                <div class="col-md-12 mb-2">
                  <h6>Historial</h6>
                  <ul>
                    <li>
                      {withdrawal_order ? (
                        format(
                          new Date(withdrawal_order.arrival_date),
                          "dd/M/yyyy H:ii:ss"
                        ).toString() +
                        " - " +
                        withdrawal_order.warehouse.name
                      ) : (
                        <div>No existe Historial disponible</div>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <h4 class="mb-3">Depósito</h4>
                  <hr />
                </div>

                <div className="col-md-12">
                    {entry_order ? (
                      entry_order.entry_details.length > 0 ? (
                        entry_order.entry_details.map((detail) => (
                          <div className="row">
                            <div class="col-md-2 my-2">
                              <h6>Fecha</h6>
                              <p>
                                {format(
                                  new Date(entry_order.created_at),
                                  "dd/M/yyyy"
                                ).toString()}
                              </p>
                            </div>
                            <div class="col-md-3 my-2">
                              <h6>Peso neto transportista</h6>
                              <p>{detail.total_weight} kg</p>
                            </div>
                            <div class="col-md-2 my-2">
                              <h6>Tara</h6>
                              <p>{detail.tare} kg</p>
                            </div>
                            <div class="col-md-2 my-2">
                              <h6>Bruto</h6>
                              <p>{detail.gross_weight} kg</p>
                            </div>
                            <div class="col-md-6 my-2">
                              <h6 className="mb-1">Observaciones</h6>
                              <div className="d-flex">
                                {detail.comments
                                  ? detail.comments
                                  : "No existen observaciones"}
                              </div>
                            </div>
                            <div class="col-md-6 my-2">
                              <h6 className="mb-1">Imagenes adjuntas</h6>
                              <div className="d-flex p-1">
                                { /* detail.images.length > 0 ? (
                                  detail.images.map((img) => (
                                    <img
                                      src={img.url}
                                      style={{ width: "100px" }}
                                      className="img-fluid me-1 border"
                                      alt=""
                                    />
                                  ))
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="70px"
                                    height="70px"
                                    fill="currentColor"
                                    class="bi bi-image-alt border p-1"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M7 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zm4.225 4.053a.5.5 0 0 0-.577.093l-3.71 4.71-2.66-2.772a.5.5 0 0 0-.63.062L.002 13v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4.5l-4.777-3.947z" />
                                  </svg>
                                ) */}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="my-3">Tambor no ingresado</div>
                      )
                    ) : (
                      <div className="my-3">No existen datos</div>
                    )}
                </div>
              </div>

              {
                drum[0].analyzed == 1
                ? 
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <h4 class="mb-3">Laboratorio</h4>
                    <hr />
                  </div>
                  <div class="col-md-3 my-2">
                    <h6>ID Muestra</h6>
                    <p>{drum[0].id}</p>
                  </div>
                  <div class="col-md-2 my-2">
                    <h6>Estado</h6>
                    <p>{drum[0].approved == 1? "Aprobado" : "Rechazado"}</p>
                  </div>
                  <div class="col-md-2 my-2">
                    <h6>Archivo</h6>
                    <p>{drum[0].file}</p>
                  </div>
                  <div class="col-md-3 my-2">
                    <h6>Lote</h6>
                    <p>{drum[0].lot_analysis.lot_analysis_code ? drum[0].lot_analysis.lot_analysis_code : drum[0].lot_analysis_id}</p>
                  </div>
  
                  <div class="col-md-12 mt-2">
                    <p>
                      <strong>Análisis individuales</strong>
                    </p>
                  </div>
                  <div class="col-md-3 my-2">
                    <h6>Color</h6>
                    <p>{drum[0].color} mm</p>
                  </div>
                  <div class="col-md-3 my-2">
                    <h6>Humedad</h6>
                    <p>{drum[0].moisture} %</p>
                  </div>
                  <div class="col-md-3 my-2">
                    <h6>Adulteración</h6>
                    <p>{drum[0].adulteration}</p>
                  </div>
                  <div class="col-md-3 my-2">
                    <h6>Solidificación</h6>
                    <p>{drum[0].solidification}</p>
                  </div>
  
                  <div class="col-md-12 d-none mt-2">
                    <p>
                      <strong>Análisis adicionales</strong>
                    </p>
                  </div>
                  <div class="col-md-3 d-none my-2">
                    <h6>Lorem</h6>
                    <p>32 mm</p>
                  </div>
                  <div class="col-md-3 d-none my-2">
                    <h6>Lorem</h6>
                    <p>%15,5</p>
                  </div>
                  <div class="col-md-3 d-none my-2">
                    <h6>Lorem</h6>
                    <p>Negativo</p>
                  </div>
                  <div class="col-md-3 d-none my-2">
                    <h6>Lorem</h6>
                    <p>Liquida</p>
                  </div>
  
                  <div class="col-md-6 mb-2">
                    <h6 class="mb-2">Observaciones</h6>
                    <p>
                      {drum[0].comments}
                    </p>
                  </div>
                  <div class="col-md-6 mb-2 d-none">
                    <h6 class="mb-2">Imagenes adjuntas</h6>
                    <img src="assets/images/img-adjunta.png" alt="" />
                    <img src="assets/images/img-adjunta.png" alt="" />
                    <img src="assets/images/img-adjunta.png" alt="" />
                  </div>
                </div>
                :  <div className="row">
                  <div class="col-md-12 mt-3">
                    <h4 class="mb-3">Laboratorio</h4>
                    <hr />
                  </div>
                  <div class="col-md-3 my-2">
                    <h6>No existen datos</h6>
                  </div>
                </div>
              }
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center py-5">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalDrumsDetails;
