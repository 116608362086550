import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  //Lista
  orderPurchasePending: null,
  orderPurchaseAnalize: null,
  
  lotPending: null,
  lotAnalyze: null,

  homogenizationPending: null,
  homogenizationAnalize: null,
  homogenizationanalized: null,

  search: null,
}

export const laboratorySlice = createSlice({
  name: "laboratory",
  initialState: initialState,
  reducers: {
    setOrderPurchase_pending: (state, action) => {
      state.orderPurchasePending = action.payload
    },
    setOrderPurchase_analize: (state, action) => {
      state.orderPurchaseAnalize = action.payload
    },

    setLot_pending: (state, action) => {
      state.lotPending = action.payload
    },
    setLot_analize: (state, action) => {
      state.lotAnalyze = action.payload
    },

    setHomogenize_pending: (state, action) => {
      state.homogenizationPending = action.payload
    },
    setHomogenize_analyze: (state, action) => {
      state.homogenizationAnalize = action.payload
    },
    setHomogenize_analyzed:  (state, action) => {
      state.homogenizationanalized = action.payload
    },
  },
});


//Async functions
export const fetchAnalyzeOrderPurchase = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params.analyzed) {
        case 0:{ //All Orders pending
          return dispatch(setOrderPurchase_pending(result.data));
        }
        break;
        case 1:{ //analyze
          return dispatch(setOrderPurchase_analize(result.data));
        }
        break;
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchAnalyzeLot = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params.analyzed_status) {
        case 0:{ //Pending
          return dispatch(setLot_pending(result.data));
        }
        break;
        case 1:{ //Analize
          return dispatch(setLot_analize(result.data));
        }
        break;
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchAnalyzeHomogenize = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params.homogenized) {
        case 0:{
          dispatch(setHomogenize_pending(result.data))
        }break;
        case 1:{
          switch (params.analyzed) {
            case 0:{
            dispatch(setHomogenize_analyze(result.data))
            }
            break;
            case 1:{
              dispatch(setHomogenize_analyzed(result.data))
            } break;
          }
        } break;
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const {setOrderPurchase_analize, setOrderPurchase_pending, setLot_analize, setLot_pending, setHomogenize_pending, setHomogenize_analyzed, setHomogenize_analyze} = laboratorySlice.actions;

export const laboratoryState = (state) => state.laboratory;

export default laboratorySlice.reducer;