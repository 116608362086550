import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { authContext } from "../../../../context/AuthContext";
import { drumState, fetchDrumsFractional, fetchDrumsHomogenize } from "../../../../slices/drums/Index";
import Alerts from "../../../../components/alerts/Alerts";
import { ConcatDrums } from "../../../../utils/ConcatDrums";
import FiltersDrums from "../../../contracts/filters/FiltersDrums";
import EdiTables from "../../../../components/editables/EdiTables";
import Spinner from "../../../../components/loads/Spinner";


const DrumsFractional = ({
  data,
  setActive
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_DRUM } = values;

  const _drums = useSelector(drumState);
  const drums = _drums.drums_fractional;


  const [form, setForm] = useState(null)

  const [params, setParams] = useState({});

  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState([]);

  const [h, setH] = useState([]);

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "seleccionar_todos":{
          let result = h;
          let selection = select;
          let setValue;

          contents.map((content, index) => {
            let element = contents[index];

            if (e.target.checked) {
              result.push(element.drum_id[0]);
              selection.push(element);
            } else {
              result.splice(result.indexOf(element.drum_id[0]), 1);
              selection = selection.filter(
                (item) => item.id[0] != element.drum_id[0]
              );
            }

            setValue = form;
            setValue[index]["homogeneizar"][0] = e.target.checked;
          });

          setForm([...setValue]);
          setSelect([...selection]);
          return setH([...result]);
      } break;
      case "homogeneizar":{
        let selection = select;
        let result = h;
        let element = contents[e.target.id];

        if(e.target.checked){
          result.push(element.drum_id[0]);
          selection.push(element);
        } else {
          result.splice(result.indexOf(element.drum_id[0]), 1);
          selection = selection.filter((item) => item.id[0] != element.drum_id[0]);
        }
        
        let setValue = form;
        setValue[e.target.id][e.target.name][0] = e.target.checked;

        setSelect([...selection]);
        setForm([...setValue]);
        return setH([...result])
      } break;
     
    }
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "page":{
        let params = {
          url: value,
          analyzed: 1,
          homogenize: 1,
          with_contract: 0,
        };
        dispatch(fetchDrumsHomogenize(params));
      }
      break;
      case "crear_lote": {
          let send = {
            drums: select, 
            h: h
          }

          if(h.length == 0){
            return Alerts({
              msg: "Alert",
              title: "Seleccione al menos un tambor",
              code: "401",
              type: "warning",
            });
          }

          
          setSelect(send);
          //dispatch(setSelectDrums(send));
          //setGuard("form_drums", send)

          return setModal(true);
      } break;
    }
  };

  /********************************************************************/

  const campos = [
    {
      name: "TAMBOR",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ESTADO",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "HMF",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "COLOR",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "HUMEDAD",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "KG BRUTO",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "KG TARA",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "KG NETO",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "SOLIDIFICACION",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "AM I",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "AM II",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "GLISOFATO",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "FG",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "PROVEEDOR",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "O/C",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "Nº SENASA",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "OBSERVACIONES",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
  ];
  const order = [
    {
      content_name: "id",
      property: "drum_id",

      name: "id",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "approved",
      property: "approved",

      name: "approved",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "hmf",
      property: "hmf",

      name: "hmf",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "color",
      property: "color",

      name: "color",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "moisture",
      property: "moisture",

      name: "moisture",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "gross_weight",
      property: "gross_weight",

      name: "gross_weight",
      form: "texttarea",
      type: "text",
      disabled: true,
    },
    {
      content_name: "tare",
      property: "tare",

      name: "tare",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "net_weight",
      property: "net_weight",

      name: "net_weight",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "solidification",
      property: "solidification",

      name: "solidification",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "am_i",
      property: "am_i",

      name: "am_i",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "am_ii",
      property: "am_ii",

      name: "am_ii",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "glifosato",
      property: "glifosato",

      name: "glifosato",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "fg",
      property: "fg",

      name: "fg",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "provider_name",
      property: "provider_name",
      
      name: "provider_name",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "purchase_order_id",
      property: "purchase_order_id",

      name: "purchase_order_id",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "senasa",
      property: "senasa",

      name: "senasa",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "comments",
      property: "comments",

      name: "comments",
      form: "input",
      type: "text",
      disabled: true,
    },
  ];
  const contents = form ? form : [];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = drums ? drums : {
    current_page: 0,
    first_page_url: 0,
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  };

  const pagination = {
    type: 1,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  /********************************************************************/

  

  useEffect(() => {
    if (!drums) {
      let params = {
        url: URL_INDEX_DRUM,
        export_lot_id: data.id,
        only_fractioned: 1
      };
      dispatch(fetchDrumsFractional(params));
    }
  },[id]);

  useEffect(() => {
    if(drums){
      setForm(ConcatDrums(drums.data, [], h));
    }
  },[drums]);

  /*
  useEffect(() => {
    clearGuard("form_drums");
  },[])
  */

  //console.log(select);
  //console.log(i);
  console.log(data);
  console.log(drums);
  return (
    <main class="w-100">
      <div class="w-100 position-relative iq-banner">
        <div class="iq-navbar-header" style={{ height: "215px" }}>
          <div class="container-fluid m-0 iq-container p-0">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <div class="box-img">
                      <img
                        src="/assets/images/barrel.svg"
                        alt="icono-contratos-activos"
                      />
                    </div>
                    <div>
                      <h1 class="ms-2 mb-0 mt-2 text-dark">Tambores Fraccionados</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              class="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div class="conatiner-fluid content-inner mt-n5 py-0">
        <div class="row">
          <div class="col-12 mt-5 mb-3 d-flex flex-row justify-content-between">
            <div>
              <label className="btn btn-primary">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.83828 7.64785C3.88472 7.6013 3.93989 7.56436 4.00063 7.53915C4.06137 7.51395 4.12648 7.50098 4.19224 7.50098C4.258 7.50098 4.32311 7.51395 4.38385 7.53915C4.44459 7.56436 4.49976 7.6013 4.5462 7.64785L10.1915 13.2942L15.8369 7.64785C15.8834 7.60137 15.9385 7.5645 15.9993 7.53934C16.06 7.51419 16.1251 7.50124 16.1908 7.50124C16.2566 7.50124 16.3217 7.51419 16.3824 7.53934C16.4431 7.5645 16.4983 7.60137 16.5448 7.64785C16.5913 7.69434 16.6281 7.74952 16.6533 7.81025C16.6785 7.87098 16.6914 7.93608 16.6914 8.00181C16.6914 8.06755 16.6785 8.13264 16.6533 8.19337C16.6281 8.2541 16.5913 8.30929 16.5448 8.35577L10.5455 14.3551C10.4991 14.4016 10.4439 14.4386 10.3831 14.4638C10.3224 14.489 10.2573 14.5019 10.1915 14.5019C10.1258 14.5019 10.0607 14.489 9.99993 14.4638C9.93919 14.4386 9.88402 14.4016 9.83758 14.3551L3.83828 8.35577C3.79173 8.30933 3.75479 8.25416 3.72958 8.19342C3.70438 8.13268 3.69141 8.06757 3.69141 8.00181C3.69141 7.93605 3.70438 7.87094 3.72958 7.8102C3.75479 7.74946 3.79173 7.69429 3.83828 7.64785Z"
                    fill="white"
                  />
                </svg>
                Volver al listado
                <button
                  className="d-none"
                  name=""
                  onClick={(e) => setActive(null)}
                ></button>
              </label>
            </div>
            <div>
            </div>
          </div>
          <div class="col-md-12 bg-grey rounded">
            <div class="row row-cols-1">

              <div className="col-md-12">
                <div class="card">
                  {drums ? (
                    <EdiTables
                      hidden_Filters={true}
                      campos={campos}
                      order={order}
                      contents={contents}
                      pagination={pagination}
                      onClick={onClick}
                      onChange={onChange}
                    />
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </main>
  );
};

export default DrumsFractional;
