export const ConcatContract = (contracts) => {
  let result = [];

  contracts.map((contract) => {
    contract = {
      ...contract,
      destiny: contract.country.name,
      client: contract.customer.business_name,
      conteiner: contract.units - contract.export_lots_count
    }

    result.push(contract)
  })


  return result;
}