import RoadSpinner from "../loads/RoadSpinner";
import Spinner from "../loads/Spinner";

const InputSearchMini = ({
  //Eventos
  onChange,
  //search
  label,
  name,
  type,
  value,
  defaultValue,
  //Autocomplete
  onClick,
  nameAutocomplete,
  valueAutocomplete,
  showIndex,
  sendIndex,
  validate,
  disabled,
}) => {
  return (
    <div className="form-group m-0">
      <label className="form-label">{label}</label>
      <input
        type={type}
        value={value}
        className="form-control"
        placeholder=" "
        name={name}
        onChange={(event) => onChange(event)}
        autoComplete="localidad"
        disabled={disabled}
      />
      {validate.validate ? (
        <div className="text-danger">{validate.msg}</div>
      ) : (
        ""
      )}

      {valueAutocomplete ? (
        valueAutocomplete.length > 0 && value ? (
          <ul
            class="list-group p-0 m-0 scrollbar overflow-scroll"
            style={{ maxHeight: "300px" }}
          >
            {valueAutocomplete.map((value) => (
              <li class="list-group-item p-0 m-0 border-0 bg-white">
                <label
                  className="w-100 btn btn-outline-primary py-1 text-nowrap rounded-0"
                  style={{ fontSize: "12px" }}
                >
                  {value[showIndex]}
                  <button
                    className="d-none"
                    name={nameAutocomplete}
                    onClick={(event) => onClick(event, value)}
                  ></button>
                </label>
              </li>
            ))}
          </ul>
        ) : (
          ""
        )
      ) : (
        <ul
          class="list-group p-0 m-0 scrollbar overflow-scroll"
          style={{ maxHeight: "300px" }}
        >
          <li class="list-group-item p-0 m-0 border-0 bg-white py-3">
            <RoadSpinner />
          </li>
        </ul>
      )}
    </div>
  );
};

export default InputSearchMini;
