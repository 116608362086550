import { useState } from "react";
import Table from "../../../components/tables/Table";
import { ConcatHoneyDrums } from "../../../utils/ConcatHoneyDrums";

const ContentDrums = ({ data }) => {
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(50);
  const [drums, setDrums] = useState(ConcatHoneyDrums(
    data.drums)
    );
  console.log(drums)
 
  /*********************************************** INITIAL STATE ***********************************************************/
  const campos = [
    {
      name: "TAMBORES",
      order: true,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "PESO BRUTO",
      order: true,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "NETO",
      order: true,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "TARA",
      order: true,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "ESTIBA",
      order: true,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "OBSERVACIONES",
      order: true,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
  ];
  const order = [
    "geomiel_code",
    "gross_weight",
    "net_weight",
    "tare",
    "estiba",
    "comments",
  ];
  const contents = ConcatHoneyDrums(drums.map((item)=> {
    if(item.entry_details[0]){
      return {
        ...item, 
        geomiel_code: item.entry_details[0].geomiel_code != 'null' ? item.entry_details[0].geomiel_code : '-'
      }
    }
    if(item.withdrawal_detail){
      return {...item, geomiel_code: item.withdrawal_detail.geomiel_code}
    }else{
      return {...item, geomiel_code: '-'}
    }
  })).slice(page, currentPage);

  const actions = [];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = {
    current_page: 0,
    first_page_url: 0,
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  };
  const pagination = {
    type: null,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };
  console.log(contents, 'campos')
  const onClick = (e, value) => {
    switch (e.target.name) {
      case "detalle_tambor":
        {
          //console.log(value);
          //setSelect(value);
          //setModalDrumDetails(true);
        }
        break;
      case "next":
        {
          if (currentPage < data.drums.length) {
            setPage(page + 5);
            setCurrentPage(currentPage + 5);
          }
        }
        break;
      case "back":
        {
          if (page !== 0) {
            setPage(page - 5);
            setCurrentPage(currentPage - 5);
          }
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":
        {
          if (e.target.value == "") {
            return setDrums(data.drums);
          }

          var result = drums.filter((item) =>
            String(item.id).includes(e.target.value)
          );

          setDrums(result);
        }
        break;
    }
  };

  return (
    <div>
      {drums ? (
        drums.length <= currentPage - page ? (
          ""
        ) : (
          <div className="d-flex justify-content-end mb-3">
            <label className="btn btn-primary me-2">
              Anterior
              <button
                className="d-none"
                name="back"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
            <label className="btn btn-primary">
              Siguiente
              <button
                className="d-none"
                name="next"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </div>
        )
      ) : (
        ""
      )}
      <Table
        print={true}
        campos={campos}
        order={order}
        contents={
          contents
          
        }
        actions={actions}
        pagination={pagination}
        onClick={onClick}
        onChange={onChange}
      />
      {drums ? (
        drums.length <= currentPage - page ? (
          ""
        ) : (
          <div className="d-flex justify-content-end mb-3">
            <label className="btn btn-primary me-2">
              Anterior
              <button
                className="d-none"
                name="back"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
            <label className="btn btn-primary">
              Siguiente
              <button
                className="d-none"
                name="next"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default ContentDrums;
