import { NavLink } from "react-router-dom";

const Li_NavLink = ({ url, text, icon, hidden }) => {
  return (
    <li className="nav-item">
      <NavLink
        to={url}
        className={(navData) =>
          navData.isActive ? "nav-link active" : "nav-link"
        }
      >
        {icon ? (
          <i className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <g>
                <circle cx="12" cy="12" r="8" fill="#EEB899"></circle>
              </g>
            </svg>
          </i>
        ) : (
          ""
        )}
        <span className="item-name">{text}</span>
      </NavLink>
    </li>
  );
};

export default Li_NavLink;
