import { useState } from "react";
import { useContext } from "react";
import Table from "../../../../components/tables/Table";
import { authContext } from "../../../../context/AuthContext";
import {
  fetchAnalyzeHomogenize,
  fetchAnalyzeOrderPurchase,
} from "../../../../slices/laboratory/Index";
import { ClearParams } from "../../../../utils/ClearParams";
import { ConcatAnlizeOC } from "../../../../utils/ConcatAnlizeOC";
import { ConcatOrderPurchase } from "../../../../utils/ConcatOrderPurchase";

const Analize = ({
  data,

  setParams,
  params,
  dispatch,
  setSelect,
  status,
}) => {
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_ANALIZE } = values;

  const [loading, setLoading] = useState(false);
  /*********************************************** INITIAL STATE ***********************************************************/
  const campos = [
    {
      name: "FECHA",
      order: {
        state: true,
        param: "date",
        active: params.sort,
      },
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "O/C",
      order: {
        state: true,
        param: "id",
        active: params.sort,
      },
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "PROVEEDOR",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "ACCIONES",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-center align-items-center",
    },
  ];
  const order = ["date", "oc_name", "provider_name"];
  const contents = data ? ConcatAnlizeOC(data.data) : null;
  const actions = [
    {
      option: "",
      title: "Ver análisis",
      name: "ver_analisis",
      icon: "",
      id: "",
      class:
        "btn btn-secondary border-0 d-flex align-items-center justify-content-center",
      text: "Ver Análisis",
    },
  ];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = data
    ? data
    : {
        current_page: 0,
        first_page_url: 0,
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: "",
        to: 0,
        total: 0,
      };
  const pagination = {
    type: 1,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  /**********************************************************************************************************************/

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "order":
        {
          let setValueParams = params;
          let sort = [];

          setValueParams.sort.map((order) => {
            if (order.order_by == value.param) {
              order = {
                ...order,
                ordering: value.order,
              };
            }

            sort.push(order);
          });

          params = {
            ...params,
            sort: [
              {
                order_by: value.param,
                ordering: value.order,
              },
            ],
          };

          setParams(params);
          let clear_params = ClearParams(params);

          dispatch(fetchAnalyzeOrderPurchase(clear_params));
        }
        break;
      case "page":
        {
          params = {
            ...params,
            url: value,
            analyzed: status == "pending" ? 0 : status == "analize" ? 1 : null,
          };

          dispatch(fetchAnalyzeOrderPurchase(params));
        }
        break;
      case "download-xls":
        {
        }
        break;
      case "download-print":
        {
          return true;
        }
        break;
      case "ver_analisis":
        {
          setSelect(value);
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":
        {
          setParams({
            ...params,
            search: e.target.value,
          });

          if (e.target.value.length > params.search.length) {
            return dispatch(
              fetchAnalyzeHomogenize({
                url: URL_INDEX_ANALIZE,
                search: e.target.value,
                analyzed:
                  status == "pending" ? 0 : status == "analyze" ? 1 : null,
                order_by: "id",
                ordering: "desc",
              })
            );
          }

          if (e.target.value.trim() == "") {
            return dispatch(
              fetchAnalyzeHomogenize({
                url: URL_INDEX_ANALIZE,
                search: e.target.value,
                analyzed:
                  status == "pending" ? 0 : status == "analyze" ? 1 : null,
                order_by: "id",
                ordering: "desc",
              })
            );
          }
        }
        break;
    }
  };

  return (
    <div class="card-body">
      <Table
        print={true}
        campos={campos}
        order={order}
        contents={contents}
        actions={actions}
        pagination={pagination}
        onClick={onClick}
        onChange={onChange}
      />
    </div>
  );
};

export default Analize;
