export const ConcatVehicles = (vehicles) => {
  let result = [];

  vehicles.map((vehicle) => {
    vehicle = {
      ...vehicle,
      name: vehicle.model+" - "+vehicle.patent
    }

    result.push(vehicle)
  })

  return result;
}