import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/loads/Spinner";
import { authContext } from "../../context/AuthContext";
import {
  fetchHomogenized,
  fetchHomogenizedReady,
  homogenizedState,
} from "../../slices/homogenize/Index";
import { meState } from "../../slices/users/Index";
import Consolidated from "../lot/consolidated/Index";
import Filters from "./filters/Filters";
import Fractional from "./fractional/Index";
import Pending from "./pending/Index";
import Ready from "./ready/Index";

const LotHomogenization = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useParams();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_EXPORT_LOT } = values;
  //Initial States
  const _user = useSelector(meState);
  const _homogenization = useSelector(homogenizedState);

  const lot_h_pending = _homogenization.homogenized_pending;
  const lot_h_consolidated = _homogenization.homogenized_consolidated;
  const lot_h_ready = _homogenization.homogeneize_ready;
  const lot_h_fractional = _homogenization.homogeneize_fractioner;

  const me = _user.me;
  const rol_id = me.roles[0].id;

  const [select, setSelect] = useState(null);
  //

  const [params, setParams] = useState({
    url: URL_INDEX_EXPORT_LOT,
    consolidated: status == "pending" ? 0 : status == "consolidated" ? 1 : null,
    search: "",
    start_date: "",
    end_date: "",
    sort: [
      {
        order_by: "id",
        ordering: "desc",
      },
    ],
  });

  //Title
  const h1 =
    status == "pending"
      ? "Lotes Homogeneizados"
      : status == "consolidated"
      ? "Lotes Homogeneizados Consolidados"
      : status == "ready"
      ? "Lotes homogeneizados"
      : status == "fractional"
      ? "Lotes homogeneizados"
      : "";

  useEffect(() => {
    if (!lot_h_pending || !lot_h_consolidated) {
      let params = {
        url: URL_INDEX_EXPORT_LOT,
        consolidated:
          status == "pending" ? 0 : status == "consolidated" ? 1 : 0,
        contain_contract: 
          status == "pending" ? 0 : status == "consolidated" ? 1 : 0,
        //analyzed: 1,
        lot_type: "H",
        sort: [
          {
            order_by: "id",
            ordering: "desc",
          },
        ],
      };
      dispatch(fetchHomogenized(params));
    }
  }, [status]);

  useEffect(() => {
    if (
      !lot_h_ready ||
      !lot_h_fractional 
    ) {
      if(status == "ready" || status == "fractional" ){ 
        let params = {
          url: URL_INDEX_EXPORT_LOT,
          //homogenized: 1,
          fully_fractionated:
            status == "ready" ? 0 : status == "fractional" ? 1 : null,
          lot_type: "H",
          sort: [
            {
              order_by: "id",
              ordering: "desc",
            },
          ],
        };
        dispatch(fetchHomogenizedReady(params)); 
      }
    }
  }, [status]);

  return (
    <main className="main-content">
      <div className="position-relative iq-banner">
        <div className="iq-navbar-header" style={{ height: "215px" }}>
          <div className="container-fluid iq-container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <div className="box-img">
                    <img src="/assets/images/icon-ingreso.svg" width="80%" />
                  </div>
                  <h1 className="ms-2 mb-0">{h1}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              className="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div className="conatiner-fluid content-inner mt-n5 py-0">
        <div className="row">
          <div className="col-md-12 bg-grey rounded">
            <div className="row row-cols-1">
              <div className="col-12 mt-5 mb-3">
                {/* Filters */}
                <Filters
                  label={status}
                  setParams={setParams}
                  params={params}
                  dispatch={dispatch}
                />
              </div>

              <div className="col-md-12">
                <div className="card">
                  {lot_h_pending || lot_h_consolidated ? (
                    status == "pending" && lot_h_pending ? (
                      <Pending
                        data={lot_h_pending}
                        setParams={setParams}
                        params={params}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        label={status}
                      />
                    ) : status == "consolidated" && lot_h_consolidated ? (
                      <Consolidated
                        data={lot_h_consolidated}
                        setParams={setParams}
                        params={params}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={() => {}}
                        label={status}
                      />
                    ) : status == "ready" && lot_h_ready ? (
                      <Ready
                        data={lot_h_ready}
                        setParams={setParams}
                        params={params}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={() => {}}
                        label={status}
                      />
                    ) : status == "fractional" && lot_h_fractional ? (
                      <Fractional
                      data={lot_h_fractional}
                      setParams={setParams}
                      params={params}
                      dispatch={dispatch}
                      setSelect={setSelect}
                      setModal={() => {}}
                      label={status}
                    />
                    ) : (
                      <Spinner />
                    )
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LotHomogenization;
