import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContentSwiper from "../../../components/sliders/Swiper";
import { authContext } from "../../../context/AuthContext";
import {
  fetchOrderEntry,
  orderEntryState,
} from "../../../slices/orderEntry/Index";
import ContentOrderEntry from "../Contents/ContentOrderEntry";
import Notifications from "../notifications/Notifications";

const Storage = ({ orderPurchase }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_ENTRY } = values;

  const _orderEntry = useSelector(orderEntryState);
  const orderEntry_pending = _orderEntry.orderEntry_pending;

  /*********************************************** INITIAL STATE ***********************************************************/
  const [SwiperMediaQuery, setSwiperMediaQuery] = useState(4);

  //Sliders
  const swipper_oc = [
    {
      title: "Ordenes Pendientes",
      link: "/dashboard/orderpurchase/pending",
      lineLink: "Ver Todos",

      total: orderEntry_pending ? orderEntry_pending.total : 0,
      icon: "/assets/images/icon-advertencia.svg",
      name: "redirect",
    },
    {
      title: "Ordenes Entregadas",
      link: "/dashboard/orderpurchase/submitted",
      lineLink: "Ver Todos",

      total: 0,
      icon: "/assets/images/icon-check.svg",
      name: "redirect",
    },
  ];

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "redirect":
        {
          navigate(value);
        }
        break;
      case "modalGenerateOrderWithdrawal":
        {
        }
        break;
    }
  };

  useEffect(() => {
    if (!orderEntry_pending) {
      let params = {
        url: URL_INDEX_ENTRY,
        order_state_id: 1,
      };
      dispatch(fetchOrderEntry(params));
    }
  }, []);
  return (
    <div className="row">
      <div className="col-md-12 col-lg-8">
        <div className="overflow-hidden d-slider1">
          <ContentSwiper
            SwiperMediaQuery={SwiperMediaQuery}
            contents={swipper_oc}
            onClick={onClick}
          />
        </div>

        <div className="row">
          <div className="col-md-12">
            <ContentOrderEntry
              data={orderEntry_pending}
              title={"O/C Pendientes de ingreso"}
            />
          </div>

          <div className="col-md-6 d-none">
            <div className="d-flex mb-3">
              <h2 className="me-5">Lotes pendientes de egreso</h2>
              <a
                href="ordenes-compra-pendiente.php"
                className="d-flex align-items-center btn-link"
              >
                Ver todos
                <svg
                  className="ms-2"
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                    fill="#8A92A6"
                  />
                </svg>
              </a>
            </div>
            <div
              id="search"
              className="card card-body rounded-pill d-flex justify-content-around text-center py-3"
            >
              <div className="input-group search-input">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Buscar órden de compra"
                />
                <svg
                  className="ms-3"
                  width="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11.7669"
                    cy="11.7666"
                    r="8.98856"
                    stroke="#F16A1B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></circle>
                  <path
                    d="M18.0186 18.4851L21.5426 22"
                    stroke="#F16A1B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </div>
            <div
              className="overflow-hidden card"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="p-0 card-body">
                <div className="table-responsive">
                  <table
                    id="table table-responsive"
                    className="table mb-0 table-striped"
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th>O/C</th>
                        <th>O/R</th>
                        <th>Productos</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>OC2356</td>
                        <td>5135</td>
                        <td>Varios</td>
                        <td className="d-flex justify-content-end">
                          <a
                            href="#"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#nueva-ordenModal"
                          >
                            Ingresar
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>OC2356</td>
                        <td>5135</td>
                        <td>Varios</td>
                        <td className="d-flex justify-content-end">
                          <a
                            href="#"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#nueva-ordenModal"
                          >
                            Ingresar
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>OC2356</td>
                        <td>5135</td>
                        <td>Varios</td>
                        <td className="d-flex justify-content-end">
                          <a
                            href="#"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#nueva-ordenModal"
                          >
                            Ingresar
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>OC2356</td>
                        <td>5135</td>
                        <td>Varios</td>
                        <td className="d-flex justify-content-end">
                          <a
                            href="#"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#nueva-ordenModal"
                          >
                            Ingresar
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Notificactions */}

      <Notifications />
    </div>
  );
};

export default Storage;
