import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  notifications: null,
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});


//Async functions
export const fetchNotifications = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      dispatch(setNotifications(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};




export const { setNotifications } = notificationSlice.actions;

export const notificationState = (state) => state.notifications;

export default notificationSlice.reducer;