import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";
import { GlobalConst } from "../../consts/UrlConst";

const { URL_INDEX_EQUIVALENCE } = GlobalConst;

const initialState = {
  supplies: null,
  equivalence: null,
  stocks: null,
  orders: null,
};

export const suppliesSlice = createSlice({
  name: "supplies",
  initialState: initialState,
  reducers: {
    setEquivalenceList: (state, action) => {
      state.equivalence = action.payload;
    },
    setStocksList: (state, action) => {
      state.stocks = action.payload;
    },
    setOrderList: (state, action) => {
      state.orders = action.payload;
    },
  },
});

//Async functions
export const fetchSupplies = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setEquivalenceList(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchStocks = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setStocksList(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchOrders = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setOrderList(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const { setEquivalenceList, setStocksList, setOrderList } =
  suppliesSlice.actions;

export const suppliesState = (state) => state.supplies;

export default suppliesSlice.reducer;
