import { useNavigate, useParams } from "react-router-dom";
import Filters from "./filters/Filters";

import { memo, useContext, useEffect, useState } from "react";
import Table from "../../components/tables/Table";
import { authContext } from "../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderPurchaseList,
  orderPurchaseState,
} from "../../slices/orderPurchase/Index";
import { providersState } from "../../slices/providers/Index";
import Spinner from "../../components/loads/Spinner";
import Submitted from "./submitted/Index";
import Pending from "./pending/Index";
import ModalProviders from "../../components/modals/ModalProviders";
import ModalOrderPurchase from "../../components/modals/ModalOrderPurchase";
import BtnSticky from "../../components/forms/BtnSticky";
import { paymentMethodsState } from "../../slices/paymentMethods/Index";
import { productsState } from "../../slices/products/Index";
import { fetchSupplies, suppliesState } from "../../slices/supplies/Index";
import { geolocationState } from "../../slices/geolocation";
import { useCallback } from "react";
import { add, format } from "date-fns";
import { meState } from "../../slices/users/Index";
import BtnAccept from "../../components/forms/BtnAccept";
import ModalOrderWithdrawal from "../orderWithdrawal/modals/ModalOrderWithdrawal";

const OrderPurchase = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { label } = useParams();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_PURCHASE, URL_INDEX_EQUIVALENCE } = values;
  //Initial States
  const _user = useSelector(meState);
  const _supplies = useSelector(suppliesState);
  const _orderPurchase = useSelector(orderPurchaseState);

  const orderPurchase = _orderPurchase.orderPurchase;
  const orderPurchase_pending = _orderPurchase.orderPurchase_pending;
  const orderPurchase_submitted = _orderPurchase.orderPurchase_submitted;
  const me = _user.me;
  const rol_id = me.roles[0].id;
  const rol = me ? me.roles[0] : null;

  const supplies = _supplies.supplies;
  const [select, setSelect] = useState(null);
  //Modals
  const [modalProviders, setModalProviders] = useState(false);
  const [modalOrderPurchase, setModalOrderPurchase] = useState(false);
  const [modal_orderWithdrawal, setModalOrderWithdrawal] = useState(false)

  const [params, setParams] = useState({
    url: URL_INDEX_PURCHASE,
    order_state_id: label == "pending" ? 1 : label == "submitted" ? 2 : null,
    search: "",
    start_date: "",
    end_date: "",
    sort: [
      /*
      {
        order_by: "date",
        ordering: "desc",
      },
      */
      {
        order_by: "id",
        ordering: "desc",
      },
      /*
      {
        order_by: "providers.business_name",
        ordering: "desc",
      },
      */
    ],
  });

  const [paramsEquivalence, setParamsEquivalence] = useState({
    url: URL_INDEX_EQUIVALENCE,
  });

  //Title
  const h1 =
    label == "pending"
      ? "Órdenes de compra pendientes"
      : label == "submitted"
        ? "Órdenes entregadas"
        : "";

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "nuevo_retiro": {
        setModalOrderWithdrawal(true)
      } break;
    }
  }

  useEffect(() => {
    if (!supplies) {
      dispatch(fetchSupplies(paramsEquivalence));
    }
  }, [paramsEquivalence]);

  useEffect(() => {
    if (!orderPurchase || !orderPurchase_submitted || !orderPurchase_pending) {
      let doParams = {
        url: URL_INDEX_PURCHASE,
        order_state_id:
          label == "pending" ? 1 : label == "submitted" ? 2 : null,
        sort: [
          {
            order_by: "id",
            ordering: "desc",
          },
        ],
      };
      dispatch(fetchOrderPurchaseList(doParams));
    }
  }, [label]);

  return (
    <main className="main-content">
      <div className="position-relative iq-banner">
        <div className="iq-navbar-header" style={{ height: "215px" }}>
          <div className="container-fluid iq-container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <div className="box-img">
                    <img
                      src="/assets/images/icon-ordenes-de-compra.svg"
                      width="80%"
                    />
                  </div>
                  <h1 className="ms-2 mb-0">{h1}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              className="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div className="conatiner-fluid content-inner mt-n5 py-0">
        <div className="row">

          <div className="col-md-12 bg-grey rounded">
            <div className="row row-cols-1">
              <div className="col-md-12">
                <div className="d-flex justify-content-end">
                  {
                    label == "pending" && rol_id == 5
                      ?
                      <BtnAccept
                        onClick={onClick}
                        name={"nuevo_retiro"}
                        value={"Generar Retiro"}
                        loading={false}
                        loadingMsg={""}
                      />
                      : ""
                  }
                </div>
              </div>
              <div className="col-12 mt-5 mb-3">
                {/* Filters */}
                <Filters
                  label={label}
                  setParams={setParams}
                  params={params}
                  dispatch={dispatch}
                />
              </div>

              <div className="col-md-12">
                <div className="card">
                  {orderPurchase_pending || orderPurchase_submitted ? (
                    label == "pending" && orderPurchase_pending ? (
                      <Pending
                        data={orderPurchase_pending}
                        setParams={setParams}
                        params={params}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={setModalOrderPurchase}
                        label={label}
                        rol={rol_id}
                      />
                    ) : label == "submitted" && orderPurchase_submitted ? (
                      <Submitted
                        data={orderPurchase_submitted}
                        setParams={setParams}
                        params={params}
                        navigate={navigate}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={setModalOrderPurchase}
                        label={label}
                        rol={rol_id}
                      />
                    ) : (
                      <Spinner />
                    )
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(modalProviders && rol_id == 5) || (modalProviders && rol_id == 1) ? (
        <ModalProviders
          /**PRIMARY***/
          setSelect={setSelect}
          select={select}
          setModalProviders={setModalProviders}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}

      {(modalOrderPurchase && rol_id == 5) ||
        (modalOrderPurchase && rol_id == 1) ? (
        <ModalOrderPurchase
          setSelect={setSelect}
          select={select}
          setModalOrderPurchase={setModalOrderPurchase}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}
      {modal_orderWithdrawal ? (
        <ModalOrderWithdrawal
          setModal={setModalOrderWithdrawal}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}

      {rol_id == 5 || rol_id == 1 ? (
        <BtnSticky
          setModalProviders={setModalProviders}
          setModalOrderPurchase={setModalOrderPurchase}
        />
      ) : (
        ""
      )}
    </main>
  );
};

export default OrderPurchase;
