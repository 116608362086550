import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/loads/Spinner";
import { authContext } from "../../../context/AuthContext";
import {
  fetchAnalyzeOrderPurchase,
  laboratoryState,
} from "../../../slices/laboratory/Index";
import { meState } from "../../../slices/users/Index";
import Analize from "./analyzed";
import SeeSamples from "./analyzed/samples/Samples";
import Filters from "./filters/Filters";
import ModalOrderPurchaseDetails from "./modals/ModalOrderPurchaseDetails";
import Pending from "./pending";
import Samples from "./pending/samples/Samples";

const AnalyzeOrderPurchase = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useParams();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_ANALIZE } = values;
  //Initial States
  const _user = useSelector(meState);
  const _lab = useSelector(laboratoryState);

  const orderPurchasePending = _lab.orderPurchasePending;
  const orderPurchaseAnalize = _lab.orderPurchaseAnalize;
  const me = _user.me;
  const rol_id = me.roles[0].id;

  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState(null);
  //

  const [params, setParams] = useState({
    url: URL_INDEX_ANALIZE,
    analyzed: status == "pending" ? 0 : status == "analyze" ? 1 : null,
    search: "",
    start_date: "",
    end_date: "",
    sort: [
      {
        order_by: "id",
        ordering: "desc",
      },
    ],
  });

  //Title
  const h1 =
    status == "pending"
      ? "O/C Pendientes"
      : status == "analyze"
      ? "O/C Analizadas"
      : "";

  useEffect(() => {
    if (!orderPurchasePending || !orderPurchaseAnalize) {
      let doParams = {
        url: URL_INDEX_ANALIZE,
        analyzed: status == "pending" ? 0 : status == "analyze" ? 1 : null,
        sort: [
          {
            order_by: "id",
            ordering: "desc",
          },
        ],
      };
      dispatch(fetchAnalyzeOrderPurchase(doParams));
    }
  }, [status]);

  useEffect(() => {
    setSelect(null);
  },[status])
  return (
    <main className="main-content">
      <div className="position-relative iq-banner">
        <div className="iq-navbar-header" style={{ height: "215px" }}>
          <div className="container-fluid iq-container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <div className="box-img">
                    <img src="/assets/images/icon-ingreso.svg" width="80%" />
                  </div>
                  <h1 className="ms-2 mb-0">{select ? "O/C Muestras" : h1}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              className="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div className="conatiner-fluid content-inner mt-n5 py-0">
        <div className="row">
          <div className="col-md-12 bg-grey rounded">
            <div className="row row-cols-1">
              <div className="col-12 mt-5 mb-3">
                {/* Filters */}
                <Filters
                  label={status}
                  setParams={setParams}
                  params={params}
                  dispatch={dispatch}
                />
              </div>

              {select ? (
                <div class="col-12 mt-5 mb-3 d-flex flex-row justify-content-between">
                  <div>
                    <label className="btn btn-secondary">
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.83828 7.64785C3.88472 7.6013 3.93989 7.56436 4.00063 7.53915C4.06137 7.51395 4.12648 7.50098 4.19224 7.50098C4.258 7.50098 4.32311 7.51395 4.38385 7.53915C4.44459 7.56436 4.49976 7.6013 4.5462 7.64785L10.1915 13.2942L15.8369 7.64785C15.8834 7.60137 15.9385 7.5645 15.9993 7.53934C16.06 7.51419 16.1251 7.50124 16.1908 7.50124C16.2566 7.50124 16.3217 7.51419 16.3824 7.53934C16.4431 7.5645 16.4983 7.60137 16.5448 7.64785C16.5913 7.69434 16.6281 7.74952 16.6533 7.81025C16.6785 7.87098 16.6914 7.93608 16.6914 8.00181C16.6914 8.06755 16.6785 8.13264 16.6533 8.19337C16.6281 8.2541 16.5913 8.30929 16.5448 8.35577L10.5455 14.3551C10.4991 14.4016 10.4439 14.4386 10.3831 14.4638C10.3224 14.489 10.2573 14.5019 10.1915 14.5019C10.1258 14.5019 10.0607 14.489 9.99993 14.4638C9.93919 14.4386 9.88402 14.4016 9.83758 14.3551L3.83828 8.35577C3.79173 8.30933 3.75479 8.25416 3.72958 8.19342C3.70438 8.13268 3.69141 8.06757 3.69141 8.00181C3.69141 7.93605 3.70438 7.87094 3.72958 7.8102C3.75479 7.74946 3.79173 7.69429 3.83828 7.64785Z"
                          fill="white"
                        />
                      </svg>
                      Volver al listado
                      <button className="d-none" name="" onClick={(e) => setSelect(null)}></button>
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="col-md-12">
                <div className="card">
                  {orderPurchasePending || orderPurchaseAnalize ? (
                    status == "pending" && orderPurchasePending ? (
                      select && !modal ? (
                        <Samples select={select} setSelect={setSelect} />
                      ) : (
                        <Pending
                          data={orderPurchasePending}
                          setParams={setParams}
                          params={params}
                          dispatch={dispatch}
                          setSelect={setSelect}
                          setModal={setModal}
                          status={status}
                        />
                      )
                    ) : status == "analyze" && orderPurchaseAnalize ? 
                      select && !modal ? (
                        <SeeSamples select={select} setSelect={setSelect} />
                      ) : (
                      <Analize
                        data={orderPurchaseAnalize}
                        setParams={setParams}
                        params={params}
                        navigate={navigate}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={() => {}}
                        status={status}
                      />
                    ) : (
                      <Spinner />
                    )
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        modal
        ? <ModalOrderPurchaseDetails
          setModal={setModal}
          setSelect={setSelect}
          select={select}
        />
        : ""
      }
    </main>
  );
};

export default AnalyzeOrderPurchase;
