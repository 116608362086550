import DrumsAnalyzed from "../pages/contracts/drums/DrumsAnalyzed";

export const ConcatDrums = (drums, select, h) => {
  let result = [];


  drums.map((drum) => {
    let seleccionar = false;
    let homogeneizar = false;

    if (select.indexOf(drum.id) >= 0) {
      seleccionar = true;
    }

    if (h.indexOf(drum.id) >= 0) {
      homogeneizar = true;
    }

    /*
    Entry details
  */
    let senasa = "";
    let geocode = "";

    drum.entry_details.map((details) => {
      senasa = details.senasa_code;
      geocode = details.geomiel_code;
    });

    let form = {
      adulteration: [drum.adulteration ? drum.adulteration : "", ""], //contract_number
      analyzed: [drum.analyzed, ""],
      approved: [
        drum.approved !== null
          ? drum.approved == 1
            ? "Aprobado"
            : drum.approved == 0
            ? "Rechazado"
            : ""
          : "Sin analizar",
        "",
      ],
      senasa: [senasa, ""],
      geocode: [geocode, ""],
      color: [drum.color ? drum.color : "", ""],
      comments: [drum.comments ? drum.comments : "", ""],
      export_lot_id: [drum.export_lot_id ? drum.export_lot_id : "", ""],
      file: [drum.file ? drum.file : "", ""],
      gross_weight: [drum.gross_weight ? drum.gross_weight : "", ""],
      id: [drum.id ? drum.id : "", ""],
      drum_id: [drum.id ? drum.id : "", ""],
      lot_analysis_id: [drum.lot_analysis_id ? drum.lot_analysis_id : "", ""],
      moisture: [drum.moisture ? drum.moisture : "", ""],
      net_weight: [drum.net_weight ? drum.net_weight : "", ""],
      purchase_order_id: [
        drum.purchase_order_id ? drum.purchase_order_id : "",
        "",
      ],
      sector_id: [drum.sector_id ? drum.sector_id : "", ""],
      solidification: [drum.solidification ? drum.solidification : "", ""],
      state_id: [drum.state_id ? drum.state_id : "", ""],
      tare: [drum.tare ? drum.tare : "", ""],
      updated_at: [drum.updated_at ? drum.updated_at : "", ""],

      am_i: ["", ""],
      am_ii: ["", ""],
      glifosato: ["", ""],
      fg: ["", ""],

      homogeneizar: [homogeneizar, ""],
      seleccionar: [seleccionar, ""],
      provider_name: [drum.purchase_order ? drum.purchase_order.provider.business_name : "", ""],
      hmf: ["", ""],
    };

    result.push(form);
  });

  return result;
};
