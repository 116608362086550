import { format } from "date-fns";
import { Fill } from "./Fill";

export const ConcatAnalizeLot = (orders) => {
  let result = [];

  orders.map((order) => {
    /* Result */
    order = {
      ...order,

      date: format(new Date(order.created_at), "dd/MM/yyyy").toString(),

      hmf_value: order.hmf_value,
      sacarosa: order.sacarosa,
      adulterated_honey: order.adulterated_honey,
      antibiotic: "",

      oc_name: order.purchase_order.purchase_order_code 
      ? order.purchase_order.purchase_order_code 
      : String(order.purchase_order_id).length >= 4
          ? "OC " + order.purchase_order_id
          : "OC " + Fill(order.purchase_order_id, 4),

      provider_name: String(order.purchase_order.provider.id).length >= 4
        ? order.purchase_order.provider.id+" - "+order.purchase_order.provider.business_name
        : Fill(order.purchase_order.provider.id, 4)+" - "+order.purchase_order.provider.business_name,
    };

    result.push(order);
  });

  return result;
}