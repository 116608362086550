export const ConcatSupplies = (supplies) => {
  let result = [];

  supplies.map((supply) => {
    supply = {
      ...supply,
      condition: supply.greater_than_50ml == 1? "Mayor a 50ml" : "Menor a 50ml",
      supply: supply.supply,
      equivalence: supply.supply_quantity+" "+supply.supply+" - "+supply.product_quantity+" "+supply.product
    }

    result.push(supply)
  })

  return result
}