//Components
import { useContext, useEffect, useState } from "react";
import ModalProviders from "../../components/modals/ModalProviders";
import BtnSticky from "../../components/forms/BtnSticky";
import ModalOrderPurchase from "../../components/modals/ModalOrderPurchase";
import { authContext } from "../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderPurchaseList,
  orderPurchaseState,
} from "../../slices/orderPurchase/Index";
import { fetchProviders, providersState } from "../../slices/providers/Index";
import { fetchSupplies, suppliesState } from "../../slices/supplies/Index";

import Administrator from "./Label/Administrator";
import Buyer from "./Label/Buyer";
import { meState } from "../../slices/users/Index";
import Logistic from "./Label/Logistic";
import {
  fetchOrderWithdrawal,
  orderState,
} from "../../slices/orderwithdrawal/Index";
import Storage from "./Label/Storage";
import {
  fetchOrderEntry,
  orderEntryState,
} from "../../slices/orderEntry/Index";
import Laboratory from "./Label/Laboratory";
import Exports from "./Label/Exports";

const Home = () => {
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const {
    URL_INDEX_PURCHASE,
    URL_INDEX_EQUIVALENCE,
    URL_INDEX_WITHDRAWAL,
    URL_INDEX_PROVIDERS,
    URL_INDEX_ENTRY,
  } = values;
  //Initial States
  const _user = useSelector(meState);
  const _supplies = useSelector(suppliesState);
  const _providers = useSelector(providersState);
  const _orderPurchase = useSelector(orderPurchaseState);
  const _withdrawal_order = useSelector(orderState);
  const _orderEntry = useSelector(orderEntryState);

  const orderEntry = _orderEntry.orderEntry;

  const withdrawal_order_submitted =
    _withdrawal_order.withdrawal_order_submitted;
  const withdrawal_order_actives = _withdrawal_order.withdrawal_order_actives;
  const withdrawal_order_pending = _withdrawal_order.withdrawal_order_pending;
  const withdrawal_order = _withdrawal_order.withdrawal_order;

  const providers = _providers.providers;
  const orderPurchase = _orderPurchase.orderPurchase;
  const supplies = _supplies.supplies;
  const me = _user.me;
  const rol = me.roles[0].name;
  const rol_id = me.roles[0].id;

  const [label, setLabel] = useState(rol);
  const [select, setSelect] = useState(null);
  //Modals
  const [modalProviders, setModalProviders] = useState(false);
  const [modalOrderPurchase, setModalOrderPurchase] = useState(false);
  //params
  const [paramsEntry, setParamsEntry] = useState({
    url: URL_INDEX_ENTRY,
    order_state_id: null,
  });

  const [params, setParams] = useState({
    url: URL_INDEX_PURCHASE,
    order_state_id: null,
    sort: [
      {
        order_by: "id",
        ordering: "desc",
      },
    ],
  });

  const [paramsEquivalence, setParamsEquivalence] = useState({
    url: URL_INDEX_EQUIVALENCE,
  });

  const [paramsProviders, setParamsProviders] = useState({
    url: URL_INDEX_PROVIDERS,
  });

  //Label Rol
  const LabelRol = () => {
    switch (label) {
      case "administracion":
        {
          return (
            <Administrator
              orderPurchase={orderPurchase}
              orderEntry={orderEntry}
              providers={providers}
            />
          );
        }
        break;
      case "compras":
        {
          return (
            <Buyer
              orderPurchase={orderPurchase}
              providers={providers}
              setParams={setParams}
              params={params}
              setSelect={setSelect}
              setModal={setModalOrderPurchase}
            />
          );
        }
        break;
      case "deposito":
        {
          return <Storage orderPurchase={orderPurchase} />;
        }
        break;
      case "logistica":
        {
          return <Logistic orderWithdrawal={withdrawal_order_pending} />;
        }
        break;
      case "exportacion":
        {
          return <Exports />;
        }
        break;
      case "laboratorio":
        {
          return <Laboratory />;
        }
        break;
    }
  };

  useEffect(() => {
    if (!supplies) {
      dispatch(fetchSupplies(paramsEquivalence));
    }
  }, [paramsEquivalence]);

  useEffect(() => {
    if (!orderEntry) {
      let params = {
        url: URL_INDEX_ENTRY,
        order_state_id: null,
      };
      dispatch(fetchOrderEntry(params));
    }
  }, [label]);

  useEffect(() => {
    if (!orderPurchase) dispatch(fetchOrderPurchaseList(params));
  }, [label]);

  useEffect(() => {
    if (
      !withdrawal_order ||
      !withdrawal_order_actives ||
      !withdrawal_order_pending ||
      !withdrawal_order_submitted
    ) {
      let params = {
        url: URL_INDEX_WITHDRAWAL,
        ["state_id[0]"]: 1,
        order_by: "id",
        ordering: "desc",
      };
      dispatch(fetchOrderWithdrawal(params));
    }
  }, [label]);

  useEffect(() => {
    if (!providers) {
      dispatch(fetchProviders(paramsProviders));
    }
  }, [paramsProviders]);
  return (
    <main className="main-content">
      <div className="position-relative iq-banner">
        <div className="iq-navbar-header" style={{ height: "215px" }}>
          <div className="container-fluid iq-container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <div className="box-img">
                    <img
                      src="/assets/images/icon-home-01.svg"
                      alt=""
                      width="50%"
                    />
                  </div>
                  <h1 className="ms-2 mb-0">Bienvenido/a Nuevamente</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              className="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div className="conatiner-fluid content-inner mt-n5 py-0">
        {/* Label rol */}

        {LabelRol()}
      </div>
      {/* Modals */}

      {(modalProviders && rol_id == 5) || (modalProviders && rol_id == 1) ? (
        <ModalProviders
          /**PRIMARY***/
          setSelect={setSelect}
          select={select}
          setModalProviders={setModalProviders}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}

      {(modalOrderPurchase && rol_id == 5) ||
      (modalOrderPurchase && rol_id == 1) ? (
        <ModalOrderPurchase
          setSelect={setSelect}
          select={select}
          setModalOrderPurchase={setModalOrderPurchase}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}

      {rol_id == 5 || rol_id == 1 ? (
        <BtnSticky
          setModalProviders={setModalProviders}
          setModalOrderPurchase={setModalOrderPurchase}
        />
      ) : (
        ""
      )}
    </main>
  );
};

export default Home;
