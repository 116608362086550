import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BtnSticky from "../../components/forms/BtnSticky";
import Spinner from "../../components/loads/Spinner";
import ModalOrderPurchase from "../../components/modals/ModalOrderPurchase";
import ModalProviders from "../../components/modals/ModalProviders";
import { authContext } from "../../context/AuthContext";
import { fetchProviders, providersState } from "../../slices/providers/Index";
import { fetchSupplies, suppliesState } from "../../slices/supplies/Index";
import { meState } from "../../slices/users/Index";
import { fetchWareHous, wareHouseState } from "../../slices/wareHouses/Index";
import ContentTable from "./content/ContentTables";
import Filters from "./filters/Filters";
import ModalWareHouses from "./modals/ModalWareHouses";

const WareHouses = () => {
  //Global Const
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_WAREHOUSES, URL_INDEX_PROVIDERS, URL_INDEX_EQUIVALENCE } =
    values;
  //Dispatch reducer
  const dispatch = useDispatch();

  //Initial States
  const user = useSelector(meState);
  const _wareHouses = useSelector(wareHouseState);
  const _supplies = useSelector(suppliesState);
  const _providers = useSelector(providersState);

  const wareHouses = _wareHouses.wareHouses;
  const providers = _providers.providers;
  const supplies = _supplies.supplies;
  const me = user.me;
  const rol = me ? me.roles[0] : null;
  const rol_id = me.roles[0].id;

  //Select Item
  const [select, setSelect] = useState(null);
  //Modals
  const [modalProviders, setModalProviders] = useState(false);
  const [modalOrderPurchase, setModalOrderPurchase] = useState(false);
  const [modalWareHouses, setModalWareHouses] = useState(false);

  //Params
  const [paramsProviders, setParamsProviders] = useState({
    url: URL_INDEX_PROVIDERS,
  });

  const [paramsEquivalence, setParamsEquivalence] = useState({
    url: URL_INDEX_EQUIVALENCE,
  });

  const [paramsWareHouses, setParamsWareHouses] = useState({
    url: URL_INDEX_WAREHOUSES,
  });

  //
  useEffect(() => {
    if (!supplies) {
      dispatch(fetchSupplies(paramsEquivalence));
    }
  }, [paramsEquivalence]);

  useEffect(() => {
    if (!providers) {
      dispatch(fetchProviders(paramsProviders));
    }
  }, [paramsProviders]);

  useEffect(() => {
    if (!wareHouses) {
      dispatch(fetchWareHous(paramsWareHouses));
    }
  }, [paramsWareHouses]);
  return (
    <main class="main-content">
      <div class="position-relative iq-banner">
        <div class="iq-navbar-header" style={{ height: "215px" }}>
          <div class="container-fluid iq-container">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center">
                  <div class="box-img">
                    <img src="/assets/images/icon-equivalencias.svg" />
                  </div>
                  <h1 class="ms-2 mb-0">Destinos</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              class="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div class="conatiner-fluid content-inner mt-n5 py-0">
        <div class="row">
          <div class="col-md-12 bg-grey rounded">
            <div class="row row-cols-1">
              <div class="col-12 mt-5 mb-3">
                {/* FILTERS */}
                <Filters rol={rol} setModal={setModalWareHouses} />
              </div>
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    {wareHouses ? (
                      <ContentTable
                        data={wareHouses}
                        setModal={setModalWareHouses}
                        params={paramsWareHouses}
                        dispatch={dispatch}
                      /> 
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}

      {modalWareHouses && rol_id == 5 ? (
        <ModalWareHouses setSelect={setSelect} setModal={setModalWareHouses} />
      ) : (
        ""
      )}

      {(modalProviders && rol_id == 5) || (modalProviders && rol_id == 1) ? (
        <ModalProviders
          /**PRIMARY***/
          setSelect={setSelect}
          select={select}
          setModalProviders={setModalProviders}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}

      {(modalOrderPurchase && rol_id == 5) ||
      (modalOrderPurchase && rol_id == 1) ? (
        <ModalOrderPurchase
          setSelect={setSelect}
          select={select}
          setModalOrderPurchase={setModalOrderPurchase}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}

      {rol_id == 5 || rol_id == 1 ? (
        <BtnSticky
          setModalProviders={setModalProviders}
          setModalOrderPurchase={setModalOrderPurchase}
        />
      ) : (
        ""
      )}
    </main>
  );
};

export default WareHouses;
