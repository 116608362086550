import { useState } from "react";
import Spinner from "../../../components/loads/Spinner";
import Table from "../../../components/tables/Table";
import { ConcatHoneyDrums } from "../../../utils/ConcatHoneyDrums";
import ModalDrumsDetails from "../../drums/modals/ModalDrumsDetails";

const ProductsDetails = ({
  data,
  honey_drums,
  select,
  setSelect,
  modalDrumDetails,
  setModalDrumDetails,
}) => {
  const {
    id,
    created_at,
    exchange_details,
    price_per_kilo,
    product_id,
    purchase_order_id,
    total_kilos,
    total_unit_weight,
    unit_gross_weight,
    unit_tara,
    units,
    updated_at,
  } = data;
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(30);
  const [drums, setDrums] = useState(honey_drums.map((item)=> {return {...item, geomiel_code: item.entry_details[0].geomiel_code}}));

  switch (parseInt(product_id)) {
    case 1:
      {
        /********************************************************************* */
        const campos = [
          {
            name: "TAMBORES",
            order: false,
            class: "border-0 d-flex justify-content-between align-items-center",
          },
          {
            name: "PESO",
            order: false,
            class: "border-0 d-flex justify-content-between align-items-center",
          },
          {
            name: "ESTADO",
            order: false,
            class: "border-0 d-flex justify-content-between align-items-center",
          },
          {
            name: "ACCIONES",
            order: false,
            class: "border-0 d-flex justify-content-center align-items-center",
          },
        ];
        const special_status = {
          position: 2,
          status: ["Transporte", "Depósito", "Laboratorio", "Venta"],

          position_name: [
            {
              name: "logística",
              position: 1,
            },
            {
              name: "depósito",
              position: 2,
            },
            {
              name: "laboratorio",
              position: 3,
            },
            {
              name: "exportación",
              position: 4,
            },
          ],
        };
        const order = ["geomiel_code", "net_weight", "sector_name"];
        const contents = ConcatHoneyDrums(drums).slice(page, currentPage);
        const actions = [
          {
            title: "Detalle",
            name: "detalle_tambor",
            icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 13 11" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:evenodd;fill:rgb(94.509804%,41.568627%,10.588235%);fill-opacity:1;" d="M 13 5.75 C 13 5.75 10.65625 1.5 6.75 1.5 C 2.84375 1.5 0.5 5.75 0.5 5.75 C 0.5 5.75 2.84375 10 6.75 10 C 10.65625 10 13 5.75 13 5.75 Z M 1.417969 5.75 C 1.792969 6.316406 2.230469 6.847656 2.714844 7.328125 C 3.71875 8.324219 5.09375 9.226562 6.75 9.226562 C 8.40625 9.226562 9.78125 8.324219 10.789062 7.328125 C 11.273438 6.847656 11.707031 6.316406 12.085938 5.75 C 11.707031 5.183594 11.273438 4.652344 10.789062 4.171875 C 9.78125 3.175781 8.40625 2.273438 6.75 2.273438 C 5.09375 2.273438 3.71875 3.175781 2.710938 4.171875 C 2.226562 4.652344 1.792969 5.183594 1.414062 5.75 Z M 1.417969 5.75 "/><path style=" stroke:none;fill-rule:evenodd;fill:rgb(94.509804%,41.568627%,10.588235%);fill-opacity:1;" d="M 6.75 3.785156 C 6.230469 3.785156 5.730469 3.992188 5.359375 4.359375 C 4.992188 4.730469 4.785156 5.230469 4.785156 5.75 C 4.785156 6.269531 4.992188 6.769531 5.359375 7.140625 C 5.730469 7.507812 6.230469 7.714844 6.75 7.714844 C 7.269531 7.714844 7.769531 7.507812 8.140625 7.140625 C 8.507812 6.769531 8.714844 6.269531 8.714844 5.75 C 8.714844 5.230469 8.507812 4.730469 8.140625 4.359375 C 7.769531 3.992188 7.269531 3.785156 6.75 3.785156 Z M 4 5.75 C 4 5.019531 4.289062 4.320312 4.804688 3.804688 C 5.320312 3.289062 6.019531 3 6.75 3 C 7.480469 3 8.179688 3.289062 8.695312 3.804688 C 9.210938 4.320312 9.5 5.019531 9.5 5.75 C 9.5 6.480469 9.210938 7.179688 8.695312 7.695312 C 8.179688 8.210938 7.480469 8.5 6.75 8.5 C 6.019531 8.5 5.320312 8.210938 4.804688 7.695312 C 4.289062 7.179688 4 6.480469 4 5.75 Z M 4 5.75 "/></g></svg>',
            id: "editar",

            class:
              "btn border-0 d-flex align-items-center justify-content-center",
            text: "",
          },
        ];

        const pagination = {
          type: null,
          current_page: 0,
          first_page_url: 0,
          from: 0,
          last_page: 0,
          last_page_url: "",
          links: [],
          next_page_url: "",
          path: "",
          per_page: 0,
          prev_page_url: "",
          to: 0,
          total: 0,
        };

        const onClick = (e, value) => {
          switch (e.target.name) {
            case "detalle_tambor":
              {
                //console.log(value);
                setSelect(value);
                setModalDrumDetails(true);
              }
              break;
            case "next":
              {
                if (currentPage < honey_drums.length) {
                  setPage(page + 30);
                  setCurrentPage(currentPage + 30);
                }
              }
              break;
            case "back":
              {
                if (page !== 0) {
                  setPage(page - 30);
                  setCurrentPage(currentPage - 30);
                }
              }
              break;
          }
        };

        const onChange = (e, value) => {
          switch (e.target.name) {
            case "search":
              {
                if (e.target.value == "") {
                  return setDrums(honey_drums);
                }

                var result = drums.filter((item) =>
                  String(item.id).includes(e.target.value)
                );

                setDrums(result);
              }
              break;
          }
        };
        return (
          <div className="row m-0 p-0">
            <div className="col-md-12 mt-3">
              <hr />
              <h4 class="mb-3">Tambores</h4>
            </div>

            <div class="col-md-3 my-2">
              <h5 class="mb-3">Precio</h5>
              <p>${price_per_kilo}/kg</p>
            </div>
            <div class="col-md-3 my-2">
              <h5 class="mb-3">Tambores</h5>
              <p>{units} unidades</p>
            </div>
            <div class="col-md-3 my-2">
              <h5 class="mb-3">Peso total de miel</h5>
              <p>{total_kilos} kg</p>
            </div>
            <div class="col-md-3 my-2">
              <h5 class="mb-3">Precio peso total</h5>
              <p>${parseFloat(total_kilos) * parseFloat(price_per_kilo)}</p>
            </div>

            {exchange_details.map((exchange) => (
              <div className="col-md-12">
                <div className="row">
                  <div class="col-md-12 mt-5">
                    <h2>Transacciones realizadas</h2>
                    <hr />
                    <h4 class="mb-3">Canjes</h4>
                  </div>
                  <div class="col-md-3 my-2">
                    <h5 class="mb-3">Producto</h5>
                    <p>{exchange.exchange_type}</p>
                  </div>
                  <div class="col-md-3 my-2">
                    <h5 class="mb-3">Equivalencia</h5>
                    <p>{exchange.exchange_type}</p>
                  </div>
                  <div class="col-md-3 my-2">
                    <h5 class="mb-3">Unidades Canjeadas</h5>
                    <p>{exchange.units_to_exchange}</p>
                  </div>
                  <div class="col-md-3 my-2">
                    <h5 class="mb-3">Precio total a pagar</h5>
                    <p>
                      <strong>$12500</strong>
                    </p>
                  </div>
                </div>
              </div>
            ))}

            {honey_drums ? (
              honey_drums.length <= (currentPage - page) ? (
                ""
              ) : (
                <div className="d-flex justify-content-end mb-3">
                  <label className="btn btn-primary me-2">
                    Anterior
                    <button
                      className="d-none"
                      name="back"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                  <label className="btn btn-primary">
                    Siguiente
                    <button
                      className="d-none"
                      name="next"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                </div>
              )
            ) : (
              ""
            )}

            <div class="col-12 my-5">
              <Table
                campos={campos}
                order={order}
                contents={contents}
                special_contents={special_status}
                actions={actions}
                pagination={pagination}
                onClick={onClick}
                onChange={onChange}
              />
            </div>

            {honey_drums ? (
              honey_drums.length <= (currentPage - page)  ? (
                ""
              ) : (
                <div className="d-flex justify-content-end mb-3">
                  <label className="btn btn-primary me-2">
                    Anterior
                    <button
                      className="d-none"
                      name="back"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                  <label className="btn btn-primary">
                    Siguiente
                    <button
                      className="d-none"
                      name="next"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                </div>
              )
            ) : (
              ""
            )}
          </div>
        );
      }
      break;
    case 4:
    case 3:
    case 2:
      {
        return (
          <div className="row m-0 p-0">
            <div class="col-md-12 mt-3">
              <h4 class="mb-3">Cera</h4>
              <hr />
            </div>
            <div class="col-md-3 my-2">
              <h5 class="mb-3">Tipo</h5>
              <p>Opérculo</p>
            </div>
            <div class="col-md-3 my-2">
              <h5 class="mb-3">Precio</h5>
              <p>${price_per_kilo}/kg</p>
            </div>
            <div class="col-md-3 my-2">
              <h5 class="mb-3">Peso total</h5>
              <p>{total_kilos} kg</p>
            </div>
            <div class="col-md-3 my-2">
              <h5 class="mb-3">PRECIO TOTAL A PAGAR</h5>
              <p>
                <strong>${price_per_kilo * total_kilos}</strong>
              </p>
            </div>
          </div>
        );
      }
      break;
  }

  return <Spinner />;
};

export default ProductsDetails;
