import { useDispatch, useSelector } from "react-redux";
import { fetchNotifications, notificationState } from "../../../slices/notifications/Index";
import { useEffect } from "react";

const Notifications = () => {
  const dispatch = useDispatch();
  const _notifications = useSelector(notificationState);
  const notifications = _notifications.notifications;
  
  useEffect(() => {
    if (!notifications) {
      let params = {
        url: "notification/all"
      };
      dispatch(fetchNotifications(params));
    }
  }, []);

  console.log(notifications);
  return (
<div className="col-md-12 col-lg-4">
            <div className="row">
              <div className="col-md-12 col-lg-12">
              <div
                  className="p-4 rounded border-0 card-notificaciones"
                  data-aos="fade-up"
                  data-aos-delay="900"
                >
                <h5 className="mb-4">Notificaciones</h5>
                {
                  /*
                   
                  <div className="p-0">
                    <a
                      href="#"
                      className="btn-link d-flex align-items-center mb-3"
                    >
                      <img src="/assets/images/shapes/alert.svg" />
                      <p className="ms-3 mb-0">
                        El tambor 2 de la OC 2315 fue observado.
                      </p>
                    </a>
                    <a
                      href="#"
                      className="btn-link d-flex align-items-center mb-3"
                    >
                      <img src="/assets/images/shapes/money.svg" />
                      <p className="ms-3 mb-0 ">La OC 2253 fue pagada.</p>
                    </a>
                    <a
                      href="#"
                      className="btn-link d-flex align-items-center mb-3"
                    >
                      <img src="/assets/images/shapes/money.svg" />
                      <p className="ms-3 mb-0 ">La OC 2365 fue pagada.</p>
                    </a>
                    <a
                      href="#"
                      className="btn-link d-flex align-items-center mb-3"
                    >
                      <img src="/assets/images/shapes/alert.svg" />
                      <p className="ms-3 mb-0 ">
                        El tambor 52 de la OC 362 fue observado.
                      </p>
                    </a>
                    <a
                      href="#"
                      className="btn-link d-flex align-items-center mb-3"
                    >
                      <img src="/assets/images/shapes/shop.svg" />
                      <p className="ms-3 mb-0 ">
                        Se ha modificado la equivalencia en Tambor nuevo.
                      </p>
                    </a>
                  </div>
                  */
                }
                </div>
              </div>
            </div>
          </div>
  )
}

export default Notifications