import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/tables/Table";
import { authContext } from "../../../context/AuthContext";
import { fetchOrderPurchaseList } from "../../../slices/orderPurchase/Index";
import { ConcatAnalizeLot } from "../../../utils/ConcatAnalizeLot";
import { ConcatAnlizeOC } from "../../../utils/ConcatAnlizeOC";
import LoteDetails from "../../analize/lot/details/LoteDetails";
import Filters from "../filters/Filters";

const ContentAnalized = ({ lot, orderPurchase }) => {
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const {
    URL_INDEX_ANALIZE,
  } = values;

  //Tabla
  const campos = [
    {
      name: "O/C",
      order: false,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "PRODUCTOR",
      order: false,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "",
      order: false,
      class: "border-0 d-flex justify-content-center align-items-center",
    },
  ];

  const order = ["oc_name", "provider_name"];
  const contents = orderPurchase ? ConcatAnlizeOC(orderPurchase.data) : [];
  const actions = [
    {
      title: "Ver detalle",
      name: "ver_detalle_oc",
      icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 5L15.5 12L8.5 19" stroke="#F16A1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      id: "llegada",

      class: "",
      text: "",
    },
  ];

  const pagination = {
    type: null,
    current_page: "",
    first_page_url: "",
    from: "",
    last_page: "",
    last_page_url: "",
    links: "",
    next_page_url: "",
    path: "",
    per_page: "",
    prev_page_url: "",
    to: "",
    total: "",
  };

  /*********************************************************/
  const campos_lot = [
    {
      name: "O/C",
      order: false,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "PRODUCTOR",
      order: false,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "",
      order: false,
      class: "border-0 d-flex justify-content-center align-items-center",
    },
  ];

  const order_lot = ["oc_name", "provider_name"];
  const contents_lot = lot ? ConcatAnalizeLot(lot.data) : [];
  const actions_lot = [
    {
      title: "Ver detalle",
      name: "ver_detalle_lot",
      icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 5L15.5 12L8.5 19" stroke="#F16A1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      id: "llegada",

      class: "",
      text: "",
    },
  ];

  const pagination_lot = {
    type: null,
    current_page: "",
    first_page_url: "",
    from: "",
    last_page: "",
    last_page_url: "",
    links: "",
    next_page_url: "",
    path: "",
    per_page: "",
    prev_page_url: "",
    to: "",
    total: "",
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "ver_detalle_lot":
        {
        }
        break;
        case "ver_detalle_oc":{
          
        } break;
    }
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <Filters 
        h1={"O/C Pendientes de análisis"}
        URL={URL_INDEX_ANALIZE}
        fetch={fetchOrderPurchaseList}
        initialParams={{
          url: URL_INDEX_ANALIZE,
          analyzed: 0,
          order_state_id: null,
          order_by: "id",
          ordering: "desc",
        }}
        btn={{redirect: "/dashboard/orderpurchase/analyze/pending"}}
        />
        <Table
          hidden_Filters={true}
          print={false}
          campos={campos}
          order={order}
          contents={contents}
          actions={actions}
          pagination={pagination}
          onClick={onClick}
        />
      </div>
      <div className="col-md-6">
        <Filters 
        h1={"Lotes Pendientes de análisis"}
        URL={""}
        fetch={""}
        initialParams={""}
        btn={{redirect: "/dashboard/lot/analyze/pending"}}
        />
        <Table
          hidden_Filters={true}
          print={false}
          campos={campos_lot}
          order={order_lot}
          contents={contents_lot}
          actions={actions_lot}
          pagination={pagination_lot}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default ContentAnalized;
