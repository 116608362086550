import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContentSwiper from "../../../components/sliders/Swiper";
import ModalOrderWithdrawal from "../../orderWithdrawal/modals/ModalOrderWithdrawal";
import Notifications from "../notifications/Notifications";

const Administrator = ({ orderPurchase, orderEntry, providers }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /*********************************************** INITIAL STATE ***********************************************************/
  
  const [modal_orderWithdrawal, setModalOrderWithdrawal] = useState(false);

  /* Slider */
  const swipper_oc = [
    {
      title: "O/C Pendientes",
      link: "/dashboard/orderpurchase/pending",
      lineLink: "Ver Todos",

      total: orderPurchase ? orderPurchase.pending_orders : 0,
      icon: "/assets/images/icon-advertencia.svg",
      name: "redirect",
    },
    {
      title: "O/C Entregadas",
      link: "/dashboard/orderpurchase/submitted",
      lineLink: "Ver Todos",

      total: orderPurchase ? orderPurchase.delivered_orders : 0,
      icon: "/assets/images/icon-check.svg",
      name: "redirect",
    },
    {
      title: "Proveedores",
      link: "/dashboard/providers",
      lineLink: "Ver Todos",

      total:  providers? providers.total : 0,
      icon: "/assets/images/icon-check.svg",
      name: "redirect",
    },
  ];

  const swipper_or = [
    {
      title: "Orden de retiro",
      link: "/dashboard/orderwithdrawal/generate",
      lineLink: "Generar nueva orden",
      total: "",
      icon: "",
      name: "modalGenerateOrderWithdrawal",
    },
  ];

  const swipper_deposit = [
    {
      title: "Ingresos Pendientes",
      link: "/dashboard/orderentry/pending",
      lineLink: "Ver todos",

      total: orderEntry ? orderEntry.total : 0,
      icon: "/assets/images/icon-advertencia.svg",
      name: "redirect"
    },
    {
      title: "Egresos Pendientes",
      link: "#",
      lineLink: "Ver todos",

      total: 0,
      icon: "/assets/images/icon-advertencia.svg",
    },
  ];

  /**********************************************************************************************************************/

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "redirect":
        {
          navigate(value);
        }
        break;
      case "modalGenerateOrderWithdrawal":
        {
          setModalOrderWithdrawal(true);
        }
        break;
    }
  };

  const SwiperMediaQuery = 4;
  useEffect(() => {
    //Media Query Swipper
  }, []);
  return (
    <div className="row">
      <div className="col-md-12 col-lg-8">
        <div className="overflow-hidden d-slider1">
          <ContentSwiper
            SwiperMediaQuery={SwiperMediaQuery}
            contents={swipper_oc}
            onClick={onClick}
          />
        </div>
        <div className="row border-0">
          <div className="col-md-4">
            <span className="fw-bold fs-3 text-dark">Sector Logística</span>
            <ContentSwiper
              SwiperMediaQuery={1}
              contents={swipper_or}
              onClick={onClick}
            />
          </div>
          <div className="col-md-8">
            <span className="fw-bold fs-3 text-dark">Sector depósito</span>
            <ContentSwiper
              SwiperMediaQuery={2}
              contents={swipper_deposit}
              onClick={onClick}
            />
          </div>
        </div>
      </div>

      {/* Modals */}

      {modal_orderWithdrawal ? (
        <ModalOrderWithdrawal
          setModal={setModalOrderWithdrawal}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}

      {/* Notificactions */}

      <Notifications />
    </div>
  );
};

export default Administrator;
