import { format } from "date-fns";
import { id } from "date-fns/locale";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/loads/Spinner";
import Table from "../../../components/tables/Table";
import clientAxios from "../../../config/ClientAxios";
import { authContext } from "../../../context/AuthContext";
import { Fill } from "../../../utils/Fill";
import ContentDrums from "../contents/ContentDrums";
import ContentWax from "../contents/ContentWax";
import ModalOrderEntryDetails from "../modals/ModalOrderEntryDetails";
import ModalOrderEntryTableDetails from "../modals/ModalOrderEntryTableDetails";

const OrderEntryPending = () => {
  /*********************************************** INITIAL STATE ***********************************************************/
  const context = useContext(authContext);
  const { values } = context;
  const { URL_CREATE_ENTRY, URL_ENTRY, URL_SHOW_PURCHASE } = values;

  const dispatch = useDispatch();
  const { oi_id } = useParams();

  const [modalOrderEntryDetails, setModalOrderEntryDetails] = useState(false);
  const [orderEntry, setOrderEntry] = useState(null);
  const [orderPurchase, setOrderPurchase] = useState(null);
  const [totals, setTotals] = useState({
    delivered: 0,
    entry: 0,
    spare: 0,
  });

  const { id, date, sector, purchase_details, provider } = orderPurchase
    ? orderPurchase[0]
    : {
        id: 0,
        date: "2001/01/01",
        sector: { name: "" },
        purchase_details: [],
        provider: { business_name: "" },
      };

  const { user } = orderEntry
    ? orderEntry.withdrawal_order_id
      ? orderEntry.withdrawal_order[0]
      : { user: { name: "" } }
    : { user: { name: "" } };

  const total = () => {
    let delivered = 0;
    let entry = 0;
    let spare = 0;

    orderEntry.details.map((d) => {
      d.drums.map((drum) => {
        delivered = parseFloat(drum.net_weight) + delivered;
      });
    });

    return {
      delivered: delivered,
      entry: entry,
      spare: spare,
    };
  };

  useEffect(() => {
    const fetch = async () => {
      let params = {
        url: URL_ENTRY,
        purchase_order_id: oi_id,
      };
      const response = await clientAxios.get(URL_ENTRY, { params });
      setOrderEntry(response.data);
    };

    fetch();
  }, [oi_id]);

  useEffect(() => {
    const fetch = async () => {
      let params = {
        url: URL_SHOW_PURCHASE,
        id: oi_id,
      };
      const response = await clientAxios.get(URL_SHOW_PURCHASE, { params });
      setOrderPurchase(response.data);
    };

    fetch();
  }, [oi_id]);

  useEffect(() => {
    if (orderEntry && orderPurchase) {
      setTotals(total());
    }
  }, [orderEntry, orderPurchase]);

  console.log(orderEntry);
  //console.log(orderPurchase);
  //console.log(totals);
  return (
    <div class="card my-5 p-5">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between align-items-center pb-4">
          <div className="d-flex">
            <h1>Detalle</h1>
            <button
              className="border-0 bg-transparent text-primary ms-3 d-flex align-items-center justify-content-center"
              title="imprimir"
              onClick={() => {
                window.print();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-printer"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
              </svg>
            </button>
          </div>
          <label className="btn btn-secondary d-flex justify-content-center px-3">
            <svg
              class="me-2"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2530_34177)">
                <rect
                  width="20"
                  height="20"
                  transform="translate(0 0.5)"
                  fill="white"
                  fill-opacity="0.01"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.002 3.00195C10.1677 3.00195 10.3267 3.0678 10.4439 3.18501C10.5611 3.30222 10.627 3.46119 10.627 3.62695V9.87695H16.877C17.0427 9.87695 17.2017 9.9428 17.3189 10.06C17.4361 10.1772 17.502 10.3362 17.502 10.502C17.502 10.6677 17.4361 10.8267 17.3189 10.9439C17.2017 11.0611 17.0427 11.127 16.877 11.127H10.627V17.377C10.627 17.5427 10.5611 17.7017 10.4439 17.8189C10.3267 17.9361 10.1677 18.002 10.002 18.002C9.83619 18.002 9.67722 17.9361 9.56001 17.8189C9.4428 17.7017 9.37695 17.5427 9.37695 17.377V11.127H3.12695C2.96119 11.127 2.80222 11.0611 2.68501 10.9439C2.5678 10.8267 2.50195 10.6677 2.50195 10.502C2.50195 10.3362 2.5678 10.1772 2.68501 10.06C2.80222 9.9428 2.96119 9.87695 3.12695 9.87695H9.37695V3.62695C9.37695 3.46119 9.4428 3.30222 9.56001 3.18501C9.67722 3.0678 9.83619 3.00195 10.002 3.00195Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2530_34177">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            Ingresar Muestras
            <button
              class="d-none"
              onClick={(event) => setModalOrderEntryDetails(true)}
            ></button>
          </label>
        </div>
        <hr />
        <div class="col-md-3 my-2">
          <h5 class="mb-3">Fecha</h5>
          <p>{format(new Date(date), "dd/M/yyyy").toString()}</p>
        </div>
        <div class="col-md-3 my-2">
          <h5 class="mb-3">N° de O/C</h5>
          <p>{String(oi_id).length > 4 ? oi_id : Fill(oi_id, 4)}</p>
        </div>
        <div class="col-md-3 my-2">
          <h5 class="mb-3">Proveedor</h5>
          <p>{provider.business_name}</p>
        </div>
        <div class="col-md-3 my-2">
          <h5 class="mb-3">Transporte</h5>
          <p>{user.name}</p>
        </div>
        <div class="col-md-3 my-2">
          <h5 class="mb-3">KG Entregados</h5>
          <p>{totals.delivered} kg</p>
        </div>
        <div class="col-md-3 my-2">
          <h5 class="mb-3">KG Ingresados</h5>
          <p class="mb-0">-</p>
        </div>
        <div class="col-md-3 my-2">
          <h5 class="mb-3">KG Sobrantes</h5>
          <p class="mb-0">-</p>
        </div>

        <div class="col-12 my-5">
          {orderEntry ? (
            orderEntry.details.map((detail) => <ContentDrums data={detail} />)
          ) : (
            <Spinner />
          )}
        </div>

        <div class="col-12 my-5">
          {orderEntry ? (
            orderEntry.details.map((detail) => <ContentWax data={detail} />)
          ) : (
            <Spinner />
          )}
        </div>
      </div>

      {/* Modal */}

      {/*modalOrderEntryDetails && orderEntry && orderPurchase ? (
        <ModalOrderEntryDetails
          dispatch={dispatch}
          setModal={setModalOrderEntryDetails}
          entryOrder={orderEntry}
          purchaseOrder={orderPurchase[0]}
        />
      ) : (
        ""
      )*/}

      {modalOrderEntryDetails && orderEntry && orderPurchase ? (
        <ModalOrderEntryTableDetails
          dispatch={dispatch}
          setModal={setModalOrderEntryDetails}
          entryOrder={orderEntry}
          purchaseOrder={orderPurchase[0]}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default OrderEntryPending;
