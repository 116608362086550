import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import Alerts from "../../../components/alerts/Alerts";
import BtnAccept from "../../../components/forms/BtnAccept";
import Input from "../../../components/forms/Input";
import InputSearchMini from "../../../components/forms/InputSearchMini";
import Password from "../../../components/forms/password";
import Select from "../../../components/forms/Select";
import TextTarea from "../../../components/forms/TextTarea";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { fetchCityList, geolocationState } from "../../../slices/geolocation";
import { fetchRoles, fetchUsers, meState } from "../../../slices/users/Index";
import { Validate } from "../../../utils/ValidateForms";

const ModalUser = ({
  setModal,
  setSelect,
  select,
  rol,

  dispatch,
}) => {
  /*********************************************** INITIAL STATE ***********************************************************/
  const context = useContext(authContext);
  const { values, forms, validations } = context;
  const { URL_CREATE_USER, URL_UPDATE_PROVIDER, URL_INDEX_USERS, URL_INDEX_ROLES } = values;
  const { FORM_USERS } = forms;
  const { VALIDATION_USERS } = validations;

  const _user = useSelector(meState);
  const _geolocation = useSelector(geolocationState);

  const province = _geolocation.province;
  const city = _geolocation.city;
  const roles = _user.roles;

  const [searchCity, setSearchCity] = useState([]);
  const [loading, setLoading] = useState(false);
  //Forms
  const [form, setForm] = useState(JSON.parse(JSON.stringify(FORM_USERS)));
  //validate
  const [validation, setValidation] = useState({ ...VALIDATION_USERS });
  /*************************************************************************************************************************/

  const inititalState = {
    activity_start_date: "",
    address: "",
    business_name: "",
    city_id: "",
    comments: "",
    created_at: "",
    cuit: "",
    email: "",
    hives: "",
    id: "",
    telephone: "",
    updated_at: "",
  };

  const {
    address,
    name,
    city_id,
    comments,
    created_at,
    cuit,
    email,
    hives,
    id,
    telephone,
    updated_at,
  } = select ? select : inititalState;

  const onClick = async (e, value) => {
    e.preventDefault();

    switch (e.target.name) {
      case "close":
        {
          setSelect(null);
          setModal(false);
        }
        break;
      case "aceptar":
        {
          const validation = Validate(form);
          setValidation(validation);
          //Validaciones
          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "201",
              type: "error",
            });
          }

          //create form
          let formData = new FormData();
          formData.append("name", form.nombre[0]);
          //formData.append("cuit", form.cuit[0]);
          //formData.append("address", form.direccion[0]);
          //formData.append("telephone", form.telefono[0]);
          formData.append("email", form.email[0]);
          formData.append("password", form.password[0]);
          formData.append("role", form.rol[0]);
          //if (form.observaciones[0] !== "") formData.append("comments", form.observaciones[0]);
          //formData.append("city_name", form.localidad[0]);
          //formData.append("province_id", form.provincia[0]);
          //formData.append("city_id", );

          //
          const response = await ApiRest({
            url: URL_CREATE_USER,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Creando nuevo usuario.",
              code: "201",
              type: "success",
            });

            //Actualizar datos providers
            let params;
            dispatch(
              fetchUsers((params = { url: URL_INDEX_USERS, role: rol }))
            );

            //cerrar modal
            return setModal(false);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "guardar":
        {
          /*
          //create form
          let formData = new FormData();

          formData.append("id", id);
          formData.append("_method", "PUT");

          if(form.razon_social[0]) formData.append("business_name", form.razon_social[0]);
          if(form.cuit[0]) formData.append("cuit", form.cuit[0]);
          if(form.direccion[0]) formData.append("address", form.direccion[0]);
          if(form.telefono[0]) formData.append("telephone", form.telefono[0]);
          if(form.email[0]) formData.append("email", form.email[0]);
          if(form.inicio_actividad[0]) formData.append("activity_start_date", form.inicio_actividad[0]);
          if(form.colmenas[0]) formData.append("hives", form.colmenas[0]);
          if(form.observaciones[0]) formData.append("comments", form.observaciones[0]);
          //formData.append("city_id", form.localidad[0]); Espera de cambio
          if(form.localidad[0]) formData.append("city_id", form.localidad[0]);
          if(form.provincia[0]) formData.append("province_id", form.provincia[0]); else formData.append("province_id", select.province_id);


         //
         const response = await ApiRest({
          url: URL_UPDATE_PROVIDER,
          formData: formData,
          setLoading: setLoading,
          method: "post"
        });

        //Error
        if (response.status == 201 || response.status == 200) {
          Alerts({
            msg: "Alert",
            title: "Actualizando proveedor.",
            code: "201",
            type: "success",
          });

          //Actualizar datos providers
          dispatch(fetchProviders(params))

          //cerrar modal
          return setModal(false);
        }

        Object.keys(response.data).map((key) => {
          return Alerts({
            msg: "Alert",
            title: response.data[key][0],
            code: "401",
            type: "error",
          });
        });
        */
        }
        break;
      case "localidad":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = value.name;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });

          return setSearchCity([]);
        }
        break;
      default:
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "cuit":
        {
          let setCampo = form;
          let cuit = e.target.value;
          let oldValue = setCampo[e.target.name][0];

          //Validate format CUIT
          if (cuit.length == 2 && oldValue <= cuit) {
            cuit = cuit + "-";
          }
          if (cuit.length == 11 && oldValue <= cuit) {
            cuit = cuit + "-";
          }

          setCampo[e.target.name][0] = cuit;

          if (cuit.length <= 13) {
            setForm({
              ...form,
              [e.target.name]: setCampo[e.target.name],
            });
          }
        }
        break;
      case "observaciones":
      case "email":
      case "rol":
      case "password":
      case "telefono":
      case "direccion":
      case "nombre":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
      case "provincia":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });

          dispatch(fetchCityList(e.target.value));
        }
        break;
      case "searchCity":
        {
          let setCampo = form;
          setCampo["localidad"][0] = e.target.value;

          setForm({
            ...form,
            localidad: setCampo["localidad"],
          });

          if (e.target.value.length > 3) {
            const result = city.filter(
              (item) =>
                item.name.toLowerCase().search(e.target.value.toLowerCase()) !=
                -1
            );
            return setSearchCity(result);
          }

          return setSearchCity([]);
        }
        break;
      default:
        break;
    }
  };

  
  useEffect(() => {
    if (!roles) {
      let params = {
        url: URL_INDEX_ROLES,
      };
      dispatch(fetchRoles(params));
    }
  }, []);
  return (
    <div
      class="modal d-block bg-dark bg-opacity-25  modal-transition bg-modal-blur "
      tabindex="-1"
      aria-labelledby="nuevo-proveedorModalLabel"
      aria-hidden="true"
      id="modal-provider"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content modal-naranja">
          <label className="btn btn-close">
            <button
              type="button"
              class="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div class="d-flex align-items-center pt-5 px-5">
            <img
              src="/assets/images/icon-provedor.svg"
              style={{ width: "40px" }}
              alt=""
            />
            <h1 class="text-white">Usuario - {select ? id : "0000"} </h1>
          </div>
          <div class="modal-body p-5">
            <form autoComplete="off">
              <div class="row">
                <div class="col-md-6 mb-3 text-white">
                  <Input
                    onChange={onChange}
                    label={"Nombre"}
                    name={"nombre"}
                    type={"text"}
                    value={
                      form.nombre[0] !== null
                        ? form.nombre[0]
                        : select
                        ? name
                        : form.nombre[0]
                    }
                    validate={validation.nombre}
                  />
                </div>
                <div class="col-md-6 mb-3 text-white d-none">
                  <Input
                    onChange={onChange}
                    label={"CUIT"}
                    name={"cuit"}
                    type={"text"}
                    value={
                      form.cuit[0] !== null
                        ? form.cuit[0]
                        : select
                        ? cuit
                        : form.cuit[0]
                    }
                    validate={validation.cuit}
                  />
                </div>
                <div class="col-md-3 mb-3 text-white d-none">
                  <Input
                    onChange={onChange}
                    label={"Dirección"}
                    name={"direccion"}
                    type={"text"}
                    value={
                      form.direccion[0] !== null
                        ? form.direccion[0]
                        : select
                        ? address
                        : form.direccion[0]
                    }
                    validate={validation.direccion}
                  />
                </div>
                <div className="col-md-3 mb-3 text-white d-none">
                  <Select
                    onChange={onChange}
                    label="Provincial"
                    name="provincia"
                    type=""
                    defaultValue={
                      form.provincia[0] !== null
                        ? form.provincia[0]
                        : select
                        ? select.city.province_id
                        : form.provincia[0]
                    }
                    value={province}
                    showIndex="name"
                    sendIndex="id"
                    validate={validation.provincia}
                  />
                </div>
                <div class="col-md-3 mb-3 text-white d-none">
                  <InputSearchMini
                    onChange={onChange}
                    label={"Localidad"}
                    name={"searchCity"}
                    type={"text"}
                    //defaultValue={select? select.city.name : ""}
                    value={
                      form.localidad[0] !== null
                        ? form.localidad[0]
                        : select
                        ? select.city.name
                        : form.localidad[0]
                    }
                    //Autocomplete
                    onClick={onClick}
                    nameAutocomplete="localidad"
                    valueAutocomplete={searchCity ? searchCity : []}
                    showIndex="name"
                    sendIndex="name"
                    validate={validation.localidad}
                  />
                </div>
                <div class="col-md-3 mb-3 text-white d-none">
                  <Input
                    onChange={onChange}
                    label={"Telefono"}
                    name={"telefono"}
                    type={"number"}
                    value={
                      form.telefono[0] !== null
                        ? form.telefono[0]
                        : select
                        ? telephone
                        : form.telefono[0]
                    }
                    validate={validation.telefono}
                  />
                </div>

                <div class="col-md-6 mb-3 text-white">
                  <Input
                    onChange={onChange}
                    label={"Email"}
                    name={"email"}
                    type={"email"}
                    value={
                      form.email[0] !== null
                        ? form.email[0]
                        : select
                        ? email
                        : form.email[0]
                    }
                    validate={validation.email}
                  />
                </div>

                <div className="col-md-6 mb-3 text-white">
                  <Select
                    onChange={onChange}
                    label="Roles"
                    name="rol"
                    type=""
                    defaultValue={form.rol[0] !== null
                      ? form.rol[0]
                      : ""
                    }
                    value={roles? roles : []}
                    showIndex="name"
                    sendIndex="name"
                    validate={validation.rol}
                  />
                </div>

                <div class="col-md-6 mb-3 text-white">
                  <Password
                    onChange={onChange}
                    label={"Contraseña"}
                    name={"password"}
                    value={
                      form.password[0]
                    }
                    validate={validation.password}
                  />
                </div>

                <div class="col-12 mb-3 text-white d-none">
                  <TextTarea
                    onChange={onChange}
                    label={"Observaciones"}
                    name={"observaciones"}
                    type={"text"}
                    value={
                      form.observaciones[0] !== null
                        ? form.observaciones[0]
                        : select
                        ? comments
                        : form.observaciones[0]
                    }
                    validate={validation.observaciones}
                  />
                </div>

                <div class="col-md-12 d-flex justify-content-end align-items-end">
                  <div class="btn-group">
                    <BtnAccept
                      onClick={onClick}
                      name={select ? "guardar" : "aceptar"}
                      value={select ? "Guardar" : "Aceptar"}
                      loading={loading}
                      loadingMsg={
                        select ? "Guardando datos..." : "Subiendo datos..."
                      }
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUser;
