

export const ConcatHoneyDrums = (honey_drums) => {
  let result = [];
  

  honey_drums.map((honeyDrum) => {
    let estiba = "";
    let observacion = ""

    if(honeyDrum.entry_details.length > 0){
        estiba = honeyDrum.entry_details[0].stowage
        observacion = honeyDrum.entry_details[0].comments
    }

    honeyDrum = {
      ...honeyDrum,
      sector_name: honeyDrum.sector.name,
      sector_id: honeyDrum.sector_id,
      weight: "0 kg",
      id_name:  "ID "+honeyDrum.id,
      estiba: estiba,
      comments: honeyDrum.comments +" "+ observacion
    }

    result.push(honeyDrum);
  })

  return result;
}