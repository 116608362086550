import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  //Lista
  withdrawal_order: null,
  withdrawal_order_pending: null,
  withdrawal_order_submitted: null,
  withdrawal_order_actives: null,

  withdrawal_details: null,
}

export const orderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    setWithdrawalOrder: (state, action) => {
      state.withdrawal_order = action.payload
    },
    setWithdrawalOrder_pending: (state, action) => {
      state.withdrawal_order_pending = action.payload
    },
    setWithdrawalOrder_submitted: (state, action) => {
      state.withdrawal_order_submitted = action.payload
    },
    setWithdrawalOrder_actives: (state, action) => {
      state.withdrawal_order_actives = action.payload
    },
    setWithdrawalDetails: (state, action) => {
      state.withdrawal_details = action.payload
    },
    setResetWithdrawalDetails: (state, action) => {
      state.withdrawal_details = null
    },
  },
});


//Async functions
export const fetchOrderWithdrawal = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params["state_id[0]"]) {
        case null:{
          dispatch(setWithdrawalOrder(result.data))
        } break;
        case 1:{ //Pending
          return dispatch(setWithdrawalOrder_pending(result.data));
        }break;
        case 2:{ //Submitted
          return dispatch(setWithdrawalOrder_submitted(result.data));
        }break; 
        case 3:{ //Actives
          return dispatch(setWithdrawalOrder_actives(result.data));
        }break;
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchOrderWithdrawalDetails = (params) => (dispatch) => {
  clientAxios
    .get(params.url, {params})
    .then((result) => {
      dispatch(setWithdrawalDetails(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};



export const { setWithdrawalOrder, setWithdrawalOrder_pending, setWithdrawalOrder_actives, setWithdrawalOrder_submitted, setWithdrawalDetails, setResetWithdrawalDetails } = orderSlice.actions;

export const orderState = (state) => state.order;

export default orderSlice.reducer;