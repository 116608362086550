export const ClearParams = (params) => {
  let result =  {};

  Object.keys(params).map((p) => {
    if(
      params[p] !== "" 
    ){
      result = {
        ...result,
        [p]: params[p]
      }
    }
  })

  return result;
}