export const Validate = (form) => {
  let resValidate = { error: { validate: false, msg: "" } };

  //Validacion de campos
  Object.keys(form).map((campo) => {
    //
    let validate = false;
    let msg = "";

    //Require validate
    if (!form[campo][0] && form[campo].indexOf("required") > -1 || form[campo][0] == "" && form[campo].indexOf("required") > -1) {
      validate = true;
      msg = "El campo " + campo + " es requerido.";
      resValidate = {
        ...resValidate,
        [campo]: {
          validate: validate,
          msg: msg,
        },
        error: {
          validate: validate,
          msg: msg,
        },
      };
    }

    let min = Array.isArray(form[campo]) 
    ? ""
    : form[campo].filter(
      (item) =>
        item.search("min") !=
        -1
    );
    min = min.length > 0 ? min[0].split(":")[1] : 0

    let max = Array.isArray(form[campo])
    ? ""
    : form[campo].filter(
      (item) =>
        item.search("max") !=
        -1
    );
    max = max.length > 0 ? max[0].split(":")[1] : 0

    if (min !== 0 && form[campo][0].length < min ) {
      validate = true;
      msg = "El campo " + campo + " debe tener mas de "+min+" caracteres.";
      resValidate = {
        ...resValidate,
        [campo]: {
          validate: validate,
          msg: msg,
        },
        error: {
          validate: validate,
          msg: msg,
        },
      };
    }

    if (max !== 0 && form[campo][0].length > max ) {
      validate = true;
      msg = "El campo " + campo + " supera los "+max+" caracteres.";
      resValidate = {
        ...resValidate,
        [campo]: {
          validate: validate,
          msg: msg,
        },
        error: {
          validate: validate,
          msg: msg,
        },
      };
    }

    //check validation
    if (!validate) {
      resValidate = {
        ...resValidate,
        [campo]: {
          validate: validate,
          msg: msg,
        },
      };
    }
  });

  return resValidate;
};
