const ContentWax = ({ data }) => {
  return (
    <table id="example" class="table table-striped" data-toggle="data-table">
      <thead>
        <tr>
          <th>Cera</th>
          <th>Opérculo</th>
          <th>Recupero</th>
          <th>Borra</th>
          <th>Observaciones</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{data.wax_amount} kg</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ContentWax;
