const GoogleMaps = ({ direction, province, city, setModal }) => {
  const onClick = (e, value) => {
    switch (e.target.name) {
      case "close":
        {
          setModal(false);
        }
        break;
    }
  };
  return (
    <div
      className="modal fade show bg-dark bg-opacity-25  modal-transition bg-modal-blur"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco">
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div className="modal-header border-0">
            <h1 className="modal-title bold" id="nueva-ordenModalLabel">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                fill="currentColor"
                class="bi bi-geo-alt d-none"
                viewBox="0 0 16 16"
              >
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
            </h1>
          </div>
          <div className="modal-body">
            <iframe
              src={
                "https://maps.google.com/?q=" +
                encodeURI(direction + " " + province + " " + city) +
                "&z=14&t=m&output=embed"
              }
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              height="400px"
              className="my-3 w-100"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleMaps;
