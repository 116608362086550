export const setGuard = async (name, data) => {
  const result = await localStorage.setItem(name, data);
  return ""
}

export const getGuard = (name) => {
  let data = localStorage.getItem(name);
  return data
}

export const clearGuard = (name) => {
  localStorage.removeItem(name);
  return ""
} 