export const INITIAL = {
  DESTINO: [
    {
      id: 1,
      name: "Zona Franca"
    },
    {
      id: 2,
      name: "Depósito"
    }
  ],
  TRANSPORTISTA: [
    {
      id: 1,
      name: "Juan Herrera"
    },
    {
      id: 2,
      name: "Pablo Gómez"
    }
  ],
  VEHICULO: [
    {
      id: 1,
      type: "VEHICULO",
      patent: "PATENTE - MODELO",
      model: "MODELO"
    }
  ]
}