import { format } from "date-fns";
import { Fill } from "./Fill";

export const ConcatOrderPurchase = (orderPurchase) => {
  let result = [];

  orderPurchase.map((order) => {
    let movement_op = 0;

    /* Wax Operculo */
    let kg_op_oc = 0;
    let price_per_units = 0;

    let kg_op_or = 0;

    /* 
      Drums Details 
    */
    let kg_miel_oc = 0;
    let price_per_kilo_oc = 0;

    let kg_miel_or = 0;

    /* Order Purchase */

    order.purchase_details.map((detail) => {
      /* Wax Operculo */
      if (detail.product_id >= 2) {
        movement_op = movement_op + 0;
        kg_op_oc = parseFloat(detail.total_kilos) + kg_op_oc;
        price_per_units = parseFloat(detail.price_per_kilo);
      }

      /* Drums */
      if (detail.product_id == 1) {
        price_per_kilo_oc = detail.price_per_kilo;
        kg_miel_oc = parseFloat(detail.total_unit_weight) * parseInt(detail.units);
      }
    });

    /* Order Withdrawal */

    if (order.withdrawal_order) {
      order.withdrawal_order.withdrawal_details.map((detail) => {
        //Operculo
        if (detail.product_id >= 2) {
          movement_op = movement_op + 0;
          kg_op_or = kg_op_or + parseFloat(detail.weight_per_unit);
        }

        //Tambor de miel
        if (detail.product_id == 1) {
          kg_miel_or = parseFloat(detail.total_weight) + kg_miel_or;
        }
      });
    }


    let name = order.purchase_order_code ? order.purchase_order_code : order.id ;
    /* Result */
    order = {
      ...order,
      date: format(new Date(order.date + " 00:00:00"), "dd/MM/yyyy").toString(),
      //oc_name: String(order.id).length >= 4? "OC " + order.id : "OC " + Fill(order.id, 4),
      oc_name: "OC "+name,
      provider_name: order.provider.business_name,
      sector_name: order.sector.name,
      sector_id: order.sector.id,

      /* Wax Operculo */
      movement_op: movement_op + " KG OP",
      balance_op: (kg_op_oc - kg_op_or) + " KG OP",
      kg_op: kg_op_oc + " KG OP",
      price_per_units: "$ " + price_per_units,

      /* Drums */
      price_per_kilo: "$ " + price_per_kilo_oc,
      balance_miel: (kg_miel_oc - kg_miel_or) + " KG",
      kg_miel: kg_miel_oc + " KG",
    };

    result.push(order);
  });

  return result;
};
