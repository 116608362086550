import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import CheckBox from "../../../components/forms/CheckBox";
import InputFile from "../../../components/forms/InputFile";
import TextTarea from "../../../components/forms/TextTarea";
import Toogle from "../../../components/forms/Toogle";
import Spinner from "../../../components/loads/Spinner";
import clientAxios from "../../../config/ClientAxios";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { setForm_drum_request } from "../../../utils/setForms";

const ExportLotDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { values } = context;
  const { id } = useParams();

  const {
    URL_SHOW_EXPORT_LOT,
    URL_UPDATE_EXPORT_LOT,
    URL_INDEX_EXPORT_LOT,
    URL_FINISHED_EXPORT_LOT,
    URL_ASSIGN_CHECK_DRUM,
  } = values;

  const [load, setLoad] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lot, setLot] = useState(null);
  const [drums, setDrums] = useState(null);

  const [images, setImages] = useState([]);

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "chequear":
        {
        }
        break;
      case "etiquetar":
        {
        }
        break;
      case "observacion":
        {
        }
        break;
    }
  };

  const onChange = async (e, value) => {
    switch (e.target.name) {
      case "finalizar": {
        if (
          !lot.primary_color ||
          !lot.secondary_color
        ) {
          return Alerts({
            msg: "Alert",
            title: "Antes de finalizar un lote debe asignar colores de etiquetado",
            code: "401",
            type: "error",
          });
        }
        let formData = new FormData();

        formData.append("_method", "PUT");
        formData.append("export_lot_id", lot.id);

        const response = await ApiRest({
          url: URL_FINISHED_EXPORT_LOT,
          formData: formData,
          setLoading: setLoading,
          method: "post",
        });

        //Error
        if (response.status == 201 || response.status == 200) {
          Alerts({
            msg: "Alert",
            title: "Lote finalizado",
            code: "201",
            type: "exito",
          });


          //cerrar modal
          return navigate("/dashboard/export/lot/realized");
        }

        Object.keys(response.data).map((key) => {
          if (Array.isArray(response.data[key])) {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          } else {
            return Alerts({
              msg: "Alert",
              title: response.data[key],
              code: "401",
              type: "error",
            });
          }
        });
      } break;
      case "imagen":
        {
        }
        break;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      let params = {
        id: id,
      };
      const response = await clientAxios.get(URL_SHOW_EXPORT_LOT, { params });
      setLot(response.data);
      console.log(response.data)
      if (response.data.homogenize == 1) {
        setDrums(setForm_drum_request(response.data.fractionated_honey_drums));
      } else {
        setDrums(setForm_drum_request(response.data.purchased_honey_drums));
      }
    };
    fetch();
  }, [load]);

  console.log('lot:', lot);
  //console.log(drums);
  return (
    <main className="main-content">
      <div className="position-relative iq-banner">
        {lot ? (
          <div className="container">
            <div className="card my-5 p-5">
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-start">
                  <div className="pe-0">
                    <h1>
                      <strong>Detalle</strong>
                    </h1>
                  </div>
                  <div className="d-flex ms-2 px-0">
                    <a
                      href="#"
                      className="d-none d-flex align-items-center justify-content-center me-2"
                    >
                      <img
                        src="/assets/images/icon-download.svg"
                        width="18px"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="#"
                      className="d-none d-flex align-items-center justify-content-center"
                    >
                      <img
                        src="/assets/images/icon-print.svg"
                        width="18px"
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
                <div>
                  <label className="btn btn-secondary d-flex justify-content-center px-3 d-none">
                    <svg
                      className="me-2"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2530_34177)">
                        <rect
                          width="20"
                          height="20"
                          transform="translate(0 0.5)"
                          fill="white"
                          fill-opacity="0.01"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.002 3.00195C10.1677 3.00195 10.3267 3.0678 10.4439 3.18501C10.5611 3.30222 10.627 3.46119 10.627 3.62695V9.87695H16.877C17.0427 9.87695 17.2017 9.9428 17.3189 10.06C17.4361 10.1772 17.502 10.3362 17.502 10.502C17.502 10.6677 17.4361 10.8267 17.3189 10.9439C17.2017 11.0611 17.0427 11.127 16.877 11.127H10.627V17.377C10.627 17.5427 10.5611 17.7017 10.4439 17.8189C10.3267 17.9361 10.1677 18.002 10.002 18.002C9.83619 18.002 9.67722 17.9361 9.56001 17.8189C9.4428 17.7017 9.37695 17.5427 9.37695 17.377V11.127H3.12695C2.96119 11.127 2.80222 11.0611 2.68501 10.9439C2.5678 10.8267 2.50195 10.6677 2.50195 10.502C2.50195 10.3362 2.5678 10.1772 2.68501 10.06C2.80222 9.9428 2.96119 9.87695 3.12695 9.87695H9.37695V3.62695C9.37695 3.46119 9.4428 3.30222 9.56001 3.18501C9.67722 3.0678 9.83619 3.00195 10.002 3.00195Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2530_34177">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Iniciar Retiro
                    <button
                      className="d-none"
                      name="retirar"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                </div>
              </div>
              <div>
                <div className="row mt-5">
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">FECHA</h5>
                    <p className="text-dark">{lot.consolidation_date}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">
                      Nº Lote de exportacion
                    </h5>
                    <p className="text-dark">{lot.name}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">DESTINO</h5>
                    <p className="text-dark">{lot.contract.country.name}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">PAÍS DE EXPORTACIÓN</h5>
                    <p className="text-dark">Argentina</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">CLIENTE</h5>
                    <p className="text-dark">{lot.contract.customer.business_name}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">Nº DE CONTRATO</h5>
                    <p className="text-dark">{lot.contract.contract_number}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">FINALIZADO</h5>
                    <p className="text-dark">
                      <CheckBox
                        onChange={onChange}
                        label={"Si"}
                        name={"finalizar"}
                        type={"checkbox"}
                        value={false}
                      />
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 mb-5">
                    <h3 className="mb-2 text-secondary">
                      DATOS DE EXPORTACIÓN
                    </h3>
                    <hr />
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">FECHA DE EMBARQUE</h5>
                    <p className="text-dark">{lot.contract.shipment_date}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">Nº DE CONTAINER</h5>
                    <p className="text-dark">{lot.contract.contract_number}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">ID SENASA</h5>
                    <p className="text-dark">{lot.senasa_lot}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">Nº DE PRECINTO ANA</h5>
                    <p className="text-dark">{lot.ana_seal}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">Nº DE PRECINTO TSH</h5>
                    <p className="text-dark">{lot.tsh_seal}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">Nº DE PRECINTO XX</h5>
                    <p className="text-dark">{lot.line_seal}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 mb-5">
                    <h3 className="mb-2 text-secondary">
                      TAMBORES INCLUIDOS
                    </h3>
                    <hr />
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">COLOR PRIMARIO</h5>
                    <p className="text-dark">
                      <div
                        className="btn rounded-pill"
                        style={{ backgroundColor: lot?.primary_color?.hex_code }}
                      ></div>{" "}
                      {"Nombre " + lot?.primary_color?.name}
                    </p>
                  </div>

                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">COLOR SECUNDARIO</h5>
                    <p className="text-dark">
                      <div
                        className="btn rounded-pill"
                        style={{ backgroundColor: lot.secondary_color?.hex_code }}
                      ></div>{" "}
                      {"Nombre " + lot.secondary_color?.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <p className="text-dark">Tambores</p>
                  <hr className="hrcolor" />
                </div>
                <div className="col-12 my-5">
                  <table
                    id="example"
                    className="table table-striped table-hover"
                  >
                    <thead>
                      <tr>
                        <th className="text-center">TAMBOR</th>
                        <th className="text-center">ESTIBA</th>
                        <th className="text-center">COLOR</th>
                        <th className="text-center">TIPO</th>
                        <th className="text-center">PESO</th>
                        <th className="text-center">TARA</th>
                        <th className="text-center">NETO</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      {drums ? (
                        drums.map((drum, index) => (
                          <tr key={index}>
                            <td>
                              <div className="text-center">
                                {lot.honey_drums[index].entry_details[0].geomiel_code}
                              </div>
                            </td>
                            <td></td>
                            <td>{drum.peso[0] + " KG"}</td>
                            <td>{drum.peso[0] + " KG"}</td>
                            <td>{drum.peso[0] + " KG"}</td>
                            <td>{drum.peso[0] + " KG"}</td>
                            <td>{drum.peso[0] + " KG"}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No existen tambores ingresados</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="min-vh-100 d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        )}
      </div>
    </main>
  );
};

export default ExportLotDetails;
