import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  //Lista
  orderEntry: null,
  orderEntry_pending: null,
  orderEntry_submitted: null,
}

export const orderEntrySlice = createSlice({
  name: "orderEntry",
  initialState: initialState,
  reducers: {
    setOrderEntry: (state, action) => {
      state.orderEntry = action.payload
    },
    setOrderEntry_pending: (state, action) => {
      state.orderEntry_pending = action.payload
    },
    setOrderEntry_submitted: (state, action) => {
      state.orderEntry_submitted = action.payload
    },
  },
});


//Async functions
export const fetchOrderEntry = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params.order_state_id) {
        case null:{ //All Orders pending
          return dispatch(setOrderEntry(result.data));
        }
        case 1:{ //All Orders pending
          return dispatch(setOrderEntry_pending(result.data));
        }
        break;
        case 2:{ //submitted
          return dispatch(setOrderEntry_submitted(result.data));
        }
        break;
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};



export const { setOrderEntry, setOrderEntry_pending, setOrderEntry_submitted} = orderEntrySlice.actions;

export const orderEntryState = (state) => state.orderEntry;

export default orderEntrySlice.reducer;