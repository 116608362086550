import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { authContext } from "../../context/AuthContext";
import { Logout } from "../../helpers/Logout";
import { meState } from "../../slices/users/Index";
import NotificationsHead from "./notifications/NotificationsHead";

const NavBar = ({ onClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(meState);
  const me = user.me;
  const rol = me.roles[0].name;
  const rol_id = me.roles[0].id;


  console.log(me.roles[0]);
  return (
    <nav className="nav navbar navbar-expand-lg navbar-light iq-navbar w-100">
      <div className="container-fluid navbar-inner">
        <Link to={"/dashboard/home"} className="navbar-brand ">
          <img
            src="/assets/images/geomiel-logo.svg"
            alt="Geomiel"
            height="44px"
          />
        </Link>
        <label className="sidebar-toggle ">
        <i className="icon">
            <svg width="20px" height="20px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
              />
            </svg>
          </i>
        <button
          className="d-none"
          data-toggle="sidebar"
          data-active="true"

          name="sidebar-toggle"
          onClick={(event) => onClick(event)}
        >
        </button>
        </label>
        <label 
          className="btn p-2 navbar-toggler">
        <span className="navbar-toggler-icon">
            <span className="mt-2 navbar-toggler-bar bar1"></span>
            <span className="navbar-toggler-bar bar2"></span>
            <span className="navbar-toggler-bar bar3"></span>
          </span>
        <button
        className="d-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          name="navbar-toggle"
          onClick={(event) => onClick(event)}
        >
        </button>
        </label>
        <label className="ms-2 btn btn-primary">
          Carga masiva de datos
          <button className="d-none"
          name="carga-masiva"
          onClick={(event) => onClick(event)}></button>
        </label>
        <div className="collapse navbar-collapse " id="navBar-toggle" >
          <ul className="mb-2 navbar-nav ms-auto align-items-center navbar-list mb-lg-0">
            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link"
                id="notification-drop"
                data-bs-toggle="dropdown"
              >
                <svg
                  width="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.7695 11.6453C19.039 10.7923 18.7071 10.0531 18.7071 8.79716V8.37013C18.7071 6.73354 18.3304 5.67907 17.5115 4.62459C16.2493 2.98699 14.1244 2 12.0442 2H11.9558C9.91935 2 7.86106 2.94167 6.577 4.5128C5.71333 5.58842 5.29293 6.68822 5.29293 8.37013V8.79716C5.29293 10.0531 4.98284 10.7923 4.23049 11.6453C3.67691 12.2738 3.5 13.0815 3.5 13.9557C3.5 14.8309 3.78723 15.6598 4.36367 16.3336C5.11602 17.1413 6.17846 17.6569 7.26375 17.7466C8.83505 17.9258 10.4063 17.9933 12.0005 17.9933C13.5937 17.9933 15.165 17.8805 16.7372 17.7466C17.8215 17.6569 18.884 17.1413 19.6363 16.3336C20.2118 15.6598 20.5 14.8309 20.5 13.9557C20.5 13.0815 20.3231 12.2738 19.7695 11.6453Z"
                    fill="#FAB428"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M14.0088 19.2283C13.5088 19.1215 10.4627 19.1215 9.96275 19.2283C9.53539 19.327 9.07324 19.5566 9.07324 20.0602C9.09809 20.5406 9.37935 20.9646 9.76895 21.2335L9.76795 21.2345C10.2718 21.6273 10.8632 21.877 11.4824 21.9667C11.8123 22.012 12.1482 22.01 12.4901 21.9667C13.1083 21.877 13.6997 21.6273 14.2036 21.2345L14.2026 21.2335C14.5922 20.9646 14.8734 20.5406 14.8983 20.0602C14.8983 19.5566 14.4361 19.327 14.0088 19.2283Z"
                    fill="#FAB428"
                  ></path>
                </svg>
                <span className="bg-danger dots"></span>
              </a>
              <div
                className="p-0 sub-drop dropdown-menu dropdown-menu-end"
                aria-labelledby="notification-drop"
              >
                <div className="m-0 shadow-none card">
                  <div className="py-3 card-header d-flex justify-content-between">
                    <div className="header-title">
                      <h5 className="mb-0">Notificaciones</h5>
                    </div>
                  </div>
                  <NotificationsHead 
                  me={me}
                  dispatch={dispatch}
                  />
                </div>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="py-0 nav-link d-flex align-items-center"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="/assets/images/avatars/01.png"
                  alt="User-Profile"
                  className="theme-color-default-img img-fluid avatar avatar-50 avatar-rounded"
                />
                <img
                  src="/assets/images/avatars/avtar_1.png"
                  alt="User-Profile"
                  className="theme-color-purple-img img-fluid avatar avatar-50 avatar-rounded"
                />
                <img
                  src="/assets/images/avatars/avtar_2.png"
                  alt="User-Profile"
                  className="theme-color-blue-img img-fluid avatar avatar-50 avatar-rounded"
                />
                <img
                  src="/assets/images/avatars/avtar_4.png"
                  alt="User-Profile"
                  className="theme-color-green-img img-fluid avatar avatar-50 avatar-rounded"
                />
                <img
                  src="/assets/images/avatars/avtar_5.png"
                  alt="User-Profile"
                  className="theme-color-yellow-img img-fluid avatar avatar-50 avatar-rounded"
                />
                <img
                  src="/assets/images/avatars/avtar_3.png"
                  alt="User-Profile"
                  className="theme-color-pink-img img-fluid avatar avatar-50 avatar-rounded"
                />
                <div className="caption ms-3 d-none d-md-block ">
                  <h6 className="mb-0 caption-title">{me.name}</h6>
                  <p className="mb-0 caption-sub-title">{rol}</p>
                </div>
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <label className="dropdown-item btn ">
                    Cerrar sesión
                    <button
                      className="d-none"
                      onClick={(event) => Logout(navigate, dispatch)}
                    ></button>
                  </label>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
