import axios from "axios";

const url = process.env.REACT_APP_API_URL;

let clientAxios = axios.create({
  baseURL: url
})

//HEADERS AXIOS

clientAxios.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";

clientAxios.interceptors.request.use(function (config) {
  const token = JSON.parse(localStorage.getItem("Session"));
  //const adress = localStorage.getItem("adress");
  config.headers.Authorization = token ? `Bearer ${token.access_token}` : "";
  //config.headers.adress = adress ? `${adress}` : "";
  return config;
});


export default clientAxios