import { useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alerts from "../../../components/alerts/Alerts";
import BtnAccept from "../../../components/forms/BtnAccept";
import InputSearchMini from "../../../components/forms/InputSearchMini";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import {
  contractState,
  fetchSearchContract,
  setSearch_contract,
} from "../../../slices/contracts/Index";

const ModalAssingContract = ({ select, setModal, loading, setLoading }) => {
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { values, forms, validations } = context;
  //context
  const { URL_INDEX_CONTRACT, URL_CONTRACT_EXPORT_LOT } = values;
  const { FORM_ASIGNEM_CONTRACT } = forms;
  const { VALIDATION_ASIGNEM_CONTRACT } = validations;

  const _contract = useSelector(contractState);
  const search = _contract.search;

  const [form, setForm] = useState({ ...FORM_ASIGNEM_CONTRACT });
  const [validation, setValidation] = useState({
    ...VALIDATION_ASIGNEM_CONTRACT,
  });

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "close":
        {
          //setSelect(null);
          setModal(false);
        }
        break;
      case "guardar":
        {
          if(form.contrato[0] == ""){
            return Alerts({
              msg: "Alert",
              title: "Selecciona un contrato valido.",
              code: "201",
              type: "warning",
            });
          }

          let formData = new FormData();

          formData.append("_method", "PUT");
          formData.append("contract_id", form.id[0]);
          formData.append("export_lot_id", select.id);

           //
           const response = await ApiRest({
            url: URL_CONTRACT_EXPORT_LOT,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Asignando contrato.",
              code: "201",
              type: "success",
            });

            //Actualizar datos providers
           

            //cerrar modal
            return setModal(false);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "contrato":
        {
          console.log(value);
          let setCampo = form;
          setCampo[e.target.name][0] = value.contract_number;
          setCampo["id"][0] = value.id;
          setCampo["cliente"][0] = value.customer.business_name;
          setCampo["destino"][0] = value.country.name;
          setCampo["fecha_embarque"][0] = value.shipment_date;
          setCampo["color"][0] = value.color;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
            ["id"]: setCampo["id"],
            ["cliente"]: setCampo["cliente"],
            ["destino"]: setCampo["destino"],
            ["fecha_embarque"]: setCampo["fecha_embarque"],
            ["color"]: setCampo["color"],
          });

          return dispatch(setSearch_contract([]));
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search_contrato":
        {
          //form
          let setCampo = form;

          //
          let params = {
            url: URL_INDEX_CONTRACT,
            search: "",
          };

          if (
            e.target.value.length > 2 &&
            e.target.value.length > setCampo["contrato"][0].length
          ) {
            params.search = e.target.value;
            dispatch(fetchSearchContract(params));
          }

          if (e.target.value.trim() == "") {
            dispatch(setSearch_contract([]));
          }

          //Insert value form
          setCampo["contrato"][0] = e.target.value;

          setForm({
            ...form,
            cliente: setCampo["contrato"],
          });
        }
        break;
    }
  };

  console.log(form);
  console.log(search);
  return (
    <div
      className="modal fade show bg-dark bg-opacity-25 border-0 min-vh-100 h-100 modal-transition bg-modal-blur"
      style={{ display: "block", zIndex: 5 }}
      id="focus-modal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        style={{ maxWidth: "500px" }}
      >
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 5 }}>
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div className="modal-header w-100 border-0 p-3">
            <h3
              className="modal-title text-start  border-0 w-100"
              id="nueva-ordenModalLabel"
            >
              Lote {select.name}
            </h3>
          </div>
          <div className="modal-body p-3">
            <div className="row mb-3">
              <div className="col-md-12">
                <InputSearchMini
                  onChange={onChange}
                  label={"Nº Contrato"}
                  name={"search_contrato"}
                  type={"text"}
                  value={form.contrato[0]}
                  //Autocomplete
                  onClick={onClick}
                  nameAutocomplete="contrato"
                  valueAutocomplete={search}
                  showIndex="contract_number"
                  sendIndex="id"
                  validate={validation.contrato}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-3">
                <div className="detail">
                  Cliente
                  <div className="d-flex flex-row">{form.cliente[0]}</div>{" "}
                </div>
              </div>
              <div className="col-md-3">
                <div className="detail">
                  Destino
                  <div className="d-flex flex-row">{form.destino[0]}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="detail">
                  Color
                  <div className="d-flex flex-row">{form.color[0]}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="detail">
                  Fecha de embarque
                  <div className="d-flex flex-row">
                    {form.fecha_embarque[0]}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 d-flex justify-content-end align-items-end">
              <div class="btn-group">
                <BtnAccept
                  onClick={onClick}
                  name={"guardar"}
                  value={"Asignar contrato"}
                  loading={loading}
                  loadingMsg={"Guardando Lote"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAssingContract;
