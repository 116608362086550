import { logDOM } from "@testing-library/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";

const SpeedDial = ({ data }) => {
  const navigate = useNavigate();
  const [floating, setFloating] = useState(false);

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "floating-action":
        if (!floating) {
          setFloating(true);
          document
            .getElementById("fab_ctn")
            .classList.add("is-showing-options");
        } else {
          setFloating(false);
          document
            .getElementById("fab_ctn")
            .classList.remove("is-showing-options");
        }
        break;
      case "modal":
        {
          value(true);

          setFloating(false);
          document
            .getElementById("fab_ctn")
            .classList.remove("is-showing-options");
        }
        break;
      default:
        break;
    }
  };
  return (
    <div
      className={
        data.length > 0 ? "mdl-button--fab_flinger-container" : "d-none"
      }
      id="fab_ctn"
      style={{ zIndex: "4" }}
    >
      <button
        className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect bg-primary"
        id="fab_btn"
        name="floating-action"
        onClick={(event) => onClick(event)}
      >
        <i
          style={{ pointerEvents: "none" }}
          className="material-icons text-white"
        >
          add
        </i>
      </button>
      <div className="mdl-button--fab_flinger-options">
        {data.map((btn) => (
          <div className={btn.hidden ? "d-none" : ""}>
            <label>
              {btn.setModal ? (
                <button
                  className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect"
                  data-tip
                  data-for={btn.name}
                  name="modal"
                  onClick={(event) => onClick(event, btn.setModal)}
                >
                  <div
                    contentEditable="true"
                    style={{ pointerEvents: "none" }}
                    dangerouslySetInnerHTML={{ __html: btn.icon }}
                  ></div>
                </button>
              ) : (
                <button
                  className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect"
                  data-tip
                  data-for={btn.name}
                  name="modal"
                  onClick={(event) => navigate(btn.redirect)}
                >
                  <div
                    contentEditable="true"
                    style={{ pointerEvents: "none" }}
                    dangerouslySetInnerHTML={{ __html: btn.icon }}
                  ></div>
                </button>
              )}
            </label>

            <ReactTooltip
              id={btn.name}
              data-bs-placement="left"
              className="custom-tooltip"
              effect="solid"
              style={{ zIndex: 4 }}
            >
              {btn.text}
            </ReactTooltip>
          </div>
        ))}

        <div className="d-none">
          <label>
            <button
              className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect"
              data-tip
              data-for="NuevaOrdenDeCompra"
              name="modal-orderPurchase"
              onClick={(event) => onClick(event)}
            >
              <img
                style={{ pointerEvents: "none" }}
                src="/assets/images/icon-nuevo-proveedor.svg"
                width="22px"
              />
            </button>
          </label>

          <ReactTooltip
            id="NuevaOrdenDeCompra"
            data-bs-placement="left"
            className="custom-tooltip"
            effect="solid"
            style={{ zIndex: 4 }}
          >
            Nueva órden de compra
          </ReactTooltip>
        </div>
      </div>
    </div>
  );
};

export default SpeedDial;
