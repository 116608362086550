import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  payment_methods: [
    {
      id: 1,
      name: "Factura",
      description: null,
    },
    {
      id: 2,
      name: "Efectivo",
      description: null,
    },
    /*
    {
      id: 3,
      name: "Dolares",
      description: null,
    },
    */
  ],
};

export const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState: initialState,
  reducers: {

  },
});


//Async functions
export const fetchPaymentMethods = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params })
    .then((result) => {
      return "";
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};



export const {  } = paymentMethodsSlice.actions;

export const paymentMethodsState = (state) => state.paymentMethods;

export default paymentMethodsSlice.reducer;