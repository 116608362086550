import { format } from "date-fns";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import CheckBox from "../../../components/forms/CheckBox";
import Spinner from "../../../components/loads/Spinner";
import clientAxios from "../../../config/ClientAxios";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { clientState } from "../../../slices/clients/Index";
import { setForm_contract } from "../../../utils/setForms";

const ContractClientDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /*********************************************** INITIAL STATE ***********************************************************/
  const context = useContext(authContext);
  const { values, forms, validations } = context;

  const {
    URL_CREATE_CONTRACT,
    URL_INDEX_CONTRACT,
    URL_INDEX_CUSTOMER,
    URL_SHOW_CONTRACT,
    URL_UPDATE_CONTRACT,
    URL_FINISH_CONTRACT,
  } = values;
  const { FORM_CONTRACT } = forms;
  const { VALIDATION_CONTRACT } = validations;

  const _clients = useSelector(clientState);
  const clients = _clients.search;

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(null);
  const [validation, setValidation] = useState(VALIDATION_CONTRACT);

  const onChange = async (e, value) => {
    switch (e.target.name) {
      case "finalizar":
        {
          //create form
          let formData = new FormData();
          formData.append("_method", "PUT");
          formData.append("contract_id", id);

          //
          const response = await ApiRest({
            url: URL_FINISH_CONTRACT,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Creando nuevo contrato.",
              code: "201",
              type: "success",
            });

            //Actualizar datos providers

            let params = {
              id: id,
            };
            
            const response = await clientAxios.get(URL_SHOW_CONTRACT, {
              params,
            });

            setForm(setForm_contract(response.data));

            return "";
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
    }
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "finalizar":
        {
        }
        break;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      let params = {
        id: id,
      };
      const response = await clientAxios.get(URL_SHOW_CONTRACT, { params });
      console.log(response.data);

      setForm(setForm_contract(response.data));
    };

    fetch();
  }, [id]);

  console.log(form);
  return (
    <main className="main-content">
      <div className="position-relative iq-banner">
        {form ? (
          <div className="container">
            <div className="card my-5 p-5">
              <div className="d-flex justify-content-between">
                <div className="w-100 d-flex justify-content-between">
                  <div className="pe-0">
                    <h1>
                      <strong>
                        Detalle de contrato Nº {form.numero_contrato[0]}
                      </strong>
                    </h1>

                    <div className="d-flex ms-2 px-0">
                      <a
                        href="#"
                        className="d-none d-flex align-items-center justify-content-center me-2"
                      >
                        <img
                          src="/assets/images/icon-download.svg"
                          width="18px"
                          loading="lazy"
                        />
                      </a>
                      <a
                        href="#"
                        className="d-none d-flex align-items-center justify-content-center"
                      >
                        <img
                          src="/assets/images/icon-print.svg"
                          width="18px"
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="row mt-5">
                <div className="col-4 mb-5">
                  <h5 className="mb-2 text-secondary">Fecha</h5>
                  <p className="text-dark">
                    {format(
                      new Date(form.fecha_creacion[0]),
                      "dd/MM/yyyy H:i:s"
                    ).toString()}
                  </p>
                </div>
                <div className="col-4 mb-5">
                  <h5 className="mb-2 text-secondary">Finalizado</h5>
                  <p className="text-dark">
                    <CheckBox
                      onChange={onChange}
                      label={"Si"}
                      name={"finalizar"}
                      type={"checkbox"}
                      value={false}
                    />
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <p className="text-dark">CONDICIONES</p>
                  <hr className="hrcolor" />
                </div>
                <div className="col-4 mb-5">
                  <h5 className="mb-2 text-secondary">Cantidad a enviar</h5>
                  <p className="text-dark">{form.unidad[0]}</p>
                </div>
                <div className="col-4 mb-5">
                  <h5 className="mb-2 text-secondary">Insumo</h5>
                  <p className="text-dark">
                    {form.producto[0] == 1 ? "Miel" : "Cera"}
                  </p>
                </div>
                <div className="col-4 mb-5">
                  <h5 className="mb-2 text-secondary">Color</h5>
                  <p className="text-dark">{form.color[0] + "mm"}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <p className="text-dark">CONTAINERS</p>
                  <hr className="hrcolor" />
                </div>
                <div className="col-12 my-5">
                  <table
                    id="example"
                    className="table table-striped table-hover"
                  >
                    <thead>
                      <tr>
                        <th className="text-center">Nº de container</th>
                        <th className="text-center">Fecha de embarque</th>
                        <th className="text-center">Nº de precinto</th>
                        <th className="text-center">Lote asociado</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      {form.lotes_exportacion[0].map((lot) => (
                        <tr>
                          <td className="border-0">{lot.container}</td>
                          <td className="border-0">{form.fecha_envio[0]}</td>
                          <td className="border-0">{lot.tsh_seal}</td>
                          <td className="border-0">{lot.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="min-vh-100 d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        )}
      </div>
    </main>
  );
};

export default ContractClientDetails;
