import { useNavigate, useParams } from "react-router-dom";
import Filters from "./filters/Filters";
import { useContext, useEffect, useState } from "react";
import { authContext } from "../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderPurchaseList,
  orderPurchaseState,
} from "../../slices/orderPurchase/Index";
import Spinner from "../../components/loads/Spinner";
import ModalProviders from "../../components/modals/ModalProviders";
import ModalOrderPurchase from "../../components/modals/ModalOrderPurchase";
import BtnSticky from "../../components/forms/BtnSticky";
import { fetchSupplies, suppliesState } from "../../slices/supplies/Index";
import { format } from "date-fns/esm";
import { add } from "date-fns";
import { meState } from "../../slices/users/Index";
import {
  fetchOrderEntry,
  orderEntryState,
} from "../../slices/orderEntry/Index";
import Pending from "./pending/Index";
import Submitted from "./submitted/Index";

const OrderEntry = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { label } = useParams();
  const context = useContext(authContext);
  const { values, guards } = context;
  //context
  const {
    URL_INDEX_PURCHASE,
    URL_INDEX_EQUIVALENCE,
    URL_INDEX_ENTRY,
  } = values;
  const {
    ORDER_WITHDRAWAL_DETAILS
  } = guards
  //Initial States
  const _user = useSelector(meState);
  const _supplies = useSelector(suppliesState);
  const _orderPurchase = useSelector(orderPurchaseState);
  const _orderEntry = useSelector(orderEntryState);

  const orderEntry_pending = _orderEntry.orderEntry_pending;
  const orderEntry_submitted= _orderEntry.orderEntry_submitted;

  const orderPurchase = _orderPurchase.orderPurchase;
  const supplies = _supplies.supplies;
  const me = _user.me;
  const rol_id = me.roles[0].id;

  const [select, setSelect] = useState(null);

  //Modals
  const [modalProviders, setModalProviders] = useState(false);
  const [modalOrderPurchase, setModalOrderPurchase] = useState(false);

  //params
  const [paramsEntry, setParamsEntry] = useState({
    url: URL_INDEX_ENTRY,
    order_state_id:
      label == "pending"
        ? 1
        : label == "submitted"
        ? 2
        : label == "actives"
        ? 3
        : null,
    search: "",
    start_date: format(
      add(new Date(), { months: -12 }),
      "yyyy-MM-dd"
    ).toString(),
    end_date: format(new Date(), "yyyy-MM-dd").toString(),
    sort: [
      {
        order_by: "id",
        ordering: "desc",
      },
    ],
  });

  const [params, setParams] = useState({
    url: URL_INDEX_PURCHASE,
  });

  const [paramsEquivalence, setParamsEquivalence] = useState({
    url: URL_INDEX_EQUIVALENCE,
  });

  //Title
  const h1 =
    label == "pending"
      ? "Ingresos pendientes"
      : label == "submitted"
      ? "Ingresos realizados"
      : label == "actives"
      ? "Órdenes activas"
      : "";

  //Api
  //
  useEffect(() => {
    if (!supplies) {
      dispatch(fetchSupplies(paramsEquivalence));
    }
  }, [paramsEquivalence]);

  useEffect(() => {
    if (!orderPurchase) dispatch(fetchOrderPurchaseList(params));
  }, [params]);

  useEffect(() => {
    if (!orderEntry_pending || !orderEntry_submitted) {
      let params = {
        url: URL_INDEX_ENTRY,
        order_state_id: label == "pending"
        ? 1
        : label == "submitted"
        ? 2
        : label == "actives"
        ? 3
        : null,
        sort: [
          {
            order_by: "id",
            ordering: "desc",
          },
        ],
      };
      dispatch(fetchOrderEntry(params));
    }
  }, [label]);
  return (
    <main class="main-content">
      <div class="position-relative iq-banner">
        <div class="iq-navbar-header" style={{ height: "215px" }}>
          <div class="container-fluid iq-container">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center">
                  <div class="box-img">
                    <img
                      src="/assets/images/icon-ordenes-de-compra.svg"
                      width="80%"
                    />
                  </div>
                  <h1 class="ms-2 mb-0">{h1}</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              class="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div class="conatiner-fluid content-inner mt-n5 py-0">
        <div class="row">
          <div class="col-md-12 bg-grey rounded">
            <div class="row row-cols-1">
              <div class="col-12 mb-3">
                {/* Filters */}
                <Filters
                  setParams={setParamsEntry}
                  params={paramsEntry}
                  dispatch={dispatch}
                  label={label}
                />
              </div>

              <div className="col-md-12">
                <div class="card">
                  {orderEntry_pending || orderEntry_submitted ? (
                    label == "pending" && orderEntry_pending ? (
                      <Pending
                        data={orderEntry_pending}
                        setParams={setParamsEntry}
                        params={paramsEntry}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={setModalProviders}
                        navigate={navigate}
                        label={label}
                      />
                    ) : label == "submitted" && orderEntry_submitted ? (
                      <Submitted
                        data={orderEntry_submitted}
                        setParams={setParamsEntry}
                        params={paramsEntry}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={setModalProviders}
                        navigate={navigate}
                        label={label}
                      />
                    ) : label == "actives" && orderEntry_pending ? (
                      ""
                    ) : (
                      <Spinner />
                    )
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(modalProviders && rol_id == 5) || rol_id == 1 ? (
        <ModalProviders
          /**PRIMARY***/
          setSelect={setSelect}
          select={select}
          setModalProviders={setModalProviders}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}

      {(modalOrderPurchase && rol_id == 5) || rol_id == 1 ? (
        <ModalOrderPurchase
          setSelect={setSelect}
          select={select}
          setModalOrderPurchase={setModalOrderPurchase}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}

      {rol_id == 5 || rol_id == 1 ? (
        <BtnSticky
          setModalProviders={setModalProviders}
          setModalOrderPurchase={setModalOrderPurchase}
        />
      ) : (
        ""
      )}
    </main>
  );
};

export default OrderEntry;
