import { useState } from "react"
import BtnAccept from "../../../components/forms/BtnAccept"
import { format } from "date-fns";
import { suppliesState } from "../../../slices/supplies/Index";
import { useSelector } from "react-redux";

const StateCountModal = ({
  setModal,
  data
}) => {
  const [loading, setLoading] = useState(false);

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "": { } break;
    }
  }
  const onChange = (e, value) => {
    switch (e.target.name) {
      case "": { } break;
    }
  }


  let equivalencia = (parseFloat(data.selling_price) * parseFloat(data.units) / parseFloat(data.price_per_kg))

  if (data.applied_balance == "Miel") {
    if (data.type == "Canje") {
      equivalencia = 0.00;
    }

    if (data.type == "Adelanto") {
      equivalencia = parseFloat(data.kilograms);
    }


    if (data.type == "Servicios") {
      equivalencia = (parseFloat(data.price_per_unit) * parseFloat(data.units) / parseFloat(data.price_per_kg))
      data = {
        ...data,
        selling_price: data.price_per_unit
      }
    }
  }

  if (data.applied_balance == "Opérculo") {
    let equivalencia = (parseFloat(data.selling_price) * parseFloat(data.units) / parseFloat(data.price_per_kg))

    if (data.type == "Canje") {
      equivalencia = 0.00;
    }

    if (data.type == "Adelanto") {
      equivalencia = parseFloat(data.kilograms);
    }


    if (data.type == "Servicios") {
      equivalencia = (parseFloat(data.price_per_unit) * parseFloat(data.units) / parseFloat(data.price_per_kg))
      data = {
        ...data,
        selling_price: data.price_per_unit
      }
    }
  }


  return (
    <div
      className="modal  fade show bg-dark bg-opacity-25  modal-transition bg-modal-blur"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        style={{ maxWidth: "900px" }}
      >
        <div className="modal-content bg-modal-blanco">
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => setModal(false)}
            ></button>
          </label>
          <div className="modal-header p-5">
            <h1 className="modal-title bold" id="nueva-ordenModalLabel">
              <strong>
                Detalle de movimiento
              </strong>
            </h1>
          </div>
          <div className="modal-body p-5">
            <div className="row">
              <div className="col-md-4 text-center mb-5">
                <div className="detail">
                  <div className="text-dark text-start fw-bold">
                    FECHA
                  </div>
                  <p className="text-start mt-1 mb-0">{format(new Date(data.date), 'yyyy-MM-dd H:i:s').toString()}</p>
                </div>
              </div>
              <div className="col-md-4 text-center mb-5 px-5">
                <div className="detail">
                  <div className="text-dark text-start fw-bold">
                    TIPO DE MOVIMIENTO
                  </div>
                  <p className="text-start mt-1 mb-0 text-nowrap">
                    {
                      data.transaction
                    }
                  </p>
                </div>
              </div>
              <div className="col-md-4 text-center mb-5">
                <div className="detail">
                  <div className="text-dark text-start fw-bold">
                    NÚMERO DE COMPROBANTE
                  </div>
                  <p className="text-start mt-1 mb-0 text-nowrap">
                    {
                      data.receipt
                    }
                  </p>
                </div>
              </div>


              <div className="col-md-4 text-center mb-5">
                <div className="detail">
                  <div className="text-dark text-start fw-bold">
                    Descripcion
                  </div>
                  <p className="text-start mt-1 mb-0 text-nowrap">
                    {
                      data.item
                    }
                  </p>
                </div>
              </div>
              <div className="col-md-4 text-center mb-5 px-5">
                <div className="detail">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="text-dark text-start pe-5">
                      </span>
                      <p className="text-start mt-1 mb-0 text-nowrap">
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center mb-5">
                <div className="detail">
                  <div className="text-dark text-start fw-bold">
                    SALDO AL QUE APLICA
                  </div>
                  <p className="text-start mt-1 mb-0 text-nowrap">
                    {
                      data.applied_balance
                    }
                  </p>
                </div>
              </div>

              {
                data.applied_balance == "Miel"
                  ? <div className="row">
                    <div className="col-md-12">
                      <h1 className="mb-5">
                        <strong>
                          Transacción
                        </strong>
                      </h1>
                    </div>
                    <div className="col-md-4">
                      <div className="text-dark text-start fw-bold">
                        TIPO DE EGRESO
                      </div>
                      <p>{data.type}</p>
                    </div>
                    <div className="col-md-4">
                      <div className="text-dark text-start fw-bold">
                        PRECIO NEGOCIADO / KG
                      </div>
                      <p>{data.price_per_kg}</p></div>
                    <div className="col-md-4">
                      <div className="text-dark text-start fw-bold">
                        KG DE <span className="text-uppercase">{data.applied_balance}</span>
                      </div>
                      <p>{(equivalencia).toFixed(2)}</p>
                    </div>

                    <div className="col-md-4">
                      <div className="text-dark text-start fw-bold">
                        SALDO
                      </div>
                      <p>{equivalencia * data.price_per_kg}</p></div>
                  </div>
                  : ""
              }

              {
                data.applied_balance == "Opérculo"
                  ? <div className="row">
                    <div className="col-md-12">
                      <h1 className="mb-5">
                        <strong>
                          Transacción
                        </strong>
                      </h1>
                    </div>
                    <div className="col-md-4">
                      <div className="text-dark text-start fw-bold">
                        TIPO DE EGRESO
                      </div>
                      <p>{data.type}</p>
                    </div>
                    <div className="col-md-4">
                      <div className="text-dark text-start fw-bold">
                        PRECIO NEGOCIADO / KG
                      </div>
                      <p>{data.wax_price_per_kg}</p></div>
                    <div className="col-md-4">
                      <div className="text-dark text-start fw-bold">
                        KG DE <span className="text-uppercase">{data.applied_balance}</span>
                      </div>
                      <p>{(equivalencia).toFixed(2)}</p>
                    </div>

                    <div className="col-md-4">
                      <div className="text-dark text-start fw-bold">
                        SALDO
                      </div>
                      <p>{equivalencia * data.wax_price_per_kg}</p></div>
                  </div>
                  : ""
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StateCountModal