import { createContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FORMS, FORM_ORODER_PURCHASE } from "../consts/FormsConst";
import { GlobalConst } from "../consts/UrlConst";
import { INITIAL } from "../consts/InitialConst";
import { VALIDATIONS } from "../consts/ValidationConst";
import { fetchCountryList, fetchProvinceList, geolocationState } from "../slices/geolocation";
import { fetchMe, meState } from "../slices/users/Index";
import { GUARDS } from "../consts/Guards";

export const authContext = createContext(); 

export const AuthProvider = ({children}) => {
const dispatch = useDispatch();
const _geolocation = useSelector(geolocationState);
const province = _geolocation.province;
const country = _geolocation.country;

const global_values = GlobalConst;
const forms = FORMS;
const validations = VALIDATIONS;
const initial = INITIAL;
const guards = GUARDS;

useEffect(() => {
  if(!province){
    dispatch(fetchProvinceList())
  }
},[])


useEffect(() => {
  if(!country){
    dispatch(fetchCountryList())
  }
},[])
return (
  <authContext.Provider value={{
    values: global_values, 
    forms: forms, 
    validations: validations,
    initial: initial,
    guards: guards,

  }}  > 
    {children}
  </authContext.Provider>
)
}

