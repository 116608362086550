import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import BtnAccept from "../../../components/forms/BtnAccept";
import CheckBox from "../../../components/forms/CheckBox";
import Spinner from "../../../components/loads/Spinner";
import clientAxios from "../../../config/ClientAxios";
import { authContext } from "../../../context/AuthContext";
import { getGuard } from "../../../guards/guards";
import ApiRest from "../../../hooks/useRest";
import { drumState, fetchDrums } from "../../../slices/drums/Index";
import CreateContractLotH from "./CreateContractLotH";
import TextTarea from "../../../components/forms/TextTarea";

const CreateContractLot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const context = useContext(authContext);
  const { values, forms } = context;
  //context
  const {
    URL_SHOW_CONTRACT,
    URL_CREATE_EXPORT_LOT,
    URL_SET_HOMOGENEIZATION,
    URL_INDEX_DRUM,
  } = values;
  const { FORM_EXTRA_ANALYZED } = forms;

  const _drums = useSelector(drumState);
  const drums = _drums.drums_lot;

  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [extraAnalyzed, setExtraAnalyzed] = useState(false);
  const [formExtraAnalyzed, setFormExtraAnalyzed] = useState(
    JSON.parse(JSON.stringify(FORM_EXTRA_ANALYZED))
  );
console.log('drums', _drums)
  const [lot, setLot] = useState({
    drums: [],
    drums_id: [],
  });

  const [homogenize, setHomogenize] = useState({
    drums: [],
    drums_id: [],
  });
  //const [drums, setDrums] = useState(null);

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "crear":
        {
          //set lot and drums homogenize

          let formData = new FormData();
          formData.append("contract_id", contract.id);

          let formDrumsHomogenize = new FormData();
          formDrumsHomogenize.append("_method", "PUT");

          homogenize.drums.map((drum, index) => {
            formDrumsHomogenize.append(
              "honey_drums[" + index + "]",
              drum.id[0]
            );
          });

          /* Create Lot */
          lot.drums.map((drum, index) => {
            formData.append("honey_drums[" + index + "][id]", drum.id[0]);
            formData.append(
              "honey_drums[" + index + "][homogenize]",
              drum.homogeneizar[0] ? 1 : 0
            );
          });

          formData.append("analysis", extraAnalyzed ? 1 : 0);
          if (extraAnalyzed) {
            //formData.append("dextrins", formExtraAnalyzed? 1 : 0);
            //formData.append("measurement_type_hmf", formExtraAnalyzed.hmf? 1 : 0);
            //formData.append("hmf_value", formExtraAnalyzed.hmf_value? 1 : 0);
            formData.append(
              "analysis_types[fructose]",
              formExtraAnalyzed.fructosa[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[glucose]",
              formExtraAnalyzed.glucosa[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sucrose]",
              formExtraAnalyzed.sacarosa[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[fg]",
              formExtraAnalyzed.fg[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[f+g]",
              formExtraAnalyzed.f_g[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[g+h]",
              formExtraAnalyzed.g_h[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[adulterated_honey]",
              formExtraAnalyzed.miel_adulterada[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[p-m]",
              formExtraAnalyzed.p_m[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[rim]",
              formExtraAnalyzed.rim[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[rip]",
              formExtraAnalyzed.rip[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[%c4]",
              formExtraAnalyzed.c4[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[diastose]",
              formExtraAnalyzed.diastosa[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[conductivity]",
              formExtraAnalyzed.conductividad[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[table_by_hanna]",
              formExtraAnalyzed.color_hanna[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[moisture_by_refractometry]",
              formExtraAnalyzed.humedad_refractometro[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sz]",
              formExtraAnalyzed.sz[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[smt]",
              formExtraAnalyzed.smt[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[ss]",
              formExtraAnalyzed.ss[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[smm]",
              formExtraAnalyzed.smm[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[daps]",
              formExtraAnalyzed.daps[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sdm]",
              formExtraAnalyzed.sdm[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[smx]",
              formExtraAnalyzed.smx[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sp]",
              formExtraAnalyzed.sp[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[smp]",
              formExtraAnalyzed.smp[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sd]",
              formExtraAnalyzed.sd[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sq]",
              formExtraAnalyzed.sq[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[st]",
              formExtraAnalyzed.st[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[sm]",
              formExtraAnalyzed.sm[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[scp]",
              formExtraAnalyzed.scp[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[tmp]",
              formExtraAnalyzed.tmp[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[qnl]",
              formExtraAnalyzed.qnl[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[str]",
              formExtraAnalyzed.str[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[ceft]",
              formExtraAnalyzed.ceft[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[tyl]",
              formExtraAnalyzed.tyl[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[taf]",
              formExtraAnalyzed.taf[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[tcn]",
              formExtraAnalyzed.tcn[0] ? 1 : 0
            );
            formData.append(
              "analysis_types[comments]",
              formExtraAnalyzed.comments[0] 
            );
          }

          //
          if (lot.drums.length > 0) {
            const response = await ApiRest({
              url: URL_CREATE_EXPORT_LOT,
              formData: formData,
              setLoading: setLoading,
              method: "post",
            });

            //Error
            if (response.status == 201 || response.status == 200) {
              Alerts({
                msg: "Alert",
                title: "Creando nuevo lote de exportacion.",
                code: "201",
                type: "success",
              });
            } else {
              Object.keys(response.data).map((key) => {
                return Alerts({
                  msg: "Alert",
                  title: response.data[key][0],
                  code: "401",
                  type: "error",
                });
              });
            }
          }

          /* Set drums homogenize */
          if (homogenize.drums.length > 0) {
            const drums_response = await ApiRest({
              url: URL_SET_HOMOGENEIZATION,
              formData: formDrumsHomogenize,
              setLoading: setLoading,
              method: "post",
            });

            //Error
            if (drums_response.status == 201 || drums_response.status == 200) {
              Alerts({
                msg: "Alert",
                title: "Enviando tambores a homogeneizar.",
                code: "201",
                type: "success",
              });

              let params = {
                url: URL_INDEX_DRUM,
                analyzed: 1,
                homogenize: 0,
                with_contract: 0,
              };
              dispatch(fetchDrums(params));

              //cerrar modal
              return navigate("/dashboard/lot/pending");
            } else {
              Object.keys(drums_response.data).map((key) => {
                return Alerts({
                  msg: "Alert",
                  title: drums_response.data[key][0],
                  code: "401",
                  type: "error",
                }); 
              });

              return "";
            }
          }

          return navigate("/dashboard/lot/pending");
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "comments": {
        let setValue = formExtraAnalyzed;
        setValue[e.target.name][0] = e.target.value;

        setFormExtraAnalyzed({
          ...formExtraAnalyzed,
          [e.target.name]: setValue[e.target.name],
        });
      } break;
      case "seleccionar":
        {
          let drums = lot.drums;
          let drums_id = lot.drums_id;

          if (e.target.checked) {
            drums.push(value);
            drums_id.push(value.id[0]);
          } else {
            drums_id = drums_id.filter((item) => item != value.id[0]);
            drums = drums.filter((item) => item.id[0] != value.id[0]);
          }

          setLot({ ...lot, drums_id: [...drums_id], drums: [...drums] });
        }
        break;
      case "homogeneizar":
        {
          let drums = homogenize.drums;
          let drums_id = homogenize.drums_id;

          if (e.target.checked) {
            drums.push(value);
            drums_id.push(value.id[0]);
          } else {
            drums_id = drums_id.filter((item) => item != value.id[0]);
            drums = drums.filter((item) => item.id[0] != value.id[0]);
          }

          setHomogenize({ ...homogenize, drums_id: drums_id, drums: drums });
        }
        break;
      case "analisis_extra":
        {
          if (extraAnalyzed) {
            setExtraAnalyzed(false);
          } else {
            setExtraAnalyzed(true);
          }
        }
        break;
      case "tcn":
      case "tyl":
      case "str":
      case "taf":
      case "ceft":
      case "qnl":
      case "tmp":
      case "sd":
      case "daps":
      case "scp":
      case "smp":
      case "smm":
      case "sm":
      case "sp":
      case "ss":
      case "st":
      case "smx":
      case "smt":
      case "sq":
      case "sdm":
      case "sz":
      case "humedad_refractometro":
      case "color_hanna":
      case "diastosa":
      case "conductividad":
      case "c4":
      case "rip":
      case "rim":
      case "p_m":
      case "miel_adulterada":
      case "g_h":
      case "f_g":
      case "fg":
      case "sacarosa":
      case "glucosa":
      case "fructosa":
        {
          let setValue = formExtraAnalyzed;
          setValue[e.target.name][0] = e.target.checked;

          setFormExtraAnalyzed({
            ...formExtraAnalyzed,
            [e.target.name]: setValue[e.target.name],
          });
        }
        break;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      let params = {
        id: id,
      };
      const response = await clientAxios.get(URL_SHOW_CONTRACT, { params });
      setContract(response.data);
    };

    fetch();
  }, [id]);

  useEffect(() => {
    if (drums) {
      let L = [];
      let H = [];

      drums.drums.map((drum, index) => {
        if (!drum.homogeneizar[0]) {
          L.push(drum);
        } else {
          H.push(drum);
        }
      });

      setLot({
        drums: L,
        drums_id: drums.i,
      });

      setHomogenize({
        drums: H,
        drums_id: drums.h,
      });
    }
  }, [drums]);

  //console.log(contract);
  //console.log(drums);
  //console.log(lot);
  console.log(drums);
  return (
    <main class="main-content">
      <div class="position-relative iq-banner">
        {contract && drums ? (
          <div class="container">
            <div class="card my-5 p-5">
              <div class="d-flex justify-content-start">
                <div class="pe-0">
                  <h1>
                    <strong>Lote N° 00000</strong>
                  </h1>
                </div>
              </div>
              <div class="row d-flex justify-content-between mt-5">
                <div class="col-2 card">
                  <p>N° de Contrato</p>
                  <p class="fw-bold">{contract.contract_number}</p>
                </div>
                <div class="col-3 card">
                  <p>Destino</p>
                  <p class="fw-bold">Estados Unidos</p>
                </div>
                <div class="col-3 card">
                  <p>Cliente</p>
                  <p class="fw-bold">{contract.customer.business_name}</p>
                </div>
                <div class="col-2 card">
                  <p>Color</p>
                  <p class="fw-bold">{contract.color} mm</p>
                </div>
              </div>
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Lotes de exportación
                  </button>
                </li>
                <li class="ms-5 nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Tambores a homogeneizar
                  </button>
                </li>
              </ul>
              <hr />
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <div class="table-responsive">
                    <div class="tool-table d-flex">
                      <a
                        href="#"
                        class="d-flex align-items-center justify-content-center me-2"
                      >
                        <img
                          src="../assets/images/icon-download.svg"
                          width="18px"
                        />
                      </a>
                      <button
                        className="border-0 bg-transparent text-primary ms-3 d-flex align-items-center justify-content-center"
                        title="imprimir"
                        onClick={() => {
                          window.print();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="currentColor"
                          class="bi bi-printer"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                          <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                        </svg>
                      </button>
                    </div>
                    <table
                      id="example"
                      class="table table-striped"
                      data-toggle="data-table"
                    >
                      <thead>
                        <tr>
                          <th class="text-center"></th>
                          <th class="text-center">Tambor</th>
                          <th class="text-center">Estado</th>
                          <th class="text-center">HMF</th>
                          <th class="text-center">Color</th>
                          <th class="text-center">Humedad</th>
                          <th class="text-center">Kg Bruto</th>
                          <th class="text-center">Kg Tara</th>
                          <th class="text-center">Kg Neto</th>
                          <th class="text-center">Solidificacion</th>
                          <th class="text-center">AM I</th>
                          <th class="text-center">AM II</th>
                          <th class="text-center">Glifosato</th>
                          <th class="text-center">FG</th>
                          <th class="text-center">Proveedor</th>
                          <th class="text-center">OC</th>
                          <th class="text-center">N° SENASA</th>
                          <th class="text-center">Observaciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {drums.drums.map((drum, index) => {
                          if (!drum.homogeneizar[0]) {
                            return (
                              <tr>
                                <td class="text-center">
                                  <div className="form-check mb-3 w-100">
                                    <input
                                      type="checkbox"
                                      name="seleccionar"
                                      onChange={(event) =>
                                        onChange(event, drum)
                                      }
                                      className="form-check-input m-0 my-1 me-1"
                                      checked={
                                        lot.drums_id.indexOf(drum.id[0]) >= 0
                                          ? true
                                          : false
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      for="customCheck1"
                                    ></label>
                                  </div>
                                </td>
                                <td class="text-center">{drum.geocode[0]}</td>
                                <td class="text-center">{drum.approved[0]}</td>
                                <td class="text-center">{drum.hmf[0]}</td>
                                <td class="text-center">{drum.color[0]}</td>
                                <td class="text-center">{drum.moisture[0]}</td>
                                <td class="text-center">
                                  {drum.gross_weight[0]}
                                </td>
                                <td class="text-center">{drum.tare[0]}</td>
                                <td class="text-center">
                                  {drum.net_weight[0]}
                                </td>
                                <td class="text-center">
                                  {drum.solidification[0]}
                                </td>
                                <td class="text-center">{""}</td>
                                <td class="text-center">{""}</td>
                                <td class="text-center">{""}</td>
                                <td class="text-center">{""}</td>
                                <td class="text-center">
                                  {drum.provider_name[0]}
                                </td>
                                <td class="text-center">
                                  {drum.purchase_order_id[0]}
                                </td>
                                <td class="text-center">{drum.senasa[0]}</td>
                                <td class="text-center">{drum.comments[0]}</td>
                              </tr>
                            );
                          }
                        })}

                        <tr className="d-none">
                          <td class="text-center"></td>
                          <td class="text-center">324,932</td>
                          <td class="text-center">Aprobado</td>
                          <td class="text-center">2,0</td>
                          <td class="text-center">31 mm</td>
                          <td class="text-center">15,5%</td>
                          <td class="text-center">302,5</td>
                          <td class="text-center">18</td>
                          <td class="text-center">287</td>
                          <td class="text-center">Liquida</td>
                          <td class="text-center">???</td>
                          <td class="text-center">???</td>
                          <td class="text-center"></td>
                          <td class="text-center"></td>
                          <td class="text-center">
                            Carmisiano Fernando Damián
                          </td>
                          <td class="text-center">11474</td>
                          <td class="text-center">18080</td>
                          <td class="text-center"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabindex="0"
                >
                  <div class="table-responsive">
                    <div class="tool-table d-flex">
                      <a
                        href="#"
                        class="d-flex align-items-center justify-content-center me-2"
                      >
                        <img
                          src="../assets/images/icon-download.svg"
                          width="18px"
                        />
                      </a>
                      <a
                        href="#"
                        class="d-flex align-items-center justify-content-center"
                      >
                        <img
                          src="../assets/images/icon-print.svg"
                          width="18px"
                        />
                      </a>
                    </div>
                    <table
                      id="example"
                      class="table table-striped"
                      data-toggle="data-table"
                    >
                      <thead>
                        <tr>
                          <th class="text-center"></th>
                          <th class="text-center">Tambor</th>
                          <th class="text-center">Estado</th>
                          <th class="text-center">HMF</th>
                          <th class="text-center">Color</th>
                          <th class="text-center">Humedad</th>
                          <th class="text-center">Kg Bruto</th>
                          <th class="text-center">Kg Tara</th>
                          <th class="text-center">Kg Neto</th>
                          <th class="text-center">Solidificacion</th>
                          <th class="text-center">AM I</th>
                          <th class="text-center">AM II</th>
                          <th class="text-center">Glifosato</th>
                          <th class="text-center">FG</th>
                          <th class="text-center">Proveedor</th>
                          <th class="text-center">OC</th>
                          <th class="text-center">N° SENASA</th>
                          <th class="text-center">Observaciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {drums.drums.map((drum, index) => {
                          if (drums.h.indexOf(drum.id[0]) >= 0) {
                            return (
                              <tr>
                                <td class="text-center">
                                  <div className="form-check mb-3 w-100">
                                    <input
                                      type="checkbox"
                                      name="homogeneizar"
                                      onChange={(event) =>
                                        onChange(event, drum)
                                      }
                                      className="form-check-input m-0 my-1 me-1"
                                      checked={
                                        homogenize.drums_id.indexOf(
                                          drum.id[0]
                                        ) >= 0
                                          ? true
                                          : false
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      for="customCheck1"
                                    ></label>
                                  </div>
                                </td>
                                <td class="text-center">{drum.id}</td>
                                <td class="text-center">{drum.approved}</td>
                                <td class="text-center">{drum.hmf}</td>
                                <td class="text-center">{drum.color}</td>
                                <td class="text-center">{drum.moisture}</td>
                                <td class="text-center">{drum.gross_weight}</td>
                                <td class="text-center">{drum.tare}</td>
                                <td class="text-center">{drum.net_weight}</td>
                                <td class="text-center">
                                  {drum.solidification}
                                </td>
                                <td class="text-center">{""}</td>
                                <td class="text-center">{""}</td>
                                <td class="text-center">{""}</td>
                                <td class="text-center">{""}</td>
                                <td class="text-center">
                                  {drum.provider_name[0]}
                                </td>
                                <td class="text-center">
                                  {drum.purchase_order_id}
                                </td>
                                <td class="text-center">{drum.senasa[0]}</td>
                                <td class="text-center">{drum.comments}</td>
                              </tr>
                            );
                          }
                        })}
                        <tr className="d-none">
                          <td class="text-center">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="exampleCheck1"
                            />
                            <label
                              class="form-check-label"
                              for="exampleCheck1"
                            ></label>
                          </td>
                          <td class="text-center">324,932</td>
                          <td class="text-center">Aprobado</td>
                          <td class="text-center">2,0</td>
                          <td class="text-center">31 mm</td>
                          <td class="text-center">15,5%</td>
                          <td class="text-center">302,5</td>
                          <td class="text-center">18</td>
                          <td class="text-center">287</td>
                          <td class="text-center">Liquida</td>
                          <td class="text-center">???</td>
                          <td class="text-center">???</td>
                          <td class="text-center"></td>
                          <td class="text-center"></td>
                          <td class="text-center">
                            Carmisiano Fernando Damián
                          </td>
                          <td class="text-center">11474</td>
                          <td class="text-center">18080</td>
                          <td class="text-center"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class=" mt-3">
                  <CheckBox
                    onChange={onChange}
                    label={"Análisis extra"}
                    name={"analisis_extra"}
                    type={"checkbox"}
                    value={extraAnalyzed}
                  />
                </div>
                <hr />
                {extraAnalyzed ? (
                  <div
                    id="collapseOne"
                    class="accordion-collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="row">
                      <div class="col-12 card card-body">
                        <p class="mb-3 fw-bold">Azúcares</p>
                        <div class="row">
                          <div class="col d-flex">
                            <span class="me-3">Fructosa</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"fructosa"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.fructosa[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">Glucosa (G)</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"glucosa"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.glucosa[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">Sacarosa</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"sacarosa"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.sacarosa[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">F/G</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"fg"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.fg[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">F+G</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"f_g"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.f_g[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">G+H</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"g_h"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.g_h[0]}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 card card-body">
                        <p class="mb-3 fw-bold">Adulteraciones</p>
                        <div class="row">
                          <div class="col d-flex">
                            <span class="me-3">Miel adulterada</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"miel_adulterada"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.miel_adulterada[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">Δ (P-M)</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"p_m"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.p_m[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">RIM</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"rim"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.rim[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">RIP</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"rip"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.rip[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">%C4</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"c4"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.c4[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">Conductividad</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"conductividad"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.conductividad[0]}
                            />
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col d-flex">
                            <span class="me-3">Diastasa (Phadebas)</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"diastosa"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.diastosa[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">Color por HANNA</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"color_hanna"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.color_hanna[0]}
                            />
                          </div>

                          <div class="col-3 d-flex">
                            <span class="me-3">
                              Humedad por Refractòmetro (H)
                            </span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"humedad_refractometro"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.humedad_refractometro[0]}
                            />
                          </div>

                          <div class="col"></div>
                          <div class="col"></div>
                        </div>
                      </div>
                      <div class="col-12 card card-body">
                        <p class="mb-3 fw-bold">Antibióticos Kids AI</p>
                        <div class="row">
                          <div class="col d-flex">
                            <span class="me-3">SZ</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"sz"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.sz[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">SDM</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"sdm"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.sdm[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">SQ</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"sq"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.sq[0]}
                            />
                          </div>

                          <div class="col d-flex d-flex">
                            <span class="me-3">SMT</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"smt"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.smt[0]}
                            />
                          </div>

                          <div class="col d-flex d-flex">
                            <span class="me-3">SMX</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"smx"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.smx[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">ST</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"st"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.st[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">SS</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"ss"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.ss[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">SP</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"sp"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.sp[0]}
                            />
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col d-flex">
                            <span class="me-3">SM</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"sm"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.sm[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">SMM</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"smm"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.smm[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">SMP</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"smp"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.smp[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">SCP</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"scp"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.scp[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">DAPS</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"daps"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.daps[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">SD</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"sd"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.sd[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">TMP</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"tmp"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.tmp[0]}
                            />
                          </div>
                          <div class="col "></div>
                        </div>
                      </div>
                      <div class="col-12 card card-body">
                        <p class="mb-3 fw-bold">Antibióticos Kids AII</p>
                        <div class="row">
                          <div class="col d-flex">
                            <span class="me-3">QNL</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"qnl"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.qnl[0]}
                            />
                          </div>
                          <div class="col d-flex">
                            <span class="me-3">CEFT</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"ceft"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.ceft[0]}
                            />
                          </div>
                          <div class="col d-flex">
                            <span class="me-3">TAF</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"taf"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.taf[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">STR</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"str"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.str[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">TYL</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"tyl"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.tyl[0]}
                            />
                          </div>

                          <div class="col d-flex">
                            <span class="me-3">TCN</span>
                            <CheckBox
                              onChange={onChange}
                              label={""}
                              name={"tcn"}
                              type={"checkbox"}
                              value={formExtraAnalyzed.tcn[0]}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 card card-body">
                    <TextTarea
                  onChange={onChange}
                  label={"Notas"}
                  name={"comments"}
                  type={"text"}
                  value={formExtraAnalyzed.comments[0]}
                  validate={{ msg: "", validate: false }}
                />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div class="col-12 text-end">
                  <BtnAccept
                    onClick={onClick}
                    name={"crear"}
                    value={"Crear lote"}
                    loading={loading}
                    loadingMsg={"Creando...."}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-100 d-flex justify-content-center align-items-center min-vh-100">
            <Spinner />
          </div>
        )}
      </div>

      {modal ? (
        <CreateContractLotH
          setModal={setModal}
          drums={homogenize}
          contract={contract}
        />
      ) : (
        ""
      )}
    </main>
  );
};

export default CreateContractLot;
