import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import EdiTables from "../../../components/editables/EdiTables";
import Spinner from "../../../components/loads/Spinner";
import { authContext } from "../../../context/AuthContext";
import {
  drumState,
  fetchDrums,
  fetchDrumsHomogenize,
  fetchDrumsMerms,
} from "../../../slices/drums/Index";
import { ConcatDrums } from "../../../utils/ConcatDrums";
import FiltersDrums from "../../contracts/filters/FiltersDrums";
import CreateContractLotH from "../../lot/details/CreateContractLotH";

const DrumsMerms = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_DRUM } = values;

  const _drums = useSelector(drumState);
  const drums = _drums.drums_merms;

  const [form, setForm] = useState(null);

  const [params, setParams] = useState({});

  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState([]);

  const [h, setH] = useState([]);

  /********************************************************************/

  const campos = [
    {
      name: "TAMBOR",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ESTADO",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "HMF",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "COLOR",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "HUMEDAD",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "KG BRUTO",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "KG TARA",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "KG NETO",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "SOLIDIFICACION",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "AM I",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "AM II",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "GLISOFATO",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "FG",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "PROVEEDOR",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "O/C",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "Nº SENASA",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "OBSERVACIONES",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
  ];
  const order = [
    {
      content_name: "id",
      property: "drum_id",

      name: "id",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "approved",
      property: "approved",

      name: "approved",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "hmf",
      property: "hmf",

      name: "hmf",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "color",
      property: "color",

      name: "color",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "moisture",
      property: "moisture",

      name: "moisture",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "gross_weight",
      property: "gross_weight",

      name: "gross_weight",
      form: "texttarea",
      type: "text",
      disabled: true,
    },
    {
      content_name: "tare",
      property: "tare",

      name: "tare",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "net_weight",
      property: "net_weight",

      name: "net_weight",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "solidification",
      property: "solidification",

      name: "solidification",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "am_i",
      property: "am_i",

      name: "am_i",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "am_ii",
      property: "am_ii",

      name: "am_ii",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "glifosato",
      property: "glifosato",

      name: "glifosato",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "fg",
      property: "fg",

      name: "fg",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "provider_name",
      property: "provider_name",

      name: "provider_name",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "purchase_order_id",
      property: "purchase_order_id",

      name: "purchase_order_id",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "senasa",
      property: "senasa",

      name: "senasa",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "comments",
      property: "comments",

      name: "comments",
      form: "input",
      type: "text",
      disabled: true,
    },
  ];
  const contents = form ? form : [];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = drums
    ? drums
    : {
        current_page: 0,
        first_page_url: 0,
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: "",
        to: 0,
        total: 0,
      };

  const pagination = {
    type: 1,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  /********************************************************************/

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "homogeneizar":
        {
          let selection = select;
          let result = h;
          let element = contents[e.target.id];

          if (e.target.checked) {
            result.push(element.drum_id[0]);
            selection.push(element);
          } else {
            result.splice(result.indexOf(element.drum_id[0]), 1);
            selection = selection.filter(
              (item) => item.id[0] != element.drum_id[0]
            );
          }

          let setValue = form;
          setValue[e.target.id][e.target.name][0] = e.target.checked;

          setSelect([...selection]);
          setForm([...setValue]);
          return setH([...result]);
        }
        break;
    }
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "page":
        {
          let params = {
            url: value,
            analyzed: 1,
            homogenize: 1,
            with_contract: 0,
          };
          dispatch(fetchDrumsHomogenize(params));
        }
        break;
      case "crear_lote":
        {
          let send = {
            drums: select,
            h: h,
          };

          if (h.length == 0) {
            return Alerts({
              msg: "Alert",
              title: "Seleccione al menos un tambor",
              code: "401",
              type: "warning",
            });
          }

          setSelect(send);
          //dispatch(setSelectDrums(send));
          //setGuard("form_drums", send)

          return setModal(true);
        }
        break;
    }
  };

  useEffect(() => {
    if (!drums) {
      let params = {
        url: URL_INDEX_DRUM,
        only_fractioned: 1,
        only_decrease: 1,
      };
      dispatch(fetchDrumsMerms(params));
    }
  }, [id]);

  useEffect(() => {
    if (drums) {
      setForm(ConcatDrums(drums.data, [], h));
    }
  }, [drums]);

  //console.log(select);
  //console.log(i);
  //console.log(h);
  //console.log(drums);
  return (
    <main class="main-content">
      <div class="position-relative iq-banner">
        <div class="iq-navbar-header" style={{ height: "215px" }}>
          <div class="container-fluid iq-container">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <div class="box-img">
                      <img
                        src="/assets/images/barrel.svg"
                        alt="icono-contratos-activos"
                      />
                    </div>
                    <div>
                      <h1 class="ms-2 mb-0 mt-2">Tambores en mermas</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              class="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div class="conatiner-fluid content-inner mt-n5 py-0">
        <div class="row">
          <div class="col-12 mt-5 mb-3 d-flex flex-row justify-content-between">
            <div></div>
            <div>
              <label className="btn btn-primary d-none">
                Crear Lote
                <button
                  className="d-none"
                  name="crear_lote"
                  onClick={(e) => onClick(e)}
                ></button>
              </label>
            </div>
          </div>
          <div class="col-md-12 bg-grey rounded">
            <div class="row row-cols-1">
              <div class="col-12 mb-3">
                {/* Filters */}
                <FiltersDrums
                  setParams={setParams}
                  params={params}
                  dispatch={dispatch}
                />
              </div>

              <div className="col-md-12">
                <div class="card">
                  {drums ? (
                    <EdiTables
                      hidden_Filters={true}
                      campos={campos}
                      order={order}
                      contents={contents}
                      pagination={pagination}
                      onClick={onClick}
                      onChange={onChange}
                    />
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modal ? (
        <CreateContractLotH
          setModal={setModal}
          drums={select}
          //contract={contract}
        />
      ) : (
        ""
      )}
    </main>
  );
};

export default DrumsMerms;
