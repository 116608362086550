import { memo, useCallback } from "react";
import { format } from "date-fns";
import InputSearch from "../forms/InputSearch";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import EditHead from "./EditHead";
import EditFoot from "./EditFoot";
import EditBody from "./EditBody";

const EdiTables = ({
  hidden_Filters,
  //Elements
  campos,
  order,
  contents,
  special_contents,
  actions,
  actionSelect,
  pagination,

  onClick,
  onChange,
}) => {
  return (
    <div className="table-responsive p-0" id="tabla-contenido">
      {
        hidden_Filters
        ? ""
        : <div className="row pt-4 m-0 mb-3">
        <div className="col-md-6">
          <InputSearch
            onChange={onChange}
            uniqueKey={"inputSearch"}
            label={""}
            name={"search"}
            type={"text"}
            placeholder={"Busqueda por termino"}
            validate={{ msg: "", validate: false }}
            onClick={onClick}
          />
        </div>
        <div className="col-md-6">
          <div className="w-100 d-flex justify-content-end mb-3">
            <label class="btn p-1 d-flex align-items-center justify-content-center me-3">
              <img
                src="/assets/images/icon-download.svg"
                width="20px"
                title="Descargar XLS"
              />
              <ReactHTMLTableToExcel
                id="buttonDownloadExcel"
                className="d-none"
                table="tabla-general"
                filename={"reporte"}
                sheet="pagina 1"
                buttonText=""
              />
            </label>
            <label class="btn p-1 d-flex align-items-center justify-content-center">
              <img
                src="/assets/images/icon-print.svg"
                width="20px"
                title="imprimir"
              />
              <button
                className="d-none"
                name="download-print"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </div>
        </div>
      </div>
      }
      <table className="table table-hover p-0 m-0" id="tabla-general">
        <EditHead campos={campos} onClick={onClick} />
        <EditBody
          actionSelect={actionSelect}
          special_contents={special_contents}
          order={order}
          contents={contents}
          actions={actions}
          onClick={onClick}
          onChange={onChange}
        />
        {pagination ? (
          <EditFoot
            length={campos.length}
            pagination={pagination}
            onClick={onClick}
          />
        ) : (
          <tfoot className="border-0">
            <tr>
              <td className="border-0" colSpan={campos.length + 1}></td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default memo(EdiTables);
