import { useContext } from "react";
import { authContext } from "../context/AuthContext";


export const InsertProductsOrderPurchase = (form, products_details) => {
  const context = useContext(authContext);
  const { forms } = context;
  const { FORM_PRODUCTS,  } = forms;

  let productos = [[], "required"];
  let canjeables = [[], ""];

  products_details.map((product) => {

    /***********EXCHANGE****************/

    product.exchange_details.map((exchange) => {
      let exchanges = {
        //
        mas_de_50ml: [exchange.greater_than_50ml == 1? true : false, "required"],
        tipo: [exchange.exchange_type, "required"],
        unidad_canjeable: [exchange.units_to_exchange, "required"],
      };

      canjeables[0].push(exchanges);
    })

    
    /***********************************/

    let form = {
      producto: [product.product_id, "required"], //producto_id
      unidades: [product.units, ""], //units
      precio_por_kilo: [product.price_per_kilo, "required"], //price_per_kilo
      total_de_kilos: [product.total_kilos, "required"], //total_kilos

      bruto_unitario: [product.unit_gross_weight, ""], //unit bruto
      tara_unitario: [product.unit_tare, ""], //unit tara
      neto_unitario: [product.total_unit_weight, ""], //unit neto

      canjeo: [product.exchange == 1 ? true : false, ""], //exchange boolean
      canjeables: canjeables
    };

    productos[0].push(form);
  });

  //console.log(JSON.parse(JSON.stringify(form.productos[0])));
  //console.log(JSON.parse(JSON.stringify([FORM_PRODUCTS])));

  form.productos[0] = productos[0].length > 0 ? productos[0] : JSON.parse(JSON.stringify([FORM_PRODUCTS]));

  return form;
};
