import { format } from "date-fns";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import Spinner from "../../../components/loads/Spinner";
import clientAxios from "../../../config/ClientAxios";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { orderState } from "../../../slices/orderwithdrawal/Index";
import { Fill } from "../../../utils/Fill";
import ModalDrumsDetails from "../../drums/modals/ModalDrumsDetails";
import ModalOrderWithdrawalDetails from "../../logistics/modals/ModalOrderWithdrawalDetails";
import GoogleMaps from "../../maps/modals/GoogleMaps";

const OrderWithdrawalSubmitted = () => {
  /*********************************************** INITIAL STATE ***********************************************************/
  const context = useContext(authContext);
  const { values, forms, validations } = context;

  const { or_id } = useParams();
  const dispatch = useDispatch();

  const { URL_SHOW_WITHDRAWAL, URL_DELIVERED } = values;

  const _withdrawal_order = useSelector(orderState);
  //const withdrawal_details = _withdrawal_order.withdrawal_details;

  const [select, setSelect] = useState(null);
  const [withdrawal_details, setwithdrawalDetails] = useState(null);
  const [currentDetails, setCurrentDetails] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalDrumsDetails, setModalDrumDetails] = useState(false);
  const [modalMaps, setModalMaps] = useState(false);

  const { id, provider, date } = withdrawal_details
    ? withdrawal_details.details[currentDetails].purchase_order
    : {
        provider: {
          city: { name: "" },
          province: { name: "" },
          business_name: "",
        },
        date: "2000-01-01",
        id: 0,
      };
  const {
    carrier,
    warehouse,
    vehicle,
    dollars,
    pesos,
    priority,
    arrival_date,
  } = withdrawal_details
    ? withdrawal_details.withdrawal_order[0]
    : {
        carrier: "",
        warehouse: {
          name: "",
          address: "",
        },
        dollars: "",
        pesos: "",
        priority: "",
        vehicle: "",
        arrival_date: "2001-01-01 00:00:00",
      };
  /*********************************************** INITIAL STATE ***********************************************************/
  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "llegada":
        {
          //create form
          let formData = new FormData();
          formData.append("withdrawal_order_id", or_id);

          //
          const response = await ApiRest({
            url: URL_DELIVERED,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: format(new Date(), "H:mm:ss").toString() + "hs",
              code: "201",
              type: "arrival",
            });

            //cerrar modal
            //return setModal(false);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "next-oc":
        {
          if (currentDetails < withdrawal_details.details.length - 1) {
            setCurrentDetails(currentDetails + 1);
          }
        }
        break;
      case "back-oc":
        {
          if (currentDetails != 0) {
            setCurrentDetails(currentDetails - 1);
          }
        }
        break;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      let params = {
        withdrawal_order_id: or_id,
      };
      const response = await clientAxios.get(URL_SHOW_WITHDRAWAL, { params });
      setwithdrawalDetails(response.data);
    };
    //dispatch(fetchOrderWithdrawalDetails(params));

    fetch();
  }, [or_id]);
  return (
    <main className="main-content">
      <div className="position-relative iq-banner">
        <div className="container">
          <div className="card my-5 p-5">
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-start">
                <div className="pe-0">
                  <h1>
                    <strong>Detalle</strong>
                  </h1>
                </div>
                <div className="d-flex ms-2 px-0">
                  <a
                    href="#"
                    className="d-none d-flex align-items-center justify-content-center me-2"
                  >
                    <img
                      src="/assets/images/icon-download.svg"
                      width="18px"
                      loading="lazy"
                    />
                  </a>
                  <button
                    className="border-0 bg-transparent text-primary ms-3 d-flex align-items-center justify-content-center"
                    title="imprimir"
                    onClick={() => {
                      window.print()
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      class="bi bi-printer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <label className="btn btn-primary d-flex justify-content-center px-3">
                  {"Llegada a las " +
                    format(new Date(arrival_date), "HH:ii:ss").toString()}
                  <button
                    className="d-none"
                    name="llegada"
                    onClick={(event) => onClick(event)}
                  ></button>
                </label>
              </div>
            </div>
            <div>
              <div className="row mt-5">
                <div className="col-4 mb-5">
                  <h5 className="mb-2 text-secondary">O/C</h5>
                  <p className="text-dark">
                    {String(id).length >= 4 ? "OC " + id : "OC " + Fill(id, 4)}
                  </p>
                </div>
                <div className="col-4 mb-5">
                  <h5 className="mb-2 text-secondary">Fecha</h5>
                  <p className="text-dark">
                    {format(new Date(arrival_date), "d/M/yyyy").toString()}
                  </p>
                </div>
                <div className="col-4 mb-5">
                  <h5 className="mb-2 text-secondary">Proveedor</h5>
                  <p className="text-dark">{provider.business_name}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-4 mb-5">
                  <h5 className="mb-2 text-secondary">Email</h5>
                  <p className="text-dark">{provider.email}</p>
                </div>
                <div className="col-4 mb-5">
                  <h5 className="mb-2 text-secondary">Telefono de contacto</h5>
                  <p className="text-dark">{provider.telephone}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-4 mb-5">
                  <h5 className="mb-2 text-secondary">Ubicación</h5>
                  <p className="mb-0 text-dark">{provider.address}</p>
                  <p className="text-dark">
                    {provider.city.name}, {provider.province.name}.
                  </p>
                  <label className="nav-link">
                    <svg
                      className="me-2 org1"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2425_45417)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.7713 0.641029C19.8428 0.699699 19.9005 0.773527 19.94 0.857185C19.9796 0.940842 20.0001 1.03224 20 1.12478V18.6248C19.9999 18.7692 19.9498 18.9092 19.8582 19.0208C19.7666 19.1325 19.6391 19.209 19.4975 19.2373L13.2475 20.4873C13.1666 20.5034 13.0834 20.5034 13.0025 20.4873L6.875 19.2623L0.7475 20.4873C0.656861 20.5054 0.563332 20.5032 0.473651 20.4808C0.38397 20.4584 0.30037 20.4164 0.228874 20.3578C0.157378 20.2993 0.0997667 20.2255 0.0601897 20.142C0.0206127 20.0585 5.58159e-05 19.9672 0 19.8748L0 2.37478C8.72276e-05 2.23035 0.0501951 2.0904 0.141804 1.97874C0.233413 1.86708 0.360869 1.79059 0.5025 1.76228L6.7525 0.512279C6.83337 0.496115 6.91663 0.496115 6.9975 0.512279L13.125 1.73728L19.2525 0.512279C19.3431 0.494049 19.4366 0.496141 19.5263 0.518404C19.616 0.540667 19.6997 0.582548 19.7713 0.641029V0.641029ZM12.5 2.88728L7.5 1.88728V18.1123L12.5 19.1123V2.88728ZM13.75 19.1123L18.75 18.1123V1.88728L13.75 2.88728V19.1123ZM6.25 18.1123V1.88728L1.25 2.88728V19.1123L6.25 18.1123Z"
                          fill=""
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2425_45417">
                          <rect
                            width="20"
                            height="20"
                            fill="orange"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    VER EN MAPAS
                    <button
                      className="d-none"
                      name="ver_mapas"
                      onClick={(event) =>
                        setModalMaps({
                          direction: provider.address,
                          province: provider.province.name,
                          city: provider.city.name,
                        })
                      }
                    ></button>
                  </label>
                </div>
                <div className="col-4 mb-5">
                  <h5 className="mb-2 text-secondary">Destino</h5>
                  <p className="mb-0 text-dark">{warehouse.name}</p>
                  <p className="text-dark">{warehouse.address}</p>
                  <label className="nav-link">
                    <svg
                      className="me-2 org1"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2425_45417)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.7713 0.641029C19.8428 0.699699 19.9005 0.773527 19.94 0.857185C19.9796 0.940842 20.0001 1.03224 20 1.12478V18.6248C19.9999 18.7692 19.9498 18.9092 19.8582 19.0208C19.7666 19.1325 19.6391 19.209 19.4975 19.2373L13.2475 20.4873C13.1666 20.5034 13.0834 20.5034 13.0025 20.4873L6.875 19.2623L0.7475 20.4873C0.656861 20.5054 0.563332 20.5032 0.473651 20.4808C0.38397 20.4584 0.30037 20.4164 0.228874 20.3578C0.157378 20.2993 0.0997667 20.2255 0.0601897 20.142C0.0206127 20.0585 5.58159e-05 19.9672 0 19.8748L0 2.37478C8.72276e-05 2.23035 0.0501951 2.0904 0.141804 1.97874C0.233413 1.86708 0.360869 1.79059 0.5025 1.76228L6.7525 0.512279C6.83337 0.496115 6.91663 0.496115 6.9975 0.512279L13.125 1.73728L19.2525 0.512279C19.3431 0.494049 19.4366 0.496141 19.5263 0.518404C19.616 0.540667 19.6997 0.582548 19.7713 0.641029V0.641029ZM12.5 2.88728L7.5 1.88728V18.1123L12.5 19.1123V2.88728ZM13.75 19.1123L18.75 18.1123V1.88728L13.75 2.88728V19.1123ZM6.25 18.1123V1.88728L1.25 2.88728V19.1123L6.25 18.1123Z"
                          fill=""
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2425_45417">
                          <rect
                            width="20"
                            height="20"
                            fill="orange"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    VER EN MAPAS
                    <button
                      className="d-none"
                      name="ver_mapas"
                      onClick={(event) =>
                        setModalMaps({
                          direction: "",
                          province: "-27.4811600",
                          city: "-58.9394320",
                        })
                      }
                    ></button>
                  </label>
                </div>

                <div className="col-md-12">
                  {withdrawal_details ? (
                    <ul className="pagination">
                      <li className="page-item">
                        <label
                          className={
                            currentDetails == 0
                              ? "btn btn-outline-secondary border disabled"
                              : "btn btn-outline-secondary border"
                          }
                          title="Anterior O/C"
                        >
                          <span aria-hidden="true">«</span>
                          <button
                            className="d-none"
                            aria-label="Previous"
                            name="back-oc"
                            onClick={(event) => onClick(event)}
                          ></button>
                        </label>
                      </li>
                      <li className="page-item">
                        <label
                          className={
                            currentDetails <
                            withdrawal_details.details.length - 1
                              ? "btn btn-outline-secondary border"
                              : "btn btn-outline-secondary border disabled"
                          }
                          title="Siguiente O/C"
                        >
                          <span aria-hidden="true">»</span>
                          <button
                            className="d-none"
                            name="next-oc"
                            onClick={(event) => onClick(event)}
                          ></button>
                        </label>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className="text-dark">Detalles</p>
                <hr className="hrcolor" />
              </div>
              <div className="col-12 my-5">
                <table id="example" className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th className="text-center">Comprobante</th>
                      <th className="text-center">Tambores a retirar</th>
                      <th className="text-center">Cera a retirar</th>
                      <th className="text-center border-0 d-flex justify-content-center">
                        Observaciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-0">
                    {withdrawal_details ? (
                      withdrawal_details.details.map((order) => (
                        <tr>
                          <td className="border-0 border-bottom text-center">
                            OC {Fill(order.purchase_order.id, 4)}
                          </td>
                          <td className="border-0 border-bottom text-center">
                            {order.honey_drums_amount} unidades
                          </td>
                          <td className="border-0 border-bottom text-center">
                            {order.wax ? order.wax_amount : 0} kilos
                          </td>
                          <td className="border-0 border-bottom text-center">
                            {order.purchase_order.comments
                              ? order.purchase_order.comments
                              : ""}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colspan={4} className="border-0">
                          <Spinner />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className="text-dark">Entregas</p>
                <hr className="hrcolor" />
              </div>
              <div className="col-12 my-5">
                <table id="example" className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th className="text-center">Comprobante</th>
                      <th className="text-center">Insumo</th>
                      <th className="text-center">Cantidad</th>
                      <th className="text-center border-0 d-flex justify-content-center">
                        Observaciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-0">
                    {withdrawal_details ? (
                      withdrawal_details.details.map((order) => (
                        <>
                          {order.purchase_order.exchange_details.map(
                            (exchange) => (
                              <tr>
                                <td className="border-0 border-bottom text-center">
                                  OC {Fill(order.purchase_order.id, 4)}
                                </td>
                                <td className="border-0 border-bottom text-center">
                                  {exchange.exchange_type}
                                </td>
                                <td className="border-0 border-bottom text-center">
                                  {exchange.units_to_exchange}
                                </td>
                                <td className="border-0 border-bottom text-center">
                                  {order.purchase_order.comments
                                    ? order.purchase_order.comments
                                    : ""}
                                </td>
                              </tr>
                            )
                          )}
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colspan={4} className="border-0">
                          <Spinner />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalMaps ? (
        <GoogleMaps
          direction={modalMaps.direction}
          city={modalMaps.city}
          province={modalMaps.province}
          setModal={setModalMaps}
        />
      ) : (
        ""
      )}
    </main>
  );
};

export default OrderWithdrawalSubmitted;
