import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/loads/Spinner";
import clientAxios from "../../../config/ClientAxios";
import { authContext } from "../../../context/AuthContext";

const ClientDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const context = useContext(authContext);
  const { values } = context;

  const { URL_SHOW_CUSTOMER, URL_SHOW_CUSTOMER_CONTRACT } = values;

  const [client, setClient] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      let params = {
        id: id,
      };
      const response = await clientAxios.get(URL_SHOW_CUSTOMER_CONTRACT, { params });

      setClient(response.data);
    };

    fetch();
  }, []);

  console.log(client);
  return (
    <main className="main-content">
      <div className="position-relative iq-banner">
        {client ? (
          <div className="container">
            <div className="card my-5 p-5">
              <div className="d-flex justify-content-between">
                <div className="w-100 d-flex justify-content-between">
                  <div className="pe-0">
                    <h1>
                      <strong>{client.business_name}</strong>
                    </h1>

                    <div className="d-flex ms-2 px-0">
                      <a
                        href="#"
                        className="d-none d-flex align-items-center justify-content-center me-2"
                      >
                        <img
                          src="/assets/images/icon-download.svg"
                          width="18px"
                          loading="lazy"
                        />
                      </a>
                      <a
                        href="#"
                        className="d-none d-flex align-items-center justify-content-center"
                      >
                        <img
                          src="/assets/images/icon-print.svg"
                          width="18px"
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
              <div>
                <div className="row mt-5">
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">País de origen</h5>
                    <p className="text-dark">{client.city.name}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">Domicilio</h5>
                    <p className="text-dark">
                      {client.city.province.name +
                        ", " +
                        client.city.province.country.name +
                        ", " +
                        client.address}
                    </p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">Email</h5>
                    <p className="text-dark">{client.email}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <p className="text-dark">CONTRATOS</p>
                  <hr className="hrcolor" />
                </div>
                <div className="col-12 my-5">
                  <table
                    id="example"
                    className="table table-striped table-hover"
                  >
                    <thead>
                      <tr>
                        <th className="text-center">Nº DE CONTRATO</th>
                        <th className="text-center">CONTENEDORES A ENVIAR</th>
                        <th className="text-center">CONTENEDORES ENVIADOS</th>
                        <th className="text-center">ESTADO</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      {
                        client.contracts.map((contract) => (
                            <tr>
                              <td className="border-0">{contract.contract_number}</td>
                              <td className="border-0">{contract.units}</td>
                              <td className="border-0">{contract.contract_number}</td>
                              <td className="border-0">
                                <label className="btn btn-primary">
                                  {
                                    contract.active == 1
                                    ? "Activo"
                                    : "Finalizado"
                                  }
                                  <button className="d-none"
                                  onClick={(event) => navigate("/dashboard/clients/contract/"+contract.id)}></button>
                                </label>
                              </td>
                            </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="min-vh-100 d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        )}
      </div>
    </main>
  );
};

export default ClientDetails;
