import { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Alerts from "../../../../components/alerts/Alerts";
import Table from "../../../../components/tables/Table";
import { authContext } from "../../../../context/AuthContext";
import ApiRest from "../../../../hooks/useRest";
import { fetchAnalyzeHomogenize } from "../../../../slices/laboratory/Index";
import { ConcatExpoLot } from "../../../../utils/ConcatExpoLot";

const Homogenize = ({
  data,

  setParams,
  params,
  dispatch,
  setSelect,
  setModal,
  status,
}) => {
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_START_HOMOGENIZATION, URL_INDEX_HOMOGENIZATION } = values;

  const [loading, setLoading] = useState(false);
  /*********************************************** INITIAL STATE ***********************************************************/
  const campos = [
    {
      name: "Nº LOTE EXP",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "UNIDADES",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "KILOS DE MIEL",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ACCIONES",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-center align-items-center",
    },
  ];
  const order = ["name", "honey_drums_sector_three_count", "honey_drums_sector_three_sum_net_weight"];
  const contents = data ? ConcatExpoLot(data.data) : [];
  const actions = [
    {
      title: "Homogeneizar",
      name: "homogeneizar",
      icon: "",
      id: "homogeneizar",

      class:
        "btn btn-secondary d-flex align-items-center justify-content-center",
      text: "Homogeneizar",
    },
  ];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = data
    ? data
    : {
        current_page: 0,
        first_page_url: 0,
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: "",
        to: 0,
        total: 0,
      };
  const pagination = {
    type: 1,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  /**********************************************************************************************************************/

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "page":
        {
          let params = {
            url: value,
            consolidated: 0,
            analyzed: 0,
            homogenized: 0,
            order_by: "id",
            ordering: "desc",
          };
          dispatch(fetchAnalyzeHomogenize(params));
        }
        break;
      case "homogeneizar":
        {
          //console.log(value);

          let formData = new FormData();

          formData.append("export_lot_id", value.id);
          formData.append("_method", "PUT");
          //
          const response = await ApiRest({
            url: URL_START_HOMOGENIZATION,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Homogeneizando lote.",
              code: "201",
              type: "success",
            });

            //Actualizar datos providers
            let params = {
              url: URL_INDEX_HOMOGENIZATION,
              analyzed: 0,
              homogenized:   0,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchAnalyzeHomogenize(params));

            params = {
              url: URL_INDEX_HOMOGENIZATION,
              analyzed:  0,
              homogenized:   1,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchAnalyzeHomogenize(params));

            //cerrar modal
            return "";
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "download-xls":
        {
        }
        break;
      case "download-print":
        {
          return true;
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "":
        {
        }
        break;
    }
  };

  //console.log(data);
  return (
    <div class="card-body">
      <Table
        print={true}
        campos={campos}
        order={order}
        contents={contents}
        actions={actions}
        pagination={pagination}
        onClick={onClick}
        onChange={onChange}
      />
    </div>
  );
};

export default Homogenize;
