import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import EdiTables from "../../../components/editables/EdiTables";
import BtnAccept from "../../../components/forms/BtnAccept";
import Spinner from "../../../components/loads/Spinner";
import ModalSetFile from "../../../components/modals/ModalSetFile";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { fetchOrderEntry } from "../../../slices/orderEntry/Index";
import { Fill } from "../../../utils/Fill";
import {
  setForms_OrderEntry_drums,
  setForms_OrderEntry_wax,
} from "../../../utils/setForms";
import OrderEntry from "../Index";

const ModalOrderEntryTableDetails = ({
  dispatch,
  setModal,
  entryOrder,
  purchaseOrder,
}) => {
  const navigate = useNavigate();
  /*********************************************** INITIAL STATE ***********************************************************/
  const context = useContext(authContext);
  const { values, forms, validations } = context;

  const { URL_ENTRY, URL_CREATE_ENTRY, URL_INDEX_ENTRY, URL_DELIVERED_DRUM } =
    values;
  const { FORM_ENTRY_ORDER_DETAILS_DRUM, FORM_ENTRY_ORDER_DETAILS_WAX } = forms;
  const {
    VALIDATION_ENTRY_ORDER_DETAILS_DRUM,
    VALIDATION_ENTRY_ORDER_DETAILS_WAX,
  } = validations;

  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(50);

  const [form_drum, setFormDrum] = useState(null);
  const [validation_drum, setValidationDrum] = useState({
    ...VALIDATION_ENTRY_ORDER_DETAILS_DRUM,
  });

  const [form_wax, setFormWax] = useState(null);
  const [validation_wax, setValidationWax] = useState({
    ...VALIDATION_ENTRY_ORDER_DETAILS_WAX,
  });

  //const { provider_name, oc_name, or_name, purchase_order, id } = {};
  const [modalImageDrum, setModalImageDrum] = useState(false);
  const [modalImageWax, setModalImageWax] = useState(false);
  const [select, setSelect] = useState(null);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [contDrums, setContDrums] = useState(0);
  const [contProduct, setContProduct] = useState(0); //0 tambores 1 cera
  const [currentDetails, setCurrentDetails] = useState(0);
  const { drums, drums_amount, wax, wax_amount, purchase_order_id } = entryOrder
    ? entryOrder.details[currentDetails]
    : {
        drums_amount: 0,
        drums: [],
        wax: null,
        wax_amount: null,
        purchase_order: {
          provider: {
            city: { name: "" },
            province: { name: "" },
            business_name: "",
          },
          date: "",
          id: 0,
        },
      };

  const onChange = async (e, value) => {
    switch (e.target.name) {
      case "entregar_todos":
        {
          let setValue = form_drum;

          if (e.target.checked) {
            setValue.map((value, index) => {
              setValue[index]["entregado"][0] = true;
            });
          } else {
            setValue.map((value, index) => {
              setValue[index]["entregado"][0] = false;
            });
          }

          return setFormDrum([...setValue]);
        }
        break;
      case "tambor_entregado":
        {
        
          let setValue = form_drum;
          let item = setValue[e.target.id]["tambor_id"][0];
          let entregado = setValue[e.target.id]["entregado"][0] ? 1 : 2;

          let formData = new FormData();
          formData.append("_method", "PUT");
          formData.append("honey_drums[0][id]", item);
          formData.append("honey_drums[0][state]", entregado);

          const response = await ApiRest({
            url: URL_DELIVERED_DRUM,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            let params = {
              url: URL_INDEX_ENTRY,
              order_state_id: 1,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchOrderEntry(params));

            params = {
              url: URL_INDEX_ENTRY,
              order_state_id: 2,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchOrderEntry(params));

            Alerts({
              msg: "Alert",
              title: entregado == 2 ? "Tambor entregado" : "Tambor sin entregar",
              code: "201",
              type: "exito",
            });

            setValue[e.target.id]["entregado"][0] = entregado == 2 ? true : false ;

            return setFormDrum([...setValue]);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "neto":
      case "bruto":
      case "tara":
        {
          let setValue = form_drum;
          setValue[e.target.id][e.target.name][0] = e.target.value;

          if (e.target.name == "neto")
            setValue[e.target.id]["bruto"][0] =
              parseFloat(e.target.value) +
              parseFloat(
                setValue[e.target.id]["tara"][0]
                  ? setValue[e.target.id]["tara"][0]
                  : 0
              );
          if (e.target.name == "tara")
            setValue[e.target.id]["neto"][0] =
              -parseFloat(e.target.value) +
              parseFloat(
                setValue[e.target.id]["bruto"][0]
                  ? setValue[e.target.id]["bruto"][0]
                  : 0
              );

          setFormDrum([...setValue]);
        }
        break;
      case "imagenes":
        {
          let setValue = form_drum;

          setValue[e.target.id][e.target.name + "_url"][0] = [
            ...setValue[e.target.id][e.target.name + "_url"][0],
            URL.createObjectURL(e.target.files[0]),
          ];

          setValue[e.target.id][e.target.name][0] = [
            ...setValue[e.target.id][e.target.name][0],
            e.target.files[0],
          ];

          setValue[e.target.id]["cantidad_archivos"][0] =
            setValue[e.target.id]["imagenes_url"][0].length + " Imagenes";

          setFormDrum([...setValue]);
        }
        break;
      case "codigo_geomiel":
        {
          console.log(form_drum)
          let setValue = form_drum;
          setValue[e.target.id][e.target.name][0] = e.target.value;

         

          setFormDrum([...setValue]);
        }
        break
      case "estiba":
      case "tipo_tambor":
      case "observacion":
      case "codigo_senasa":
        {
          let setValue = form_drum;
          setValue[e.target.id][e.target.name][0] = e.target.value;

          setFormDrum([...setValue]);
        }
        break;
      /* Form wax */
      case "cera_entregado":
        {
          let setValue = form_wax;
          setValue[e.target.id][e.target.name][0] = e.target.checked;

          setFormWax([...setValue]);
        }
        break;
      case "observacion_wax":
        {
          let setValue = form_wax;
          setValue[e.target.id]["observacion"][0] = e.target.value;

          setFormWax([...setValue]);
        }
        break;
      case "imagenes_wax":
        {
          let setValue = form_wax;

          setValue[e.target.id]["imagenes_url"][0] = [
            ...setValue[e.target.id]["imagenes_url"][0],
            URL.createObjectURL(e.target.files[0]),
          ];

          setValue[e.target.id]["imagenes"][0] = [
            ...setValue[e.target.id]["imagenes"][0],
            e.target.files[0],
          ];

          setValue[e.target.id]["cantidad_archivos"][0] =
            setValue[e.target.id]["imagenes_url"][0].length + " Imagenes";

          setFormWax([...setValue]);
        }
        break;
      case "unidad":
      case "operculo":
      case "borra":
      case "recupero":
      case "peso_unidad":
        {
          let setValue = form_wax;
          setValue[e.target.id][e.target.name][0] = e.target.value;

          setFormWax([...setValue]);
        }
        break;
    }
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "close":
        /* */
        //setGuard(ORDER_WITHDRAWAL_DRUMS_DETAILS, JSON.stringify(form_drum));
        //setGuard(ORDER_WITHDRAWAL_WAX_DETAILS, JSON.stringify(form_wax));

        setModal(false);
        break;
      case "modal_file_drums":
        {
          setSelect(value);
          setModalImageDrum(true);
        }
        break;
      case "modal_file_wax":
        {
          setSelect(value);
          setModalImageWax(true);
        }
        break;
      case "imagenes_url_wax":
        {
          let setValue = form_wax;

          delete setValue[e.target.id][e.target.name][0][value];

          delete setValue[e.target.id]["imagenes"][0][value];

          setValue[e.target.id][e.target.name][0][value].filter(
            (item) => item != null
          );
          setValue[e.target.id]["imagenes"][0][value].filter(
            (item) => item != null
          );

          setFormWax([...setValue]);
        }
        break;
      case "imagenes_url":
        {
          let setValue = form_drum;

          delete setValue[e.target.id][e.target.name][0][value];

          delete setValue[e.target.id]["imagenes"][0][value];

          setValue[e.target.id][e.target.name][0][value].filter(
            (item) => item != null
          );
          setValue[e.target.id]["imagenes"][0][value].filter(
            (item) => item != null
          );

          setFormDrum([...setValue]);
        }
        break;
      case "aceptar":
        {
          let products = [];
          let validation;

          if (form_drum.length > 0) {
            form_drum.map((form) => {
              //Delete property
              //validation = Validate(form);
              //setValidationDrum(validation);
              //Validaciones
              if(form.entregado[0]){
                products.push(form);
              }

            });

            /*
                if (validation.error.validate) {
                  return Alerts({
                    msg: "Warning",
                    title: validation.error.msg,
                    code: "201",
                    type: "error",
                  });
                }
                */
          }

          let validation_wax;

          if (form_wax.length > 0) {
            form_wax.map((wax) => {
              //Delete property
              //validation_wax = Validate(wax);
              //setValidationWax(validation_wax);

              products.push(wax);
            });

            //Validaciones
            /*
                if (validation_wax.error.validate) {
                  return Alerts({
                    msg: "Warning",
                    title: validation_wax.error.msg,
                    code: "201",
                    type: "error",
                  });
                }
                */
          }

          //Armar formulario
          /*
              let formData = {
                withdrawal_order_id: withdrawal_details.withdrawal_order[0].id,
                products: [],
              };
              */

          let resultProducts = [];

          let formData = new FormData();
          formData.append("purchase_order_id", purchaseOrder.id);

          products.map((product, index) => {
            let formProduct = {};
            if (product.producto_id[0] == 1) {
              formData.append(
                "products[" + index + "][product_id]",
                product.producto_id[0]
              );

              formData.append(
                "products[" + index + "][senasa_code]",
                product.codigo_senasa[0]
              );

              formData.append(
                "products[" + index + "][drum_height]",
                product.tipo_tambor[0]
              );

              formData.append(
                "products[" + index + "][geomiel_code]",
                product.codigo_geomiel[0]
              );

              formData.append(
                "products[" + index + "][stowage]",
                product.estiba[0]
              );

              formData.append("products[" + index + "][tare]", product.tara[0]);

              formData.append(
                "products[" + index + "][total_weight]",
                product.neto[0]
              );
              formData.append(
                "products[" + index + "][gross_weight]",
                product.bruto[0]
              );
              formData.append(
                "products[" + index + "][honey_drum_id]",
                product.tambor_id[0]
              );
              formData.append(
                "products[" + index + "][comments]",
                product.observacion[0]
              );

              product.imagenes[0].map((img, index) => {
                formData.append(
                  "products[" + index + "][images][" + index + "]",
                  img
                );
              });
            } else {
              formData.append(
                "products[" + index + "][product_id]",
                product.producto_id[0]
              );
              formData.append(
                "products[" + index + "][wax_id]",
                product.cera_id[0]
              );
              formData.append(
                "products[" + index + "][kilos_entered]",
                product.kilos_ingresados[0]
              );
              formData.append(
                "products[" + index + "][kilos_operculo]",
                product.operculo[0]
              );
              formData.append(
                "products[" + index + "][kilos_borra]",
                product.borra[0]
              );
              formData.append(
                "products[" + index + "][kilos_recupero]",
                product.recupero[0]
              );
              formData.append(
                "products[" + index + "][comments]",
                product.observacion[0]
              );

              product.imagenes[0].map((img, index) => {
                formData.append(
                  "products[" + index + "][images][" + index + "]",
                  img
                );
              });
            }

            resultProducts.push(formProduct);
          });

          /*
              formData = {
                ...formData,
                products: resultProducts,
              };
              */

          //formData.append("exchange", form.provincia[0]);

          const response = await ApiRest({
            url: URL_CREATE_ENTRY,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Realizado exitosamente",
              code: "201",
              type: "exito",
            });

            //clearGuard(ORDER_WITHDRAWAL_DRUMS_DETAILS);
            //clearGuard(ORDER_WITHDRAWAL_WAX_DETAILS);

            let params = {
              url: URL_INDEX_ENTRY,
              order_state_id: 1,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchOrderEntry(params));

            params = {
              url: URL_INDEX_ENTRY,
              order_state_id: 2,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchOrderEntry(params));

            /* Close Modal */
            setModal(false);
            return navigate("/dashboard/orderentry/submitted");
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "next":
        {
          if (currentPage < form_drum.length) {
            setPage(page + 50);
            setCurrentPage(currentPage + 50);
          }
        }
        break;
      case "back":
        {
          if (page !== 0) {
            setPage(page - 50);
            setCurrentPage(currentPage - 50);
          }
        }
        break;
    }
  };

  const campos = [
    {
      name: "O/C",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "PRODUCTO",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ID",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ENTREGADO",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",

      input: {
        name: "entregar_todos",
        type: "checkbox",
        onChange: onChange,
      },
    },
    {
      name: "TIPO TAMBOR",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ESTIBA",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "CODIGO SENASA",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "CODIGO GEOMIEL",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "PESO INICIAL",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "BRUTO",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "TARA",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "NETO",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "OBSERVACION",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "IMAGENES",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
  ];
  const order = [
    {
      content_name: "purchase_order",
      property: "purchase_order",

      name: "purchase_order",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "producto_nombre",
      property: "producto_nombre",

      name: "producto_nombre",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "tambor_id",
      property: "tambor_id",

      name: "id",
      form: "input",
      type: "number",
      disabled: true,
    },
    {
      property: "entregado",

      name: "tambor_entregado",
      text: "Si",
      form: "checkbox",
      type: "checkbox",
      disabled: false,
    },
    {
      property: "tipo_tambor",

      name: "tipo_tambor",
      form: "select",
      type: "select",
      selectValue: [{ name: "Petiso" }, { name: "Alto" }],
      disabled: false,
    },
    {
      property: "estiba",

      name: "estiba",
      form: "select",
      type: "select",
      selectValue: [{ name: "A" }, { name: "B" }, { name: "C" }],
      disabled: false,
    },
    {
      content_name: "codigo_senasa",
      property: "codigo_senasa",

      name: "codigo_senasa",
      form: "input",
      type: "text",
      disabled: false,
    },
    {
      content_name: "codigo_geomiel",
      property: "codigo_geomiel",

      name: "codigo_geomiel",
      form: "input",
      type: "text",
      disabled: false,
    },
    {
      content_name: "peso_inicial",
      property: "peso_inicial",

      name: "peso_inicial",
      form: "input",
      type: "number",
      disabled: true,
    },
    {
      content_name: "bruto",
      property: "bruto",

      name: "bruto",
      form: "input",
      type: "number",
      disabled: false,
    },
    {
      content_name: "tara",
      property: "tara",

      name: "tara",
      form: "input",
      type: "number",
      disabled: false,
    },
    {
      content_name: "neto",
      property: "neto",

      name: "neto",
      form: "input",
      type: "number",
      disabled: false,
    },
    {
      content_name: "observacion",
      property: "observacion",

      name: "observacion",
      form: "texttarea",
      type: "text",
      disabled: false,
    },
    {
      content_name: "imagenes",
      property: "cantidad_archivos",

      name: "modal_file_drums",
      form: "btn",
      type: "file",
      disabled: false,
    },
  ];
  const contents = form_drum ? form_drum.slice(page, currentPage) : [];

  /* Form wax */

  const campos_wax = [
    {
      name: "O/C",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "PRODUCTO",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ID",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "KILOS INGRESADOS",
      order: true,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "OPÉRCULO",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "RECUPERO",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "BORRA",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "IMAGENES",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ARCHIVOS",
      order: false,
      class: "border-0 d-flex justify-content-start align-items-center",
    },
  ];
  const order_wax = [
    {
      content_name: "purchase_order",
      property: "purchase_order",

      name: "purchase_order",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "producto_nombre",
      property: "producto_nombre",

      name: "producto_nombre",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "cera_id",
      property: "cera_id",

      name: "id",
      form: "input",
      type: "number",
      disabled: true,
    },
    {
      property: "kilos_ingresados",

      name: "kilos_ingresados",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "operculo",
      property: "operculo",

      name: "operculo",
      form: "input",
      type: "number",
      disabled: false,
    },
    {
      content_name: "recupero",
      property: "recupero",

      name: "recupero",
      form: "input",
      type: "number",
      disabled: false,
    },
    {
      content_name: "borra",
      property: "borra",

      name: "borra",
      form: "input",
      type: "number",
      disabled: false,
    },
    {
      content_name: "imagenes",
      property: "cantidad_archivos",

      name: "modal_file_wax",
      form: "btn",
      type: "file",
      disabled: false,
    },
  ];
  
  const contents_wax = entryOrder ? form_wax : [];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = {
    current_page: 0,
    first_page_url: 0,
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  };
  const pagination = {
    type: null,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };
  /**********************************************************************************************************************/

  /* Set forms */
  useEffect(() => {
    if (entryOrder) {
      console.log('entryOrder',entryOrder)
      setFormWax(setForms_OrderEntry_wax(entryOrder.details));
      setFormDrum(setForms_OrderEntry_drums(entryOrder.details));
    }
  }, [entryOrder]);

  return (
    <div
      className="modal fade show bg-dark bg-opacity-25  modal-transition bg-modal-blur"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        style={{ maxWidth: "2000px" }}
      >
        <div className="modal-content bg-modal-blanco">
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div className="modal-header p-5">
            <h1 className="modal-title bold" id="nueva-ordenModalLabel">
              <strong>
                O/C N°{" "}
                {String(purchaseOrder.id).length > 4
                  ? purchaseOrder.id
                  : Fill(purchaseOrder.id, 4)}
              </strong>
            </h1>
          </div>
          <div className="modal-body p-5">
            <div className="row">
              <div className="col text-center mb-5">
                <div className="detail">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="text-dark text-start">
                        N° Orden de retiro
                      </span>
                      <p className="text-start mt-1 mb-0">
                        {entryOrder.withdrawal_order[0]
                          ? String(entryOrder.withdrawal_order[0].id).length > 4
                            ? entryOrder.withdrawal_order[0].id
                            : Fill(entryOrder.withdrawal_order[0].id, 4)
                          : "No existe orden de retiro"}
                      </p>
                    </div>
                    <img
                      src="/assets/images/icon-ordenes-de-compra.svg"
                      className="ms-2"
                      width="50px"
                      height="50px"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col text-center mb-5 px-5">
                <div className="detail">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="text-dark text-start pe-5">
                        Apicultor
                      </span>
                      <p className="text-start mt-1 mb-0 text-nowrap">
                        {purchaseOrder.provider.business_name}
                      </p>
                    </div>
                    <img
                      src="/assets/images/icon-provedor.svg"
                      className="ms-5"
                      width="50px"
                      height="50px"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            {form_drum ? (
              form_drum.length <= currentPage - page ? (
                ""
              ) : (
                <div className="d-flex justify-content-end mb-3">
                  <label className="btn btn-primary me-2">
                    Anterior
                    <button
                      className="d-none"
                      name="back"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                  <label className="btn btn-primary">
                    Siguiente
                    <button
                      className="d-none"
                      name="next"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                </div>
              )
            ) : (
              ""
            )}

            <div className="mb-4">
              {form_drum ? (
                <EdiTables
                  hidden_Filters={true}
                  campos={campos}
                  order={order}
                  contents={contents}
                  pagination={pagination}
                  onClick={onClick}
                  onChange={onChange}
                />
              ) : (
                <div>
                  {" "}
                  <Spinner />{" "}
                </div>
              )}
            </div>

            {form_drum ? (
              form_drum.length <= currentPage - page ? (
                ""
              ) : (
                <div className="d-flex justify-content-end mb-3">
                  <label className="btn btn-primary me-2">
                    Anterior
                    <button
                      className="d-none"
                      name="back"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                  <label className="btn btn-primary">
                    Siguiente
                    <button
                      className="d-none"
                      name="next"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                </div>
              )
            ) : (
              ""
            )}

            <div className="mb-4">
              {form_wax ? (
                <EdiTables
                  hidden_Filters={true}
                  campos={campos_wax}
                  order={order_wax}
                  contents={contents_wax}
                  pagination={pagination}
                  onClick={onClick}
                  onChange={onChange}
                />
              ) : (
                <div>
                  {" "}
                  <Spinner />{" "}
                </div>
              )}
            </div>

            <div className="w-100 d-flex justify-content-end">
              <div className="btn-group">
                <BtnAccept
                  onClick={onClick}
                  name={"aceptar"}
                  value={"Aceptar"}
                  loading={loading}
                  loadingMsg={"Generando..."}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalImageDrum && form_drum ? (
        <ModalSetFile
          setModal={setModalImageDrum}
          name={"imagenes"}
          setSelect={setSelect}
          index={select}
          form={form_drum}
          setForm={setFormDrum}
          onChange={onChange}
        />
      ) : (
        ""
      )}

      {modalImageWax && form_wax ? (
        <ModalSetFile
          setModal={setModalImageWax}
          name={"imagenes_wax"}
          setSelect={setSelect}
          index={select}
          form={form_wax}
          setForm={setFormWax}
          onChange={onChange}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ModalOrderEntryTableDetails;
