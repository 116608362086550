export const VALIDATIONS = {
  VALIDATION_ORDER_PURCHASE: {
  comprador: { validate: false, msg: "" }, //buyer
  metodo_pago: { validate: false, msg: "" }, //payment_method_id
  observacion: { validate: false, msg: "" }, //comments
  fecha: { validate: false, msg: "" }, //date
  proveedor: { validate: false, msg: "" }, //provider_id
  proveedor_id: { validate: false, msg: "" }, //
  cuit: { validate:false, msg: ""},
  prepa: { validate: false, msg: "" }, //prepaid
  canjeo: { validate: false, msg: "" }, //exchange boolean

  imagenes: { validate: false, msg: "" }, //producto_id
  productos: { validate: false, msg: "" }, //products boolean
  canjeables: { validate: false, msg: "" }, //exchanges

  //Campos de productos
  producto: { validate: false, msg: "" }, //producto_id
  unidades: { validate: false, msg: "" },//units
  precio_por_kilo: { validate: false, msg: "" }, //price_per_kilo
  total_de_kilos: { validate: false, msg: "" },//total_kilos

  bruto_unitario: { validate: false, msg: "" },//unit gross weight
  tara_unitario: { validate: false, msg: "" }, //unit tare
  neto_unitario: { validate: false, msg: "" },//unit neto
  },

  VALIDATION_PRODUCTS: {
    producto: { validate: false, msg: "" }, //producto_id
    unidades: { validate: false, msg: "" },//units
    precio_por_kilo: { validate: false, msg: "" }, //price_per_kilo
    total_de_kilos: { validate: false, msg: "" },//total_kilos
  
    bruto_unitario: { validate: false, msg: "" },//unit gross weight
    tara_unitario: { validate: false, msg: "" }, //unit tare
    neto_unitario: { validate: false, msg: "" },//unit neto
  },

  VALIDATION_EXCHANGES:{
    mas_de_50ml: { validate: false, msg: "" }, //greate_than_50ml
    tipo: { validate: false, msg: "" }, //exchange_type
    unidades: { validate: false, msg: "" }, //units_to_exchange
  },

  VALIDATION_PROVIDERS: {
    razon_social: { validate: false, msg: "" }, //False paso la validacion || True validacion no pasada
    cuit: { validate: false, msg: "" },
    direccion: { validate: false, msg: "" },
    provincia: { validate: false, msg: "" },
    localidad: { validate: false, msg: "" },
    telefono: { validate: false, msg: "" },
    email: { validate: false, msg: "" },
    inicio_actividad: { validate: false, msg: "" },
    colmenas: { validate: false, msg: "" },
    observaciones: { validate: false, msg: "" },
  },

  VALIDATION_EQUIVALENCE:{
    insumo: { validate: false, msg: "" }, //supply
    producto: { validate: false, msg: "" }, //product
    cantidad_producto: { validate: false, msg: "" }, //qunatity_product
    cantidad_insumo: { validate: false, msg: "" }, //quantity_supply
    mas_de_50ml: { validate: false, msg: "" }, //greater_than_50ml
  },

  VALIDATION_ORDER_WITHDRAWAL:{
    orden_compra_id: { validate: false, msg: "" }, //Orden de compra id

    prioritario: { validate: false, msg: "" },
    destino: { validate: false, msg: "" },
    vehiculo: { validate: false, msg: "" },
    transportista: { validate: false, msg: "" },

    pesos_entregados: { validate: false, msg: "" },
    dolares_entregados: { validate: false, msg: "" },

    fecha: { validate: false, msg: "" },
  },

  VALIDATION_ORDER_DETAILS_DRUM:{
    //Tambor
    producto_id:{ validate: false, msg: "" }, //product_id
    tambor_entregado: { validate: false, msg: "" }, //Delivery
    tipo_tambor: { validate: false, msg: "" },
    codigo_senasa: { validate: false, msg: "" }, //senasa_code
    codigo_geomiel: { validate: false, msg: "" }, //geomiel_code
    tara: { validate: false, msg: "" }, //tare
    bruto: { validate: false, msg: "" }, //gross_weight
    neto: { validate: false, msg: "" }, //total_weight
    
    tambor_id: { validate: false, msg: "" }, //honey_drum_id
    observacion: { validate: false, msg: "" }, //comments
    imagenes: { validate: false, msg: "" }, //images
  },

  VALIDATION_ORDER_DETAILS_WAX:{
    //Cera
    cera_id: { validate: false, msg: "" }, //wax_id
    unidad: { validate: false, msg: "" }, //Units
    peso_unidad: { validate: false, msg: "" }, //weight_per_unit
    observacion: { validate: false, msg: "" }, //comments
    imagenes: { validate: false, msg: "" }, //images
  },

  VALIDATION_VEHICLE: {
    modelo: { validate: false, msg: "" },
    patente: { validate: false, msg: "" },
  },

  VALIDATION_USERS: {
    rol: { validate: false, msg: "" },
    cuit: { validate: false, msg: "" },
    nombre: { validate: false, msg: "" },
    email: { validate: false, msg: "" },
    direccion: { validate: false, msg: "" },
    provincia: { validate: false, msg: "" },
    localidad: { validate: false, msg: "" },
    telefono: { validate: false, msg: "" },
    observaciones: { validate: false, msg: "" },
    password: { validate: false, msg: "" },
  },

  /* Order Entry Details */

  
  /* Order Entry Details Drums*/
   VALIDATION_ENTRY_ORDER_DETAILS_DRUM: {
    codigo_senasa: { validate: false, msg: "" },
    codigo_geomiel: { validate: false, msg: "" },
    tipo_tambor: { validate: false, msg: "" },
    peso_inicial: { validate: false, msg: "" },
    neto: { validate: false, msg: "" },
    bruto: { validate: false, msg: "" },
    tara: { validate: false, msg: "" },
    estiba: { validate: false, msg: "" },
    observacion: { validate: false, msg: "" },
    imagenes: { validate: false, msg: "" }, //images
  },

  /* Order Entry Details Wax*/
   VALIDATION_ENTRY_ORDER_DETAILS_WAX: {
    kilos_ingresados: { validate: false, msg: "" },
    operculo: { validate: false, msg: "" },
    recupero: { validate: false, msg: "" },
    borra: { validate: false, msg: "" },
    observacion: { validate: false, msg: "" },
    imagenes: { validate: false, msg: "" }, //images
  },

  /* ANALYZE LOT */
  VALIDATION_ANALYZE_LOT: {
    lote_id: { validate: false, msg: "" }, //lot_analysis_id
    dextrinas: { validate: false, msg: "" }, //dextrins
    tipo_medicion_hmf: { validate: false, msg: "" }, //measurement_type_hmf
    //measurement_type_hmf
    valor_hmf: { validate: false, msg: "" },//hmg_value
    fructuosa: { validate: false, msg: "" },//fructose
    glucosa: { validate: false, msg: "" },//glucose
    sacarosa: { validate: false, msg: "" },//sucrose
    fg: { validate: false, msg: "" },//fg
    f_g: { validate: false, msg: "" },//f+g
    g_h: { validate: false, msg: "" }, //G+H
    miel_adulterada: { validate: false, msg: "" },//adulteres_honey
    p_m: { validate: false, msg: "" },//p-m
    rim: { validate: false, msg: "" },//rim
    rip: { validate: false, msg: "" },//rip
    c4: { validate: false, msg: "" },//%c4
    diastasa: { validate: false, msg: "" },//diastose
    conductividad: { validate: false, msg: "" },//conductivity
    color_por_hanna: { validate: false, msg: "" },//table_by_hanna
    humedad_refractometro: { validate: false, msg: "" },//moisture_by_refractometry
    sz: { validate: false, msg: "" },//sz
    smt: { validate: false, msg: "" },//smt
    ss: { validate: false, msg: "" },//ss
    smm:{ validate: false, msg: "" },//smm
    daps: { validate: false, msg: "" },//daps
    sdm: { validate: false, msg: "" },//sdm
    smx: { validate: false, msg: "" },//smx
    sp: { validate: false, msg: "" },//sp
    smp: { validate: false, msg: "" },//smp
    sd: { validate: false, msg: "" },//sd
    sq: { validate: false, msg: "" },//sq
    st: { validate: false, msg: "" },//st
    sm: { validate: false, msg: "" },//sm
    scp: { validate: false, msg: "" },//scp
    tmp: { validate: false, msg: "" },//tmp
    qnl: { validate: false, msg: "" },//qnl
    str: { validate: false, msg: "" },//str
    ceft: { validate: false, msg: "" },//ceft
    tyl: { validate: false, msg: "" },//tyl
    taf: { validate: false, msg: "" },//taf
    tcn: { validate: false, msg: "" },//tcm
    observaciones: { validate: false, msg: "" },//comments
  },

  
  /* Clients */
  VALIDATION_CLIENT: {
    empresa: { validate: false, msg: "" },
    pais: { validate: false, msg: "" },
    direccion: { validate: false, msg: "" },
    provincia: { validate: false, msg: "" },
    ciudad: { validate: false, msg: "" },
    email: { validate: false, msg: "" },
    condicion: { validate: false, msg: "" },
    observacion: { validate: false, msg: "" },
  },

  
  /* Contract */
  VALIDATION_CONTRACT: {
    numero_contrato: { validate: false, msg: "" },//contract_number
    cliente: { validate: false, msg: "" },//customer_id
    pais: { validate: false, msg: "" },//country_id
    color: { validate: false, msg: "" },//color
    fecha_envio: { validate: false, msg: "" },//shipment_date
    producto: { validate: false, msg: "" },//product_id
    kilos: { validate: false, msg: "" },//kilos
    unidad: { validate: false, msg: "" },//units
    condiciones_venta: { validate: false, msg: "" },//sales_condition
    precio_final: { validate: false, msg: "" },//full_price
    precio_argentina: { validate: false, msg: "" },//arg_price
    condicion_completa: { validate: false, msg: "" },//condition_full
    condicion_argentina: { validate: false, msg: "" },//condition_arg
    flete: { validate: false, msg: "" },//cfr_freight
  },

  /* Asignem Contract */

  VALIDATION_ASIGNEM_CONTRACT: {
    id: { validate: false, msg: "" },
    contrato: { validate: false, msg: "" },
    cliente: { validate: false, msg: "" },
    destino: { validate: false, msg: "" },
    color: { validate: false, msg: "" },
    fecha_embarque:{ validate: false, msg: "" },
  },

 /* Supply  */

 VALIDATION_FORM_SUPPLY: {
  nombre: { validate: false, msg: "" },
  detalle: { validate: false, msg: "" },
  nombre_modelo: { validate: false, msg: "" },
  precio_venta: { validate: false, msg: "" },
},

/* Stocks */

VALIDATION_FORM_STOCK: {
  modelo_id: { validate: false, msg: "" },
  fecha: { validate: false, msg: "" },
  metodo_pago: { validate: false, msg: "" },
  precio_venta: { validate: false, msg: "" },
  numero: { validate: false, msg: "" },
  unidad: { validate: false, msg: "" },
  precio_unidad: { validate: false, msg: "" },
  observacion: { validate: false, msg: "" },
},

// Estado de cuenta 

VALIDATION_ESTADO_CUENTA:{
  transaction:{ validate: false, msg: "" },
  receipt: { validate: false, msg: "" },
  date: { validate: false, msg: "" },
  type: { validate: false, msg: "" },
  applied_balance: { validate: false, msg: "" },
  item: { validate: false, msg: "" },
  provider_id: { validate: false, msg: "" },
  kilograms: { validate: false, msg: "" },
  supply_id: { validate: false, msg: "" },
  selling_price: { validate: false, msg: "" },
  units: { validate: false, msg: "" },
  price_per_kg: { validate: false, msg: "" },
  price_per_unit: { validate: false, msg: "" },
  equivalence_id: { validate: false, msg: "" },
}, 
VALIDATION_DESTINO:{
  name:{ validate: false, msg: "" },
  adress: { validate: false, msg: "" },
  city_id: { validate: false, msg: "" }
  
},


}
