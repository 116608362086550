import { useState } from "react";
import Input from "../../components/forms/Input";
import Papa from "papaparse";
import BtnAccept from "../../components/forms/BtnAccept";
import Alerts from "../../components/alerts/Alerts";
import ApiRest from "../../hooks/useRest";
import { useNavigate } from "react-router-dom";
import { th } from "date-fns/locale";

const MasiveStore = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    honey_drums: [],
    orders_purchase: [],
    lots_analysis: [],
    lots_export: [],
    contracts: [],
    orders_purchase_analysis: []
  });
  const allowedExtensions = ["csv"];

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "collapse": {
        let id = value.accordion;
        let btn = value.btn;
        if (document.getElementById(id).classList.contains('show')) {
          document.getElementById(id).classList.remove('show');
          document.getElementById(btn).classList.remove('collapsed');
        } else {
          document.getElementById(id).classList.add('show');
          document.getElementById(btn).classList.add('collapsed');
        }
      } break;
      case "guardar": {
        let formData = new FormData;

        data.lots_analysis.map((la, indexLA) => {
          //Lote de analisis
          formData.append('lots_analysis[' + indexLA + '][purchase_order_code]', la.purchase_code);
          formData.append('lots_analysis[' + indexLA + '][lot_analysis_code]', la.lot_analysis_code);
          formData.append('lots_analysis[' + indexLA + '][lot_export_code]', la.export_lot_code);
          formData.append('lots_analysis[' + indexLA + '][analysis_date]', la.analysis_date);
          formData.append('lots_analysis[' + indexLA + '][dextrins]', la.dextrins);
          formData.append('lots_analysis[' + indexLA + '][measurement_type_hmf]', la.measurement_type_hmf);
          formData.append('lots_analysis[' + indexLA + '][hmf_value]', la.hmf_value);
          formData.append('lots_analysis[' + indexLA + '][fructose]', la.fructose);
          formData.append('lots_analysis[' + indexLA + '][glucose]', la.glucose);
          formData.append('lots_analysis[' + indexLA + '][sucrose]', la.sucrose);
          formData.append('lots_analysis[' + indexLA + '][p-m]', la['p-m']);
          formData.append('lots_analysis[' + indexLA + '][fg]', la.lt_fg);
          formData.append('lots_analysis[' + indexLA + '][sdm]', la.sdm);
          formData.append('lots_analysis[' + indexLA + '][f+g]', la['f+g']);
          formData.append('lots_analysis[' + indexLA + '][g+h]', la['g+h']);
          formData.append('lots_analysis[' + indexLA + '][adulterated_honey]', la.adulterated_honey);
          formData.append('lots_analysis[' + indexLA + '][rim]', la.rim);
          formData.append('lots_analysis[' + indexLA + '][rip]', la.rip);
          formData.append('lots_analysis[' + indexLA + '][%c4]', la['%c4']);
          formData.append('lots_analysis[' + indexLA + '][diastose]', la.diastose);
          formData.append('lots_analysis[' + indexLA + '][conductivity]', la.conductivity);
          formData.append('lots_analysis[' + indexLA + '][table_by_hanna]', la.table_by_hanna);
          formData.append('lots_analysis[' + indexLA + '][moisture_by_refractometry]', la.moisture_by_refractometry);
          formData.append('lots_analysis[' + indexLA + '][smt]', la.smt);
          formData.append('lots_analysis[' + indexLA + '][ss]', la.ss);
          formData.append('lots_analysis[' + indexLA + '][smm]', la.smm);
          formData.append('lots_analysis[' + indexLA + '][daps]', la.daps);
          formData.append('lots_analysis[' + indexLA + '][smx]', la.smx);
          formData.append('lots_analysis[' + indexLA + '][sp]', la.sp);
          formData.append('lots_analysis[' + indexLA + '][sz]', la.sz);
          formData.append('lots_analysis[' + indexLA + '][smp]', la.smp);
          formData.append('lots_analysis[' + indexLA + '][sd]', la.sd);
          formData.append('lots_analysis[' + indexLA + '][sq]', la.sq);
          formData.append('lots_analysis[' + indexLA + '][st]', la.st);
          formData.append('lots_analysis[' + indexLA + '][sm]', la.sm);
          formData.append('lots_analysis[' + indexLA + '][scp]', la.scp);
          formData.append('lots_analysis[' + indexLA + '][tmp]', la.tmp);
          formData.append('lots_analysis[' + indexLA + '][qnl]', la.qnl);
          formData.append('lots_analysis[' + indexLA + '][str]', la.str);
          formData.append('lots_analysis[' + indexLA + '][ceft]', la.ceft);
          formData.append('lots_analysis[' + indexLA + '][tyl]', la.tyl);
          formData.append('lots_analysis[' + indexLA + '][taf]', la.taf);
          formData.append('lots_analysis[' + indexLA + '][tcn]', la.tcn);

          la.honey_drums.map((hd, indexHD) => {
            formData.append('lots_analysis[' + indexLA + '][honey_drums][' + indexHD + '][geomiel_code]', hd.geomiel_code);
          })
        })



        data.contracts.map((ct, indexCT) => {

          formData.append('contracts[' + indexCT + '][contract_number]', ct.contract_number);
          formData.append('contracts[' + indexCT + '][customer_id]', ct.customer_id);
          formData.append('contracts[' + indexCT + '][country_name]', ct.country_name);
          formData.append('contracts[' + indexCT + '][full_price]', ct.full_price);
          formData.append('contracts[' + indexCT + '][arg_price]', ct.arg_price);
          formData.append('contracts[' + indexCT + '][color]', ct.ct_color);
          formData.append('contracts[' + indexCT + '][condition_full]', ct.condition_full);
          formData.append('contracts[' + indexCT + '][condition_arg]', ct.condition_arg);
          formData.append('contracts[' + indexCT + '][cfr_freight]', ct.cfr_freight);
          formData.append('contracts[' + indexCT + '][sales_condition]', ct.sales_condition);
          formData.append('contracts[' + indexCT + '][shipment_date]', ct.shipment_date);

          ct.lots_export.map((d, indexLE) => {
            //Lote de exportacion
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][lot_export_code]', d.export_lot_code);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][color]', d.lt_color);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][purchase_order_code]', d.purchase_code);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][name]', d.name);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][consolidation_date]', d.consolidation_date + " 00:00:00");
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][booking]', d.booking);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][permission]', d.permission);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][isf]', d.isf);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][analysis_name]', d.analysis_name);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][received]', d.received);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][sae]', d.sae);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][senasa_lot]', d.senasa_lot);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][senasa_seal]', d.senasa_seal);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][container]', d.container);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][ana_seal]', d.ana_seal);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][line_seal]', d.line_seal);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][tsh_seal]', d.tsh_seal);

            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][full_price]', d.lt_full_price);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][arg_price]', d.lt_arg_price);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][cfr_full]', d.lt_cfr_full);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][cfr_arg]', d.lt_cfr_arg);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][cfr_freight]', d.lt_cfr_freight);

            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][primary_color]', d.primary_color);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][secondary_color]', d.secondary_color);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][color_description]', d.color_description);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][checked]', d.checked);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][fully_fractionated]', d.fully_fractionated);
            formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][finished]', d.finished);
            //Ordenes de compra
            d.orders_purchase.map((oc, indexOC) => {
              formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][purchase_order_code]', oc.purchase_code);
              formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][price_per_kilo]', oc.price_per_kilo);
              formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][lot_export_code]', oc.export_lot_code);
              formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][buyer]', oc.buyer);
              formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][date]', oc.date + " 00:00:00");
              formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][order_state_id]', oc.order_state_id);
              formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][provider_id]', oc.provider_id);
              formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][prepaid]', oc.prepaid);
              formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][payment_method_id]', oc.payment_method_id);
              formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][warehouse_id]', oc.warehouse_id);
              //Tambores de miel
              oc.honey_drums.map((hd, indexHD) => {
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][purchase_code]', hd.purchase_code);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][export_lot_code]', hd.export_lot_code);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][lot_analysis_code]', hd.lot_analysis_code);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][state_id]', hd.state_id);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][sector_id]', hd.sector_id);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][net_weight]', hd.net_weight);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][tare]', hd.tare);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][gross_weight]', hd.gross_weight);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][comments]', hd.comments);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][color]', hd.color);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][moisture]', hd.moisture);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][adulteration]', hd.adulteration);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][solidification]', hd.solidification);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][file]', hd.file);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][geomiel_code]', hd.geomiel_code);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][senasa_code]', hd.senasa_code);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][homogenize]', hd.homogenize);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][hmf]', hd.hmf);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][am1]', hd.am1);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][am2]', hd.am2);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][approved]', 1);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][glyphosate]', hd.glyphosate);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][fg]', hd.fg);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][homogenization_analyzed]', hd.homogenization_analyzed);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][homogenized]', hd.homogenized);
                formData.append('contracts[' + indexCT + '][lots_export][' + indexLE + '][order_purchase][' + indexOC + '][honey_drums][' + indexHD + '][type]', hd.type);
              })
            })

          })
        })

        const response = await ApiRest({
          url: 'bulkload',
          formData: formData,
          setLoading: setLoading,
          method: "post",
        });

        //Error
        if (response.status == 201 || response.status == 200) {
          Alerts({
            msg: "Alert",
            title: "Carga de datos exitosa.",
            code: "201",
            type: "success",
          });

          return setData({
            ...{
              honey_drums: [],
              orders_purchase: [],
              lots_analysis: [],
              lots_export: [],
              contracts: [],
              orders_purchase_analysis: []
            }
          })
        }
      } break;
    }
  }

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "file": {
        const input_file = e.target.files[0];
        const fileExtension = input_file?.type.split("/")[1];
        if (!allowedExtensions.includes(fileExtension)) {
          console.log("Ingrese un Archivo CSV");
          return;
        }

        if (!input_file) return console.log("Ingrese un Archivo Valido");

        const reader = new FileReader();

        reader.onload = async ({ target }) => {
          const csv = Papa.parse(target.result, { header: true });
          const parsedData = csv?.data;

          let honey_drums = [];
          let orders_purchase = [];
          let lots_export = [];
          let lots_analysis = [];
          let orders_purchase_analysis = [];
          let contracts = []
          parsedData.map((pD, index) => {
            let honey_drum = {};
            let order_purchase = {};
            let lot_export = {};
            let lot_analysis = {};
            let contract = {};

            Object.keys(pD).map((key, indexKey) => {

              if (
                key == "contract_number" ||
                key == "shipment_date" ||
                key == "customer_id" ||
                key == "ct_color" ||
                key == "country_name" ||
                key == "full_price" ||
                key == "arg_price" ||
                key == "condition_full" ||
                key == "condition_arg" ||
                key == "cfr_freight" ||
                key == "sales_condition"
              ) {
                contract = {
                  ...contract,
                  [key]: pD[key],
                  lots_export: []
                }
              }

              if (
                key == "export_lot_code" ||
                key == "name" ||
                key == "consolidation_date" ||
                key == "booking" ||
                key == "container" ||
                key == "permission" ||
                key == "isf" ||
                key == "analysis_name" ||
                key == "received" ||
                key == "settled" ||
                key == "sae" ||
                key == "senasa_lot" ||
                key == "senasa_seal" ||
                key == "ana_seal" ||
                key == "line_seal" ||
                key == "tsh_seal" ||
                key == "lt_color" ||
                key == "lt_full_price" ||
                key == "lt_arg_price" ||
                key == "lt_cfr_full" ||
                key == "lt_cfr_arg" ||
                key == "lt_cfr_freight" ||
                //key == "lt_fg" ||
                key == "primary_color" ||
                key == "secondary_color" ||
                key == "color_description" ||
                key == "lt_labeled" ||
                key == "checked" ||
                key == "fully_fractionated" ||
                key == "finished" ||
                key == "contract_number"
              ) {
                lot_export = {
                  ...lot_export,
                  [key]: pD[key],
                  orders_purchase: []
                }
              }


              if (
                key == "export_lot_code" ||
                key == "purchase_code" ||
                key == "lot_analysis_code" ||
                key == "state_id" ||
                //key == "sector_id" ||
                key == "net_weight" ||
                key == "tare" ||
                key == "gross_weight" ||
                key == "comments" ||
                key == "color" ||
                key == "moisture" ||
                key == "adulteration" ||
                key == "solidification" ||
                key == "file" ||
                key == "geomiel_code" ||
                key == "senasa_code" ||
                key == "homogenize" ||
                key == "hmf" ||
                key == "am1" ||
                key == "am2" ||
                key == "glyphosate" ||
                key == "fg" ||
                key == "homogenization_analyzed" ||
                key == "homogenized" ||
                key == "type"
              ) {
                honey_drum = {
                  ...honey_drum,
                  [key]: pD[key]
                }
              }

              if (
                key == "price_per_kilo" ||
                key == "buyer" ||
                key == "date" ||
                key == "purchase_code" ||
                key == "order_state_id" ||
                key == "provider_id" ||
                key == "prepaid" ||
                key == "payment_method_id" ||
                key == "warehouse_id" ||

                key == "export_lot_code" ||
                key == "lot_analysis_code"
              ) {
                order_purchase = {
                  ...order_purchase,
                  [key]: pD[key],
                  honey_drums: [],
                  lots_analysis: []
                }
              }



              if (
                key == "purchase_code" ||
                key == "export_lot_code" ||
                key == "lot_analysis_code" ||
                key == "analysis_date" ||
                key == "dextrins" ||
                key == "measurement_type_hmf" ||
                key == "hmf_value" ||
                key == "fructose" ||
                key == "glucose" ||
                key == "sucrose" ||
                key == "lt_fg" ||
                key == "f+g" ||
                key == "g+h" ||
                key == "adulterated_honey" ||
                key == "rim" ||
                key == "rip" ||
                key == "%c4" ||
                key == "diastose" ||
                key == "conductivity" ||
                key == "table_by_hanna" ||
                key == "moisture_by_refractometry" ||
                key == "smt" ||
                key == "ss" ||
                key == "smm" ||
                key == "daps" ||
                key == "sdm" ||
                key == "smx" ||
                key == "sp" ||
                key == "smp" ||
                key == "sd" ||
                key == "sz" ||
                key == "sq" ||
                key == "st" ||
                key == "sm" ||
                key == "scp" ||
                key == "tmp" ||
                key == "qnl" ||
                key == "str" ||
                key == "ceft" ||
                key == "tyl" ||
                key == "taf" ||
                key == "p-m" ||
                key == "tcn"
              ) {
                lot_analysis = {
                  ...lot_analysis,
                  [key]: pD[key],
                  honey_drums: []
                }
              }
            })

            honey_drums.push(honey_drum);

            if (
              lots_analysis.filter((l) => l.lot_analysis_code == lot_analysis.lot_analysis_code && l.purchase_code == lot_analysis.purchase_code).length == 0
            ) {
              lots_analysis.push(lot_analysis);
            }

            if (
              orders_purchase_analysis.filter((o) => o.purchase_code == order_purchase.purchase_code).length == 0
            ) {
              orders_purchase_analysis.push(order_purchase);
            }

            if (
              orders_purchase.filter((o) => o.purchase_code == order_purchase.purchase_code && o.export_lot_code == order_purchase.export_lot_code).length == 0
            ) {
              orders_purchase.push(order_purchase);
            }

            if (
              lots_export.filter((l) => l.export_lot_code == lot_export.export_lot_code).length == 0
            ) {
              lots_export.push(lot_export);
            }

            if (
              contracts.filter((l) => l.contract_number == contract.contract_number).length == 0
            ) {
              contracts.push(contract);
            }
          })



          //Agrupar por lote de exportacion
          orders_purchase_analysis.map((o, index) => {
            lots_analysis.map((la, indexLA) => {
              if (la.purchase_code == o.purchase_code) {
                o.lots_analysis.push(la)
              }
            })
          });

          orders_purchase.map((o, index) => {
            honey_drums.map((h) => {
              if (h.purchase_code == o.purchase_code && h.export_lot_code == o.export_lot_code) {
                o.honey_drums.push(h)
              }
            })
          });

          lots_export.map((l, index) => {
            orders_purchase.map((o) => {
              if (o.export_lot_code == l.export_lot_code) {
                l.orders_purchase.push(o)
              }
            })
          })

          contracts.map((c, index) => {
            lots_export.map((l) => {
              if (l.contract_number == c.contract_number) {
                c.lots_export.push(l);
              }
            })
          })

          lots_analysis.map((la, index) => {
            honey_drums.map((h) => {
              if (h.purchase_code == la.purchase_code && h.lot_analysis_code == la.lot_analysis_code) {
                la.honey_drums.push(h)
              }
            })
          });

          setData({
            ...data,
            honey_drums: honey_drums,
            orders_purchase: orders_purchase,
            lots_export: lots_export,
            lots_analysis: lots_analysis,
            contracts: contracts,
            orders_purchase_analysis: orders_purchase_analysis
          });
        };

        reader.readAsText(input_file);
      } break;
    }
  }

  console.log(data);
  return (
    <div class="card my-5 p-5">
      <div class="row">
        <div className="col-md-12 mb-5">
          <label className="btn btn-primary">
            <i class="bi bi-arrow-90deg-left"></i>
            <button className="d-none"
              onClick={(event) => navigate("/dashboard/home")}></button>
          </label>
        </div>
        <div class="col-md-12 d-flex justify-content-between align-items-center pb-4">
          <div className="d-flex">
            <h1>Carga masiva de datos</h1>
          </div>
        </div>

        <div className="col-md-12 mb-4">
          <Input
            onChange={onChange}
            label={"Archivo de datos CSV"}
            name={"file"}
            type={"file"}
            validate={{ validate: false, msg: "" }}
          />
        </div>

        <div className="col-md-12 ">
          <h3 className="my-3">Lista de contratos</h3>
        </div>

        {
          data.contracts.map((ct, indexCT) => (
            <div class="accordion my-3" id={"contract-" + indexCT}>
              <div class="accordion-item">
                <h2 class="accordion-header" id={"header-contract-" + indexCT}>
                  <label class="accordion-button" type="button" data-bs-toggle="collapse" aria-expanded="true"
                    id={"btn-contract-" + indexCT}>
                    <h5>{ct.contract_number}</h5>
                    <button
                      className="d-none"
                      name="collapse"
                      onClick={(event) => onClick(event, { accordion: "body-contract-" + indexCT, btn: "btn-contract-" + indexCT })}>
                    </button>
                  </label>
                </h2>
                <div
                  id={"body-contract-" + indexCT}
                  class="accordion-collapse collapse border border-primary"
                  aria-labelledby="headingTwo"
                  data-bs-parent={"#accordion-" + indexCT}
                >

                  <div class="accordion-body px-2 py-2">

                    <div className="table-responsive p-0">
                      <table className="table table-hover p-0 m-0" id="tabla-general">
                        <thead>
                          <tr>
                            <th>NUMERO DE CONTRATO</th>
                            <th>FECHA DE EMBARQUE</th>
                            <th>CLIENTE ID</th>
                            <th>COLOR</th>
                            <th>PAIS</th>
                            <th>PRECIO FULL</th>
                            <th>PRECIO ARG</th>
                            <th>CONDICION FULL</th>
                            <th>CONDICION ARG</th>
                            <th>FLETE</th>
                            <th>CONDICION DE VENTA</th>
                          </tr>
                        </thead>
                        <tbody className="">
                          <tr>
                            {
                              Object.keys(ct).map((key) => {
                                if (key !== "lots_export") {
                                  return (
                                    <td>{ct[key]}</td>
                                  )
                                }
                              })
                            }
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h6 className="text-muted mt-4 mb-3">Lotes de exportación</h6>
                    {
                      ct.lots_export.map((lt, indexLE) => (
                        <div class="accordion my-3" id={"lot-export-" + indexLE}>
                          <div class="accordion-item">
                            <h2 class="accordion-header " id={"header-lot-export-" + indexLE}>
                              <label class="accordion-button" type="button" data-bs-toggle="collapse" aria-expanded="true"
                                id={"btn-lot-export-" + indexLE + "-" + indexCT}>
                                <h5>
                                  {lt.export_lot_code}</h5>
                                <button
                                  className="d-none"
                                  name="collapse"
                                  onClick={(event) => onClick(event, { accordion: "body-lot-export-" + indexLE + "-" + indexCT, btn: "btn-lot-export-" + indexLE + "-" + indexCT })}
                                >
                                </button>
                              </label>
                            </h2>
                            <div id={"body-lot-export-" + indexLE + "-" + indexCT} class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent={"#accordion-" + indexLE}>
                              <div class="accordion-body px-2 py-2">

                                <div className="table-responsive p-0">
                                  <table className="table table-hover p-0 m-0" id="tabla-general">
                                    <thead>
                                      <tr>
                                        <th>CODIGO DE LOTE EXPORTACIÓN</th>
                                        <th>NOMBRE</th>
                                        <th>FECHA DE CONSOLIDACIÓN</th>
                                        <th>RESERVA</th>
                                        <th>CODIGO CONTENEDOR</th>
                                        <th>PERMISO</th>
                                        <th>ISF</th>
                                        <th>NOMBRE ANALISIS</th>
                                        <th>RECIBIDO</th>
                                        <th>ESTABLECIDO</th>
                                        <th>SAE</th>
                                        <th>LOTE SENASA</th>
                                        <th>SELLO SENASA</th>
                                        <th>SELLO ANA</th>
                                        <th>SELLO DE LINEA</th>
                                        <th>SELLO TSH</th>
                                        <th>COLOR</th>
                                        <th>PRECIO FULL</th>
                                        <th>PRECIO ARG</th>
                                        <th>CRF FULL</th>
                                        <th>CRF ARG</th>
                                        <th>CRF FLETE</th>
                                        <th>COLOR PRIMARIO</th>
                                        <th>COLOR SECUNDARIO</th>
                                        <th>COLOR DESCRIPCION</th>
                                        <th>ETIQUETADO</th>
                                        <th>CHECK</th>
                                        <th>FRACCIONAMIENTO COMPLETO</th>
                                        <th>FINALIZADO</th>
                                        <th>NUMERO DE CONTRATO</th>
                                      </tr>
                                    </thead>
                                    <tbody className="">
                                      <tr>
                                        {
                                          Object.keys(lt).map((key) => {
                                            if (key !== "orders_purchase") {
                                              return (
                                                <td>{lt[key]}</td>
                                              )
                                            }
                                          })
                                        }
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                <h6 className="text-muted mt-4 mb-3">Ordenes de compra</h6>

                                {
                                  lt.orders_purchase.map((oc, indexOC) => (
                                    <div class="accordion my-3" id={"order-purchase-" + indexOC}>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header " id={"header-order-purchase-" + indexOC}>
                                          <label class="accordion-button" type="button" data-bs-toggle="collapse" aria-expanded="true"
                                            id={"btn-order-purchase-" + indexOC + "-" + indexLE + "-" + indexCT}>
                                            <h5>
                                              {oc.purchase_code}</h5>
                                            <button
                                              className="d-none"
                                              name="collapse"
                                              onClick={(event) => onClick(event, { accordion: "body-order-purchase-" + indexOC + "-" + indexLE + "-" + indexCT, btn: "btn-order-purchase-" + indexOC + "-" + indexLE + "-" + indexCT })}
                                            >
                                            </button>
                                          </label>
                                        </h2>
                                        <div id={"body-order-purchase-" + indexOC + "-" + indexLE + "-" + indexCT} class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent={"#accordion-" + indexOC}>
                                          <div class="accordion-body px-2 py-2">

                                            <div className="table-responsive p-0">
                                              <table className="table table-hover p-0 m-0" id="tabla-general">
                                                <thead>
                                                  <tr>
                                                    <th>PRECIO POR KILO</th>
                                                    <th>COMPRADOR</th>
                                                    <th>FECHA</th>
                                                    <th>CODIGO DE OC</th>
                                                    <th>ESTADO ID</th>
                                                    <th>PROVEEDOR ID</th>
                                                    <th>PAGO POR ADELANTADO</th>
                                                    <th>METODO DE PAGO</th>
                                                    <th>DEPOSITO</th>
                                                    <th>CODIGO DE LOTE ANALISIS</th>
                                                    <th>CODIGO DE LOTE EXPORTACIÓN</th>
                                                  </tr>
                                                </thead>
                                                <tbody className="">
                                                  <tr>
                                                    {
                                                      Object.keys(oc).map((keyOC) => {
                                                        if (keyOC !== "honey_drums" && keyOC !== "lots_analysis") {
                                                          return (
                                                            <td>{oc[keyOC]}</td>
                                                          )
                                                        }
                                                      })
                                                    }
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>

                                            <h6 className="text-muted mt-4 mb-3">Tambores de miel</h6>

                                            <div className="table-responsive p-0">
                                              <table className="table table-hover p-0 m-0" id="tabla-general">
                                                <thead>

                                                  <tr>
                                                    <th>Adulteraciones</th>
                                                    <th>AM1</th>
                                                    <th>AM2</th>
                                                    <th>COLOR</th>
                                                    <th>OBSERVACIONES</th>
                                                    <th>CODIGO DE LOTE EXPORTACION</th>
                                                    <th>FG</th>
                                                    <th>ARCHIVO</th>
                                                    <th>CODIGO GEOMIEL</th>
                                                    <th>GLIFOSATO</th>
                                                    <th>HMF</th>
                                                    <th>ANALISIS HOMOGENEIZACION</th>
                                                    <th>ENVIADO A HOMOGENEIZACION</th>
                                                    <th>HOMOGENEIZADO</th>
                                                    <th>CODIGO DE LOTE ANALISIS</th>
                                                    <th>HUMEDAD</th>

                                                    <th>PESO TARA</th>
                                                    <th>PESO NETO</th>
                                                    <th>PESO BRUTO</th>
                                                    <th>PROVEEDOR ID</th>

                                                    <th>CODIGO DE OC</th>
                                                    <th>CODIGO SENASA</th>
                                                    <th>SOLIDIFACION</th>
                                                    <th>ESTADO</th>
                                                    <th>TIPO</th>
                                                  </tr>
                                                </thead>
                                                <tbody className="">
                                                  {
                                                    oc.honey_drums.map((hd) => {
                                                      return (
                                                        <tr>
                                                          <td>{hd.adulteration}</td>
                                                          <td>{hd.am1}</td>
                                                          <td>{hd.am2}</td>
                                                          <td>{hd.color}</td>
                                                          <td>{hd.comments}</td>
                                                          <td>{hd.export_lot_code}</td>
                                                          <td>{hd.fg}</td>
                                                          <td>{hd.file}</td>
                                                          <td>{hd.geomiel_code}</td>
                                                          <td>{hd.glyphosate}</td>
                                                          <td>{hd.hmf}</td>
                                                          <td>{hd.homogenization_analyzed}</td>
                                                          <td>{hd.homogenize}</td>
                                                          <td>{hd.homogenized}</td>
                                                          <td>{hd.lot_analysis_code}</td>
                                                          <td>{hd.moisture}</td>

                                                          <td>{hd.tare}</td>
                                                          <td>{hd.net_weight}</td>
                                                          <td>{hd.gross_weight}</td>
                                                          <td>{hd.provider_id}</td>

                                                          <td>{hd.purchase_code}</td>
                                                          <td>{hd.senasa_code}</td>
                                                          <td>{hd.solidification}</td>
                                                          <td>{hd.state_id}</td>
                                                          <td>{hd.type}</td>
                                                        </tr>
                                                      )
                                                    })
                                                  }
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>

                </div>
              </div>
            </div>
          ))
        }


        <div className="col-md-12 ">
          <h3 className="my-3">Lista de lotes de analisis</h3>
        </div>


        {
          data.orders_purchase_analysis.map((oc, indexOC) => {
            return (
              <div className="my-3">
                <span>{"OC " + oc.purchase_code}</span>
                {
                  oc.lots_analysis.map((la, indexLA) => (
                    <div class="accordion my-3" id={"lot-analysis-" + indexLA}>
                      <div class="accordion-item">
                        <h2 class="accordion-header " id={"header-lot-analysis-" + indexLA + "-" + indexOC}>
                          <label class="accordion-button" type="button" data-bs-toggle="collapse" aria-expanded="true"
                            id={"btn-lot-analysis-" + indexLA + "-" + indexOC}>
                            <h5>
                              {"LOTE " + la.lot_analysis_code}</h5>
                            <button
                              className="d-none"
                              name="collapse"
                              onClick={(event) => onClick(event, { accordion: "body-lot-analysis-" + indexLA + "-" + indexOC, btn: "btn-lot-analysis-" + indexLA + "-" + indexOC })}
                            >
                            </button>
                          </label>
                        </h2>
                        <div id={"body-lot-analysis-" + indexLA + "-" + indexOC} class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent={"#accordion-" + indexLA + "-" + indexOC}>
                          <div class="accordion-body px-2 py-2">

                            <div className="table-responsive p-0">
                              <table className="table table-hover p-0 m-0" id="tabla-general">
                                <thead>
                                  <tr>
                                    <th>CODIGO OC</th>
                                    <th>CODIGO LOTE</th>
                                    <th>FECHA ANALISIS</th>
                                    <th>DEXTRINAS</th>
                                    <th>TIPO DE MEDIDA HMF</th>
                                    <th>VALOR HMF</th>
                                    <th>FRUCTOSA</th>
                                    <th>GLUCOSA</th>
                                    <th>SACAROSA</th>
                                    <th>FG</th>
                                    <th>F+G</th>
                                    <th>G+H</th>
                                    <th>MIEL ADULTERADA</th>
                                    <th>RIM</th>
                                    <th>RIP</th>
                                    <th>%C4</th>
                                    <th>DIASTOSE</th>
                                    <th>CONDUCTIVIDAD</th>
                                    <th>TABLA POR HANNA</th>
                                    <th>HUMEDAD POR REFRACTOMETRÍA</th>
                                    <th>SZ</th>
                                    <th>SMT</th>
                                    <th>SS</th>
                                    <th>SMM</th>
                                    <th>DAPS</th>
                                    <th>SDM</th>
                                    <th>SMX</th>
                                    <th>SP</th>
                                    <th>SMP</th>
                                    <th>SD</th>
                                    <th>SQ</th>
                                    <th>ST</th>
                                    <th>SM</th>
                                    <th>SCP</th>
                                    <th>TMP</th>
                                    <th>QNL</th>
                                    <th>STR</th>
                                    <th>CEFT</th>
                                    <th>TYL</th>
                                    <th>TAF</th>
                                    <th>TCN</th>
                                  </tr>
                                </thead>
                                <tbody className="">
                                  <tr>
                                    {
                                      Object.keys(la).map((key) => {
                                        if (key !== "honey_drums" && key !== "export_lot_code") {
                                          return (
                                            <td>{la[key]}</td>
                                          )
                                        }
                                      })
                                    }
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            )
          })
        }

        <div class="col-md-12 d-flex justify-content-end align-items-end">
          <div class="btn-group">
            <BtnAccept
              onClick={onClick}
              name={"guardar"}
              value={"Guardar"}
              loading={loading}
              loadingMsg={"Subiendo datos..."}
            />
          </div>
        </div>

      </div>
    </div>
  )
}

export default MasiveStore