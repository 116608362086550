import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import BtnAccept from "../../../components/forms/BtnAccept";
import CheckBox from "../../../components/forms/CheckBox";
import InputFile from "../../../components/forms/InputFile";
import TextTarea from "../../../components/forms/TextTarea";
import Toogle from "../../../components/forms/Toogle";
import Spinner from "../../../components/loads/Spinner";
import clientAxios from "../../../config/ClientAxios";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { setForm_drum_request } from "../../../utils/setForms";

const RequestLotDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { values } = context;
  const { id } = useParams();

  const {
    URL_SHOW_EXPORT_LOT,
    URL_UPDATE_EXPORT_LOT,
    URL_INDEX_EXPORT_LOT,
    URL_ASSIGN_LABEL_DRUM,
    URL_ASSIGN_CHECK_DRUM,
    URL_UPLOAD_EXPORT_LOT,
  } = values;

  const [load, setLoad] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lot, setLot] = useState(null);
  const [drums, setDrums] = useState(null);

  const [images, setImages] = useState({
    images: [],
    url: [],
  });
  const [observacion, setObservacion] = useState([]);

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "imagen_url":
        {
          let setValue = images;

          setValue["images"].splice(value, 1);
          setValue["url"].splice(value, 1);

          setImages({
            ...images,
            images: [...setValue["images"]],
            url: [...setValue["url"]],
          });
        }
        break;
      case "guardar":
        {
          let formData = new FormData();
          formData.append("id", id);
          formData.append("comments", observacion);

          images.images.map((image, index) => {
            formData.append("files[" + index + "]", image);
          });

          const response = await ApiRest({
            url: URL_UPLOAD_EXPORT_LOT,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Actualizando detalle de lote",
              code: "201",
              type: "exito",
            });

            //cerrar modal
            return setLoad(load + 1);
          }

          Object.keys(response.data).map((key) => {
            if (Array.isArray(response.data[key])) {
              return Alerts({
                msg: "Alert",
                title: response.data[key][0],
                code: "401",
                type: "error",
              });
            } else {
              return Alerts({
                msg: "Alert",
                title: response.data[key],
                code: "401",
                type: "error",
              });
            }
          });
        }
        break;
    }
  };

  const onChange = async (e, value) => {
    switch (e.target.name) {
      case "chequeado":
        {
          let setValue = drums;
          setValue[e.target.id][e.target.name][0] = e.target.checked;

          setDrums([...setValue]);

          let formData = new FormData();

          formData.append("_method", "PUT");
          formData.append("id", setValue[e.target.id]["tambor_id"][0]);

          const response = await ApiRest({
            url: URL_ASSIGN_CHECK_DRUM,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title:
                "Tambor " +
                setValue[e.target.id]["tambor_id"][0] +
                " Chequeado",
              code: "201",
              type: "exito",
            });

            //cerrar modal
            return setLoad(load + 1);
          }

          Object.keys(response.data).map((key) => {
            if (Array.isArray(response.data[key])) {
              return Alerts({
                msg: "Alert",
                title: response.data[key][0],
                code: "401",
                type: "error",
              });
            } else {
              return Alerts({
                msg: "Alert",
                title: response.data[key],
                code: "401",
                type: "error",
              });
            }
          });
        }
        break;
      case "etiqueta":
        {
          let setValue = drums;
          setValue[e.target.id][e.target.name][0] = e.target.checked;

          setDrums([...setValue]);

          let formData = new FormData();

          formData.append("_method", "PUT");
          formData.append("id", setValue[e.target.id]["tambor_id"][0]);

          const response = await ApiRest({
            url: URL_ASSIGN_LABEL_DRUM,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title:
                "Tambor " +
                setValue[e.target.id]["tambor_id"][0] +
                " Etiquetado",
              code: "201",
              type: "exito",
            });

            //cerrar modal
            return setLoad(load + 1);
          }

          Object.keys(response.data).map((key) => {
            if (Array.isArray(response.data[key])) {
              return Alerts({
                msg: "Alert",
                title: response.data[key][0],
                code: "401",
                type: "error",
              });
            } else {
              return Alerts({
                msg: "Alert",
                title: response.data[key],
                code: "401",
                type: "error",
              });
            }
          });
        }
        break;
      case "imagen":
        {
          setImages({
            ...images,
            images: [...images.images, e.target.files[0]],
            url: [...images.url, URL.createObjectURL(e.target.files[0])],
          });
        }
        break;
      case "observacion":
        {
          setObservacion(e.target.value);
        }
        break;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      let params = {
        id: id,
      };
      const response = await clientAxios.get(URL_SHOW_EXPORT_LOT, { params });
      setLot(response.data);
      console.log('lot', response.data)
      setObservacion(response.data.comments);

      setImages({
        ...images,
        url: [...response.data.images],
        images: [...response.data.images],
      });

      if (response.data.homogenize == 1) {
        setDrums(setForm_drum_request(response.data.fractionated_honey_drums));
      } else {
        setDrums(setForm_drum_request(response.data.purchased_honey_drums));
      }
    };
    fetch();
  }, [load]);

  console.log(lot);
  //console.log(images);
  return (
    <main className="main-content">
      <div className="position-relative iq-banner">
        {lot ? (
          <div className="container">
            <div className="card my-5 p-5">
              <div className="d-flex justify-content-between">
                <div className="w-100 d-flex justify-content-between">
                  <div className="pe-0">
                    <h1>
                      <strong>Detalle</strong>
                    </h1>

                    <div className="d-flex ms-2 px-0">
                      <a
                        href="#"
                        className="d-none d-flex align-items-center justify-content-center me-2"
                      >
                        <img
                          src="/assets/images/icon-download.svg"
                          width="18px"
                          loading="lazy"
                        />
                      </a>
                      <a
                        href="#"
                        className="d-none d-flex align-items-center justify-content-center"
                      >
                        <img
                          src="/assets/images/icon-print.svg"
                          width="18px"
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <BtnAccept
                      onClick={onClick}
                      name={"guardar"}
                      value={"Guardar cambios"}
                      loading={loading}
                      loadingMsg={"Creando..."}
                    />
                  </div>
                </div>
                <div>
                  <label className="btn btn-secondary d-flex justify-content-center px-3 d-none">
                    <svg
                      className="me-2"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2530_34177)">
                        <rect
                          width="20"
                          height="20"
                          transform="translate(0 0.5)"
                          fill="white"
                          fill-opacity="0.01"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.002 3.00195C10.1677 3.00195 10.3267 3.0678 10.4439 3.18501C10.5611 3.30222 10.627 3.46119 10.627 3.62695V9.87695H16.877C17.0427 9.87695 17.2017 9.9428 17.3189 10.06C17.4361 10.1772 17.502 10.3362 17.502 10.502C17.502 10.6677 17.4361 10.8267 17.3189 10.9439C17.2017 11.0611 17.0427 11.127 16.877 11.127H10.627V17.377C10.627 17.5427 10.5611 17.7017 10.4439 17.8189C10.3267 17.9361 10.1677 18.002 10.002 18.002C9.83619 18.002 9.67722 17.9361 9.56001 17.8189C9.4428 17.7017 9.37695 17.5427 9.37695 17.377V11.127H3.12695C2.96119 11.127 2.80222 11.0611 2.68501 10.9439C2.5678 10.8267 2.50195 10.6677 2.50195 10.502C2.50195 10.3362 2.5678 10.1772 2.68501 10.06C2.80222 9.9428 2.96119 9.87695 3.12695 9.87695H9.37695V3.62695C9.37695 3.46119 9.4428 3.30222 9.56001 3.18501C9.67722 3.0678 9.83619 3.00195 10.002 3.00195Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2530_34177">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Iniciar Retiro
                    <button
                      className="d-none"
                      name="retirar"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                </div>
              </div>
              <div>
                <div className="row mt-5">
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">Fecha</h5>
                    <p className="text-dark">{lot.contract.shipment_date}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">
                      Nº Lote de exportacion
                    </h5>
                    <p className="text-dark">{lot.name}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">Destino</h5>
                    <p className="text-dark">{lot.contract.country.name}</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">País de exportación</h5>
                    <p className="text-dark">Argentina</p>
                  </div>
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">Condición de miel</h5>
                    <p className="text-dark">
                      {lot.homogenize == 1 ? "Homogeneizada" : "A Granel"}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 d-flex mb-3">
                    {images.url.map((url, index) => (
                      <div className="me-2 border-0">
                        <div
                          className="content-img overflow-hidden"
                          style={{ maxWidth: "100px", height: "110px" }}
                        >
                          <img
                            src={url}
                            className="img-fluid "
                            style={{ maxWidth: "100px" }}
                            alt=""
                            title="Eliminar?"
                          />
                        </div>
                        <div className="justify-content-between w-100">
                          <label className="btn text-primary border px-2 py-1 me-1">
                            <a className="text-end" href={url} target="_blank">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-eye"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                              </svg>
                            </a>
                            <button
                              id={index}
                              className="d-none"
                              name="imagenes_url"
                              onClick={""}
                            ></button>
                          </label>

                          <label className="btn text-primary border px-2 py-1 me-1">
                            <a
                              id={index}
                              className="text-end"
                              href={url}
                              download=""
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-download"
                                viewBox="0 0 16 16"
                              >
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                              </svg>
                            </a>
                            <button
                              id={index}
                              className="d-none"
                              name="imagenes_url"
                              onClick={""}
                            ></button>
                          </label>

                          <label className="btn btn-danger px-2 py-1">
                            <div className="text-end">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-trash3"
                                viewBox="0 0 16 16"
                              >
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                              </svg>
                            </div>
                            <button
                              id={index}
                              className="d-none"
                              name="imagen_url"
                              onClick={(event) => onClick(event, index)}
                            ></button>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-6 mb-5">
                    <InputFile
                      onChange={onChange}
                      label={"Subir archivo"}
                      name={"imagen"}
                      value={images.images[0]}
                      validate={""}
                    />
                  </div>
                  <div className="col-6 mb-5">
                    <TextTarea
                      onChange={onChange}
                      label={"Observaciones"}
                      name={"observacion"}
                      type={"text"}
                      value={observacion}
                      validate={""}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">Color primario</h5>
                    <p className="text-dark">
                      <div
                        className="btn rounded-pill"
                        style={{ backgroundColor: lot.primary_color.hex_code }}
                      ></div>{" "}
                      <p>{"HEX " + lot.primary_color.hex_code}</p>
                      <p>{"" + lot.primary_color.name}</p>
                    </p>
                  </div>

                  <div className="col-4 mb-5">
                    <h5 className="mb-2 text-secondary">Color secundario</h5>
                    <p className="text-dark">
                      <div
                        className="btn rounded-pill"
                        style={{ backgroundColor: lot.secondary_color.hex_code }}
                      ></div>{" "}
                      <p>{"HEX " + lot.secondary_color.hex_code}</p>
                      <p>{"" + lot.secondary_color.name}</p>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <p className="text-dark">Tambores</p>
                  <hr className="hrcolor" />
                </div>
                <div className="col-12 my-5">
                  <table
                    id="example"
                    className="table table-striped table-hover"
                  >
                    <thead>
                      <tr>
                        <th className="text-center">TAMBOR</th>
                        <th className="text-center">ESTIBA</th>
                        <th className="text-center">PESO</th>
                        <th className="text-center">ETIQUETADO</th>
                        <th className="text-center">CHEQUEADO</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      {drums ? (
                        drums.map((drum, index) => (
                          <tr>
                            <td>
                              <div className="text-center">
                                {lot.honey_drums[index].entry_details[0].geomiel_code}
                              </div>
                            </td>
                            <td></td>
                            <td>{drum.peso[0] + " KG"}</td>
                            <td className="">
                              <div className="d-flex justify-content-center w-100 text-center">
                                <Toogle
                                  uniqueKey={drum.key[0]}
                                  label={"ETIQUETAR"}
                                  value={drum.etiqueta[0]}
                                  name={"etiqueta"}
                                  onChange={onChange}
                                />
                              </div>
                            </td>
                            <td className="">
                              <div className="d-flex justify-content-center w-100 text-center">
                                <Toogle
                                  uniqueKey={drum.key[0]}
                                  label={"CHEQUEAR"}
                                  value={drum.chequeado[0]}
                                  name={"chequeado"}
                                  onChange={onChange}
                                />
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No existen tambores ingresados</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="min-vh-100 d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        )}
      </div>
    </main>
  );
};

export default RequestLotDetails;
