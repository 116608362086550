import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Input from '../../../components/forms/Input'
import { FORMS } from '../../../consts/FormsConst'
import { authContext } from '../../../context/AuthContext';
import Select from '../../../components/forms/Select';
import ApiRest from '../../../hooks/useRest';
import Alerts from '../../../components/alerts/Alerts';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStocks, fetchSupplies, suppliesState } from '../../../slices/supplies/Index';
import { wait } from '@testing-library/user-event/dist/utils';

export default function NewStateCountModal({ setModal }) {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(JSON.parse(JSON.stringify(FORMS.FORM_ESTADO_CUENTA)))
    const [transaction, setTransaction] = useState(null)
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState(null)
    const context = useContext(authContext);
    const _supplies = useSelector(suppliesState);
    const stocks = _supplies.stocks;
    const supplies = _supplies.equivalence;
    const { id } = useParams()

    const { values, forms, validations } = context;
    const {
        URL_INDEX_COUNTRY,
        URL_INDEX_PROVINCE,
        URL_CREATE_CUSTOMER,
        URL_INDEX_CUSTOMER,
        URL_UPDATE_CUSTOMER,
        URL_INDEX_EQUIVALENCE,
        URL_INDEX_SUPPLY
    } = values;
    const { FORM_ESTADO_CUENTA } = forms;
    const { VALIDATION_ESTADO_CUENTA } = validations;
    const [validation, setValidation] = useState(
        JSON.parse(JSON.stringify(VALIDATION_ESTADO_CUENTA))
    );

    const agregarEstado = async () => {

        const formDataObject = objectToFormData(formData)

        const response = await ApiRest({
            url: 'transactions',
            formData: formDataObject,
            setLoading: setLoading,
            method: "post",
        });

        //Error
        if (response.status == 201 || response.status == 200) {
            Alerts({
                msg: "Alert",
                title: "Creado estado de cuenta.",
                code: "201",
                type: "success",
            });

            //Actualizar datos providers


            //cerrar modal
            return setModal(false);
        } else {
            Object.keys(response.data).map((key) => {
                return Alerts({
                    msg: "Alert",
                    title: response.data[key][0],
                    code: "401",
                    type: "error",
                });
            });
        }
    }

    const onChange = (e, value) => {


        let setCampo = formData;
        setCampo[e.target.name][0] = e.target.value;

        setFormData({
            ...formData,
            [e.target.name]: setCampo[e.target.name],
        });
        switch (e.target.name) {
            case "transaction":
                setTransaction(e.target.value)
                console.log('cambiando', e.target.value)
                break
            case "type":
                setType(e.target.value)
            default:
                break
        }
    }

    function objectToFormData(form) {
        const Form_Data = new FormData();
        Form_Data.append("provider_id", id)

        Object.keys(formData).map((key, index) => {
            if (formData[key][0]) {
                if (formData[key][0].trim() !== "") {
                    Form_Data.append(key, formData[key][0]);
                }
            }
        })

        /*
        for (const key in form) {
            if (key === "type") {
                if (form["transaction"][0] == "Ingreso") {
                    formData.append(key, null);
                }
            } else if (key === "provider_id") {
                formData.append("provider_id", id); // Append a specific value to 'provider_id'
            } else if (Array.isArray(form[key])) {
                formData.append(key, form[key][0]); // Append only the first element of the array
            } else {
                formData.append(key, form[key]);
            }
        }
        */

        return Form_Data;
    }


    useEffect(() => {
        /*
        const fetch = async () => {
            let params = {
                provider_id: id
            }
            const response = await ApiRest({
                url: "/transactions",
                formData: params,
                setLoading: setLoading,
                method: "GET"
            })
            console.log(response)
        }

        if (id && !transaction) {
            fetch()
        } */
    }, [transaction, id]);

    useEffect(() => {
        if (!stocks) {
            let params = {
                url: URL_INDEX_SUPPLY,
                per_page: 1000,
            }
            dispatch(fetchStocks(params));
        }
    }, []);


    useEffect(() => {
        if (!supplies) {
            let params = {
                url: URL_INDEX_EQUIVALENCE,
                per_page: 1000,
            }
            dispatch(fetchSupplies(params));
        }
    }, []);

    console.log(transaction);
    return (
        <div
            className="modal  fade show bg-dark bg-opacity-25  modal-transition bg-modal-blur"
            style={{ display: "block" }}
            id="nueva-ordenModal"
            tabindex="-1"
            aria-labelledby="nueva-ordenModalLabel"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-xl modal-dialog-centered"
                style={{ maxWidth: "800px" }}
            >
                <div className="modal-content bg-modal-blanco">
                    <label className="btn btn-close">
                        <button
                            type="button"
                            className="d-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            name="close"
                            onClick={(event) => setModal(false)}
                        ></button>
                    </label>
                    <div className="modal-header p-5">
                        <h1 className="modal-title bold" id="nueva-ordenModalLabel">
                            <strong>
                                Agregar estado de Cuenta
                            </strong>
                        </h1>
                    </div>
                    {/* Formulario */}
                    <article className="container">
                        <main className='row pt-4'>

                            <div className="row">

                                <div class="col-md-3 col-6 d-flex flex-column mb-3">
                                    <Input
                                        onChange={onChange}
                                        label={"Fecha"}
                                        name={"date"}
                                        type={"date"}
                                        value={formData.date[0]}
                                        validate={validation.date}
                                        icon={"/assets/images/icon-input-fecha.svg"}
                                    />
                                </div>

                                <div class="col-md-3 col-6 d-flex flex-column mb-3">
                                    <Select
                                        onChange={onChange}
                                        label="Transacción"
                                        name="transaction"
                                        type=""
                                        defaultValue={formData.transaction[0]}
                                        value={[{ name: "Ingreso" }, { name: "Egreso" }]}
                                        showIndex="name"
                                        sendIndex="name"
                                        validate={validation.transaction}
                                    />
                                </div>

                                <div class="col-md-6 col-12 mb-3 ">
                                    <Input
                                        onChange={onChange}
                                        label="Comprobante"
                                        name={"receipt"}
                                        type={"text"}
                                        value={formData.receipt[0]}
                                        validate={validation.receipt}
                                    />
                                </div>
                            </div>

                            <h3 className='fw-bold pb-1'>Detalle</h3>
                            <hr />
                            <div className="pb-3 w-100">
                                <div className="row w-100 mb-3">
                                    {
                                        transaction != "Ingreso" &&
                                        <div className="col-md-4 col-12">
                                            <Select
                                                onChange={onChange}
                                                label="Tipo de ingreso"
                                                name="type"
                                                type=""
                                                defaultValue={formData.type[0]}
                                                value={
                                                    [
                                                        { name: "Insumo" },
                                                        { name: "Servicios" },
                                                        { name: "Adelanto" },
                                                        { name: "Canje" },
                                                    ]}
                                                showIndex="name"
                                                sendIndex="name"
                                                validate={validation.type}
                                            />
                                        </div>
                                    }
                                    <div class="col-md col-12  ">
                                        <Input
                                            onChange={onChange}
                                            label="Movimiento"
                                            name={"item"}
                                            type={"text"}
                                            value={formData.item[0]}
                                            validate={validation.item}
                                        />
                                    </div>
                                </div>
                                <div className="row w-100" style={{ rowGap: "10px" }}>
                                    <div class="col-md-3 col-12  ">
                                        <Select
                                            onChange={onChange}
                                            label="Saldo al que aplica"
                                            name="applied_balance"
                                            type=""
                                            defaultValue={formData.applied_balance[0]}
                                            value={
                                                [
                                                    { name: "Miel" },
                                                    { name: "Opérculo" },
                                                ]}
                                            showIndex="name"
                                            sendIndex="name"
                                            validate={validation.applied_balance}
                                        />
                                    </div>
                                    {
                                        transaction == "Ingreso" ?
                                            <div class="col-md-3 col-12  ">
                                                <Input
                                                    onChange={onChange}
                                                    label="Kilos"
                                                    name={"kilograms"}
                                                    type={"text"}
                                                    value={formData.kilograms[0]}
                                                    validate={validation.kilograms}
                                                />
                                            </div>
                                            :
                                            transaction != null &&
                                            <>
                                                {
                                                    type == "Adelanto" &&
                                                    <div class="col-md-3 col-12  ">
                                                        <Input
                                                            onChange={onChange}
                                                            label="Kilos"
                                                            name={"kilograms"}
                                                            type={"text"}
                                                            value={formData.kilograms[0]}
                                                            validate={validation.kilograms}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    type == "Canje" &&

                                                    <div class="col-md-3 col-12  ">
                                                        <Select
                                                            onChange={onChange}
                                                            label="Canje"
                                                            name="equivalence_id"
                                                            type=""
                                                            defaultValue={formData.equivalence_id[0]}
                                                            value={supplies ? supplies.data.map((s) => {
                                                                return {
                                                                    id: s.id,
                                                                    name: s.product_quantity+" "+s.product+" - "+s.supply_quantity+" "+s.supply
                                                                }
                                                            }) : []}
                                                            showIndex="name"
                                                            sendIndex="id"
                                                            validate={validation.equivalence_id}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    type == "Insumo" ?
                                                        <div class="col-md-3 col-12  ">
                                                            <Select
                                                                onChange={onChange}
                                                                label="Insumos"
                                                                name="supply_id"
                                                                type=""
                                                                defaultValue={formData.supply_id[0]}
                                                                value={stocks ? stocks.data : []}
                                                                showIndex="name"
                                                                sendIndex="id"
                                                                validate={validation.supply_id}
                                                            />

                                                            {
                                                                /*
                                                                <Input
                                                                onChange={onChange}
                                                                label="Insumo"
                                                                name={"supply_id"}
                                                                type={"text"}
                                                                value={formData.supply_id[0]}
                                                                validate={validation.supply_id}
                                                            />
                                                             */
                                                            }
                                                        </div>
                                                        : ""
                                                }

                                                {
                                                    type == "Insumo" || type == "Servicios" || type == "Canje"
                                                        ? <div class="col-md-3 col-12  ">
                                                            <Input
                                                                onChange={onChange}
                                                                label="Unidades"
                                                                name={"units"}
                                                                type={"text"}
                                                                value={formData.units[0]}
                                                                validate={validation.units}
                                                            />
                                                        </div>
                                                        : ""
                                                }
                                                {
                                                    type == "Insumo" || type == "Servicios" || type == "Adelanto" ?
                                                        <div class="col-md-3 col-12  ">
                                                            <Input
                                                                onChange={onChange}
                                                                label="Precio kg de miel"
                                                                name={"price_per_kg"}
                                                                type={"text"}
                                                                value={formData.price_per_kg[0]}
                                                                validate={validation.price_per_kg}
                                                            />
                                                        </div>
                                                        : ""
                                                }

                                                {
                                                    type == "Insumo" ?
                                                        <div class="col-md-3 col-12  ">
                                                            <Input
                                                                onChange={onChange}
                                                                label="Precio de Venta"
                                                                name={"selling_price"}
                                                                type={"text"}
                                                                value={formData.selling_price[0]}
                                                                validate={validation.selling_price}
                                                            />
                                                        </div>
                                                        : ""
                                                }
                                                {
                                                    type == "Servicios" ?
                                                        <div class="col-md-3 col-12  ">
                                                            <Input
                                                                onChange={onChange}
                                                                label="Precio por unidad"
                                                                name={"price_per_unit"}
                                                                type={"text"}
                                                                value={formData.price_per_unit[0]}
                                                                validate={validation.price_per_unit}
                                                            />
                                                        </div>
                                                        : ""
                                                }
                                            </>
                                    }

                                </div>
                                <div className="w-full d-flex justify-content-end mt-4">
                                    <button
                                        onClick={agregarEstado}
                                        className='btn btn-primary fw-bold'>
                                        Agregar estado
                                    </button>
                                </div>
                            </div>
                        </main>
                    </article>
                    {/* Finalización formulario */}
                </div>
            </div>
        </div>
    );
}
