const TextTarea = ({ onChange, label, name, value, index, validate, rows }) => {
  return (
    <div className="w-100">
      <label for="comprador" class={label == ""? "d-none" : "form-label"}>
        {label}
      </label>
      <textarea
        class="form-control"
        id={index}
        rows={rows? rows : 3}
        value={value}
        
        name={name}
        onChange={(event) => onChange(event)}
      ></textarea>

        {
        validate.validate
        ? <div className="text-danger">{validate.msg}</div>
        : ""
        }
    </div>
  );
};

export default TextTarea;
