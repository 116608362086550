import { Navigate, useNavigate } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import BtnAccept from "../../../components/forms/BtnAccept";
import CheckBox from "../../../components/forms/CheckBox";
import Input from "../../../components/forms/Input";
import ApiRest from "../../../hooks/useRest";
import { fetchMe } from "../../../slices/users/Index";
import { Validate } from "../../../utils/ValidateForms";

const Form = ({dispatch, form, setForm, validation, setValidation, loading, setLoading, navigate, url }) => {

  const onChange = (e) => {
    switch (e.target.name) {
      case "contraseña":
      case "correo":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name]
          })
        }
      break;
      default:
        break;
    }
  };

  const onClick = async (e) => {
    switch (e.target.name) {
      case "login":{
        e.preventDefault();

        const validation = Validate(form);
        setValidation(validation);
        //Validaciones
        if(validation.error.validate){  
          return Alerts({
            msg: "Warning",
            title: validation.error.msg,
            code: "201",
            type: "error",
          });
        }

        //create form
        let formData = new FormData();
        formData.append('email', form.correo[0]);
        formData.append('password', form.contraseña[0]);

        const response = await ApiRest({url: url, formData: formData, setLoading: setLoading, method: "post"});
        
        //Error 
        if (response.status == 201 || response.status == 200) {

          localStorage.setItem("Session", JSON.stringify(response.data));

          Alerts({
            msg: "Alert",
            title: "Sesión iniciada",
            code: "201",
            type: "success",
          })

          //dispatch(fetchMe());
          
          return navigate("/dashboard/home");
        }

        Object.keys(response.data).map((key) => {
          return Alerts({
            msg: "Alert",
            title: response.data[key],
            code: "401",
            type: "error",
          });
        });
      }    
      break;
      default:
        break;
    }
  }
  return (
    <form>
      <div className="row">
        <div className="col-lg-12 mb-3">
          <Input
            onChange={onChange}
            label={"Email"}
            name={"correo"}
            type={"email"}
            value={form.correo[0]}
            validate={validation.correo}
          />
        </div>
        <div className="col-lg-12 ">
          <Input
            onChange={onChange}
            label={"Password"}
            name={"contraseña"}
            type={"password"}
            value={form.contraseña[0]}
            validate={validation.contraseña}
          />
        </div>

        <div className="col-lg-12 d-flex justify-content-between">
          <CheckBox
            onChange={onChange}
            label={"Recordarme"}
            name={"recordarme"}
            type={"checkbox"}
            value={""}
          />
          <a href="recoverpw.html">Olvidé mi contraseña</a>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <BtnAccept 
         onClick={onClick}
         name={"login"}
         value={"Ingresar"}
         loading={loading}
         loadingMsg={"Iniciando sesión..."}
        />
      </div>
    </form>
  );
};

export default Form;
