import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Alerts from "../../../../../components/alerts/Alerts";
import EdiTables from "../../../../../components/editables/EdiTables";
import BtnAccept from "../../../../../components/forms/BtnAccept";
import Spinner from "../../../../../components/loads/Spinner";
import Table from "../../../../../components/tables/Table";
import clientAxios from "../../../../../config/ClientAxios";
import { authContext } from "../../../../../context/AuthContext";
import ApiRest from "../../../../../hooks/useRest";
import {
  fetchAnalyzeLot,
  fetchAnalyzeOrderPurchase,
} from "../../../../../slices/laboratory/Index";
import { setForm_analize_drums } from "../../../../../utils/setForms";

const Samples = ({ select, setSelect }) => {
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const {
    URL_SHOW_ANALIZE,
    URL_ANALIZE_DRUM,
    URL_INDEX_ANALIZE,
    URL_INDEX_LOT,
  } = values;

  const [loading, setLoading] = useState(false);

  const [drums, setDrums] = useState(null);
  const [forms, setForms] = useState(null);
  /*********************************************** INITIAL STATE ***********************************************************/

  const campos = [
    {
      name: "TAMBOR",

      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ID",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "COLOR",

      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "HUMEDAD",

      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ADULTERACION",

      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "SOLIDIFICACION",

      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ARCHIVO",

      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "LOTE",

      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "OBSERVACIONES",

      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
  ];
  const order = [
    {
      content_name: "geomiel_code",
      property: "geomiel_code",

      name: "geomiel_code",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "tambor_id",
      property: "tambor_id",

      name: "tambor_id",
      form: "input",
      type: "text",
      disabled: true,
    },
    {
      content_name: "color",
      property: "color",

      name: "color",
      form: "input",
      type: "text",
      disabled: false,
    },
    {
      content_name: "humedad",
      property: "humedad",

      name: "humedad",
      form: "input",
      type: "text",
      disabled: false,
    },
    {
      content_name: "adulteraciones",
      property: "adulteraciones",

      name: "adulteraciones",
      form: "select",
      type: "text",
      selectValue: [{ name: "Negativa" }, { name: "Positiva" }],
      disabled: false,
    },
    {
      content_name: "solidificacion",
      property: "solidificacion",

      name: "solidificacion",
      form: "select",
      type: "text",
      selectValue: [{ name: "Liquida" }, { name: "Solida" }],
      disabled: false,
    },
    {
      content_name: "archivo",
      property: "archivo",

      name: "archivo",
      form: "input",
      type: "text",
      disabled: false,
    },
    {
      content_name: "lote",
      property: "lote",

      name: "lote",
      form: "input",
      type: "text",
      disabled: false,
    },
    {
      content_name: "observaciones",
      property: "observaciones",

      name: "observaciones",
      form: "texttarea",
      type: "text",
      disabled: false,
    },
  ];
  const contents = forms ? forms : [];
  const actions = [
    {
      title: "Detalle",
      name: "detalle_orden",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 13 11" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:evenodd;fill:rgb(94.509804%,41.568627%,10.588235%);fill-opacity:1;" d="M 13 5.75 C 13 5.75 10.65625 1.5 6.75 1.5 C 2.84375 1.5 0.5 5.75 0.5 5.75 C 0.5 5.75 2.84375 10 6.75 10 C 10.65625 10 13 5.75 13 5.75 Z M 1.417969 5.75 C 1.792969 6.316406 2.230469 6.847656 2.714844 7.328125 C 3.71875 8.324219 5.09375 9.226562 6.75 9.226562 C 8.40625 9.226562 9.78125 8.324219 10.789062 7.328125 C 11.273438 6.847656 11.707031 6.316406 12.085938 5.75 C 11.707031 5.183594 11.273438 4.652344 10.789062 4.171875 C 9.78125 3.175781 8.40625 2.273438 6.75 2.273438 C 5.09375 2.273438 3.71875 3.175781 2.710938 4.171875 C 2.226562 4.652344 1.792969 5.183594 1.414062 5.75 Z M 1.417969 5.75 "/><path style=" stroke:none;fill-rule:evenodd;fill:rgb(94.509804%,41.568627%,10.588235%);fill-opacity:1;" d="M 6.75 3.785156 C 6.230469 3.785156 5.730469 3.992188 5.359375 4.359375 C 4.992188 4.730469 4.785156 5.230469 4.785156 5.75 C 4.785156 6.269531 4.992188 6.769531 5.359375 7.140625 C 5.730469 7.507812 6.230469 7.714844 6.75 7.714844 C 7.269531 7.714844 7.769531 7.507812 8.140625 7.140625 C 8.507812 6.769531 8.714844 6.269531 8.714844 5.75 C 8.714844 5.230469 8.507812 4.730469 8.140625 4.359375 C 7.769531 3.992188 7.269531 3.785156 6.75 3.785156 Z M 4 5.75 C 4 5.019531 4.289062 4.320312 4.804688 3.804688 C 5.320312 3.289062 6.019531 3 6.75 3 C 7.480469 3 8.179688 3.289062 8.695312 3.804688 C 9.210938 4.320312 9.5 5.019531 9.5 5.75 C 9.5 6.480469 9.210938 7.179688 8.695312 7.695312 C 8.179688 8.210938 7.480469 8.5 6.75 8.5 C 6.019531 8.5 5.320312 8.210938 4.804688 7.695312 C 4.289062 7.179688 4 6.480469 4 5.75 Z M 4 5.75 "/></g></svg>',
      id: "editar",

      class: "btn border-0 d-flex align-items-center justify-content-center",
      text: "",
    },
    {
      option: "",
      title: "Llegada",
      name: "llegada",
      icon: "",
      id: "",
      class:
        "btn btn-secondary border-0 d-flex align-items-center justify-content-center",
      text: "Analizar O/C ",
    },
  ];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = {
    current_page: 0,
    first_page_url: 0,
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  };
  const pagination = {
    type: null,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  /**********************************************************************************************************************/

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "guardar":
        {
          let products = [];

          if (forms.length > 0) {
            forms.map((form) => {
              if (form.lote[0] !== "" && form.lote[0]) {
                console.log(form);
                products.push(form);
              }
            });
          }

          let formData = new FormData();

          products.map((product, index) => {
            if (product.tambor_id[0] != "")
              formData.append(
                "products[" + index + "][honey_drum_id]",
                product.tambor_id[0]
              );

            if (product.color[0] != "")
              formData.append(
                "products[" + index + "][color]",
                product.color[0]
              );

            if (product.humedad[0] != "")
              formData.append(
                "products[" + index + "][moisture]",
                product.humedad[0]
              );

            if (product.adulteraciones[0] != "")
              formData.append(
                "products[" + index + "][adulteration]",
                product.adulteraciones[0]
              );

            if (product.solidificacion[0] != "")
              formData.append(
                "products[" + index + "][solidification]",
                product.solidificacion[0]
              );

            if (product.archivo[0] != "")
              formData.append(
                "products[" + index + "][file]",
                product.archivo[0]
              );

            if (product.lote[0] != "")
              formData.append(
                "products[" + index + "][lot_analysis_id]",
                product.lote[0]
              );

            if (product.observaciones[0] != "")
              formData.append(
                "products[" + index + "][comments]",
                product.observaciones[0]
              );
          });

          const response = await ApiRest({
            url: URL_ANALIZE_DRUM,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Realizado exitosamente",
              code: "201",
              type: "exito",
            });

            //Actualizar datos
            let doParams = {
              url: URL_INDEX_ANALIZE,
              analyzed: 0,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchAnalyzeOrderPurchase(doParams));

            doParams = {
              url: URL_INDEX_ANALIZE,
              analyzed: 1,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchAnalyzeOrderPurchase(doParams));

            let params = {
              url: URL_INDEX_LOT,
              analyzed_status: 0,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchAnalyzeLot(params));

            //cerrar modal
            return setSelect(null);
          }

          Object.keys(response.data).map((key) => {
            if (Array.isArray(response.data[key])) {
              return Alerts({
                msg: "Alert",
                title: response.data[key][0],
                code: "401",
                type: "error",
              });
            } else {
              return Alerts({
                msg: "Alert",
                title: response.data[key],
                code: "401",
                type: "error",
              });
            }
          });
        }
        break;
      case "":
        {
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "observaciones":
      case "archivo":
      case "lote":
      case "solidificacion":
      case "adulteraciones":
      case "humedad":
      case "color":
        {
          let setValue = forms;
          setValue[e.target.id][e.target.name][0] = e.target.value;

          setForms([...setValue]);
        }
        break;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      let params = {
        purchase_order_id: select.id,
        analyzed: 0,
        amount: 500,
      };
      const response = await clientAxios.get(URL_SHOW_ANALIZE, { params });
      setDrums(response.data);
    };

    fetch();
  }, []);

  useEffect(() => {
    if (drums) {
      setForms(setForm_analize_drums(drums));
    }
  }, [drums]);

  console.log(drums);
  //console.log(forms);
  return (
    <div class="card-body">
      {contents.length > 0 ? (
        <EdiTables
          hidden_Filters={true}
          campos={campos}
          order={order}
          contents={contents}
          pagination={pagination}
          onClick={onClick}
          onChange={onChange}
        />
      ) : (
        <Spinner />
      )}
      <div className="w-100 d-flex justify-content-end mt-3">
        <BtnAccept
          onClick={onClick}
          name={"guardar"}
          value={"Guardar resultados"}
          loading={loading}
          loadingMsg={"Generando..."}
        />
      </div>
    </div>
  );
};

export default Samples;
