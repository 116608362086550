import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  //Lista
  auth: "authenticated",
  me: null,

  //Users
  users_admin: null,
  users_buyer: null,
  users_logistic: null,
  users_storage: null,
  users_export: null,
  users_laboratory: null,

  //Roles
  roles: null
}

export const meSlice = createSlice({
  name: "me",
  initialState: initialState,
  reducers: {
    setNoAuth: (state, action) => {
      state.auth = false;
    },
    setMe: (state, action) => {
      state.me = action.payload;
      state.auth = true;
    },
    setLogout: (state, action) => {
      state.auth = false;
      state.me = null;
    },
    //Users
    setUsersAdminList: (state, action) => {
      state.users_admin = action.payload
    },
    setUsersBuyerList: (state, action) => {
      state.users_buyer = action.payload
    },
    setUsersLogisticList: (state, action) => {
      state.users_logistic = action.payload
    },
    setUsersStorageList: (state, action) => {
      state.users_storage = action.payload
    },
    setUsersExportList: (state, action) => {
      state.users_export = action.payload
    },
    setUsersLaboratoryList: (state, action) => {
      state.users_laboratory = action.payload
    },
    //Roles
    setRolesList: (state, action) => {
      state.roles = action.payload
    },
  },
});


//Async functions
export const fetchMe = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setMe(result.data));
    })
    .catch((error) => {
      return dispatch(setNoAuth())
    });
};

export const fetchUsers = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params.role) {
        case "compras": dispatch(setUsersBuyerList(result.data))
        break;
        case "deposito": dispatch(setUsersStorageList(result.data))
        break;
        case "logistica": dispatch(setUsersLogisticList(result.data))
        break;
        case "exportacion": dispatch(setUsersExportList(result.data))
        break;
        case "administracion": dispatch(setUsersAdminList(result.data))
        break;
        case "laboratorio": dispatch(setUsersLaboratoryList(result.data))
        break;
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchRoles = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setRolesList(result.data));
    })
    .catch((error) => {
      return dispatch(setNoAuth())
    });
};



export const {setRolesList, setNoAuth, setMe, setLogout, setUsersAdminList, setUsersBuyerList, setUsersExportList, setUsersLogisticList, setUsersStorageList , setUsersLaboratoryList} = meSlice.actions;

export const meState = (state) => state.me;

export default meSlice.reducer;