import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  country: null,
  province: null,
  city: [],

  selectCity: null,

  search_country: {data: []},
  search_province: [],
  search_city: []
};

export const geolocationSlice = createSlice({
  name: "geolocation",
  initialState: initialState,
  reducers: {
    setProvinceList: (state, action) => {
      state.province = action.payload;
    },
    setCityList: (state, action) => {
      state.city = action.payload;
    },
    setCountryList: (state, action) => {
      state.country = action.payload;
    },

    /* Search */
    setSearchCountry: (state, action) => {
      state.search_country = action.payload;
    },

    setSearchProvince: (state, action) => {
      state.search_province = action.payload;
    },

    setSearchCity: (state, action) => {
      state.search_city = action.payload;
    },
  },
});


//Async functions
export const fetchCountryList = () => (dispatch) => {
  clientAxios
  .get("geolocation/country/index")
  .then((result) => {
    return dispatch(setCountryList(result.data));
  })
  .catch((error) => {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  });
}

export const fetchProvinceList = (params) => (dispatch) => {
  clientAxios
  .get("geolocation/province/index",{params})
  .then((result) => {
    return dispatch(setProvinceList(result.data));
  })
  .catch((error) => {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  });
}

export const fetchCityList = (id) => (dispatch) => {
  clientAxios
    .get("geolocation/city/index", { params: { province_id: id } })
    .then((result) => {
      return dispatch(setCityList(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

/* Search */

export const fetchSearchCountry = (params) => (dispatch) => {
  clientAxios
  .get(params.url, {params})
  .then((result) => {
    return dispatch(setSearchCountry(result.data));
  })
  .catch((error) => {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  });
}

export const fetchSearchProvince = (params) => (dispatch) => {
  clientAxios
  .get(params.url, {params})
  .then((result) => {
    return dispatch(setSearchProvince(result.data));
  })
  .catch((error) => {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  });
}



export const { setProvinceList, setCityList, setCountryList, setSearchCountry, setSearchProvince, setSearchCity} = geolocationSlice.actions;

export const geolocationState = (state) => state.geolocation;

export default geolocationSlice.reducer;