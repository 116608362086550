export const FORMS = {
  /* Order Purchase */
  FORM_ORDER_PURCHASE: {
    comprador: [null, "required"], //buyer
    metodo_pago: [null, "required"], //payment_method_id
    observacion: [null, ""], //comments
    fecha: [null, "required"], //date
    proveedor: [null, "required"], //provider_id
    proveedor_id: [null, "required"],
    cuit: [null, "required"],
    pago_anticipado: [false, ""], //prepaid

    imagenes: [[], ""],
    imagenes_url: [[], ""],

    productos: [
      [
        {
          //Campos de productos
          producto: [null, "required"], //producto_id
          unidades: [null, ""], //units
          precio_por_kilo: [null, "required"], //price_per_kilo
          total_de_kilos: [null, "required"], //total_kilos

          bruto_unitario: [null, ""], //unit bruto
          tara_unitario: [null, ""], //unit tara
          neto_unitario: [null, ""], //unit neto

          canjeo: [false, ""], //exchange boolean
          canjeables: [[], ""], //exchanges
        },
      ],
      "required",
    ], //products boolean

    //Campos de canjeables
  },

  /* Products */
  FORM_PRODUCTS: {
    //Campos de productos
    producto: [0, "required"], //producto_id
    unidades: ["", ""], //units
    precio_por_kilo: ["", "required"], //price_per_kilo
    total_de_kilos: ["", "required"], //total_kilos

    bruto_unitario: ["", ""], //unit bruto
    tara_unitario: ["", ""], //unit tara
    neto_unitario: ["", ""], //unit neto

    canjeo: [false, ""], //exchange boolean
    canjeables: [[], ""], //exchanges
  },

  /* Excahnges */
  FORM_EXCHANGES: {
    //
    mas_de_50ml: ["", "required"],
    tipo: ["", "required"],
    unidad_canjeable: ["", "required"],
  },

  /* Providers */
  FORM_PROVIDERS: {
    razon_social: [null, "required"], //razon socail
    cuit: [null, "required"], //cuit
    direccion: [null, "required"], //direccion
    provincia: [['CóRDOBA'], "required"], //provincia
    localidad: [null, "required"], //password
    telefono: [null, "required", "min:7", "max:13"], //telefono
    email: [null, "required"], //email
    inicio_actividad: [null, "required"], //actividad
    colmenas: [null, "required"], //colmenas
    observaciones: [null, ""], //obs
  },

  /* Equivalence */
  FORM_EQUIVALENCE: {
    insumo: ["", "required"], //supply
    producto: ["", "required"], //product
    cantidad_producto: ["", "required"], //qunatity_product
    cantidad_insumo: ["", "required"], //quantity_supply
    mas_de_50ml: [false, ""], //greater_than_50ml
  },

  /*  Order Withdrawal */
  FORM_ORDER_WITHDRAWAL: {
    orden_compra_id: ["", ""], //Orden de compra id

    prioritario: [false, ""],
    destino: ["", "required"],
    vehiculo: ["", "required"],
    transportista: ["", "required"],

    pesos_entregados: ["", "required"],
    dolares_entregados: ["", "required"],

    order_purchase: ["", ""],

    fecha: ["", "required"],
  },

  /* Order Withdrawal Details Drums */
  FORM_ORDER_DETAILS_DRUM: {
    //Tambor
    producto_id: ["", ""], //product_id
    tambor_entregado: [false, ""], //deliveri
    tipo_tambor: ["", ""],
    codigo_senasa: ["", ""], //senasa_code
    codigo_geomiel: ["", ""], //geomiel_code
    tara: ["", ""], //tare
    bruto: ["", ""], //gross_weight
    neto: ["", ""], //total_weight

    tambor_id: ["", ""], //honey_drum_id
    observacion: ["", ""], //comments
    imagenes: [null, ""], //images
    imagenes_url: [null, ""],
  },

  /* Order Withdrawal Details Wax */
  FORM_ORDER_DETAILS_WAX: {
    //Cera
    producto_id: ["", ""], //product_id
    tambor_entregado: [false, ""], //deliveri
    cera_id: ["", ""], //wax_id
    unidad: ["", "required"], //Units
    peso_unidad: ["", "required"], //weight_per_unit
    observacion: ["", ""], //comments
    imagenes: [null, ""], //images
  },

  /* Vehicles */
  FORM_VEHICLE: {
    modelo: [null, "required"],
    patente: [null, "required"],
  },

  /* Users */
  FORM_USERS: {
    rol: [null, "required"], //role
    cuit: [null, ""], //cuit
    nombre: [null, "required"], //name
    email: [null, "required"], //email
    direccion: [null, ""], //address
    provincia: [null, ""], //province_id
    localidad: [null, ""], //city_id
    telefono: [null, ""], //telephone
    observaciones: [null, ""], //comments
    password: [null, "required"],
  },

  /* Order Entry Details Drums*/
  FORM_ENTRY_ORDER_DETAILS_DRUM: {
    producto_id: ["", ""], //product_id
    tambor_id: ["", ""], //honey_drum_id
    codigo_senasa: ["", "required"],
    codigo_geomiel: ["", "required"],
    tipo_tambor: ["", "required"],
    peso_inicial: ["", ""],
    neto: ["", "required"],
    bruto: ["", "required"],
    tara: ["", "required"],
    estiba: ["", "required"],

    observacion: ["", ""],
    imagenes: ["", ""], //images
  },

  /* Order Entry Details Wax*/
  FORM_ENTRY_ORDER_DETAILS_WAX: {
    producto_id: ["", ""], //product_id
    kilos_ingresados: ["", "required"],
    operculo: ["", "required"],
    recupero: ["", "required"],
    borra: ["", "required"],

    observacion: ["", ""],
    imagenes: ["", ""], //images
  },

  /* Laboratory Analize Drums */
  FORM_ANALIZE_DRUMS: {
    tambor_id: ["", ""], //honey_drum_id
    color: ["", ""], //color
    humedad: ["", ""], //moisture
    adulteraciones: ["", ""], //adulteration
    solidificacion: ["", ""], //solidification
    archivo: ["", ""], //file
    lote: ["", ""], //honey_lot
    observaciones: ["", ""], //comments
  },

  FORM_ANALIZE_LOT: {
    lote_id: ["", ""], //lot_analysis_id
    dextrinas: ["", "required"], //dextrins
    tipo_medicion_hmf: ["", "required"], //measurement_type_hmf
    //measurement_type_hmf
    valor_hmf: ["", "required"], //hmg_value
    fructuosa: ["", "required"], //fructose
    glucosa: ["", "required"], //glucose
    sacarosa: ["", "required"], //sucrose
    fg: ["", "required"], //fg
    f_g: ["", ""], //f+g
    g_h: ["", ""], //G+H
    miel_adulterada: ["", "required"], //adulteres_honey
    p_m: ["", "required"], //p-m
    rim: ["", "required"], //rim
    rip: ["", "required"], //rip
    c4: ["", "required"], //%c4
    diastasa: ["", "required"], //diastose
    conductividad: ["", "required"], //conductivity
    color_por_hanna: ["", "required"], //table_by_hanna
    humedad_refractometro: ["", "required"], //moisture_by_refractometry
    sz: ["", "required"], //sz
    smt: ["", "required"], //smt
    ss: ["", "required"], //ss
    smm: ["", "required"], //smm
    daps: ["", "required"], //daps
    sdm: ["", "required"], //sdm
    smx: ["", "required"], //smx
    sp: ["", "required"], //sp
    smp: ["", "required"], //smp
    sd: ["", "required"], //sd
    sq: ["", "required"], //sq
    st: ["", "required"], //st
    sm: ["", "required"], //sm
    scp: ["", "required"], //scp
    tmp: ["", "required"], //tmp
    qnl: ["", "required"], //qnl
    str: ["", "required"], //str
    ceft: ["", "required"], //ceft
    tyl: ["", "required"], //tyl
    taf: ["", "required"], //taf
    tcn: ["", "required"], //tcm
    observaciones: ["", ""], //comments
  },

  /* Clients */
  FORM_CLIENTS: {
    empresa: ["", "required"], //business_name

    pais_id: ["", "required"], //country_id
    pais: ["", "required"], //country

    direccion: ["", "required"], //address

    provincia: ["", "required"], //province_name
    provincia_id: ["", ""],

    ciudad: ["", "required"], //city_name

    email: ["", "required"], //email
    condicion: ["", "required"], //condition
    observacion: ["", "required"], //comments
  },

  /* Contract */
  FORM_CONTRACT: {
    numero_contrato: ["", "required"], //contract_number
    cliente_id: ["", "required"], //customer_id
    cliente: ["", "required"], //customer_id
    pais_id: ["", "required"], //country_id
    pais: ["", "required"], //country name
    color: ["", "required"], //color
    fecha_envio: ["", "required"], //shipment_date

    producto: ["", "required"], //product_id
    kilos: ["", "required"], //kilos
    unidad: ["", "required"], //units
    tipo: ["", ""],

    condiciones_venta: ["", "required"], //sales_condition

    precio_final: ["", "required"], //full_price
    precio_argentina: ["", "required"], //arg_price
    condicion_completa: ["", "required"], //condition_full
    condicion_argentina: ["", "required"], //condition_arg
    flete: ["", ""], //cfr_freight
  },

  /* DRUMS */

  FORM_DRUM: {
    adulteration: ["", ""], //contract_number
    analyzed: ["", ""],
    approved: ["", ""],
    color: ["", ""],
    comments: ["", ""],
    export_lot_id: ["", ""],
    file: ["", ""],
    gross_weight: ["", ""],
    id: ["", ""],
    lot_analysis_id: ["", ""],
    moisture: ["", ""],
    net_weight: ["", ""],
    purchase_order_id: ["", ""],
    sector_id: ["", ""],
    solidification: ["", ""],
    state_id: ["", ""],
    tare: ["", ""],
    updated_at: ["", ""],
  },

  /* EXTRA ANALYZED */

  FORM_EXTRA_ANALYZED: {
    fructosa: [false, ""],
    glucosa: [false, ""],
    sacarosa: [false, ""],
    fg: [false, ""],
    f_g: [false, ""],
    g_h: [false, ""],
    miel_adulterada: [false, ""],
    p_m: [false, ""],
    rim: [false, ""],
    rip: [false, ""],
    c4: [false, ""],
    conductividad: [false, ""],
    diastosa: [false, ""],
    color_hanna: [false, ""],
    humedad_refractometro: [false, ""],
    sz: [false, ""],
    sdm: [false, ""],
    sq: [false, ""],
    smt: [false, ""],
    smx: [false, ""],
    st: [false, ""],
    ss: [false, ""],
    sp: [false, ""],
    sm: [false, ""],
    smm: [false, ""],
    smp: [false, ""],
    scp: [false, ""],
    daps: [false, ""],
    sd: [false, ""],
    tmp: [false, ""],
    qnl: [false, ""],
    ceft: [false, ""],
    taf: [false, ""],
    str: [false, ""],
    tyl: [false, ""],
    tcn: [false, ""],
    comments: ["", ""], //cfr_freight
  },

  /* EXPORT LOT */

  FORM_EXPORT_LOT: {
    id: ["", "required"], //export_lot_id
    consolidacion: ["", "required"], //consolidation_date
    booking: ["", "required"], //Booking
    contenedor: ["", "required"], //container
    color: ["", "required"], //permision
    permiso: ["", "required"], //permision
    isf: ["", "required"], //ISF
    analisis: [false, "required"], //analysis
    nombre_analisis: ["", "required"], //analysis_name
    recibido: [false, "required"], //received
    saldado: [false, "required"], //settled
    sae: ["", "required"], //sae
    lote_senasa: ["", "required"], //seanasa_lot
    precinto_sensa: ["", "required"], //senasa_seal
    precinto_ana: ["", "required"], //ana_seal
    precinto_linea: ["", "required"], //line_seal
    precinto_tsh: ["", "required"], //tsh_seal
    precio_completo: ["", "required"], //full_price
    precio_argentina: ["", "required"], //arg_price
    cfr_completo: ["", "required"], //cfr_full
    cfr_argentina: ["", "required"], //cfr_arg
    cfr_transporte: ["", "required"], //cfr_freight
  },

  /* Asignem Contract */

  FORM_ASIGNEM_CONTRACT: {
    id: ["", "required"],
    contrato: ["", "required"],
    cliente: ["", "required"],
    destino: ["", "required"],
    color: ["", "required"],
    fecha_embarque: ["", "required"],
  },

  /* Drums homogenize */

  FORM_DRUM_HOMOGENIZE: {
    adulteration: ["", ""], //contract_number
    analyzed: ["", ""],
    approved: ["", ""],
    export_lot_id: ["", ""],
    file: ["", ""],
    gross_weight: ["", ""],
    id: ["", ""],
    lot_analysis_id: ["", ""],
    net_weight: ["", ""],
    purchase_order_id: ["", ""],
    sector_id: ["", ""],
    state_id: ["", ""],
    tare: ["", ""],

    color: ["", ""],
    moisture: ["", ""],
    solidification: ["", ""],
    fg: ["", ""],
    hmf: ["", ""],
    am1: ["", ""],
    am2: ["", ""],
    homogenization_analyzed: ["", ""],
    glyphosate: ["", ""],

    updated_at: ["", ""],
    comments: ["", ""],
  },

  /* Charge drums */

  FORM_CHARGE_DRUMS: {
    key: [0, ""],
    type: ["", ""],
    sampled: ["", ""],
    net_weight: ["", ""],
    tare: ["", ""],
    gross_weight: ["", ""],
    export_lot_id: ["", ""],
    decrease: [false, ""],
  },

  /* ChaNge Color */

  FORM_CHANGE_COLOR: {
    color_primario: ["", "required"],
    color_secundario: ["", "required"],
    comentario: ["", "required"],
  },

  FORM_COLOR: {
    hex_primario: ["", ""],
    nombre_primario: ["", ""],
    hex_secundario: ["", ""],
    nombre_secundario: ["", ""],
  },

  /* Supply  */

  FORM_SUPPLY: {
    nombre: ["", "required"],
    detalle: [
      [
        {
          model_name: ["", "required"],
          sale_price: [0, "required"],
        },
      ],
      "required",
    ],
  },

  /* Stocks */

  FORM_STOCK: {
    modelo_id: ["", "required"],
    fecha: ["", "required"],
    metodo_pago: ["", "required"],
    precio_venta: ["", "required"],
    numero: ["", "required"],
    unidad: ["", "required"],
    precio_unidad: ["", "required"],
    observacion: ["", "required"],
  },

  FORM_DESTINO:{
    name: ["", "required"],
    adress: ["", "required"],
    city_id: ["", "required"],
    
  },

  FORM_ESTADO_CUENTA:{
    transaction: ["", "required"],
    receipt: ["", "required"],
    date: ["", "required"],
    type: ["", "required"],
    applied_balance: ["", "required"],
    item: ["", "required"],
    provider_id: ["", "required"],
    kilograms: [null, ""],
    supply_id: [null, ""],
    selling_price: [null, ""],
    units: [null, ""],
    price_per_kg: [null, ""],
    price_per_unit: [null, ""],
    equivalence_id: [null, ""],
  }
};
