import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BtnSticky from "../../components/forms/BtnSticky";
import Spinner from "../../components/loads/Spinner";
import ModalOrderPurchase from "../../components/modals/ModalOrderPurchase";
import ModalProviders from "../../components/modals/ModalProviders";
import { authContext } from "../../context/AuthContext";
import { geolocationState } from "../../slices/geolocation";
import { fetchProviders, providersState } from "../../slices/providers/Index";
import { fetchSupplies, suppliesState } from "../../slices/supplies/Index";
import { fetchRoles, fetchUsers, meState } from "../../slices/users/Index";
import ContentTable from "./content/ContentTables";
import Filters from "./filters/Filters";
import ModalUser from "./modals/ModalUser";

const Users = () => {
  //Global Const
  const context = useContext(authContext);
  const { values } = context;
  //context
  const {
    URL_INDEX_ROLES,
    URL_INDEX_PROVIDERS,
    URL_INDEX_EQUIVALENCE,
    URL_INDEX_VEHICLES,
    URL_INDEX_USERS,
  } = values;
  //Dispatch reducer
  const dispatch = useDispatch();
  const { roles } = useParams();

  //Initial States
  const _user = useSelector(meState);
  const _supplies = useSelector(suppliesState);
  const _providers = useSelector(providersState);
  const _geolocation = useSelector(geolocationState);

  const providers = _providers.providers;
  const province = _geolocation.province;
  const city = _geolocation.city;
  const supplies = _supplies.supplies;
  const roles_list = _user.roles;
  const me = _user.me;
  const rol = me ? me.roles[0] : null;
  const users =
    roles == "compras"
      ? _user.users_buyer
      : roles == "deposito"
      ? _user.users_storage
      : roles == "logistica"
      ? _user.users_logistic
      : roles == "exportacion"
      ? _user.users_export
      : roles == "administracion"
      ? _user.users_admin
      : roles == "laboratorio"
      ? _user.users_laboratory
      : [];

  const h1 =
    roles == "compras"
      ? "Usuarios Compradores"
      : roles == "deposito"
      ? "Usuarios Deposito"
      : roles == "logistica"
      ? "Usuarios Transportista"
      : roles == "exportacion"
      ? "Usuarios Exportación"
      : roles == "administracion"
      ? "Usuarios Administración"
      : roles == "laboratorio"
      ? "Usuarios de laboratorio"
      : [];

  //Select Item
  const [select, setSelect] = useState(null);
  //Modals
  const [modalProviders, setModalProviders] = useState(false);
  const [modalOrderPurchase, setModalOrderPurchase] = useState(false);
  const [modalUsers, setModalUsers] = useState(false);
  //Params
  const [paramsProviders, setParamsProviders] = useState({
    url: URL_INDEX_PROVIDERS,
  });

  const [paramsEquivalence, setParamsEquivalence] = useState({
    url: URL_INDEX_EQUIVALENCE,
  });

  const [paramsVehicles, setParamsVehicles] = useState({
    url: URL_INDEX_VEHICLES,
  });

  //
  useEffect(() => {
    if (!supplies) {
      dispatch(fetchSupplies(paramsEquivalence));
    }
  }, [paramsEquivalence]);

  useEffect(() => {
    if (!providers) {
      dispatch(fetchProviders(paramsProviders));
    }
  }, [paramsProviders]);

  useEffect(() => {
    if (!users) {
      let params = {
        url: URL_INDEX_USERS,
        role: roles,
      };
      dispatch(fetchUsers(params));
    }
  }, [roles]);

  useEffect(() => {
    if (!roles_list) {
      let params = {
        url: URL_INDEX_ROLES,
      };
      dispatch(fetchRoles(params));
    }
  }, []);
  return (
    <main class="main-content">
      <div class="position-relative iq-banner">
        <div class="iq-navbar-header" style={{ height: "215px" }}>
          <div class="container-fluid iq-container">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center">
                  <div class="box-img">
                    <img src="/assets/images/icon-equivalencias.svg" />
                  </div>
                  <h1 class="ms-2 mb-0">{h1}</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              class="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div class="conatiner-fluid content-inner mt-n5 py-0">
        <div class="row">
          <div class="col-md-12 bg-grey rounded">
            <div class="row row-cols-1">
              <div class="col-12 mt-5 mb-3">
                {/* FILTERS */}
                <Filters rol={rol} setModal={setModalUsers} />
              </div>
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    {users ? (
                      <ContentTable
                        data={users}
                        setModal={setModalUsers}
                        params={{ url: URL_INDEX_USERS, rol: roles }}
                        dispatch={dispatch}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}

      {modalUsers ? (
        <ModalUser
          setModal={setModalUsers}
          setSelect={setSelect}
          select={select}
          providers={providers}
          province={province}
          city={city}
          dispatch={dispatch}
          rol={roles}
          roles={roles_list}
        />
      ) : (
        ""
      )}

      {modalProviders ? (
        <ModalProviders
          /**PRIMARY***/
          setSelect={setSelect}
          select={select}
          setModalProviders={setModalProviders}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}

      {modalOrderPurchase ? (
        <ModalOrderPurchase
          setSelect={setSelect}
          select={select}
          setModalOrderPurchase={setModalOrderPurchase}
          dispatch={dispatch}
        />
      ) : (
        ""
      )}
      <BtnSticky
        setModalProviders={setModalProviders}
        setModalOrderPurchase={setModalOrderPurchase}
      />
    </main>
  );
};

export default Users;
