import { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/tables/Table";
import { authContext } from "../../../context/AuthContext";
import { fetchAnalyzeExpoLot } from "../../../slices/exportLot/Index";
import { ConcatExpoLot } from "../../../utils/ConcatExpoLot";

const Consolidated = ({
  data,

  setParams,
  params,
  dispatch,
  setSelect,
  setModal,
  status,
}) => {
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_EXPORT_LOT } = values;

  const [loading, setLoading] = useState(false);
  /*********************************************** INITIAL STATE ***********************************************************/
  const campos = [
    {
      name: "Nº LOTE",
      order: {
        state: true,
        param: "id",
        active: params.sort,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "CODIGO LOTE",
      order: {
        state: true,
        param: "id",
        active: params.sort,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "Nº CONTENEDOR",
      order: {
        state: false,
        param: "id",
        active: params.sort,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "TAMBORES",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "CONSOLIDACION",
      order: {
        state: true,
        param: "consolidation_date",
        active: params.sort,
      },
      class: "border-0 d-flex justify-content-center align-items-center",
    },
    {
      name: "TIPO DE MIEL",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-center align-items-center",
    },
    {
      name: "KG NETO",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-center align-items-center",
    },
    {
      name: "ACCIONES",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-center align-items-center",
    },
  ];
  const order = ["name", "lot_export_code", "container", "honey_drums_count", "consolidation_date", "type", "honey_drums_net"];
  const contents = data? ConcatExpoLot(data.data) : [];
  const actions = [
    {
      title: "Descargar",
      name: "descargar",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-download text-primary" viewBox="0 0 16 16"><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/><path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/></svg>',
      id: "descargar",

      class: "btn border-0 d-flex align-items-center justify-content-center",
      text: "",
    },
    {
      title: "Editar orden",
      name: "editar_lote",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 13 11" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:evenodd;fill:rgb(94.509804%,41.568627%,10.588235%);fill-opacity:1;" d="M 13 5.75 C 13 5.75 10.65625 1.5 6.75 1.5 C 2.84375 1.5 0.5 5.75 0.5 5.75 C 0.5 5.75 2.84375 10 6.75 10 C 10.65625 10 13 5.75 13 5.75 Z M 1.417969 5.75 C 1.792969 6.316406 2.230469 6.847656 2.714844 7.328125 C 3.71875 8.324219 5.09375 9.226562 6.75 9.226562 C 8.40625 9.226562 9.78125 8.324219 10.789062 7.328125 C 11.273438 6.847656 11.707031 6.316406 12.085938 5.75 C 11.707031 5.183594 11.273438 4.652344 10.789062 4.171875 C 9.78125 3.175781 8.40625 2.273438 6.75 2.273438 C 5.09375 2.273438 3.71875 3.175781 2.710938 4.171875 C 2.226562 4.652344 1.792969 5.183594 1.414062 5.75 Z M 1.417969 5.75 "/><path style=" stroke:none;fill-rule:evenodd;fill:rgb(94.509804%,41.568627%,10.588235%);fill-opacity:1;" d="M 6.75 3.785156 C 6.230469 3.785156 5.730469 3.992188 5.359375 4.359375 C 4.992188 4.730469 4.785156 5.230469 4.785156 5.75 C 4.785156 6.269531 4.992188 6.769531 5.359375 7.140625 C 5.730469 7.507812 6.230469 7.714844 6.75 7.714844 C 7.269531 7.714844 7.769531 7.507812 8.140625 7.140625 C 8.507812 6.769531 8.714844 6.269531 8.714844 5.75 C 8.714844 5.230469 8.507812 4.730469 8.140625 4.359375 C 7.769531 3.992188 7.269531 3.785156 6.75 3.785156 Z M 4 5.75 C 4 5.019531 4.289062 4.320312 4.804688 3.804688 C 5.320312 3.289062 6.019531 3 6.75 3 C 7.480469 3 8.179688 3.289062 8.695312 3.804688 C 9.210938 4.320312 9.5 5.019531 9.5 5.75 C 9.5 6.480469 9.210938 7.179688 8.695312 7.695312 C 8.179688 8.210938 7.480469 8.5 6.75 8.5 C 6.019531 8.5 5.320312 8.210938 4.804688 7.695312 C 4.289062 7.179688 4 6.480469 4 5.75 Z M 4 5.75 "/></g></svg>',
      id: "editar",
      
      class: "btn border-0 d-flex align-items-center justify-content-center",
      text: "",
    },
  ];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = data
    ? data
    : {
        current_page: 0,
        first_page_url: 0,
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: "",
        to: 0,
        total: 0,
      };
  const pagination = {
    type: 1,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  /**********************************************************************************************************************/

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "page":
        {
          let params = {
            url: value,
            consolidated:
              status == "pending" ? 0 : status == "consolidated" ? 1 : null,
            order_by: "id",
            ordering: "desc",
          };
          //dispatch(fetchAnalyzeLot(params));
        }
        break;
        case "editar_lote":
          {
            return navigate("/dashboard/lot/details/" + value.id);
          }
          break;
      case "download-xls":
        {
        }
        break;
      case "download-print":
        {
          return true;
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":
      {
        setParams({
          ...params,
          search: e.target.value,
        });
        
        if (
          e.target.value.length > params.search.length
        ) {

          return dispatch(fetchAnalyzeExpoLot({
            url: URL_INDEX_EXPORT_LOT,
            search: e.target.value,
            consolidated:
              status == "pending" ? 0 : status == "consolidated" ? 1 : null,
              contain_contract: 1,
            order_by: "id",
            ordering: "desc",
          }));
        }

        if (e.target.value.trim() == "") {
          return  dispatch(fetchAnalyzeExpoLot({
            url: URL_INDEX_EXPORT_LOT,
            search: e.target.value,
            consolidated:
              status == "pending" ? 0 : status == "consolidated" ? 1 : null,
              contain_contract: 1,
            order_by: "id",
            ordering: "desc",
          }));
        }
      }
      break;
    }
  };
  return (
    <div class="card-body">
      <Table
        print={true}
        campos={campos}
        order={order}
        contents={contents}
        actions={actions}
        pagination={pagination}
        onClick={onClick}
        onChange={onChange}
      />
    </div>
  );
};

export default Consolidated;
