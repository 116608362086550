import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContentSwiper from "../../../components/sliders/Swiper";
import { authContext } from "../../../context/AuthContext";
import { fetchOrderPurchaseList } from "../../../slices/orderPurchase/Index";
import ContentPurchase from "../Contents/ContentPurchase";
import Filters from "../filters/Filters";
import Notifications from "../notifications/Notifications";

const Buyer = ({ orderPurchase, setParams, params, setSelect, setModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_PURCHASE } = values;

  /*********************************************** INITIAL STATE ***********************************************************/
  const [modal_orderWithdrawal, setModalOrderWithdrawal] = useState(false);
  const [SwiperMediaQuery, setSwiperMediaQuery] = useState(4);

  //Sliders
  const swipper_oc = [
    {
      title: "Ordenes Pendientes",
      link: "/dashboard/orderpurchase/pending",
      lineLink: "Ver Todos",

      total: orderPurchase ? orderPurchase.pending_orders : 0,
      icon: "/assets/images/icon-advertencia.svg",
      name: "redirect",
    },
    {
      title: "Ordenes Entregadas",
      link: "/dashboard/orderpurchase/submitted",
      lineLink: "Ver Todos",

      total: orderPurchase ? orderPurchase.delivered_orders : 0,
      icon: "/assets/images/icon-check.svg",
      name: "redirect",
    },
  ];

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "redirect":
        {
          navigate(value);
        }
        break;
      case "modalGenerateOrderWithdrawal":
        {
          setModalOrderWithdrawal(true);
        }
        break;
    }
  };

  useEffect(() => {
    //Media Query Swipper
  }, []);
  return (
    <div className="row">
      <div className="col-md-12 col-lg-8">
        <div className="overflow-hidden d-slider1">
          <ContentSwiper
            SwiperMediaQuery={SwiperMediaQuery}
            contents={swipper_oc}
            onClick={onClick}
          />
        </div>

        <div>
          <Filters
            h1={"Estado de órdenes activas"}
            URL={URL_INDEX_PURCHASE}
            fetch={fetchOrderPurchaseList}
            initialParams={{
              url: URL_INDEX_PURCHASE,
              order_state_id: null,
              order_state_id: null,
              sort: [
                {
                  order_by: "id",
                  ordering: "desc",
                },
              ],
            }}
            //btn={{redirect: "/dashboard/orderpurchase/analyze/pending"}}
          />
          <ContentPurchase
            orderPurchase={orderPurchase}
            dispatch={dispatch}
            setSelect={setSelect}
            setModal={setModal}
            setParams={setParams}
            params={params}
          />
        </div>
      </div>

      {/* Notificactions */}

      <Notifications />
    </div>
  );
};

export default Buyer;
