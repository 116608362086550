const Input = ({ onChange, label, name, type, value, validate, svg, nameType, uniqueKey, disabled, placeholder }) => {
  return (
    <div className="form-group m-0 w-100">
      <label for="email" className={label == "" ? "d-none" : "form-label"}>
        {label}
      </label>
      <div className="input-group">
        {
          svg
          ? <div className="input-group-text">
             <div contentEditable='true' dangerouslySetInnerHTML={{ __html: svg }}></div>
          </div>
          : ""
        }
        <input
          id={uniqueKey}
          type={type}
          value={value}
          className="form-control"
          aria-describedby="email"
          placeholder={placeholder? placeholder : ""}
          name={name}
          onChange={(event) => onChange(event)}

          disabled={disabled}
        />
        {
          nameType
          ? <span className="input-group-text right">{nameType}</span>
          : ""
        }
      </div>

      {validate.validate ? (
        <div className="text-danger">{validate.msg}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Input;
