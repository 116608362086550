import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  //Lista
  
  homogenized_pending: null,
  homogenized_consolidated: null,
  homogenized_mermas: null,


  homogeneize_ready: null,
  homogeneize_fractioner: null,

  search: null,
}

export const homogenizedSlice = createSlice({
  name: "homogenize",
  initialState: initialState,
  reducers: {
    /* Export */
    setHomogenized_pending: (state, action) => {
      state.homogenized_pending = action.payload
    },
    setHomogenized_consolidated: (state, action) => {
      state.homogenized_consolidated = action.payload
    },
    setHomogenized_mermas: (state, action) => {
      state.homogenized_consolidated = action.payload
    },
    /* Storage */
    setHomogenized_ready: (state, action) => {
      state.homogeneize_ready = action.payload
    },

    setHomogenize_fractioner: (state, action) => {
      state.homogeneize_fractioner = action.payload
    },
  },
});


//Async functions
export const fetchHomogenized = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params.consolidated) {
        case 0:{ //Pending
          return dispatch(setHomogenized_pending(result.data));
        }
        break;
        case 1:{ //Analize
          return dispatch(setHomogenized_consolidated(result.data));
        }
        break;
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchHomogenizedReady = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params.fully_fractionated) {
        case 0:{
          dispatch(setHomogenized_ready(result.data))
        }break;
        case 1:{
          dispatch(setHomogenize_fractioner(result.data))
        }break;
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const {setHomogenized_consolidated, setHomogenized_pending, setHomogenized_ready, setHomogenize_fractioner} = homogenizedSlice.actions;

export const homogenizedState = (state) => state.homogenize;

export default homogenizedSlice.reducer;