import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  colors: null,
};

export const colorSlice = createSlice({
  name: "colors",
  initialState: initialState,
  reducers: {
    setColor: (state, action) => {
      state.colors = action.payload;
    },
  },
});


//Async functions
export const fetchColor = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      dispatch(setColor(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const {setColor} = colorSlice.actions;

export const colorState = (state) => state.colors;

export default colorSlice.reducer;