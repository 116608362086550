const Thead = ({ campos, onClick }) => {
  const setOrderBy = (setorder, param) => {
    let order = setorder.filter((order) => order.order_by == param);
    if (order.length > 0) {
      return order[0];
    } else {
      return {
        order_by: "sin campo seleccionado",
        ordering: "sin campo seleccionado",
      };
    }
  };
  return (
    <thead>
      <tr>
        {campos.map((campo) => (
          <th className="p-1">
            <div className={campo.class}>
              <div className="p-2">{campo.name}</div>
              {campo.order ? (
                campo.order.state ? (
                  <div>
                    {
                      <>
                        <label className="btn px-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill={
                              setOrderBy(campo.order.active, campo.order.param)
                                .ordering == "asc"
                                ? "black"
                                : "grey"
                            }
                            class="bi bi-arrow-up"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                            />
                          </svg>

                          <button
                            className="d-none"
                            name="order"
                            onClick={(event) =>
                              onClick(event, {
                                param: String(campo.order.param),
                                order: "asc",
                              })
                            }
                          ></button>
                        </label>
                        <label className="btn px-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill={
                              setOrderBy(campo.order.active, campo.order.param)
                                .ordering == "desc"
                                ? "black"
                                : "grey"
                            }
                            class="bi bi-arrow-down"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                            />
                          </svg>

                          <button
                            className="d-none"
                            name="order"
                            onClick={(event) =>
                              onClick(event, {
                                param: String(campo.order.param),
                                order: "desc",
                              })
                            }
                          ></button>
                        </label>
                      </>
                    }
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default Thead;
