import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { SketchPicker, ChromePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import reactCSS from "reactcss";
import { authContext } from "../../context/AuthContext";
import ApiRest from "../../hooks/useRest";
import { colorState, fetchColor } from "../../slices/colors/Index";
import { fetchExport } from "../../slices/exportLot/Index";
import Alerts from "../alerts/Alerts";
import BtnAccept from "../forms/BtnAccept";

import Input from '../forms/Input'
import Select from "../forms/Select";
import TextTarea from "../forms/TextTarea";

const ModalColorPicker = ({ setModal, select, setSelect }) => {
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { values, forms } = context;
  //context
  const {
    URL_COLOR_EXPORT_LOT,
    URL_INDEX_EXPORT_LOT,
    URL_INDEX_COLOR,
    URL_CREATE_COLOR,
  } = values;
  const { FORM_CHANGE_COLOR, FORM_COLOR } = forms;

  const _colors = useSelector(colorState);
  const colors = _colors.colors;

  const [form, setForm] = useState(
    JSON.parse(JSON.stringify(FORM_CHANGE_COLOR))
  );
  const [formColor, setFormColor] = useState(
    JSON.parse(JSON.stringify(FORM_COLOR))
  );
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState({
    displayColorPicker: false,
    color: {
      r: "241",
      g: "112",
      b: "19",
      a: "1",
    },
  });
  const [color_secondary, setColorSecondary] = useState({
    displayColorPicker: false,
    color: {
      r: "241",
      g: "112",
      b: "19",
      a: "1",
    },
  });

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${color.color.r}, ${color.color.g}, ${color.color.b}, ${color.color.a})`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "relative",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const styles_secondary = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${color_secondary.color.r}, ${color_secondary.color.g}, ${color_secondary.color.b}, ${color_secondary.color.a})`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "relative",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "close":
        {
          setSelect(null);
          setModal(false);
        }
        break;
      case "guardar":
        {
          let response = "";

          if (
            form.color_primario[0].trim() == "" ||
            form.color_secundario[0].trim() == ""
          ) {
            return Alerts({
              msg: "Alert",
              title: "Selecciona un color valido.",
              code: "201",
              type: "warning",
            });
          }

          let formDataColor = new FormData();

          if (
            form.color_primario[0] == "nuevo_color" ||
            form.color_secundario[0] == "nuevo_color"
          ) {
            if (
              color.hasOwnProperty("hex") ||
              form.color_primario[0] == "nuevo_color"
            ) {
              //
              if (form.color_primario[0] == "nuevo_color") {
                formDataColor.append("name", formColor.nombre_primario[0]);
                formDataColor.append("hex_code", color.hex);

                response = await ApiRest({
                  url: URL_CREATE_COLOR,
                  formData: formDataColor,
                  setLoading: setLoading,
                  method: "post",
                });
              }

              let params = {
                url: URL_INDEX_COLOR,
              };
              dispatch(fetchColor(params));

              Alerts({
                msg: "Alert",
                title: "Nuevo color creado.",
                code: "201",
                type: "warning",
              });
            }

            if (
              color_secondary.hasOwnProperty("hex") &&
              form.color_secundario[0] == "nuevo_color"
            ) {
              formDataColor.append("name", formColor.nombre_secundario[0]);
              formDataColor.append("hex_code", color_secondary.hex);

              response = await ApiRest({
                url: URL_CREATE_COLOR,
                formData: formDataColor,
                setLoading: setLoading,
                method: "post",
              });

              let params = {
                url: URL_INDEX_COLOR,
              };
              dispatch(fetchColor(params));

              Alerts({
                msg: "Alert",
                title: "Nuevo color creado.",
                code: "201",
                type: "warning",
              });
            }

            //
            let formData = new FormData();

            formData.append("_method", "PUT");
            formData.append("id", select);
            formData.append("primary_color", color.hex);
            formData.append("secondary_color", color_secondary.hex);
            if (form.comentario[0].trim() != "")
              formData.append("color_description", form.comentario[0]);

            //
            response = await ApiRest({
              url: URL_COLOR_EXPORT_LOT,
              formData: formData,
              setLoading: setLoading,
              method: "post",
            });

             //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Egresando lote.",
              code: "201",
              type: "success",
            });

            //Actualizar datos providers
            let params = {
              url: URL_INDEX_EXPORT_LOT,
              consolidated: 1,
              contain_contract: 1,
              fully_fractionated: 1,
              finished: 1,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchExport(params));

            params = {
              url: URL_INDEX_EXPORT_LOT,
              consolidated: 1,
              contain_contract: 1,
              fully_fractionated: 1,
              finished: 0,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchExport(params));

            //cerrar modal
            return setModal(false);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
          }

           //
           let formData = new FormData();

           formData.append("_method", "PUT");
           formData.append("id", select);
           formData.append("primary_color", form.color_primario[0]);
           formData.append("secondary_color", form.color_secundario[0]);
           if (form.comentario[0].trim() != "")
             formData.append("color_description", form.comentario[0]);

           //
           response = await ApiRest({
             url: URL_COLOR_EXPORT_LOT,
             formData: formData,
             setLoading: setLoading,
             method: "post",
           });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Egresando lote.",
              code: "201",
              type: "success",
            });

            //Actualizar datos providers
            let params = {
              url: URL_INDEX_EXPORT_LOT,
              consolidated: 1,
              contain_contract: 1,
              fully_fractionated: 1,
              finished: 1,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchExport(params));

            params = {
              url: URL_INDEX_EXPORT_LOT,
              consolidated: 1,
              contain_contract: 1,
              fully_fractionated: 1,
              finished: 0,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchExport(params));

            //cerrar modal
            return setModal(false);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
    }
  };

  const onChange = (e, color, set) => {
    switch (e.target.name) {
      case "nombre_secundario":
      case "nombre_primario":
        {
          let setValue = formColor;

          setValue[e.target.name][0] = e.target.value;

          return setFormColor({
            ...formColor,
            [e.target.name]: setValue[e.target.name],
          });
        }
        break;
      case "comentario":
      case "color_secundario":
      case "color_primario":
        {
          let setValue = form;

          setValue[e.target.name][0] = e.target.value;

          return setForm({
            ...form,
            [e.target.name]: setValue[e.target.name],
          });
        }
        break;
      case 1:
        {
          console.log(color);
          setColor({ ...color, color: color.rgb, displayColorPicker: true });
        }
        break;
      case 2:
        {
          setColorSecondary({
            ...color,
            color: color.rgb,
            displayColorPicker: true,
          });
        }
        break;
    }
  };

  useEffect(() => {
    if (!colors) {
      let params = {
        url: URL_INDEX_COLOR,
      };
      dispatch(fetchColor(params));
    }
  }, []);

  console.log(colors);
  console.log(form);
  console.log(color);
  return (
    <div
      className="modal fade show bg-dark bg-opacity-25 border-0 min-vh-100 h-100 modal-transition bg-modal-blur"
      style={{ display: "block", zIndex: 5 }}
      id="focus-modal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        style={{ maxWidth: "500px" }}
      >
        {colors ? (
          <div className="modal-content bg-modal-blanco" style={{ zIndex: 5 }}>
            <label className="btn btn-close">
              <button
                type="button"
                className="d-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                name="close"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
            <div className="modal-header w-100 border-0 p-3">
              <h3
                className="modal-title text-start  border-0 w-100"
                id="nueva-ordenModalLabel"
              >
                Egresar Lote
              </h3>
            </div>
            <div className="modal-body p-3">
              <div className="d-flex justify-content-between mb-4">
                <div>
                  <div className="mb-3">
                    <Select
                      onChange={onChange}
                      label="Selecionar color primario"
                      name="color_primario"
                      type=""
                      value={[
                        ...colors,
                        { name: "nuevo color", hex_code: "nuevo_color" },
                      ]}
                      showIndex="name"
                      sendIndex="hex_code"
                      validate={{ message: "", validation: false }}
                      svg={true}
                    />
                  </div>
                  {form.color_primario[0] == "nuevo_color" ? (
                    <div>
                      <Input
                        onChange={onChange}
                        label={"Nombre"}
                        name={"nombre_primario"}
                        type={"text"}
                        value={formColor.nombre_primario[0]}
                        svg={
                          ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                        }
                        validate={{ msg: "", validate: false }}
                        disabled={false}
                      />
                      <div
                        className="w-100 mt-2"
                        style={styles.swatch}
                        onClick={(event) =>
                          setColor({ ...color, displayColorPicker: true })
                        }
                      >
                        Seleccionar color
                        <div className="w-100" style={styles.color} />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {color.displayColorPicker ? (
                    <div style={styles.popover}>
                      <div
                        style={styles.cover}
                        onClick={(event) =>
                          setColor({ ...color, displayColorPicker: false })
                        }
                      />
                      <SketchPicker
                        color={color.color}
                        onChange={(color) =>
                          setColor({
                            ...color,
                            color: color.rgb,
                            displayColorPicker: true,
                          })
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  <div className="mb-3">
                    <Select
                      onChange={onChange}
                      label="Selecionar color secundario"
                      name="color_secundario"
                      type=""
                      value={[
                        ...colors,
                        { name: "nuevo color", hex_code: "nuevo_color" },
                      ]}
                      showIndex="name"
                      sendIndex="hex_code"
                      validate={{ message: "", validation: false }}
                      svg={true}
                    />
                  </div>
                  {form.color_secundario[0] == "nuevo_color" ? (
                    <div>
                      <Input
                        onChange={onChange}
                        label={"Nombre"}
                        name={"nombre_secundario"}
                        type={"text"}
                        value={formColor.nombre_secundario[0]}
                        svg={
                          ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                        }
                        validate={{ msg: "", validate: false }}
                        disabled={false}
                      />
                      <div
                        className="w-100 mt-2"
                        style={styles_secondary.swatch}
                        onClick={(event) =>
                          setColorSecondary({
                            ...color_secondary,
                            displayColorPicker: true,
                          })
                        }
                      >
                        Seleccionar color
                        <div className="w-100" style={styles_secondary.color} />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {color_secondary.displayColorPicker ? (
                    <div style={styles_secondary.popover}>
                      <div
                        style={styles_secondary.cover}
                        onClick={(event) =>
                          setColorSecondary({
                            ...color_secondary,
                            displayColorPicker: false,
                          })
                        }
                      />
                      <SketchPicker
                        color={color_secondary.color}
                        onChange={(color) =>
                          setColorSecondary({
                            ...color,
                            color: color.rgb,
                            displayColorPicker: true,
                          })
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <TextTarea
                  onChange={onChange}
                  label={"Descripcion"}
                  name={"comentario"}
                  type={"text"}
                  value={form.comentario[0]}
                  validate={{ msg: "", validate: false }}
                />
              </div>
              <div class="col-md-12 d-flex justify-content-end align-items-end">
                <div class="btn-group">
                  <BtnAccept
                    onClick={onClick}
                    name={"guardar"}
                    value={"Guardar"}
                    loading={loading}
                    loadingMsg={"Guardando Lote"}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ModalColorPicker;
