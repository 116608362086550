import ReactTooltip from "react-tooltip";
import { LableEditable, TableEditable } from "../../utils/formEditable";
import { OrderEditableKeys } from "../../utils/OrderEditableKeys";

const EditBody = ({
  contents,
  order,
  actions,
  onBlur,
  onClick,
  onChange,
  actionSelect,
}) => {
  return (
    <tbody className="border-0 bg-transparent">
      {contents.map((content, index) => (
        <tr>
          {actionSelect ? (
            <td className="border-bottom">
              <label className={actionSelect.class}>
                <div
                  contentEditable="true"
                  dangerouslySetInnerHTML={{ __html: actionSelect.icon }}
                ></div>
                <button
                  className="d-none"
                  name={actionSelect.name}
                  onClick={(event) => onClick(event, { content, index })}
                ></button>
              </label>
            </td>
          ) : (
            ""
          )}

          {OrderEditableKeys(content, order).map((item) => (
            <td className="border-0 border-bottom p-1 m-0 align-middle">
              <TableEditable
                content={item}
                onChange={onChange}
                onClick={onClick}
                index={index}
              />
            </td>
          ))}

          {actions ? (
            <td
              className={
                actions.length > 0
                  ? "border-bottom p-4 d-flex justify-content-center"
                  : "d-none"
              }
            >
              {actions.map((action) => (
                <label class={action.class} data-tip data-for={action.id}>
                  <div
                    className="me-1"
                    contentEditable="true"
                    dangerouslySetInnerHTML={{ __html: action.icon }}
                  ></div>
                  {action.text}
                  <button
                    className="d-none"
                    name={action.name}
                    onClick={(event) => onClick(event, content)}
                  ></button>
                  <ReactTooltip
                    id={action.id}
                    data-bs-placement="top"
                    className="custom-tooltip py-0 px-2"
                    effect="solid"
                    style={{ zIndex: 4 }}
                  >
                    {action.title}
                  </ReactTooltip>
                </label>
              ))}
            </td>
          ) : (
            ""
          )}
        </tr>
      ))}

      {contents.length == 0 ? (
        <tr className="">
          {actions ? (
            <td colSpan={order.length + actions.length}>
              <div className="d-flex justify-content-center py-3 fs-4">
                No existen datos actualmente
              </div>
            </td>
          ) : (
            <td colSpan={order.length}>
              <div className="d-flex justify-content-center py-3 fs-4">
                No existen datos actualmente
              </div>
            </td>
          )}
        </tr>
      ) : (
        ""
      )}
    </tbody>
  );
};

export default EditBody;
