import { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../../components/tables/Table";
import { authContext } from "../../../../context/AuthContext";
import { fetchAnalyzeLot } from "../../../../slices/laboratory/Index";
import { ConcatAnalizeLot } from "../../../../utils/ConcatAnalizeLot";

const Pending = ({
  data,

  setParams,
  params,
  dispatch,
  setSelect,
  setModal,
  label,
}) => {
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_ANALIZE } = values;

  const [loading, setLoading] = useState(false);
  /*********************************************** INITIAL STATE ***********************************************************/
  const campos = [
    {
      name: "LOTE",
      order: {
        state: true,
        param: 'id',
        active: params.sort
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "O/C",
      order: {
        state: false,
        param: 'id',
        active: params.sort
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "PRODUCTOR",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ACCIONES",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-center align-items-center",
    },
  ];
  const order = ["id", "oc_name", "provider_name"];
  const contents = data ? ConcatAnalizeLot(data.data) : [];
  const actions = [
    {
      option: "",
      title: "",
      name: "analizar",
      icon: "",
      id: "",
      class:
        "btn btn-secondary border-0 d-flex align-items-center justify-content-center",
      text: "Analizar Lote",
    },
  ];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = data
    ? data
    : {
        current_page: 0,
        first_page_url: 0,
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: "",
        to: 0,
        total: 0,
      };
  const pagination = {
    type: 1,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  /**********************************************************************************************************************/
 

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "page":
        {
          let params = {
            url: value,
            order_state_id:
              label == "pending" ? 1 : label == "analize" ? 2 : null,
            order_by: "id",
            ordering: "desc",
          };
          dispatch(fetchAnalyzeLot(params));
        }
        break;
      case "analizar":{
          return navigate("/dashboard/lot/analyze/details/"+value.id+"/"+value.purchase_order_id);
      } break;
      case "download-xls":
          {
          }
          break;
      case "download-print":
          {
            return true;
          }
          break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "":
        {
        }
        break;
    }
  };

  return (
    <div class="card-body">
      <Table
        print={true}
        campos={campos}
        order={order}
        contents={contents}
        actions={actions}
        pagination={pagination}
        onClick={onClick}
        onChange={onChange}
      />
    </div>
  );
};

export default Pending