export const OrderTableKeys = (contents, orders) => {

  let result = [];



  orders.map((order) => {
  Object.keys(contents).map((key) => {
    
    if(order == key){
      result.push(contents[key]);
    }

    })
  
})

  return result
}