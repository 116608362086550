import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/loads/Spinner";
import { authContext } from "../../../context/AuthContext";
import {
  fetchAnalyzeLot,
  laboratoryState,
} from "../../../slices/laboratory/Index";
import { meState } from "../../../slices/users/Index";
import Analyzed from "./analyzed";
import Filters from "./filters/Filters";
import Pending from "./pending";

const AnalyzeLot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useParams();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_LOT } = values;
  //Initial States
  const _user = useSelector(meState);
  const _lab = useSelector(laboratoryState);

  const lotPending = _lab.lotPending;
  const lotAnalyze = _lab.lotAnalyze;
  const me = _user.me;
  const rol_id = me.roles[0].id;

  const [select, setSelect] = useState(null);
  //

  const [params, setParams] = useState({
    url: URL_INDEX_LOT,
    order_state_id: status == "pending" ? 1 : status == "analize" ? 2 : null,
    search: "",
    start_date: "",
    end_date: "",
    sort: [
      {
        order_by: "id",
        ordering: "desc",
      },
    ],
  });

  //Title
  const h1 =
    status == "pending"
      ? "Lotes pendientes"
      : status == "analyze"
      ? "Lotes analizadas"
      : "";

  useEffect(() => {
    if (!lotPending || !lotAnalyze) {
      let params = {
        url: URL_INDEX_LOT,
        analyzed_status:
          status == "pending" ? 0 : status == "analyze" ? 1 : null,
        with_extra_analysis: 1,
        sort: [
          {
            order_by: "id",
            ordering: "desc",
          },
        ],
      };
      dispatch(fetchAnalyzeLot(params));
    }
  }, [status]);
  return (
    <main className="main-content">
      <div className="position-relative iq-banner">
        <div className="iq-navbar-header" style={{ height: "215px" }}>
          <div className="container-fluid iq-container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <div className="box-img">
                    <img src="/assets/images/icon-ingreso.svg" width="80%" />
                  </div>
                  <h1 className="ms-2 mb-0">{select ? "O/C Muestras" : h1}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="iq-header-img">
            <img
              src="/assets/images/dashboard/top-header.png"
              alt="header"
              className="theme-color-default-img img-fluid w-100 h-100 animated-scale"
            />
          </div>
        </div>
      </div>
      <div className="conatiner-fluid content-inner mt-n5 py-0">
        <div className="row">
          <div className="col-md-12 bg-grey rounded">
            <div className="row row-cols-1">
              <div className="col-12 mt-5 mb-3">
                {/* Filters */}
                <Filters
                  label={status}
                  setParams={setParams}
                  params={params}
                  dispatch={dispatch}
                />
              </div>

              <div className="col-md-12">
                <div className="card">
                  {lotPending || lotAnalyze ? (
                    status == "pending" && lotPending ? (
                      <Pending
                        data={lotPending}
                        setParams={setParams}
                        params={params}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={() => {}}
                        label={status}
                      />
                    ) : status == "analyze" && lotAnalyze ? (
                      <Analyzed
                        data={lotAnalyze}
                        setParams={setParams}
                        params={params}
                        dispatch={dispatch}
                        setSelect={setSelect}
                        setModal={() => {}}
                        label={status}
                      />
                    ) : (
                      <Spinner />
                    )
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AnalyzeLot;
