import { useContext, useState, lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authContext } from "../../context/AuthContext";
//Components
import Form from "./forms/Form";

const Login = () => {
  const context = useContext(authContext);
  const { values } = context;

  //Initial States
  const {URL_LOGIN} = values;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    correo: ["", "required"], //email
    contraseña: ["", "required"], //password
  });

  const [validation, setValidation] = useState({
    correo: { validate: false, msg: "" }, //False paso la validacion || True validacion no pasada
    contraseña: { validate: false, msg: "" },
    error: { validate: false, msg: "" },
  });

  return (
    <div className="wrapper">
      <section className="login-content">
        <div className="row m-0 align-items-center bg-white vh-100">
          <div className="col-md-6">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="card card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                  <div className="card-body">
                    <a
                      href="index.php"
                      className="navbar-brand d-flex align-items-center justify-content-center mb-3"
                    >
                    <img src="assets/images/geomiel-logo.svg" loading="lazy" style={{width: "200px"}} alt="Geomiel" />
                    </a>
                    <h2 className="mb-2 text-center">Ingresar</h2>
                    <p className="text-center">
                      Iniciá sesión con tu cuenta de Geomiel.
                    </p>
                    <Form
                      form={form}
                      setForm={setForm}
                      validation={validation}
                      setValidation={setValidation}
                      loading={loading}
                      setLoading={setLoading}
                      navigate={navigate}
                      dispatch={dispatch}
                      url={URL_LOGIN}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-md-block d-none bg-primary p-0 vh-100 overflow-hidden">
            <img
              src="assets/images/auth/01.png"
              className="img-fluid gradient-main animated-scaleX"
              alt="images"
              loading="lazy"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
