import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  clients: null,

  search: [],
};

export const clientSlice = createSlice({
  name: "clients",
  initialState: initialState,
  reducers: {
    setClient: (state, action) => {
      state.clients = action.payload;
    },
    setSearchClient: (state, action) => {
      state.search = action.payload;
    },
  },
});


//Async functions
export const fetchClients = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      dispatch(setClient(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchSearchClient = (params) => (dispatch) => {
  dispatch(setSearchClient(null))

  clientAxios
    .get(params.url, { params})
    .then((result) => {
      dispatch(setSearchClient(result.data.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};



export const { setClient, setSearchClient } = clientSlice.actions;

export const clientState = (state) => state.clients;

export default clientSlice.reducer;