import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";
import { setLot_pending } from "../laboratory/Index";

const initialState = {
  //Lista
  
  lotPending: null,
  lotConsolidated: null,

  exportPending: null,
  exportRealized: null,


  search: null,
}

export const expoLotSlice = createSlice({
  name: "expoLot",
  initialState: initialState,
  reducers: {
    setExpoLot_pending: (state, action) => {
      state.lotPending = action.payload
    },
    setExpoLot_consolidated: (state, action) => {
      state.lotConsolidated = action.payload
    },
    setExport_pending: (state, action) => {
      state.exportPending = action.payload
    },
    setExport_analized: (state, action) => {
      state.exportRealized = action.payload
    }
  },
});


//Async functions
export const fetchAnalyzeExpoLot = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params.consolidated) {
        case 0:{ //Pending
          return dispatch(setExpoLot_pending(result.data));
        }
        break;
        case 1:{ //consolidated
          return dispatch(setExpoLot_consolidated(result.data));
        }
        break;
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchExport = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params.finished) {
        case 0:{ //Pending
          return dispatch(setExport_pending(result.data));
        }
        break;
        case 1:{ //consolidated
          return dispatch(setExport_analized(result.data));
        }
        break;
      }
      
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const {setExpoLot_consolidated, setExpoLot_pending, setExport_analized, setExport_pending} = expoLotSlice.actions;

export const expoLotState = (state) => state.expoLot;

export default expoLotSlice.reducer;