import { Button } from "bootstrap";
import Alerts from "../components/alerts/Alerts";
import Maps from "../pages/maps/modals/Maps";

const Test = () => {
  const onClick = (e, value) => {
    switch (e.target.name) {
      case "delete":{
        return Alerts({
          msg: "Alert",
          title: "",
          code: "201",
          type: "delete",
        });
      } break;
      case "success":
        {
          return Alerts({
            msg: "Alert",
            title: "Creado exitosamente",
            code: "201",
            type: "success",
          });
        }
        break;
      case "error":
        {
          return Alerts({
            msg: "Alert",
            title: "Ocurrio un error",
            code: "201",
            type: "error",
          });
        }
        break;
      case "warning":
        {
          return Alerts({
            msg: "Alert",
            title: "Advertencia de uso",
            code: "201",
            type: "warning",
          });
        }
        break;
        case "exito":
          {
            return Alerts({
              msg: "Alert",
              title: "Realizado exitosamente",
              code: "201",
              type: "exito",
            });
          }
          break;
          case "arrival":
            {
              return Alerts({
                msg: "Alert",
                title: "9:40hs",
                code: "201",
                type: "arrival",
              });
            }
            break;
    }
  };

  return (
    <div className="container-md">

      <button
        className="btn btn-success me-2"
        name="success"
        onClick={(event) => onClick(event)}
      >
        Success Create
      </button>

      <button
        className="btn btn-danger me-2"
        name="error"
        onClick={(event) => onClick(event)}
      >
        Success Create
      </button>

      <button
        className="btn btn-warning me-2"
        name="warning"
        onClick={(event) => onClick(event)}
      >
        Success Create
      </button>

      <button
        className="btn btn-success"
        name="exito"
        onClick={(event) => onClick(event)}
      >
        Success Create
      </button>

      <button
        className="btn btn-success"
        name="arrival"
        onClick={(event) => onClick(event)}
      >
        Arrival
      </button>

      <button
        className="btn btn-danger"
        name="delete"
        onClick={(event) => onClick(event)}
      >
        Delete
      </button>

      <div className="text-center my-5">
        <div class="spinner-grow me-1 bg-miel" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow me-1 bg-miel" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow me-1 bg-miel" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div>
          <Maps/>
      </div>
    </div>
  );
};

export default Test;
