import { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Alerts from "../../../../components/alerts/Alerts";
import Table from "../../../../components/tables/Table";
import { authContext } from "../../../../context/AuthContext";
import ApiRest from "../../../../hooks/useRest";
import { fetchAnalyzeHomogenize } from "../../../../slices/laboratory/Index";
import { ConcatExpoLot } from "../../../../utils/ConcatExpoLot";

const Analyzed = ({
  data,

  setParams,
  params,
  dispatch,
  status,
}) => {
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_START_HOMOGENIZATION, URL_INDEX_HOMOGENIZATION } = values;

  const [select, setSelect] =  useState(null);
  const [loading, setLoading] = useState(false);
  /*********************************************** INITIAL STATE ***********************************************************/
  const campos = [
    {
      name: "Nº LOTE EXP",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "UNIDADES",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "KILOS DE MIEL",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-start align-items-center",
    },
    {
      name: "ACCIONES",
      order: {
        state: false,
      },
      class: "border-0 d-flex justify-content-center align-items-center",
    },
  ];
  const order = ["name", "fractionated_honey_drums_count", "fractionated_honey_drums_sum_net_weight"];
  const contents = data ? ConcatExpoLot(data.data) : [];
  const actions = [
    {
      title: "Ver Análisis",
      name: "ver_analisis",
      icon: "",
      id: "ver_analisis",

      class:
        "btn btn-secondary d-flex align-items-center justify-content-center",
      text: "Ver Análisis",
    },
  ];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = data
    ? data
    : {
        current_page: 0,
        first_page_url: 0,
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: "",
        to: 0,
        total: 0,
      };
  const pagination = {
    type: 1,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  /**********************************************************************************************************************/

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "page":
        {
          let params = {
            url: value,
            consolidated: 0,
            analyzed: 0,
            homogenized: 0,
            order_by: "id",
            ordering: "desc",
          };
          dispatch(fetchAnalyzeHomogenize(params));
        }
        break;
      case "ver_analisis":
        {
          return navigate("/dashboard/homogenization/analyze/drums/"+value.id+"/analyzed")
        }
        break;
      case "download-xls":
        {
        }
        break;
      case "download-print":
        {
          return true;
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "":
        {
        }
        break;
    }
  };

  console.log(contents);
  return (
    <div class="card-body">
      <Table
        print={true}
        campos={campos}
        order={order}
        contents={contents}
        actions={actions}
        pagination={pagination}
        onClick={onClick}
        onChange={onChange}
      />
    </div>
  );
};

export default Analyzed;
