import ReactTooltip from "react-tooltip";

const InputSearch = ({
  //Eventos
  onChange,
  //search
  label,
  name,
  type,
  value,
  placeholder,
  //search
  onClick,
  validate,
}) => {
  return (
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={(event) => onChange(event)}
      />
      <span class=" text-white" id="basic-addon2">
        <label className="btn btn-primary px-2" data-tip data-for="searchClick">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
          <button
            className="d-none"
            name={name}
            onClick={(event) => onClick(event)}
          ></button>
        </label>

        <ReactTooltip
          id="searchClick"
          data-bs-placement="top"
          className="custom-tooltip py-0 px-2"
          effect="solid"
          style={{ zIndex: 4 }}
        >
          Buscar
        </ReactTooltip>
      </span>
    </div>
  );
};

export default InputSearch;
