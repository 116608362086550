import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import BtnAccept from "../../../components/forms/BtnAccept";
import Input from "../../../components/forms/Input";
import { authContext } from "../../../context/AuthContext";
import Alerts from "../../../components/alerts/Alerts";
import ApiRest from "../../../hooks/useRest";

const ModalCreateSupplies = ({ setModal }) => {
  //Dispatch reducer
  const dispatch = useDispatch();
  //Global Const
  const context = useContext(authContext);
  const { values, forms, validations } = context;
  //context
  const {
    URL_SHOW_STOCK,
    URL_CREATE_STOCK,
    URL_INDEX_SUPPLY,
    URL_CREATE_SUPPLY,
  } = values;
  const { FORM_SUPPLY, FORM_STOCK } = forms;
  const { VALIDATION_FORM_SUPPLY, VALIDATION_FORM_STOCK } = validations;

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(JSON.parse(JSON.stringify(FORM_SUPPLY)));
  const [validation, setValidation] = useState({ ...VALIDATION_FORM_SUPPLY });

  const onClick = async (e, value) => {
    e.preventDefault();

    switch (e.target.name) {
      case "guardar":
        {
        }
        break;
      case "aceptar":
        {
          const detail = form.detalle[0].map((item) => ({
            model_name: item.model_name[0],
            sale_price: item.sale_price[0]
          }));

          const detailJSON = JSON.stringify(detail); // Convert the array to a JSON string

          const formData = new FormData();
          formData.append('supply_name', form.nombre[0]);
          formData.append('detail', detailJSON); // Append the JSON string

          const formData2 = {
            supply_name: form.nombre[0],
            detail: detail
          }


          const response = await ApiRest({
            url: 'supply/store',
            formData: formData2,
            setLoading: setLoading,
            method: "post",
          });
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Insumo creado correctamente.",
              code: "201",
              type: "success",
            });
          }

        }
        break;
      case "eliminar_modelo":
        {
          let setValue = form;
          let detalle = setValue.detalle[0].filter(
            (d, index) => index != value
          );

          setForm({
            ...form,
            detalle: [detalle, "required"],
          });
        }
        break;
      case "añadir_modelo":
        {
          let setValue = form;
          setValue.detalle[0].push({
            model_name: ["", "required"],
            sale_price: [0, "required"],
          });

          setForm({
            ...form,
            detalle: setValue.detalle,
          });
        }
        break;
      case "close": {
        setModal(false);
      } break;
    }
  };

  const onChange = (e, value) => {
    e.preventDefault();

    switch (e.target.name) {
      case "sale_price":
      case "model_name":
        {
          let setValue = form;
          setValue.detalle[0][e.target.id][e.target.name][0] = e.target.value;

          setForm({
            ...form,
            detalle: setValue.detalle,
          });
        } break;
      case "nombre":
        {
          let setValue = form;
          setValue[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: setValue[e.target.name],
          });
        } break;
    }
  };

  return (
    <div
      className="modal fade show bg-dark bg-opacity-25  modal-transition bg-modal-blur"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 4 }}>
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div className="modal-header p-5">
            <h1 className="modal-title" id="nueva-ordenModalLabel">
              Nuevo insumo
            </h1>
          </div>
          <div className="modal-body p-5">
            <form>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Insumo a dar de alta"}
                    name={"insumo"}
                    type={"text"}
                    value={"Insumo"}
                    validate={{ msg: "", validation: false }}
                    disabled={true}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Denominacion"}
                    name={"nombre"}
                    type={"text"}
                    value={form.nombre[0]}
                    validate={validation.nombre}
                  />
                </div>

                <div className="col-md-12">
                  <span className="fs-4 fw-bold">Detalle</span>
                  <hr />
                </div>

                {form.detalle[0].map((d, index) => (
                  <div className="col-md-12 mb-3">
                    <div className="row bg-white p-3 shadow-sm">
                      <div className="col-md-6">
                        <Input
                          uniqueKey={index}
                          onChange={onChange}
                          label={"Modelo"}
                          name={"model_name"}
                          type={"text"}
                          value={d.model_name[0]}
                          validate={{ msg: "", validation: false }}
                        />
                      </div>
                      <div className="col-md-4">
                        <Input
                          uniqueKey={index}
                          onChange={onChange}
                          label={"Precio de venta"}
                          name={"sale_price"}
                          type={"text"}
                          value={d.sale_price[0]}
                          validate={{ msg: "", validation: false }}
                        />
                      </div>
                      <div className="col-md-2">
                        <label className=" btn px-0 me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-plus-lg"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                            />
                          </svg>
                          <button
                            className="d-none"
                            name="añadir_modelo"
                            onClick={(event) => onClick(event)}
                          ></button>
                        </label>
                        {index > 0 ? (
                          <label className="btn px-0 text-danger">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              class="bi bi-trash3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                            </svg>
                            <button
                              className="d-none"
                              name="eliminar_modelo"
                              onClick={(event) => onClick(event, index)}
                            ></button>
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ))}

                <div className="col-md-12 d-flex justify-content-end align-items-end">
                  <div className="btn-group">
                    <BtnAccept
                      onClick={onClick}
                      name={"aceptar"}
                      value={"Aceptar"}
                      loading={loading}
                      loadingMsg={"Creando..."}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCreateSupplies;
