import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/loads/Spinner";
import ContentSwiper from "../../../components/sliders/Swiper";
import { authContext } from "../../../context/AuthContext";
import {
  fetchAnalyzeLot,
  fetchAnalyzeOrderPurchase,
  laboratoryState,
} from "../../../slices/laboratory/Index";
import { meState } from "../../../slices/users/Index";
import ContentAnalized from "../Contents/ContentAnalized";
import Notifications from "../notifications/Notifications";

const Laboratory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { values } = context;
  //context
  const { URL_INDEX_ANALIZE, URL_INDEX_LOT } = values;
  //Initial States
  const _user = useSelector(meState);
  const _lab = useSelector(laboratoryState);

  const orderPurchasePending = _lab.orderPurchasePending;
  const orderPurchaseAnalize = _lab.orderPurchaseAnalize;
  const lotPending = _lab.lotPending;
  const lotAnalyze = _lab.lotAnalyze;
  const me = _user.me;
  const rol_id = me.roles[0].id;

  /*********************************************** INITIAL STATE ***********************************************************/
  const [SwiperMediaQuery, setSwiperMediaQuery] = useState(4);

  //Sliders
  const swipper_oc = [
    {
      title: "O/C Pendientes",
      link: "/dashboard/orderpurchase/analyze/pending",
      lineLink: "Ver Todos",

      total: orderPurchasePending? orderPurchasePending.total : 0,
      icon: "/assets/images/icon-advertencia.svg",
      name: "redirect",
    },
    {
      title: "O/C Analizadas",
      link: "/dashboard/orderpurchase/analyze/analyze",
      lineLink: "Ver Todos",

      total: orderPurchaseAnalize? orderPurchaseAnalize.total : 0,
      icon: "/assets/images/icon-check.svg",
      name: "redirect",
    },
    {
      title: "O/C a Homogeneizar",
      link: "/dashboard/homogenization/homogenize",
      lineLink: "Ver Todos",

      total: 0,
      icon: "/assets/images/icon-check.svg",
      name: "redirect",
    },
  ];

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "redirect":
        {
          return navigate(value)
        }
        break;
    }
  };

  useEffect(() => {
    if (!orderPurchasePending) {
      let doParams = {
        url: URL_INDEX_ANALIZE,
        analyzed: 0,
        order_by: "id",
        ordering: "desc",
      };
      dispatch(fetchAnalyzeOrderPurchase(doParams));
    }
  }, []);

  useEffect(() => {
    if (!lotPending) {
      let params = {
        url: URL_INDEX_LOT,
        analyzed_status: 0,
        with_extra_analysis: 1,
        order_by: "id",
        ordering: "desc",
      };
      dispatch(fetchAnalyzeLot(params));
    }
  }, []);

  useEffect(() => {
    if (!lotAnalyze) {
      let params = {
        url: URL_INDEX_LOT,
        analyzed_status: 1,
        order_by: "id",
        ordering: "desc",
      };
      dispatch(fetchAnalyzeLot(params));
    }
  }, []);

  //console.log(orderPurchasePending);
  //console.log(lotPending);
  return (
    <div className="row">
      <div className="col-md-12 col-lg-8">
        <div className="overflow-hidden d-slider1">
          <ContentSwiper
            SwiperMediaQuery={SwiperMediaQuery}
            contents={swipper_oc}
            onClick={onClick}
          />
        </div>

        <div className="row">
          <div className="col-md-12">
            {orderPurchasePending && lotPending ? (
              <ContentAnalized
                lot={lotPending}
                orderPurchase={orderPurchasePending}
              />
            ) : (
              <Spinner />
            )}
          </div>
        </div>

      </div>

      {/* Notificactions */}

      <Notifications />
    </div>
  );
};

export default Laboratory;
