import { format } from "date-fns";

export const ConcatExpoLot = (lots) => {
  let result = [];

  lots.map((lot) => {
    lot = {
      ...lot,
      consolidation_date: format(new Date(lot.consolidation_date+" 00:00:00"), "dd/MM/yyyy").toString(),
      type: lot.homogenized == 0? "A Granel" : "Homogenea",
      honey_drums_count: lot.homogenized == 0? lot.honey_drums_count : lot.fractionated_honey_drums_count,
      honey_drums_net: lot.homogenized == 0? lot.honey_drums_sum_net_weight : lot.fractionated_honey_drums_sum_net_weight,
    }

    result.push(lot);
  })

  return result;
}