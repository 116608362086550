import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import Alerts from "../../../components/alerts/Alerts";
import BtnAccept from "../../../components/forms/BtnAccept";
import CheckBox from "../../../components/forms/CheckBox";
import Input from "../../../components/forms/Input";
import Select from "../../../components/forms/Select";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { productsState } from "../../../slices/products/Index";
import { fetchSupplies, suppliesState } from "../../../slices/supplies/Index";
import { ConcatProducts } from "../../../utils/ConcatProducts";
import { Validate } from "../../../utils/ValidateForms";

const ModalSupplies = ({ setModalSupplies, select, dispatch }) => {
  /*********************************************** INITIAL STATE ***********************************************************/
  const context = useContext(authContext);
  const { values, forms, validations } = context;
  const {
    URL_CREATE_EQUIVALENCE,
    URL_INDEX_EQUIVALENCE,
    URL_UPDATE_EQUIVALENCE,
  } = values;
  const { FORM_EQUIVALENCE } = forms;
  const { VALIDATION_EQUIVALENCE } = validations;

  const _supplies = useSelector(suppliesState);
  const _product = useSelector(productsState);
  const products = _product.products;
  const supplies = _supplies.supplies;

  const [form, setForm] = useState(
    JSON.parse(JSON.stringify(FORM_EQUIVALENCE))
  );

  const [validation, setValidation] = useState({ ...VALIDATION_EQUIVALENCE });
  const [loading, setLoading] = useState(false);
  /**********************************************************************************************************************/
  const { insumo, equivalencia } = { insumo: "", equivalencia: "" };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "mas_de_50ml":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = e.target.checked;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
      case "cantidad_producto":
      case "cantidad_insumo":
      case "producto":
      case "insumo":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
      default:
        break;
    }
  };

  const onClick = async (e, value) => {
    e.preventDefault();

    switch (e.target.name) {
      case "close":
        {
          setModalSupplies(false);
        }
        break;
      case "aceptar":
        {
          //Delete property
          const validation = Validate(form);
          setValidation(validation);
          //Validaciones
          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "201",
              type: "error",
            });
          }

          let formData = new FormData();
          formData.append("greater_than_50ml", form.mas_de_50ml[0] ? 1 : 0);
          formData.append("supply", form.insumo[0]);
          formData.append("product", form.producto[0]);
          formData.append("supply_quantity", form.cantidad_insumo[0]);
          formData.append("product_quantity", form.cantidad_producto[0]);

          const response = await ApiRest({
            url: URL_CREATE_EQUIVALENCE,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            Alerts({
              msg: "Alert",
              title: "Creando nueva equivalencia.",
              code: "201",
              type: "success",
            });

            //Reset
            setForm({ ...FORM_EQUIVALENCE });

            //Actualizar datos OC
            let params = {
              url: URL_INDEX_EQUIVALENCE,
            };
            if (supplies) dispatch(fetchSupplies(params));

            //cerrar modal
            return setModalSupplies(false);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "guardar":
        {
        }
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="modal fade show bg-dark bg-opacity-25  modal-transition bg-modal-blur"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 4 }}>
          <label className="btn btn-close">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              name="close"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
          <div className="modal-header p-5">
            <h1 className="modal-title" id="nueva-ordenModalLabel">
              Nuevo equivalencia
            </h1>
          </div>
          <div className="modal-body p-5">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <Select
                    onChange={onChange}
                    label="Producto a recibir"
                    name="producto"
                    type=""
                    defaultValue={form.producto[0]}
                    value={ConcatProducts(products).filter(
                      (item) => item.id == 1
                    )}
                    showIndex="name"
                    sendIndex="name"
                    validate={""}
                    icon={"/assets/images/icon-arrow-input.svg"}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Insumo a canjear"}
                    name={"insumo"}
                    type={"text"}
                    value={form.insumo[0]}
                    validate={validation.insumo}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Cantidad recibida"}
                    name={"cantidad_producto"}
                    type={"number"}
                    value={form.cantidad_producto[0]}
                    validate={validation.cantidad_producto}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="mb-1">Color</label>
                  <CheckBox
                    onChange={onChange}
                    label={"<50ml"}
                    name={"mas_de_50ml"}
                    type={"checkbox"}
                    value={form.mas_de_50ml[0]}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <Input
                    onChange={onChange}
                    label={"Cantidad entregada"}
                    name={"cantidad_insumo"}
                    type={"number"}
                    value={form.cantidad_insumo[0]}
                    validate={validation.cantidad_insumo}
                  />
                </div>

                <div className="col-md-12 d-flex justify-content-end align-items-end">
                  <div className="btn-group">
                    <BtnAccept
                      onClick={onClick}
                      name={"aceptar"}
                      value={"Aceptar"}
                      loading={loading}
                      loadingMsg={"Creando..."}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSupplies;
