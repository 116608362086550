import { useDispatch } from "react-redux";
import Alerts from "../components/alerts/Alerts"
import clientAxios from "../config/ClientAxios";
import { GlobalConst } from "../consts/UrlConst";
import { setLogout } from "../slices/users/Index";

const {URL_LOGOUT} = GlobalConst

export const Logout = async (navigate, dispatch) => {

  const response = await clientAxios.get(URL_LOGOUT);
  localStorage.clear();
  dispatch(setLogout());
  
  Alerts({
    msg: "OK",
    title: "Cerrando sesión.",
    code: "201",
    type: "success",
  });

  return navigate('/login') 
}