import { format } from "date-fns";
import { useState } from "react";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alerts from "../../../components/alerts/Alerts";
import Spinner from "../../../components/loads/Spinner";
import SpinnerScreen from "../../../components/loads/SpinnerScreen";
import Table from "../../../components/tables/Table";
import clientAxios from "../../../config/ClientAxios";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import {
  fetchOrderWithdrawal,
  orderState,
} from "../../../slices/orderwithdrawal/Index";
import { ConcatWithdrawalOrder, ConcatWithdrawalOrderFilter } from "../../../utils/ConcatWithdrawalOrder";
import ModalOrderWithdrawalDetails from "../../logistics/modals/ModalOrderWithdrawalDetails";
import ModalOrderWithdrawalDrumsDetails from "../../orderWithdrawal/modals/ModalOrderWithdrawalDrumsDetails";

const ContentOrder = ({ data }) => {
  //Dispatch reducer
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { values, forms, validations } = context;
  //Initial States
  const {
    URL_CREATE_DETAILS_WITHDRAWAL,
    URL_INDEX_WITHDRAWAL,
    URL_START_WITHDRAWAL,
    URL_DELIVERED,
    URL_SHOW_WITHDRAWAL,
  } = values;
  const { FORM_ORDER_DETAILS_DRUM, FORM_ORDER_DETAILS_WAX } = forms;

  const _withdrawal_order = useSelector(orderState);

  const withdrawal_order_submitted =
    _withdrawal_order.withdrawal_order_submitted;
  const withdrawal_order_actives = _withdrawal_order.withdrawal_order_actives;
  const withdrawal_order_pending = _withdrawal_order.withdrawal_order_pending;
  const withdrawal_order = _withdrawal_order.withdrawal_order;
  //const withdrawal_details = _withdrawal_order.withdrawal_details;

  const [actionSelected, setActionSelected] = useState({
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-geo" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/></svg>',
    name: "seleccionar",
    class: "btn p-0 bg-transparent border-0 text-miel",
    selected: [],
  });

  const [select, setSelect] = useState(null);
  const [withdrawal_details, setWithdrawalDetails] = useState(null);

  const [modalOrderWithdrawalDetails, setModalOrderWithdrawalDetails] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const { title, btn_ruta, status } = data;

  const [params, setParams] = useState({
    search: "",
  });

  //Tabla
  const campos = [
    {
      name: "SELECCIONAR",
      order: false,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "O/R",
      order: false,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "PROVEEDOR",
      order: false,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "DESTINO",
      order: false,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "ACCIONES",
      order: false,
      class: status == 1? "d-none" : "border-0 d-flex justify-content-center align-items-center",
    },
  ];

  const order = ["or_name", "provider_name", "destiny"];
  const contents =
    status === 1
      ? ConcatWithdrawalOrder(
          withdrawal_order_pending ? withdrawal_order_pending.data : []
        )
      : status === 3
      ? ConcatWithdrawalOrder(
        withdrawal_order_actives ? withdrawal_order_actives.data : [])
      : [];
  const actions = status == 1 ? []
  : [
    {
        value: "4",
        property: "state_id",

        name: "retirar",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#FFFFFF" class="bi bi-truck" viewBox="0 0 16 16"><path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/></svg>',
        class: "btn btn-primary border-0 d-flex align-items-center justify-content-center",
        text: "Retirar"      
      },
      {
        value: "3",
        property: "state_id",

        name: "llegada",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16"><path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/></svg>',
        class: "btn btn-secondary border-0 d-flex align-items-center justify-content-center",
        text: "Llegada"      
      }
  ];

  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = withdrawal_order
    ? withdrawal_order
    : {
        current_page: 0,
        first_page_url: 0,
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: "",
        to: 0,
        total: 0,
      };

  const pagination = {
    type: null,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "ver-ruta":
        {
        }
        break;
      case "crear-ruta":
        {
          let formData = new FormData();
          formData.append("_method", "PUT");

          actionSelected.selected.map((select, index) => {
            formData.append("withdrawal_orders[" + index + "]", select);
          });

          //
          const response = await ApiRest({
            url: URL_START_WITHDRAWAL,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            let params = {
              url: URL_INDEX_WITHDRAWAL,
              ["state_id[0]"]: 1,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchOrderWithdrawal(params));

            params = {
              url: URL_INDEX_WITHDRAWAL,
              ["state_id[0]"]: 3,
              ["state_id[1]"]: 4,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchOrderWithdrawal(params));

            return Alerts({
              msg: "Alert",
              title: "Ruta generada",
              code: "201",
              type: "success",
            });
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "llegada":
        {
          //create form
          let formData = new FormData();
          formData.append("withdrawal_order_id", value.id);

          //
          const response = await ApiRest({
            url: URL_DELIVERED,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            
            let params = {
              url: URL_INDEX_WITHDRAWAL,
              state_id: null,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchOrderWithdrawal(params));

            params = {
              url: URL_INDEX_WITHDRAWAL,
              state_id: 1,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchOrderWithdrawal(params));

            params = {
              url: URL_INDEX_WITHDRAWAL,
              ["state_id[0]"]: 3,
              ["state_id[1]"]: 4,
              order_by: "id",
              ordering: "desc",
            };
            dispatch(fetchOrderWithdrawal(params));

            return Alerts({
              msg: "Alert",
              title: format(new Date(), "H:mm:ss").toString() + "hs",
              code: "201",
              type: "arrival",
            });
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "retirar":
        {
          const fetch = async () => {
            setLoading(true);

            let params = {
              url: URL_SHOW_WITHDRAWAL,
              withdrawal_order_id: value.id,
            };

            const response = await clientAxios.get(URL_SHOW_WITHDRAWAL, { params }).then((result) => {
              setLoading(false);
              setWithdrawalDetails(result.data);
            });


          };

          setSelect(value.id);
          setModalOrderWithdrawalDetails(true);
          
          fetch();
        }
        break;
      case "seleccionar":
        {
          let array = actionSelected.selected;

          if (array.indexOf(value.content.id) >= 0) {
            delete array[array.indexOf(value.content.id)];
          } else {
            array.push(value.content.id);
          }

          setActionSelected({
            ...actionSelected,
            selected: array,
          });
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":
        {
          setParams({
            ...params,
            search: e.target.value,
          });

          if (e.target.value.length > params.search.length) {
            return dispatch(
              fetchOrderWithdrawal({
                url: URL_INDEX_WITHDRAWAL,
                search: e.target.value,
                ["state_id[0]"]: status == 3? 3 : status,
                ["state_id[1]"]: status == 3? 4 : status,
                order_by: "id",
                ordering: "desc",
                amount: 20
              })
            );
          }

          if (e.target.value.trim() == "") {
            return dispatch(
              fetchOrderWithdrawal({
                url: URL_INDEX_WITHDRAWAL,
                ["state_id[0]"]: status == 3? 3 : status,
                ["state_id[1]"]: status == 3? 4 : status,
                order_by: "id",
                ordering: "desc",
                amount: 20
              })
            );
          }
        }
        break;
    }
  };

  useEffect(() => {
    if (
      !withdrawal_order ||
      !withdrawal_order_actives ||
      !withdrawal_order_pending ||
      !withdrawal_order_submitted
    ) {
      let params = {
        url: URL_INDEX_WITHDRAWAL,
        ["state_id[0]"]: status == 3? 3 : status,
        ["state_id[1]"]: status == 3? 4 : status,
        order_by: "id",
        ordering: "desc",
        amount: 20
      };
      dispatch(fetchOrderWithdrawal(params));
    }
  }, [status]);
  return (
    <div>
      <div class="col-md-12" id="search">
        <div
          class="d-flex justify-content-between align-items-center mb-3"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          <h2 class="mb-0">
            Estado de retiro en{" "}
            <span style={{ color: title.color }}>{title.text}</span>
          </h2>
          <label
            class={
              actionSelected.selected.length > 0
                ? "btn btn-primary"
                : "btn btn-primary disabled"
            }
            data-bs-toggle="modal"
            data-bs-target="#modal-map"
          >
            <svg
              class="me-2"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2425_45417)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.7713 0.641029C19.8428 0.699699 19.9005 0.773527 19.94 0.857185C19.9796 0.940842 20.0001 1.03224 20 1.12478V18.6248C19.9999 18.7692 19.9498 18.9092 19.8582 19.0208C19.7666 19.1325 19.6391 19.209 19.4975 19.2373L13.2475 20.4873C13.1666 20.5034 13.0834 20.5034 13.0025 20.4873L6.875 19.2623L0.7475 20.4873C0.656861 20.5054 0.563332 20.5032 0.473651 20.4808C0.38397 20.4584 0.30037 20.4164 0.228874 20.3578C0.157378 20.2993 0.0997667 20.2255 0.0601897 20.142C0.0206127 20.0585 5.58159e-05 19.9672 0 19.8748L0 2.37478C8.72276e-05 2.23035 0.0501951 2.0904 0.141804 1.97874C0.233413 1.86708 0.360869 1.79059 0.5025 1.76228L6.7525 0.512279C6.83337 0.496115 6.91663 0.496115 6.9975 0.512279L13.125 1.73728L19.2525 0.512279C19.3431 0.494049 19.4366 0.496141 19.5263 0.518404C19.616 0.540667 19.6997 0.582548 19.7713 0.641029V0.641029ZM12.5 2.88728L7.5 1.88728V18.1123L12.5 19.1123V2.88728ZM13.75 19.1123L18.75 18.1123V1.88728L13.75 2.88728V19.1123ZM6.25 18.1123V1.88728L1.25 2.88728V19.1123L6.25 18.1123Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2425_45417">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            {btn_ruta.text}
            <button
              className="d-none"
              name={btn_ruta.name}
              onClick={(event) => onClick(event)}
            ></button>
          </label>
        </div>
        <div
          class="card card-body rounded-pill d-flex justify-content-around text-center py-3"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          <div class="input-group search-input">
            <input
              type="search"
              class="form-control"
              placeholder="Buscar órden de retiro"
              name="search"
              onChange={(event) => onChange(event)}
            />
            <svg
              class="ms-3"
              width="20px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.7669"
                cy="11.7666"
                r="8.98856"
                stroke="#F16A1B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></circle>
              <path
                d="M18.0186 18.4851L21.5426 22"
                stroke="#F16A1B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-12">
        <div
          class="overflow-hidden card"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          <div class="p-0 card-body">
            {withdrawal_order ||
            withdrawal_order_pending ||
            withdrawal_order_actives ? (
              <Table
                hidden_Filters={true}
                campos={campos}
                order={order}
                contents={contents}
                actions={actions}
                actionSelect={actionSelected}
                pagination={pagination}
                onClick={onClick}
                onChange={onChange}
              />
            ) : (
              <div className="py-4">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>

      {
        loading
        ? <SpinnerScreen />
        : ""
      }

      {/* Modal */}

      {
      /*modalOrderWithdrawalDetails ? (
        <ModalOrderWithdrawalDetails
          dispatch={dispatch}
          setModal={setModalOrderWithdrawalDetails}
          order={select}
          withdrawal_details={withdrawal_details}
        />
      ) : (
        ""
      )*/}

      {modalOrderWithdrawalDetails && withdrawal_details ? (
        <ModalOrderWithdrawalDrumsDetails
          dispatch={dispatch}
          setModal={setModalOrderWithdrawalDetails}
          id={select}
          withdrawal_details={withdrawal_details}
          redirect={true}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ContentOrder;
