

const NavAdministrator = [
  {
    url: "/dashboard/home",
    text: "Inicio",
    dropdown: false,
  },
  /* Comprador */
  {
    url: "",
    text: "Órden de compra",
    dropdown: true,
    subMenu: [
      {
        url: "/dashboard/orderpurchase/pending",
        text: "Pendiente",
        dropdown: false,
        icon: true,
      },
      {
        url: "/dashboard/orderpurchase/submitted",
        text: "Entregados",
        dropdown: false,
        icon: true,
      },
    ],
  },
  /* Logistica */
  {
    url: "",
    text: "Órden de retiro",
    dropdown: true,
    subMenu: [
      {
        url: "/dashboard/orderwithdrawal/pending",
        text: "Pendiente",
        dropdown: false,
        icon: true,
      },
      {
        url: "/dashboard/orderwithdrawal/actives",
        text: "Activos",
        dropdown: false,
        icon: true,
      },
      {
        url: "/dashboard/orderwithdrawal/submitted",
        text: "Entregados",
        dropdown: false,
        icon: true,
      },
    ],
  },
  /* Controll */ 
  {
    url: "/dashboard/providers",
    text: "Proveedores",
    dropdown: false,
  },
  {
    url: "/dashboard/supplies",
    text: "Insumos",
    dropdown: false,
  },
  {
    url: "",
    text: "Usuarios",
    dropdown: true,
    subMenu: [
      {
        url: "/dashboard/users/logistica",
        text: "Transportistas",
        dropdown: false,
        icon: true,
      },
      {
        url: "/dashboard/users/compras",
        text: "Compradores",
        dropdown: false,
        icon: true,
      },
      {
        url: "/dashboard/users/deposito",
        text: "Encargado deposito",
        dropdown: false,
        icon: true,
      },
      {
        url: "/dashboard/users/exportacion",
        text: "Encargado exportacion",
        dropdown: false,
        icon: true,
      },
      {
        url: "/dashboard/users/administracion",
        text: "Administradores",
        dropdown: false,
        icon: true,
      },
    ],
  },
  {
    url: "/dashboard/vehicles",
    text: "Vehiculos",
    dropdown: false,
  },
  {
    url: "/dashboard/wareHouses",
    text: "Destinos",
    dropdown: false,
  },
  //Deposito
  {
    url: "/dashboard/orderentry",
    text: "Ingresos Deposito",
    dropdown: true,
    subMenu: [
      {
        url: "/dashboard/orderentry/pending",
        text: "Pendiente",
        dropdown: false,
        icon: true,
      },
      {
        url: "/dashboard/orderentry/submitted",
        text: "Ingresados",
        dropdown: false,
        icon: true,
      },
    ],
  },
];



export const RolNavLinks = (menu, rol) => {
  const NavLogistic = [
    {
      url: "/dashboard/home",
      text: "Inicio",
      dropdown: false,
    },
    {
      url: null,
      text: "Logistica",
      dropdown: false,

      name: "changeMenu",
      value: 5,
      rol: 3,

      hidden: rol == 5? false : true,
    },
    {
      url: "",
      text: "Órden de retiro",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/orderwithdrawal/pending",
          text: "Pendiente",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/orderwithdrawal/actives",
          text: "Activos",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/orderwithdrawal/submitted",
          text: "Entregados",
          dropdown: false,
          icon: true,
        },
      ],
    },
    /*
    {
      url: "/dashboard/providers",
      text: "Proveedores",
      dropdown: false,
    },
    {
      url: "/dashboard/supplies",
      text: "Insumos",
      dropdown: false,
    },
    */
  ];
  
  const NavBuyer = [
    {
      url: "/dashboard/home",
      text: "Inicio",
      dropdown: false,
    },
    {
      url: null,
      text: "Compras",
      dropdown: false,

      name: "changeMenu",
      value: 5,
      rol: 1,

      hidden: rol == 5? false : true,
    },
    {
      url: "",
      text: "Órden de compra",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/orderpurchase/pending",
          text: "Pendiente",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/orderpurchase/submitted",
          text: "Entregados",
          dropdown: false,
          icon: true,
        },
      ],
    },
    {
      url: "/dashboard/providers",
      text: "Proveedores",
      dropdown: false,
    },
    /*
    {
      url: "/dashboard/order",
      text: "Orden de pedido",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/order/pending",
          text: "Pendiente",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/order/submitted",
          text: "Entregadas",
          dropdown: false,
          icon: true,
        },
      ],
    },
    */
    {
      url: "/dashboard/supplies",
      text: "Insumos",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/supplies/stock",
          text: "Stock",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/supplies/equivalencies",
          text: "Equivalencias",
          dropdown: false,
          icon: true,
        },
      ],
      
      hidden: rol == 5? false : true,
    },
    {
      url: "/dashboard/vehicles",
      text: "Vehiculos",
      dropdown: false,

      hidden: rol == 5? false : true,
    },
    {
      url: "/dashboard/wareHouses",
      text: "Destinos",
      dropdown: false,

      
      hidden: rol == 5? false : true,
    },
  ];
  
  const NavStorage = [
    {
      url: "/dashboard/home",
      text: "Inicio",
      dropdown: false,
    },
    {
      url: null,
      text: "Depósito",
      dropdown: false,

      name: "changeMenu",
      value: 5,
      rol: 2,

      hidden: rol == 5? false : true,
    },
    {
      url: "/dashboard/orderentry",
      text: "Ingresos Deposito",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/orderentry/pending",
          text: "Pendiente",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/orderentry/submitted",
          text: "Ingresados",
          dropdown: false,
          icon: true,
        },
      ],
    },
    {
      url: "/dashboard/requestorder",
      text: "Egresos",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/requestorder/pending",
          text: "Pendientes",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/requestorder/graduated",
          text: "Egresados",
          dropdown: false,
          icon: true,
        },
      ],
    },
    {
      url: "/dashboard/lot/homogenization",
      text: "Homogeneizados",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/homogenization/ready",
          text: "Pendientes",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/homogenization/fractional",
          text: "Fraccionado",
          dropdown: false,
          icon: true,
        },
      ],
    },
  ]

  const NavExport = [
    {
      url: "/dashboard/home",
      text: "Inicio",
      dropdown: false,
    },
    {
      url: null,
      text: "Exportacion",
      dropdown: false,

      name: "changeMenu",
      value: 5,
      rol: 4,

      hidden: rol == 5? false : true,
    },
    {
      url: "",
      text: "Contratos",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/contract/actives",
          text: "Activos",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/contract/close",
          text: "Cerrados",
          dropdown: false,
          icon: true,
        },
      ],
    },
    {
      url: "/dashboard/lot/:status",
      text: "Lotes Totales",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/lot/pending",
          text: "Pendientes",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/lot/consolidated",
          text: "Consolidados",
          dropdown: false,
          icon: true,
        },
      ],
    },
    {
      url: "",
      text: "Homogeneizados",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/homogenization/drums",
          text: "Pendientes",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/lot/homogenization/pending",
          text: "Armados",
          dropdown: false,
          icon: true,
        },
        /*
        {
          url: "/dashboard/homogenization/merms",
          text: "Mermas",
          dropdown: false,
          icon: true,
        },
        */
        {
          url: "/dashboard/lot/homogenization/consolidated",
          text: "Consolidados",
          dropdown: false,
          icon: true,
        },
      ],
    },
    {
      url: "/dashboard/clients",
      text: "Clientes",
      dropdown: false,

      name: "changeMenu",
      value: 5,
      rol: 6,

      hidden: rol == 5? false : true,
    },
  ]

  const NavLaboratory = [
    {
      url: "/dashboard/home",
      text: "Inicio",
      dropdown: false,
    },
    {
      url: null,
      text: "Laboratorio",
      dropdown: false,

      name: "changeMenu",
      value: 5,
      rol: 6,

      hidden: rol == 5? false : true,
    },
    {
      url: "/dashboard/orderpurchase/analyze/",
      text: "Órden de compras",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/orderpurchase/analyze/pending",
          text: "Pendientes",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/orderpurchase/analyze/analyze",
          text: "Analizadas",
          dropdown: false,
          icon: true,
        },
      ],
    },
    {
      url: "/dashboard/lot/analyze/",
      text: "Lotes",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/lot/analyze/pending",
          text: "Pendientes",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/lot/analyze/analyze",
          text: "Analizadas",
          dropdown: false,
          icon: true,
        },
      ],
    },
    {
      url: "/dashboard/homogenization/analyze/",
      text: "Homogeneización",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/homogenization/analyze/homogenize",
          text: "A Homogeneizar",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/homogenization/analyze/analyze",
          text: "A Analizar",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/homogenization/analyze/analyzed",
          text: "Analizado",
          dropdown: false,
          icon: true,
        },
      ],
    },
  ]
  
  const NavAdministrator = [
    {
      url: null,
      text: "Compras",
      dropdown: false,

      name: "changeMenu",
      value: 1,
    },
    {
      url: null,
      text: "Logistica",
      dropdown: false,

      name: "changeMenu",
      value: 3,
    },
    {
      url: null,
      text: "Depósito",
      dropdown: false,

      name: "changeMenu",
      value: 2,
    },
    {
      url: null,
      text: "Laboratorio",
      dropdown: false,

      name: "changeMenu",
      value: 6,
    },
    {
      url: null,
      text: "Exportacion",
      dropdown: false,

      name: "changeMenu",
      value: 4,
    },
    {
      url: "/dashboard/export/lot/pending",
      text: "Exportaciones",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/export/lot/pending",
          text: "Pendientes",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/export/lot/realized",
          text: "Realizados",
          dropdown: false,
          icon: true,
        },
      ],
    },
    {
      url: "",
      text: "Usuarios",
      dropdown: true,
      subMenu: [
        {
          url: "/dashboard/users/logistica",
          text: "Transportistas",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/users/compras",
          text: "Compradores",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/users/deposito",
          text: "Encargado deposito",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/users/exportacion",
          text: "Encargado exportacion",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/users/administracion",
          text: "Administradores",
          dropdown: false,
          icon: true,
        },
        {
          url: "/dashboard/users/laboratorio",
          text: "Laboratorio",
          dropdown: false,
          icon: true,
        },
      ],

      
      hidden: rol == 5? false : true,
    },
  ]


  switch (menu) {
    //Buyer
    case 1: {
      return NavBuyer;
    }break;
    //storage
    case 2: {
      return NavStorage;
    }break;
    //logistic
    case 3: {
      return NavLogistic;
    }break;
    //Export
    case 4: {
      return NavExport;
    }break;
    //Administrator
    case 5: {
      return NavAdministrator;
    }break;
    //Laboratory
    case 6: {
      return NavLaboratory;
    }break;
  }
  return  [];
};
