export const ConcatClients = (clients) => {
  let result = [];

  clients.map((client) => {
    client = {
      ...client,
      country: client.city.province.country.name
    }

    result.push(client);
  })

  return result
}