const Select = ({
  onChange,
  label,
  uniqueKey,
  classlabel,
  name,
  type,
  defaultValue,
  value,
  showIndex,
  sendIndex,
  validate,
  svg,
  disabled
}) => {
  let def = value.filter(item => parseInt(item.id) == parseInt(defaultValue))[0];
  return (
    <div className="d-flex flex-column w-100">
      <label className={label == "" ? "d-none" : "form-label"}>{label}</label>
      <div className="input-group">
        {
          
          svg 
          ? <div className="input-group-text" id="basic-addon1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-90deg-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.854 14.854a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V3.5A2.5 2.5 0 0 1 6.5 1h8a.5.5 0 0 1 0 1h-8A1.5 1.5 0 0 0 5 3.5v9.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4z"/></svg>
          </div>
          : ""
        }
        <select
          className="form-select"
          id={uniqueKey}
          name={name}
          onChange={(event) => onChange(event, uniqueKey)}
          value={defaultValue}
          disabled={disabled}
        >
          <option selected="">{def? def.name : ""}</option>
          {value.map((item) => (
            <option value={item[sendIndex]}>{item[showIndex]}</option>
          ))}

          {validate.validate ? (
            <div className="text-danger">{validate.msg}</div>
          ) : (
            ""
          )}
        </select>
      </div>
    </div>
  );
};

export default Select;
