export const SetFormDetails = (details) => {
  //console.log(details);

  let result = [];
  let count_index = 0;

  details.map((d) => {
      d.honey_drums.map((drums, index) => {
        console.log(drums)

        let form = {
          key: [count_index, ""],
          //Tambor
          producto_nombre: ["Tambor", ""],
          producto_id: [1, ""], //product_id
          tambor_entregado: [false, ""], //deliveri
          tipo_tambor: ["", ""],
          codigo_senasa: ["", ""], //senasa_code
          codigo_geomiel: ["", ""], //geomiel_code
          tara: ["", ""], //tare
          bruto: ["", ""], //gross_weight
          neto: ["", ""], //total_weight
      
          tambor_id: [drums.id, ""], //honey_drum_id
          observacion: ["", ""], //comments
          imagenes: [[], ""], //images
          imagenes_url: [[], ""], //images
          cantidad_archivos: [0+" Imagenes", ""],
      
          purchase_order: [drums.purchase_order_id, ""]
          };

          
        count_index = count_index + 1;
      
          result.push(form);
      })
  })

  



  return result;
};
