import { format } from "date-fns";
import { Fill } from "./Fill";

export const ConcatAnlizeOC = (orders) => {
  let result = [];

  orders.map((order) => {
    /* Result */
    order = {
      ...order,

      date: format(new Date(order.date), "dd/MM/yyyy").toString(),

      oc_name:
        order.purchase_order_code 
        ? "OC " + order.purchase_order_code
        : String(order.id).length >= 4
          ? "OC " + order.id
          : "OC " + Fill(order.id, 4),

      provider_name: String(order.provider.id).length > 4 ? order.provider.id+" - "+order.provider.business_name : Fill(order.provider.id, 4)+" - "+order.provider.business_name,
      drums_amount: order.honey_drums_count,
    };

    result.push(order);
  });

  return result;
}